import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { Accordion } from 'primeng/accordion';
import { ChatService } from 'src/app/shared/services/chat.service';

import * as Mark from 'mark.js'
import * as Quill from 'quill'
import { ConfirmationService, MessageService } from 'primeng/api';

import * as Editor from '../../../assets/ckeditor/build/ckeditor';
@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(400)]),
      transition(':leave', [animate(200, style({ height: 0 }))]),
    ]),
  ]
})
export class CourseListComponent implements OnInit, OnChanges, OnDestroy {
  demo = localStorage.getItem('Id')
  sampleText = 'Welcome to the My Notebook feature of the QBank. With this tool, you can create a customizable, electronic notebook full of high-yield information to review at any time. <br> Here are some helpful tips to get started: <br><ul> <li> Accessing My Notebook: The My Notebook feature is available on the main menu bar of the QBank, and within the practice test interface.</li><br><li> Adding Content to My Notebook: Copy text and images from practice tests directly into your Notebook in just a few easy clicks.</li><br><li> Organizing My Notebook: Click-and-drag the pages within the left navigation panel to adjust the order and easily move pages between sections.</li> </ul><br> <span>We hope you enjoy using My Notebook as you prepare for your exams. Happy studying!</span>'
  @Input() notebookText = 'Welcome to the My Notebook feature of the QBank. With this tool, you can create a customizable, electronic notebook full of high-yield information to review at any time. <br> Here are some helpful tips to get started: <br><ul> <li> Accessing My Notebook: The My Notebook feature is available on the main menu bar of the QBank, and within the practice test interface.</li><br><li> Adding Content to My Notebook: Copy text and images from practice tests directly into your Notebook in just a few easy clicks.</li><br><li> Organizing My Notebook: Click-and-drag the pages within the left navigation panel to adjust the order and easily move pages between sections.</li> </ul><br> <span>We hope you enjoy using My Notebook as you prepare for your exams. Happy studying!</span>';
  @Input() quizEdit = false
  @Input() html = ""
  searchString = "";
  showSave = false;
  qbanks: any = [];
  items = [{label: 'Notes'}]
  isEdit = false;
  originalNotebook: any = []

  heading = 'Getting Started'
  newNotebook = false;
  
  currentDate = new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)
  updatedDate = new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)

  inputValue: string = '';
  inputStates: string[] = [];
  undoStats: number = 0;

  displayDelete = false
  deleteIndexes = []

  @ViewChild('accordion') accordion: Accordion;

  displaySave = false
  changeState = false;

  modifiedIndexes: any = [-1, -1, -1, -1]

  noteUpdated = false

  indexes: any = [-1,-1,-1,-1]
  textPosition = 0
  originalText = ""

  tempDetails: any = []
  tempText = ""
  spacer = false;

  sidebar = false

  searcher = false
  
  table: any;
  quill: any;

  checker = false

  selectedContent = ""
  endContent = ""

  ckEditorNotebook: any

  notebookEditor: any;

  editorPosition: any;

  isDemo = false;
  confirmDeletion = '';
  loader = false;
  
  isSwap = false;
  isSave = false;

  constructor(private chatServie: ChatService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    if (window.innerWidth <= 750) {
      document.getElementById('list').style.display = 'none'
      document.getElementById('data').style.display = 'flex'
    }
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function(e) {
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
        return true;
      }
    }
    if (localStorage.getItem('Id') == '-1') {
      this.isDemo = true
    }
    if (this.isDemo) {
      let notebook = {
        'UserID': '-1',
        'LatestNote': [0, -1, -1, -1],
        'Details': [
          {
            'qbankid': 1,
            'name': 'Demo Notes',
            'description': 'Free trial is for testing, saving text is available only for premium users',
            'createdDate': 'Jun 22, 2023',
            'updatedDate': 'Jun 22, 2023',
            'enable': true,
            'open': false
          }
        ]
      }
      this.qbanks = notebook.Details;
      this.originalNotebook = notebook.Details

      this.newNotebook = true
      this.heading = this.qbanks[0].name
      this.notebookText = this.qbanks[0].description
      this.indexes = notebook.LatestNote;
      if (this.indexes[0] != -1) {
        this.heading = this.qbanks[this.indexes[0]]?.name
        this.notebookText = this.qbanks[this.indexes[0]]?.description
        this.currentDate = this.qbanks[this.indexes[0]]?.createdDate
        this.updatedDate = this.qbanks[this.indexes[0]]?.updatedDate
        this.modifiedIndexes[0] = this.qbanks[this.indexes[0]]?.qbankid
      }

      this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))

      setTimeout(() => {
        if (this.indexes[0] != -1 && this.indexes[1] == -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
          document.getElementById('qbankheader'+this.indexes[0])?.classList.add('active')
          this.items = [{label: this.qbanks[this.indexes[0]].name}]
        }
      }, 200)
    } else {
      this.getUserNotebook()
    }
  }

  confirm(qbank, subject, system, topic, qbabkIndex, subjectIndex, systemIndex, topicIndex, description, createdDate, updatedDate, clickable) {
    this.confirmationService.confirm({
        message: 'Do you want to save this note?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.changeState = false
          this.saveNotebooktoDB()
          setTimeout(() => {
            this.sendItem(qbank, subject, system, topic, qbabkIndex, subjectIndex, systemIndex, topicIndex, description, createdDate, updatedDate, clickable)
          }, 1000)
        },
        reject: () => {
          this.changeState = false
          if(this.indexes[1] == -1){
            this.qbanks.splice(this.indexes[0], 1)
          }
          else if(this.indexes[2] == -1){
            this.qbanks[this.indexes[0]].subjects.splice(this.indexes[1], 1)
          }
          else if(this.deleteIndexes[3] == -1){
            this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system.splice(this.indexes[2], 1)
          }
          else{
            this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics.splice(this.indexes[3], 1)
          }
          this.showSave = false
          this.isEdit = false
          this.ckEditorNotebook.destroy()
          document.getElementById('notebookdialog').style.border = 'none'
          this.sendItem(qbank, subject, system, topic, qbabkIndex, subjectIndex, systemIndex, topicIndex, description, createdDate, updatedDate, clickable)
        }
    });
  }

  insertTable() {
    this.table.insertTable(2, 2); 
  }

  ngOnDestroy(): void {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function(e) {
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
        return false;
      }
    }
  }

  swapIndexes(array: any, firstIndex: any, secondIndex: any) {
    let item = array.splice(firstIndex, 1)[0];
    array.splice(secondIndex, 0, item);
    return array;
  }

  ngOnChanges(values: any) {
    if (values.quizEdit) {
      if (values.quizEdit.currentValue) {
        this.quizEdit = values.quizEdit.currentValue
      }
    }
  }

  undo() {
    this.checker = true
    if (this.undoStats < (this.inputStates.length - 1)) {
      this.undoStats++;
      if (this.undoStats != this.inputStates.length) {
        this.notebookText = this.inputStates[this.inputStates.length - (this.undoStats + 1)];
        this.quill.root.innerHTML = this.notebookText
      }
    }
  }

  redo() {
    this.checker = true
    if (this.undoStats > 0) this.undoStats--;
    if (this.undoStats >= 0) {
      this.notebookText = this.inputStates[this.inputStates.length - (this.undoStats + 1)];
      this.quill.root.innerHTML = this.notebookText
    }
  }

  highlight(qbank, subject, system, topic, marked) {
    setTimeout(() => {
      let context: any;
      if (qbank != -1 && subject == -1 && system == -1 && topic == -1) {
        context = document.querySelector(".text"+qbank)
      }
      if (qbank != -1 && subject != -1 && system == -1 && topic == -1) {
        context = document.querySelector(".text"+qbank+subject)
      }
      if (qbank != -1 && subject != -1 && system != -1 && topic == -1) {
        context = document.querySelector(".text"+qbank+subject+system)
      }
      if (qbank != -1 && subject != -1 && system != -1 && topic != -1) {
        context = document.querySelector(".text"+qbank+subject+system+topic)
      }
      var instance = new Mark(context);
      var options = {
        "className": 'search'
      }
      instance.unmark(options)
      if (marked) {
        instance.mark(this.searchString, options)
      }
    }, 100)
  }

  highlightNotebook() {
    setTimeout(() => {
      let context = document.querySelector(".notebookText")
      var instance = new Mark(context);
      var options = {
        "className": 'search'
      }
      instance.unmark(options)
      instance.mark(this.searchString, options)
    }, 100)
  }

  onSearchChange(event: any) {
    if (this.searchString != "") {
      this.searcher = true
      for (let i = 0; i < this.qbanks.length; i++) {
        if (this.qbanks[i].name.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].description.toLowerCase().includes(this.searchString.toLowerCase())) {
          this.qbanks[i].enable = true
          this.heading = this.qbanks[i].name
          this.notebookText = this.qbanks[i].description
          this.currentDate = this.qbanks[i].createdDate
          this.updatedDate = this.qbanks[i].updatedDate
          this.items = [{label: this.qbanks[i].name}]
          this.highlight(i, -1, -1, -1, true)
        }
        else {
          this.qbanks[i].enable = false
          this.highlight(i, -1, -1, -1, false)
        }
        this.qbanks[i].open = false
        document.getElementById('qbankheader'+i)?.classList.remove('active')
        if (this.qbanks[i].subjects) {
          for (let j = 0; j < this.qbanks[i].subjects.length; j++) {
            if (this.qbanks[i].subjects[j].name.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].subjects[j].description.toLowerCase().includes(this.searchString.toLowerCase())) {
              this.qbanks[i].enable = true
              this.qbanks[i].subjects[j].enable = true
              this.qbanks[i].open = true
              this.heading = this.qbanks[i].subjects[j].name
              this.notebookText = this.qbanks[i].subjects[j].description
              this.currentDate = this.qbanks[i].subjects[j].createdDate
              this.updatedDate = this.qbanks[i].subjects[j].updatedDate
              this.items = [{label: this.qbanks[i].name}, {label: this.qbanks[i].subjects[j].name}]
              this.highlight(i, j, -1, -1, true)
              setTimeout(() => {
                document.getElementById('subject'+i+j)?.classList.add('show')
                document.getElementById('subject'+i+j)?.classList.remove('hide')
              }, 200)
            }
            else {
              this.qbanks[i].subjects[j].enable = false
              this.highlight(i, j, -1, -1, false)
              setTimeout(() => {
                document.getElementById('subject'+i+j)?.classList.add('hide')
                document.getElementById('subject'+i+j)?.classList.remove('show')
              }, 200)
            }
            this.qbanks[i].subjects[j].open = false
            document.getElementById('subjectheader'+i+j)?.classList.remove('active')
            if (this.qbanks[i].subjects[j].system) {
              for (let k = 0; k < this.qbanks[i].subjects[j].system.length; k++) {
                if (this.qbanks[i].subjects[j].system[k].name.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].subjects[j].system[k].description.toLowerCase().includes(this.searchString.toLowerCase())) {
                  this.qbanks[i].enable = true
                  this.qbanks[i].subjects[j].enable = true
                  this.qbanks[i].subjects[j].system[k].enable = true
                  this.qbanks[i].open = true
                  this.qbanks[i].subjects[j].open = true
                  this.heading = this.qbanks[i].subjects[j].system[k].name
                  this.notebookText = this.qbanks[i].subjects[j].system[k].description
                  this.currentDate = this.qbanks[i].subjects[j].system[k].createdDate
                  this.updatedDate = this.qbanks[i].subjects[j].system[k].updatedDate
                  this.items = [{label: this.qbanks[i].name}, {label: this.qbanks[i].subjects[j].name}, {label: this.qbanks[i].subjects[j].system[k].name}]
                  this.highlight(i, j, k, -1, true)
                  setTimeout(() => {
                    document.getElementById('subject'+i+j)?.classList.add('show')
                    document.getElementById('subject'+i+j)?.classList.remove('hide')
                    document.getElementById('system'+i+j+k)?.classList.add('show')
                    document.getElementById('system'+i+j+k)?.classList.remove('hide')
                  }, 200)
                }
                else {
                  this.qbanks[i].subjects[j].system[k].enable = false
                  this.highlight(i, j, k, -1, false)
                  setTimeout(() => {
                    document.getElementById('system'+i+j+k)?.classList.add('hide')
                    document.getElementById('system'+i+j+k)?.classList.remove('show')
                  }, 200)
                }
                this.qbanks[i].subjects[j].system[k].open = false
                document.getElementById('systemheader'+i+j+k)?.classList.remove('active')
                if (this.qbanks[i].subjects[j].system[k].topics) {
                  for (let m = 0; m < this.qbanks[i].subjects[j].system[k].topics.length; m++) {
                    if (this.qbanks[i].subjects[j].system[k].topics[m].name.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].subjects[j].system[k].topics[m].description.toLowerCase().includes(this.searchString.toLowerCase())) {
                      this.qbanks[i].enable = true
                      this.qbanks[i].subjects[j].enable = true
                      this.qbanks[i].subjects[j].system[k].enable = true
                      this.qbanks[i].subjects[j].system[k].topics[m].enable = true
                      this.qbanks[i].open = true
                      this.qbanks[i].subjects[j].open = true
                      this.qbanks[i].subjects[j].system[k].open = true
                      this.heading = this.qbanks[i].subjects[j].system[k].topics[m].name
                      this.notebookText = this.qbanks[i].subjects[j].system[k].topics[m].description
                      this.currentDate = this.qbanks[i].subjects[j].system[k].topics[m].createdDate
                      this.updatedDate = this.qbanks[i].subjects[j].system[k].topics[m].updatedDate
                      this.items = [{label: this.qbanks[i].name}, {label: this.qbanks[i].subjects[j].name}, {label: this.qbanks[i].subjects[j].system[k].name}, {label: this.qbanks[i].subjects[j].system[k].topics[m].name}]
                      this.highlight(i, j, k, m, true)
                      setTimeout(() => {
                        document.getElementById('subject'+i+j)?.classList.add('show')
                        document.getElementById('subject'+i+j)?.classList.remove('hide')
                        document.getElementById('system'+i+j+k)?.classList.add('show')
                        document.getElementById('system'+i+j+k)?.classList.remove('hide')
                        document.getElementById('topic'+i+j+k+m)?.classList.add('show')
                        document.getElementById('topic'+i+j+k+m)?.classList.remove('hide')
                      }, 200)
                    }
                    else {
                      this.qbanks[i].subjects[j].system[k].topics[m].enable = false
                      this.highlight(i, j, k, m, false)
                      setTimeout(() => {
                        document.getElementById('topic'+i+j+k+m)?.classList.remove('show')
                        document.getElementById('topic'+i+j+k+m)?.classList.add('hide')
                      }, 200)
                    }
                    this.qbanks[i].subjects[j].system[k].topics[m].open = false
                    document.getElementById('topicheader'+i+j+k+m)?.classList.remove('active')
                  }
                }
              }
            }
          }
        }
      }
    }
    else {
      this.getUserNotebook()
    }
    this.highlightNotebook()
  }

  cancelSearch(event: any) {
    this.searchString = ""
    this.searcher = false
    this.onSearchChange(event)
  }

  getUserNotebook(){
    this.loader = true
    this.chatServie.getUserNotebook().subscribe(((res: any) => {
      this.loader = false
      if (res.status) {
        if(res?.notebook != null){
          this.qbanks = JSON.parse(res?.notebook.Details);
          this.originalNotebook = JSON.parse(res?.notebook.Details);

          this.removeNullValues()

          this.newNotebook = true
          this.heading = this.qbanks[0].name
          this.notebookText = this.qbanks[0].description
          this.indexes = JSON.parse(res?.notebook.LatestNote);
          if (this.indexes[0] != -1) {
            if (this.qbanks[this.indexes[0]]) {
              this.heading = this.qbanks[this.indexes[0]]?.name
              this.notebookText = this.qbanks[this.indexes[0]]?.description
              this.currentDate = this.qbanks[this.indexes[0]]?.createdDate
              this.updatedDate = this.qbanks[this.indexes[0]]?.updatedDate
              this.modifiedIndexes[0] = this.qbanks[this.indexes[0]]?.qbankid
            } else {
              this.indexes[0] = 0
            }
          }
          if (this.indexes[1] != -1) {
            if (this.qbanks[this.indexes[0]].subjects) {
              this.heading = this.qbanks[this.indexes[0]].subjects[this.indexes[1]]?.name
              this.notebookText = this.qbanks[this.indexes[0]].subjects[this.indexes[1]]?.description     
              this.currentDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]]?.createdDate
              this.updatedDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]]?.updatedDate
              this.modifiedIndexes[1] = this.qbanks[this.indexes[0]].subjects[this.indexes[1]]?.qbankid
            } else {
              this.indexes[1] = -1
            }
          }
          if (this.indexes[2] != -1) {
            if (this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system) {
              this.heading = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]]?.name   
              this.notebookText = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]]?.description 
              this.currentDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]]?.createdDate
              this.updatedDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]]?.updatedDate   
              this.modifiedIndexes[2] = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]]?.qbankid
            } else {
              this.indexes[2] = -1
            }
          }
          if (this.indexes[3] != -1) {
            if (this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics) {
              this.heading = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]]?.name   
              this.notebookText = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]]?.description   
              this.currentDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]]?.createdDate
              this.updatedDate = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]]?.updatedDate
              this.modifiedIndexes[3] = this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]]?.qbankid
            } else {
              this.indexes[3] = -1
            }
          }
  
          this.inputStates.push(this.notebookText)
  
          this.resetOrder()
  
          this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))
  
          setTimeout(() => {
            if (this.indexes[0] != -1 && this.indexes[1] == -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
              document.getElementById('qbankheader'+this.indexes[0])?.classList.add('active')
              this.items = [{label: this.qbanks[this.indexes[0]].name}]
            }
  
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
              this.qbanks[this.indexes[0]].open = true
              document.getElementById('subjectheader'+this.indexes[0]+this.indexes[1])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].name}]
            }
  
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] == -1) {
              this.qbanks[this.indexes[0]].open = true
              this.qbanks[this.indexes[0]].subjects[this.indexes[1]].open = true
              document.getElementById('systemheader'+this.indexes[0]+this.indexes[1]+this.indexes[2])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system.length; i++) {
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.remove('hide')
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].name}]
            }
  
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] != -1) {
              this.qbanks[this.indexes[0]].open = true
              this.qbanks[this.indexes[0]].subjects[this.indexes[1]].open = true
              this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].open = true
              document.getElementById('topicheader'+this.indexes[0]+this.indexes[1]+this.indexes[2]+this.indexes[3])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system.length; i++) {
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.remove('hide')
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics.length; i++) {
                document.getElementById('topic'+this.indexes[0]+this.indexes[1]+this.indexes[2]+i)?.classList.remove('hide')
                document.getElementById('topic'+this.indexes[0]+this.indexes[1]+this.indexes[2]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].name}, {label: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]].name}]
            }
  
            if (this.quizEdit) {
              this.runQuill()
            }
          }, 200)
        }
        else {
          if (this.quizEdit) {
            this.addNote(-1, -1, -1, -1, 'Untitled', '', '', ''); 
            this.newNotebook = true; 
            this.isEdit = true;
            document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
            this.originalText = this.notebookText
          }
        }
      }
      else {
        this.messageService.add({severity:'error', summary:'Notebook Failed', detail:'Something went wrong while receiving notes!'});
      }
    }))
  }

  sendingValues= true;
  sendItem(qbank, subject, system, topic, qbabkIndex, subjectIndex, systemIndex, topicIndex, description, createdDate, updatedDate, clickable){
    if (this.searchString != "") {
      this.highlightNotebook()
    }

    if (this.changeState) {
      this.confirm(qbank, subject, system, topic, qbabkIndex, subjectIndex, systemIndex, topicIndex, description, createdDate, updatedDate, clickable)
    }
    else {
      this.resetQbanks()
    
      if (qbabkIndex != -1 && subjectIndex == -1 && systemIndex == -1 && topicIndex == -1) {
        document.getElementById('qbankheader'+qbabkIndex)?.classList.add('active')
  
        if (!clickable) {
          this.changeState = true
        }
        else {
          this.changeState = false
        }
  
        if (this.qbanks[qbabkIndex].subjects) {
          for (let i = 0; i < this.qbanks[qbabkIndex].subjects.length; i++) {
            if (document.getElementById('subject'+qbabkIndex+i)?.classList.contains('hide')) {
              document.getElementById('subject'+qbabkIndex+i)?.classList.remove('hide')
              document.getElementById('subject'+qbabkIndex+i)?.classList.add('show')
              this.qbanks[qbabkIndex].open = true
            }
            else {
              document.getElementById('subject'+qbabkIndex+i)?.classList.add('hide')
              document.getElementById('subject'+qbabkIndex+i)?.classList.remove('show')
              this.qbanks[qbabkIndex].open = false
            }
          }
        }
      }
  
      if (qbabkIndex != -1 && subjectIndex != -1 && systemIndex == -1 && topicIndex == -1) {
        document.getElementById('subjectheader'+qbabkIndex+subjectIndex)?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[qbabkIndex].subjects.length; i++) {
            if (document.getElementById('subject'+qbabkIndex+i)?.classList.contains('hide')) {
              document.getElementById('subject'+qbabkIndex+i)?.classList.remove('hide')
              document.getElementById('subject'+qbabkIndex+i)?.classList.add('show')
            }
          }
          this.qbanks[qbabkIndex].open = true
          this.changeState = true
        }
        else {
          if (this.qbanks[qbabkIndex].subjects[subjectIndex].system) {
            for (let i = 0; i < this.qbanks[qbabkIndex].subjects[subjectIndex].system.length; i++) {
              if (document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.contains('hide')) {
                document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.remove('hide')
                document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.add('show')
                this.qbanks[qbabkIndex].subjects[subjectIndex].open = true
              }
              else {
                document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.add('hide')
                document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.remove('show')
                this.qbanks[qbabkIndex].subjects[subjectIndex].open = false
              }
            }
          }
          this.changeState = false
        }
      }
  
      if (qbabkIndex != -1 && subjectIndex != -1 && systemIndex != -1 && topicIndex == -1) {
        document.getElementById('systemheader'+qbabkIndex+subjectIndex+systemIndex)?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[qbabkIndex].subjects[subjectIndex].system.length; i++) {
            if (document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.contains('hide')) {
              document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.remove('hide')
              document.getElementById('system'+qbabkIndex+subjectIndex+i)?.classList.add('show')
            }
          }
          this.qbanks[qbabkIndex].subjects[subjectIndex].open = true
          this.changeState = true
        }
        else {
          if (this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].topics) {
            for (let i = 0; i < this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].topics.length; i++) {
              if (document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.contains('hide')) {
                document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.remove('hide')
                document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.add('show')
                this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].open = true
              }
              else {
                document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.add('hide')
                document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.remove('show')
                this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].open = false
              }
            }
          }
          this.changeState = false
        }
      }
  
      if (qbabkIndex != -1 && subjectIndex != -1 && systemIndex != -1 && topicIndex != -1) {
        document.getElementById('topicheader'+qbabkIndex+subjectIndex+systemIndex+topicIndex)?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].topics.length; i++) {
            if (document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.contains('hide')) {
              document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.remove('hide')
              document.getElementById('topic'+qbabkIndex+subjectIndex+systemIndex+i)?.classList.add('show')
            }
          }
          this.qbanks[qbabkIndex].subjects[subjectIndex].system[systemIndex].open = true
          this.changeState = true
        }
        else {
          this.changeState = false
        }
      }
  
      if (createdDate == '') {
        createdDate = new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)
      }
      if (updatedDate == '') {
        updatedDate = new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)
      }
      if (qbabkIndex == -1) {
        qbabkIndex = this.qbanks.length - 1
      }
      this.currentDate = createdDate
      this.updatedDate = updatedDate
      var items;
      if(this.sendingValues){
        this.notebookText = description;
        this.indexes = [qbabkIndex, subjectIndex, systemIndex, topicIndex];
        this.inputStates = []
        this.inputStates.push(this.notebookText)
        this.undoStats = 0
        items = (subject=='')?[{label: qbank}]:system == ''? [{label: qbank}, {label: subject}]:topic == ''? [{label: qbank}, {label: subject},{label: system}]:[{label: qbank}, {label: subject},{label: system},{label: topic}];
        this.items = items
      } 
  
      if (this.isEdit) {
        this.ckEditorNotebook.setData(this.notebookText.replace(/(<br>)/g, "\n"))
      }
      this.sendingValues = false;
      setTimeout(() => {
        this.sendingValues = true;
      }, 100);
      if (items) {
        this.heading = items[items.length-1].label;
      }
    }
  }

  createNewNote() {
    if(this.indexes[0] == -1){
      this.qbanks.push({qbankid: this.qbanks.length, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)})
    }
    else if(this.indexes[1] == -1){
      (this.qbanks[this.indexes[0]].subjects)?this.qbanks[this.indexes[0]].subjects.push({qbankid: this.qbanks[this.indexes[0]].subjects.length, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}):this.qbanks[this.indexes[0]].subjects=[{qbankid: 0, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}]
    }
    else if(this.indexes[2] == -1){
      (this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system)?this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system.push({qbankid: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system.length, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}):this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system=[{qbankid: 0, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}]
    }
    else if(this.indexes[3] == -1){
      (this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics)?this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics.push({qbankid: this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics.length, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}):this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics=[{qbankid: 0, name: "Untitled", description:"", enable: true, createdDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15), updatedDate: new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)}]
    }
    this.notebookText = '';
    this.inputStates = []
    this.undoStats = 0
    this.showSave = true;
    this.runQuill()
  }

  addNote(i,j,k,l, qbank, subject, system, topic) {
    this.indexes = [i,j,k,l];
    this.createNewNote();
    if (i == -1) {
      i = this.qbanks.length - 1
    }
    else if (j == -1) {
      j = this.qbanks[i].subjects.length - 1
    }
    else if (k == -1) {
      k = this.qbanks[i].subjects[j].system.length - 1
    }
    else if (l == -1) {
      l = this.qbanks[i].subjects[j].system[k].topics.length - 1
    }
    setTimeout(() => {
      this.sendItem(qbank, subject, system, topic, i, j, k, l, '', '', '', false)
    }, 200)
  }

  setDeleteNote(i, j, k, l) {
    this.deleteIndexes = [i,j,k,l];
    this.confirmDeletion = '';
    this.displayDelete = true
  }

  deleteNote(){
    if (this.confirmDeletion == 'delete') {
      if(this.deleteIndexes[1] == -1){
        this.qbanks.splice(this.deleteIndexes[0], 1)
      }
      else if(this.deleteIndexes[2] == -1){
        this.qbanks[this.deleteIndexes[0]].subjects.splice(this.deleteIndexes[1], 1)
      }
      else if(this.deleteIndexes[3] == -1){
        this.qbanks[this.deleteIndexes[0]].subjects[this.deleteIndexes[1]].system.splice(this.deleteIndexes[2], 1)
      }
      else{
        this.qbanks[this.deleteIndexes[0]].subjects[this.deleteIndexes[1]].system[this.deleteIndexes[2]].topics.splice(this.deleteIndexes[3], 1)
      }
      this.displayDelete = false
  
      if (JSON.stringify(this.deleteIndexes) == JSON.stringify(this.indexes)) {
        this.indexes = [0, -1, -1, -1]
      }
      if (this.qbanks.length <= 0) {
        this.newNotebook = false
      } 
  
      this.deleteUserNotebook()
    }
  }

  SaveNote(){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.updatedDate = new Date().toDateString().substring(4, 10) + ', ' + new Date().toDateString().substring(10, 15)
        if(this.indexes[1] == -1){
          this.qbanks[this.indexes[0]].updatedDate = this.updatedDate
        }
        else if(this.indexes[2] == -1){
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].updatedDate = this.updatedDate
        }
        else if(this.indexes[3] == -1){
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].updatedDate = this.updatedDate
        }
        else{
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]].updatedDate = this.updatedDate
        }
     
        if(this.indexes[1] == -1){
          this.qbanks[this.indexes[0]].description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].name = this.heading;
        }
        else if(this.indexes[2] == -1){
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].name = this.heading;
        }
        else if(this.indexes[3] == -1){
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].name = this.heading;
        }
        else{
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]].description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].subjects[this.indexes[1]].system[this.indexes[2]].topics[this.indexes[3]].name = this.heading;
        }
        this.inputStates.push(this.notebookText);
        this.undoStats = 0;
        this.showSave = true;
        resolve(true)
      }, 100)
    })
  }

  async deleteUserNotebook(){
    this.quizEdit = false
    var data = {
      userId: localStorage.getItem('Id'),
      latestNote: this.indexes,
      deleteNote: this.deleteIndexes
    }
    this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))

    if (this.isDemo) {
      this.showSave = false;
      this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
      this.isEdit = false
      this.ckEditorNotebook.destroy()
      document.getElementById('notebookdialog').style.border = 'none'
      this.displaySave = false;
      this.changeState = false;
    } else {
      this.chatServie.deleteUserNotebook(data).subscribe((res: any)=>{
        if (res.status) {
          this.showSave = false;
          this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
          this.isEdit = false
          document.getElementById('notebookdialog').style.border = 'none'
          this.displaySave = false;
          this.inputStates = []
          this.changeState = false;
          if (this.qbanks.length > 0) {
            this.sendItem(this.qbanks[0].name, '', '', '', 0, -1, -1, -1, this.qbanks[0].description, this.qbanks[0].createdDate, this.qbanks[0].updateDate, true)
          }
          if (this.ckEditorNotebook) {
            this.ckEditorNotebook.destroy()
          }
        }
        else {
          this.messageService.add({severity:'error', summary:'Delete Notebook Failed', detail:'Something went wrong while deleting note!'});
        }
      })
    }
  }

  async saveNotebooktoDB(){
    this.notebookText = this.ckEditorNotebook.getData()
    await this.SaveNote()
    var data = {
      userId: localStorage.getItem('Id'),
      // details: this.qbanks,
      latestNote: this.indexes,
      heading: this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, ''),
      description: this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '')
    }
    this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))

    if (this.isDemo) {
      if (data.latestNote[0] != -1 && data.latestNote[1] == -1 && data.latestNote[2] == -1 && data.latestNote[3] == -1) {
        this.qbanks[data.latestNote[0]].description = data.description
      }

      if (data.latestNote[0] != -1 && data.latestNote[1] != -1 && data.latestNote[2] == -1 && data.latestNote[3] == -1) {
        this.qbanks[data.latestNote[0]].subjects[data.latestNote[1]].description = data.description
      }

      if (data.latestNote[0] != -1 && data.latestNote[1] != -1 && data.latestNote[2] != -1 && data.latestNote[3] == -1) {
        this.qbanks[data.latestNote[0]].subjects[data.latestNote[1]].system[data.latestNote[2]].description = data.description
      }

      if (data.latestNote[0] != -1 && data.latestNote[1] != -1 && data.latestNote[2] != -1 && data.latestNote[3] != -1) {
        this.qbanks[data.latestNote[0]].subjects[data.latestNote[1]].system[data.latestNote[2]].topics[data.latestNote[3]].description = data.description
      }
      
      this.showSave = false;
      this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
      this.isEdit = false
      this.ckEditorNotebook.destroy()
      document.getElementById('notebookdialog').style.border = 'none'
      this.displaySave = false;
      this.changeState = false;
    } else {
      this.isSave = true
      this.chatServie.addUserNotebook(data).subscribe((res: any)=>{
        this.isSave = false
        if (res.status) {
          this.showSave = false;
          this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
          this.isEdit = false
          this.ckEditorNotebook.destroy()
          document.getElementById('notebookdialog').style.border = 'none'
          this.displaySave = false;
          this.inputStates = []
          this.changeState = false;
        }
        else {
          this.messageService.add({severity:'error', summary:'Add Notebook Failed', detail:'Something went wrong while creating new note!'});
        }
      })
    }
  }

  async swapUserNotebook(prevIndex: any, currIndex: any, type: any, indexes: any){
    this.quizEdit = false
    var data = {
      userId: localStorage.getItem('Id'),
      // details: this.qbanks,
      latestNote: indexes,
      prevIndex: prevIndex,
      currIndex: currIndex,
      type: type
    }
    this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))

    if (this.isDemo) {
      this.showSave = false;
      this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
      this.isEdit = false
      this.ckEditorNotebook.destroy()
      document.getElementById('notebookdialog').style.border = 'none'
      this.displaySave = false;
      this.changeState = false;
    } else {
      this.chatServie.swapUserNotebook(data).subscribe((res: any)=>{
        this.isSwap = false
        if (res.status) {
          this.showSave = false;
          this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
          this.isEdit = false
          if (this.ckEditorNotebook) {
            this.ckEditorNotebook.destroy()
          }
          document.getElementById('notebookdialog').style.border = 'none'
          this.displaySave = false;
          this.inputStates = []
          this.changeState = false;
        }
        else {
          this.messageService.add({severity:'error', summary:'Swap Notebook Failed', detail:'Something went wrong while swapping notes!'});
        }
      })
    }
  }

  cancelEdit(){
    this.ckEditorNotebook.destroy()
    this.showSave = false;
    this.isEdit = false;
    document.getElementById('notebookdialog').style.border = 'none'
  }

  resetQbanks() {
    for (let i = 0; i < this.qbanks.length; i++) {
      document.getElementById('qbankheader'+i)?.classList.remove('active')
      if (this.qbanks[i].subjects) {
        for (let j = 0; j < this.qbanks[i].subjects.length; j++) {
          document.getElementById('subjectheader'+i+j)?.classList.remove('active')
          if (this.qbanks[i].subjects[j].system) {
            for (let k = 0; k < this.qbanks[i].subjects[j].system.length; k++) {
              document.getElementById('systemheader'+i+j+k)?.classList.remove('active')
              if (this.qbanks[i].subjects[j].system[k].topics) {
                for (let m = 0; m < this.qbanks[i].subjects[j].system[k].topics.length; m++) {
                  document.getElementById('topicheader'+i+j+k+m)?.classList.remove('active')
                }
              }
            }
          }
        }
      }
    }
  }

  removeNullValues() {
    this.qbanks = this.qbanks.filter(item => item != null);

    for (let i = 0; i < this.qbanks.length; i++) {
      if (this.qbanks[i].subjects) {
        this.qbanks[i].subjects = this.qbanks[i].subjects.filter(item => item != null);
        for (let j = 0; j < this.qbanks[i].subjects.length; j++) {
          if (this.qbanks[i].subjects[j].system) {
            this.qbanks[i].subjects[j].system = this.qbanks[i].subjects[j].system.filter(item => item != null);
            for (let k = 0; k < this.qbanks[i].subjects[j].system.length; k++) {
              if (this.qbanks[i].subjects[j].system[k].topics) {
                this.qbanks[i].subjects[j].system[k].topics = this.qbanks[i].subjects[j].system[k].topics.filter(item => item != null);
              }
            }
          }
        }
      }
    }

    this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
  }

  resetOrder() {
    for (let i = 0; i < this.qbanks.length; i++) {
      this.qbanks[i].open = false
      this.qbanks[i].enable = true
      if (this.qbanks[i].subjects) {
        for (let j = 0; j < this.qbanks[i].subjects.length; j++) {
          this.qbanks[i].subjects[j].open = false
          this.qbanks[i].subjects[j].enable = true
          if (this.qbanks[i].subjects[j].system) {
            for (let k = 0; k < this.qbanks[i].subjects[j].system.length; k++) {
              this.qbanks[i].subjects[j].system[k].open = false
              this.qbanks[i].subjects[j].system[k].enable = true
              if (this.qbanks[i].subjects[j].system[k].topics) {
                for (let m = 0; m < this.qbanks[i].subjects[j].system[k].topics.length; m++) {
                  this.qbanks[i].subjects[j].system[k].topics[m].open = false
                  this.qbanks[i].subjects[j].system[k].topics[m].enable = true
                }
              }
            }
          }
        }
      }
    }
  }

  updateText() {
    if (this.quizEdit) {
      if (this.html.includes('<tr>') && !this.html.includes('<table>')) {
        this.html = '<table><tbody>' + this.html + '</tbody></table>'
      }
      var viewFragment = this.ckEditorNotebook.data.processor.toView(this.html);
      var modelFragment = this.ckEditorNotebook.data.toModel(viewFragment);
      this.ckEditorNotebook.model.insertContent(modelFragment, this.editorPosition);
      let control = document.getElementById('selection')
      control.style.display = 'none'
      this.quizEdit = false
      this.notebookText = this.notebookText.replace(/(\n)/g, "<br>")

      this.SaveNote()
    }
  }

  handleSelection(event: any) {
    if (this.quizEdit) {
      this.tempText = ""

      this.tempText = this.notebookText.replace(/<(?!br\s*\/?)[^>]+>/g, "")
      this.tempText = this.tempText.replace(/(&nbsp;)/g, " ")

      this.selectedContent = this.tempText.slice(event[0], event[1])
      this.endContent = this.tempText.slice(event[1], this.tempText.length)

      this.textPosition = event[1]
      let rect = window.getSelection().getRangeAt(0).getBoundingClientRect();
      if (rect.top != 0 && !isNaN(event[1])) {
        let control = document.getElementById('selection')
        control.style.top = `calc(${rect.top}px - 55px)`;
        control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 140px)`;
      }
      else {
        let control = document.getElementById('selection')
        control.style.top = `350px`;
      }
    }
  }

  openNotebook() {
    this.sidebar = !this.sidebar
    if (this.sidebar) {
      document.getElementById('list').style.display = 'flex'
      document.getElementById('data').style.display = 'none'
    }
    else {
      document.getElementById('list').style.display = 'none'
      document.getElementById('data').style.display = 'flex'
    }
  }

  runQuill() {
    this.isEdit = true
    this.showSave = true

    if (this.ckEditorNotebook) {
      if (this.ckEditorNotebook?.state != 'destroyed') {
        this.ckEditorNotebook.destroy() 
      }
    }

    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorNotebook"), {
      }).then((editor: any) => {
        this.ckEditorNotebook = editor
        editor.setData(this.notebookText.replace(/(<br>)/g, "\n"))
        editor.model.document.selection.on('change', (event) => {
          if (event.source._ranges.length > 0) {
            this.handleSelection(event.source._ranges[0].start.path)
            this.editorPosition = editor.model.document.selection.getFirstPosition();
          }
        })
        
        setTimeout(() => {
          document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
        }, 100)
      })
    }, 100)
  }
}
