<app-ai-loader *ngIf="aiLoader" [loaderText]="aiText"></app-ai-loader>
<div class="eup_flashcards">
    <div class="courses">
      <h2>AI Decks</h2>
      <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" [style]="{ width: '100%' }"></p-dropdown>
    </div>
    <hr>
    <div class="buttons d-flex justify-content-between">
      <div class="flashcard_buttons">
        <button (click)="module = 'browse'" [ngStyle]="{'color' : module == 'browse' ? '#2196F3' : 'black'}">Browse</button>
        <button (click)="module = 'study'" [ngStyle]="{'color' : module == 'study' ? '#2196F3' : 'black'}">Study</button>
      </div>
      <div class="ai_button">
        <button class="gradient" (click)="generateAIDecks()">
          <img src="../../../../assets/images/ai_stars.svg">
          <span>{{ aiDecksAvailable ? 'Regenerate AI Decks' : 'Generate AI Decks' }}</span>
        </button>
      </div>
    </div>
  
    <app-browse *ngIf="module == 'browse'" [courseId]="selectedCourse" [aiDecks]="aiDecks" [aiDecksGenerated]="aiDecksGenerated" (aiDecksLength)="checkAIDecks($event)"></app-browse>
    <app-study *ngIf="module == 'study'" [courseId]="selectedCourse" [aiDecks]="aiDecks" [aiDecksGenerated]="aiDecksGenerated"></app-study>
  </div>
  
  <p-toast></p-toast>