import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {Router , RouterLink} from '@angular/router';
import {QuizServiceService} from '../../shared/services/quiz-service.service';
import {Observable, of, Subscription, timer} from 'rxjs';
import {AutoLogout} from '../../models/globals';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { demo_variables } from 'src/app/models/demo_global';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-previous-test',
  templateUrl: './previous-test.component.html',
  styleUrls: ['./previous-test.component.scss'],
})
export class PreviousTestComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  showCalender = true;
  prevTestData: any;
  pastPagesTempArray= [{name: "test"}];
  pastPagesArray!: any[];

    tests!: any[];

    totalRecords!: number;

    cols!: any[];

    loading!: boolean;

    representatives!: any[];

    NumofRows!: any;

    selectedNum = 5;
    duplicateSelectedNum = 5;

    UserName = "Ali Asghar";

    pastPage: any;

    prevQuizData: any;

    subjects: any;

    loader = true;

    quizloader = false;
    quizScore: any;
    innerWidth: any;

    demo = localStorage.getItem('Id')
    isDemo = false;

    // CALENDER
    monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
  "Friday", "Saturday"];
  shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  calendarDates = []
  currentDate: any;

  editTitle = false
  quizTitle = ""
  displayCopy = false
  quizId = 0

  userMembership = false
  deleteModal = false;
  deleteQuizId = null;

  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup-navbar-heading').innerText=''
  }
}
value = 0
  constructor(private errorHandler: ErrorHandlerService,private adminService: AdminService,private globalService: GlobalService,private router: Router, private quizService: QuizServiceService, private clipboard: Clipboard, private authService: AuthService) {
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
   }
  ngOnDestroy(): void {
      this.subscriptions.forEach(item => item.unsubscribe());
      document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null;
  }
  removeMouseOptions(e:any){e.preventDefault()}


  async ngOnInit(){
    this.getUserSubscription()
    this.calculateCalendar();
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Previous Tests';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }

    let data = {
      userId: localStorage.getItem("Id")
    }
    this.quizloader = true;
    this.loader = false;

    this.value = 0;
    var num = 0;
    let interval = setInterval(() => {
      this.value = this.value + 15 + num++;
      if (this.value >= 98) {
          this.value = 98;
          clearInterval(interval);
      }
  }, 1000);
  if(localStorage.getItem('Id')=='-1'){
    localStorage.setItem('prevQuizData',JSON.stringify(demo_variables.previouTestData))
    this.isDemo = true
  }
  if(localStorage.getItem("prevQuizData")){
    this.prevQuizData = JSON.parse(localStorage.getItem("prevQuizData"));
    for (let i = 0; i < this.prevQuizData.length; i++) {
      for (let j = 0; j < this.calendarDates.length; j++) {
        if (this.calendarDates[j].Date === new Date(Date.parse(this.prevQuizData[i].quiz_meta.quizDate)).getDate() && this.calendarDates[j].Month === this.shortMonthNames[new Date(Date.parse(this.prevQuizData[i].quiz_meta.quizDate)).getMonth()]) {
          this.calendarDates[j].Quiz.push(this.prevQuizData[i].quiz_meta.quizTitle)
          this.calendarDates[j].Time.push(new Date(Date.parse(this.prevQuizData[i].quiz_meta.quizDate)).toLocaleTimeString())
          }
        }
      }

      // console.log(this.calendarDates)
    this.value = 100;
        this.loader = false;
        this.quizloader = false;
  }else{
    this.getPreviousQuizzes(data);
  }

    this.NumofRows = [
      {numOfRows: 5},
      {numOfRows: 10},
      {numOfRows: 15},
      {numOfRows: 20},
      {numOfRows: 25}
  ];




  this.tests = [
    {name: "Custom Test 1" , score: "50", date: "7 July, 2021", mode: "tutor", status: "completed", subjects: "single",  noofquestions: 3 , ActiveVisiors: 11},
    {name: "Custom Test 2" , score: "40", date: "10 Aug, 2021", mode: "exam", status: "suspended", subjects: "multiple", noofquestions: 4 , ActiveVisiors: 7},
    {name: "Custom Test 3" , score: "70", date: "17 Sep, 2021", mode: "tutor", status: "suspended", subjects: "multiple", noofquestions: 5 , ActiveVisiors: 13},
    {name: "Custom Test 4" , score: "60", date: "27 Jan, 2021", mode: "exam", status: "completed", subjects: "single",  noofquestions: 9 , ActiveVisiors: 15},
    {name: "Custom Test 5" , score: "80", date: "7 July, 2021", mode: "exam", status: "suspended", subjects: "multiple", noofquestions: 4 , ActiveVisiors: 10}
]
  this.loading = false;
  this.pastPage = this.tests[0].pastPages;
  }

  async getPreviousQuizzes(data: any) {
    await this.subscriptions.push(
      this.quizService.getPreviousQuizzes(data).subscribe((res: any) => {
        this.value = 100;
        this.loader = false;
        this.quizloader = false;
          for (let i = 0; i < res.quizzes.length; i++) {
          for (let j = 0; j < this.calendarDates.length; j++) {
            if (this.calendarDates[j].Date === new Date(Date.parse(res.quizzes[i].quiz_meta.quizDate)).getDate() && this.calendarDates[j].Month === this.shortMonthNames[new Date(Date.parse(res.quizzes[i].quiz_meta.quizDate)).getMonth()]) {
              this.calendarDates[j].Quiz.push(res.quizzes[i].quiz_meta.quizTitle)
              this.calendarDates[j].Time.push(new Date(Date.parse(res.quizzes[i].quiz_meta.quizDate)).toLocaleTimeString())
              }
            }
          }
          this.prevQuizData = res.quizzes
          localStorage.setItem("prevQuizData",JSON.stringify(this.prevQuizData))
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  calculateCalendar() {
    let date = new Date()
    let prevWeek;
    for (let i = 6; i >= 0; i--) {
      prevWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - i);
      this.calendarDates.push({
        "Date": prevWeek.getDate(),
        "Month": this.shortMonthNames[prevWeek.getMonth()],
        "Quiz": [],
        "Time": []
      })
    }
    this.currentDate = this.dayNames[date.getDay()] + ', ' + date.getDate() + ' ' + this.monthNames[date.getMonth()]
  }

  selectNum(a: any): void{
    // this.selectedNum = this.duplicateSelectedNum;
    this.selectedNum = a.numOfRows;
  }

  getPrevTestData(): void{
    this.loading = false;
  }

  // LAZY DEFAULT FUNCTIONS
  loadCustomers(event: LazyLoadEvent) {
        this.loading = false;
}

// Performance Screen
gotoPerformanceScreen(i: any, quizId: any, quizTitle: any, subjectName: any, quizScore: any, quizStatus: any): void{
  // localStorage.setItem("quizScore",JSON.stringify(quizScore))
  localStorage.setItem("quizStatus", quizStatus)

  if(subjectName == "No Subjects"){
    localStorage.setItem("showsubjectdetails", JSON.stringify(false));
  }else{
    localStorage.setItem("showsubjectdetails", JSON.stringify(true));
  }
  localStorage.setItem("QuizId", quizId);
  localStorage.setItem("quizTitle", quizTitle);
  if(i === 1){
    localStorage.setItem("testResult", JSON.stringify(true))
    this.router.navigate(['/dashboard/performancescreen/results']);
  } else{
    localStorage.setItem("testResult", JSON.stringify(false))
    this.router.navigate(['/dashboard/performancescreen/analysis']);
}

}

getUserSubscription(){
  this.authService.getUserSubscription().subscribe((res: any) => {
    if (res.subscriptions.length > 0) {
      res.subscriptions.forEach(element => {
        if (element.Status == 'active') {
          this.userMembership = true
        }
      });
    }
  })
}

resumeQuiz(quiz_id: any, quizScore): any{
  this.quizScore = quizScore
  if(localStorage.getItem('Id') != '-1'){
  this.loader =  true;
  let viewMode = true;
    console.log("***********************")
  this.subscriptions.push(
    this.quizService.resumePrevQuizzes(quiz_id, viewMode).subscribe((res: any) => {
      console.log(res);

      var quizScore;
        var quizTitle;
        var quizMode;
        var quiz_time;
        var quizId;
        var isTimed = false;
        var currentIndexId = 0;


        var allQuestions = [];
        var submittedQuestions = [];
        var flaggedQuestions = [];
        var notes = [{note: 'dummy data' , questionId: -1}];

        quizScore = res.data?.previousQuizzes[0]?.quiz_meta?.quizScore;
        quizTitle = res.data?.previousQuizzes[0]?.quiz_meta?.quizTitle;
        quizMode = res.data?.previousQuizzes[0]?.quiz_meta?.quizMode;
        quiz_time = res.data?.previousQuizzes[0]?.quiz_meta?.quizTime;
        quizId = res.data?.previousQuizzes[0]?.quiz_meta?.quizId;
        isTimed = res.data?.previousQuizzes[0]?.quiz_meta?.isTimed;
        currentIndexId = res.data?.previousQuizzes[0]?.quiz_meta?.currentIndex

        for (let i =0; i<res.data?.questions?.length; i++){
          // Getting Flagged Questions
          if(res.data?.questions[i].marked != -1){
            flaggedQuestions.push({QuestionId: res.data?.questions[i]?.marked, index: i})
          }

          // Getting already submitted Questions
          if(res.data?.questions[i]?.submitData && res.data?.questions[i]?.submitData != null){
            submittedQuestions.push(JSON.parse(res.data?.questions[i]?.submitData));
          }

          // Getting Notes
          if(res.data?.questions[i]?.notes && res.data?.questions[i]?.notes != null){
            notes.push({note: res.data?.questions[i]?.notes , questionId: res.data?.questions[i]?.post_id});
          }

          // getting all Questions that have options
          if(res.data?.questions[i]?.options.length > 0){
          allQuestions.push({answer_data: res.data?.questions[i]?.answer_data, answer_type: res.data?.questions[i]?.answer_type, correct_msg: res.data?.questions[i]?.correct_msg, id: res.data?.questions[i]?.id, incorrect_msg: res.data?.questions[i]?.incorrect_msg, ld_Correct_Percentage: res.data?.questions[i]?.ld_Correct_Percentage, ld_hits_details: res.data?.questions[i]?.ld_hits_details, options: res.data?.questions[i]?.options, postId: res.data?.questions[i]?.post_id, quiz_id: res.data?.questions[i]?.quiz_id , statistics: res.data?.questions[i]?.statistics , subjects: res.data?.questions[i]?.subjects , systems: res.data?.questions[i]?.systems , topics: res.data?.questions[i]?.topics , question: res.data?.questions[i]?.question, linked: res.data?.questions[i].linked , priority: res.data?.questions[i].priority, UWorldID: res.data?.questions[i]?.UWorldID, questionTime: res.data?.questions[i].submitData ? JSON.parse(res.data?.questions[i].submitData).time : 0})
          }
        }
          localStorage.setItem("AllQuestions",JSON.stringify(allQuestions));
          localStorage.setItem("quizScore", res.data.previousQuizzes[0].quiz_meta.quizScore);
          localStorage.setItem("SubmittedQuestions", JSON.stringify(submittedQuestions));
          localStorage.setItem("NotesArray", JSON.stringify(notes));
          localStorage.setItem("quizTitle", quizTitle);
          localStorage.setItem("QuizMode",quizMode);
          localStorage.setItem("quiz_time",quiz_time);
          localStorage.setItem("QuizId",quizId);
          localStorage.setItem("isTimed",JSON.stringify(isTimed));
          localStorage.setItem("isResumed", JSON.stringify(1));
          localStorage.setItem("viewMode", JSON.stringify(false));
          localStorage.setItem("currentIndexId", JSON.stringify(currentIndexId));
          localStorage.setItem("flaggedQuestion", JSON.stringify(flaggedQuestions));

        const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
        arr.forEach(i => {
          localStorage.removeItem(i);
        });
        this.router.navigate(['/quiz']);
        this.loader = false;
      },
      (        err: any) => {
        AutoLogout(err);
      }
    )
  );
}
  else{
    localStorage.setItem("AllQuestions",JSON.stringify(demo_variables.allQuestions));
          localStorage.setItem("quizScore", '0');
          localStorage.setItem('SubmittedQuestions', JSON.stringify(demo_variables.SubmittedQuestions))
          localStorage.setItem("NotesArray", JSON.stringify([]));
          localStorage.setItem("quizTitle", 'Demo Quiz');
          localStorage.setItem("QuizMode",'tutor');
          localStorage.setItem("QuizId", '-1');
          localStorage.setItem("isTimed",JSON.stringify(false));
          localStorage.setItem("isResumed", JSON.stringify(1));
          localStorage.setItem("viewMode", JSON.stringify(false));
          localStorage.setItem("flaggedQuestion", JSON.stringify([]));
          
          const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
          arr.forEach(i => {
            localStorage.removeItem(i);
          });
          this.router.navigate(['/quiz']);
  }
}

selectTitle(quiz: any) {
  this.quizId = quiz.quiz_id
  this.quizTitle = quiz.quiz_meta.quizTitle 
  this.editTitle = true
  setTimeout(() => {
    document.getElementById("editTitle").focus();
  }, 100)
}

copyText(textToCopy: string) {
  let timer = 0
  this.clipboard.copy(textToCopy);
  this.displayCopy = true
  let interval = setInterval(() => {
    if (timer == 2) {
      this.displayCopy = false
      clearInterval(interval)
    }
    timer += 1
  }, 1000)
}

selectQuiz(quizId: any) {
  this.deleteModal = true;
  this.deleteQuizId = quizId;
}

deleteQuiz() {
  const data = {
    "quizId": this.deleteQuizId,
    "userId": localStorage.getItem('Id')
  }
  this.loader = true;
  this.quizService.deleteQuiz(data).subscribe((res: any) => {
    localStorage.removeItem('prevQuizData')
    const quizData = {
      userId: localStorage.getItem("Id")
    }
    this.quizloader = true;
    this.deleteModal = false;
    this.getPreviousQuizzes(quizData);
  })
}

editQuizTitle(quiz: any, index: any) {
  let data = {
    "quizId": quiz.quiz_id,
    "userId": localStorage.getItem('Id'),
    "title": this.quizTitle
  }
  this.quizService.editQuizTitle(data).subscribe((res: any) => {
    if(localStorage.getItem("prevQuizData")){
      this.prevQuizData = JSON.parse(localStorage.getItem("prevQuizData"));
      this.prevQuizData[index].quiz_meta.quizTitle = this.quizTitle
      localStorage.setItem("prevQuizData", JSON.stringify(this.prevQuizData))
      this.editTitle = false
    }
    console.log(res)
  })
}

}
