<div class="eup-fc-root">
    <div class="eup-fc-parent">
      <div class="eup-fc-subparent">
          <!-- Top Background -->
          <div class="topbg" style="height: 120px;">
          </div>
          <!-- Search area -->
          <div class="searcharea text-center mt-5">
              <input type="text" class="searchbar mr-3" placeholder=" Search Flashcard">
            <i class="fa fa-search searchicon "></i>
          </div>
          <!-- heading Area -->
          <div class="col-sm-12 mt-5">
              <div class="col-sm-12 QuestionHeading d-flex flex-horizontal">
                  <div class="ml-2">Your Flashcards</div>
                  <!-- <div class="seeall mr-4">See all <i class="fa fa-chevron-right"></i></div> -->
              </div>
          </div>
          <!-- Flash Card Area -->
          <div class="col-sm-12 mb-5">
              <div class="flashcards mt-3">
            <!-- First Card -->
                <div class="flashcard mt-3">
                    <div class="flashcard1">
                    <div class="newcardtext mr-auto ml-auto">
                        <h5 class="plus"><i class="fa fa-plus"></i></h5>
                        <br>
                        <p>New Card</p>
                    </div>
                </div>
                </div>

            <!-- Cards for loop -->
                  <div class="flashcard mt-4">
                      <div class="cardinner pl-2 pr-2 pt-2 pb-2">
                          <img src="../../../../assets/images/fc1.png" alt="" srcset="" width="100%">
                      </div>
                      <div class="text-center w-100">Card 1</div>
                </div> 

                <div class="flashcard mt-4">
                    <div class="cardinner pl-2 pr-2 pt-2 pb-2">
                        <img src="../../../../assets/images/fc2.png" alt="" srcset="" width="100%">
                    </div>
                    <div class="text-center w-100">Card 2</div>
                </div>

                <div class="flashcard mt-4">
                    <div class="cardinner pl-2 pr-2 pt-2 pb-2">
                        <img src="../../../../assets/images/fc3.png" alt="" srcset="" width="100%">
                    </div>
                    <div class="text-center w-100">Card 3</div>
                </div>

                <div class="flashcard mt-4">
                    <div class="cardinner pl-2 pr-2 pt-2 pb-2">
                        <img src="../../../../assets/images/fc3.png" alt="" srcset="" width="100%">
                    </div>
                    <div class="text-center w-100">Card 4</div>
                </div>
                
                
              </div>
          </div>


          

      </div>
    </div>
</div>