<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<div class="eup_flashcard_browse_root">
    <div class="eup_flashcard_browse_parent">
        <div class="eup_browse_decks">
            <div class="browse_content">
                <div class="search_wrapper">
                    <input type="text" [(ngModel)]="keyword" placeholder="Search flashcards"/>
                    <i class="pi pi-search" style="cursor: pointer;" (click)="searchFlashcards()"></i>
                </div>
                <div class="create_buttons" *ngIf="!isReadyDecks">
                    <div class="deck" (click)="displayDeck = true;">
                        <i class="fa fa-folder-o"></i>
                        <span>Create Deck</span>
                    </div>
                    <div class="card" (click)="openCardDialog()" *ngIf="decks.length > 0">
                        <i class="fa fa-plus"></i>
                        <span>New Card</span>
                    </div>
                </div>
            </div>
            <div class="allDecks" *ngIf="!searcher && decks.length > 0 && !displayAll">
                <div class="single_deck" *ngFor="let deck of decks; let i = index;">
                    <div class="deck_content">
                        <div class="deck_info">
                            <div class="circle" [style.backgroundColor]="deck.Color">{{deck?.Title[0]}}</div>
                            <span>{{deck.Title}}</span>
                            <i *ngIf="!isReadyDecks" style="margin-left: 5px; font-size: 12px; cursor: pointer;" class="pi pi-ellipsis-v" (click)="openDropdown(i)"></i>
                            <div class="deck-dropdown" id="dropdown{{i}}" style="display: none;">
                                <div class="close_dropdown">
                                    <i class="pi pi-times" (click)="closeDropdown(i)"></i>
                                </div>
                                <div class="dropdown_content">
                                    <div class="item" (click)="openEditDialog(deck)">
                                        <i class="fa fa-pencil-square-o" style="margin-right: 10px;"></i>
                                        <span>Edit</span>
                                    </div>
                                    <span style="margin-left: 10px;">・</span>
                                    <div class="item" (click)="deleteUserDeck(deck.DeckID, deck.Flashcards)">
                                        <i class="fa fa-trash-o" style="margin-right: 10px;"></i>
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="deck_all" (click)="seeAll(deck, i)" *ngIf="deck.Flashcards.length > 0">
                            <span>See All</span>
                        </div>
                    </div>
                    <div class="deck_cards" style="overflow: hidden;" *ngIf="deck.Flashcards.length > 0">
                        <div class="single_card" *ngFor="let card of deck.Flashcards; let j = index;" (click)="openFlashcard(card, i, j)">
                            <span [innerHTML]="card.Question"></span>
                        </div>
                    </div>
                    <div class="no_cards" *ngIf="deck.Flashcards.length <= 0">
                        <span>No cards found for this deck.</span>
                    </div>
                </div>
            </div>
            <div class="no_cards" *ngIf="decks.length <= 0 && !searcher">
                <span>No decks found.</span>
            </div>
            <div class="searchedDecks" *ngIf="searcher">
                <div class="deck_content" style="justify-content: flex-start;">
                    <i class="pi pi-arrow-left" style="color: rgba(0,0,0,.38); margin-right: 15px; cursor: pointer; font-size: 20px;" (click)="searcher = false; keyword = ''"></i>
                    <div class="circle" style="background-color: #2196F3; padding: 3px 8px !important;"><i class="fa fa-search" style="font-size: 16px;"></i></div>
                    <span>Search Results</span>
                </div>
                <div class="deck_cards" style="overflow: auto; flex-wrap: wrap;" *ngIf="searchedFlashcards.length > 0">
                    <div class="single_card" *ngFor="let card of searchedFlashcards; let j = index;" (click)="openSearchFlashcard(card, j)">
                        <span [innerHTML]="card.Question"></span>
                    </div>
                </div>
                <div class="no_cards" *ngIf="searchedFlashcards.length <= 0">
                    <span>No cards found.</span>
                </div>
            </div>
            <div class="allDecks" *ngIf="displayAll">
                <div class="single_deck">
                    <div class="deck_content">
                        <div class="deck_info">
                            <i class="pi pi-arrow-left" style="color: rgba(0,0,0,.38); margin-right: 15px; cursor: pointer; font-size: 20px;" (click)="removeDisplayAll()"></i>
                            <div class="circle" [style.backgroundColor]="selectedDeck.Color">{{selectedDeck?.Title[0]}}</div>
                            <span>{{selectedDeck.Title}}</span>
                            <i *ngIf="!isReadyDecks" style="margin-left: 5px; font-size: 12px; cursor: pointer;" class="pi pi-ellipsis-v" (click)="openDropdown(i)"></i>
                            <div class="deck-dropdown" id="dropdown{{i}}" style="display: none;">
                                <div class="close_dropdown">
                                    <i class="pi pi-times" (click)="closeDropdown(i)"></i>
                                </div>
                                <div class="dropdown_content">
                                    <div class="item" (click)="openEditDialog(selectedDeck)">
                                        <i class="fa fa-pencil-square-o" style="margin-right: 10px;"></i>
                                        <span>Edit</span>
                                    </div>
                                    <span style="margin-left: 10px;">・</span>
                                    <div class="item" (click)="deleteUserDeck(selectedDeck.DeckID, selectedDeck.Flashcards)">
                                        <i class="fa fa-trash-o" style="margin-right: 10px;"></i>
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="deck_all" (click)="shuffleCards()">
                            <span>Shuffle Cards</span>
                        </div>
                    </div>
                    <div class="deck_cards" style="overflow: auto; flex-wrap: wrap;" *ngIf="selectedDeck.Flashcards.length > 0">
                        <div class="single_card" *ngFor="let card of selectedDeck.Flashcards; let j = index;" (click)="openFlashcard(card, selectedDeck.deckIndex, j)">
                            <span [innerHTML]="card.Question"></span>
                        </div>
                    </div>
                    <div class="no_cards" *ngIf="selectedDeck.Flashcards.length <= 0">
                        <span>No cards found for this deck.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Update Deck" [(visible)]="displayEditDeck" [modal]="true" [style]="{width: '280px'}"
    [draggable]="false" [resizable]="false">
    <div class="createDeck">
        <input type="text" placeholder="Enter Name" [(ngModel)]="deck.title" [style.borderBottomColor]="deck.color">
        <div class="colors">
            <div [style.backgroundColor]="color" class="circle" *ngFor="let color of deckColors" (click)="deck.color = color">
                <i *ngIf="deck.color == color" class="pi pi-check"></i>
            </div>
        </div>
    </div>
    <div class="createButtons">
        <button (click)="displayEditDeck = false;">Cancel</button>
        <button (click)="editUserDeck()">Update</button>
    </div>

</p-dialog>


<p-dialog header="Create Deck" [(visible)]="displayDeck" [modal]="true" [style]="{width: '280px'}"
    [draggable]="false" [resizable]="false">
    <div class="createDeck">
        <input type="text" placeholder="Enter Name" [(ngModel)]="deck.title" [style.borderBottomColor]="deck.color">
        <div class="colors">
            <div [style.backgroundColor]="color" class="circle" *ngFor="let color of deckColors" (click)="deck.color = color">
                <i *ngIf="deck.color == color" class="pi pi-check"></i>
            </div>
        </div>
    </div>
    <div class="createButtons">
        <button (click)="displayDeck = false;">Cancel</button>
        <button (click)="addUserDeck()">Create</button>
    </div>

</p-dialog>

<p-dialog header="Create Flashcard" styleClass="flashcardDialog" [(visible)]="displayCard" [modal]="true"
    [draggable]="false" [resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="closeModal()"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <div class="flashcard-decks" (click)="displayDropdown = !displayDropdown">
                <div class="circle" [style.backgroundColor]="selectedDeck?.Color">{{selectedDeck?.Title[0]}}</div>
                <span>{{selectedDeck?.Title}}</span>
                <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
            </div>
            <div class="deck-dropdown" *ngIf="displayDropdown">
                <div class="close_dropdown">
                    <i class="pi pi-times" (click)="displayDropdown = false;"></i>
                </div>
                <div class="single_deck" *ngFor="let deck of decks; let i = index" [ngStyle]="{'border-bottom' : i < decks.length - 1 ? '1px solid #e7e7e7' : 'none'}" (click)="selectedDeck = deck; displayDropdown = false;">
                    <div class="circle" [style.backgroundColor]="deck.Color"></div>
                    <span>{{deck.Title}}</span>
                </div>
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
                <div class="flashcard-front-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <ckeditor id="ckEditorFront" [editor]="ClassicEditor" [(ngModel)]="frontEditor"></ckeditor>
                    </div>
                    <!-- <span class="max-warning">Max 2000 characters allowed </span> -->
                </div>
                <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                    <button class="interchange-btn">
                        <i class="fa fa-exchange"></i>
                    </button>
                </div>
                <div class="flashcard-back-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <ckeditor id="ckEditorBack" [editor]="ClassicEditor" [(ngModel)]="backEditor"></ckeditor>
                    </div>
                    <!-- <span class="max-warning">Max 2000 characters allowed </span> -->
                </div>
            </div>
            <div class="flashcard-chip-wrapper flex-display align-center ">
                <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
                </p-chips>
            </div>
            <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
                <button class="clear-btn" (click)="closeModal()">
                    Cancel
                </button>
                <button class="apply-btn" (click)="addDeckFlashcard()">
                    Save
                </button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Flashcards" styleClass="flashcardDialog" [(visible)]="displayFlashcards" [modal]="true"
    [draggable]="false" [resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="displayFlashcards = false; displayCardOptions = false"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <div class="flashcard-decks" style="justify-content: space-between; width: 93%;">
                <div class="deck">
                    <div class="circle" [style.backgroundColor]="decks[deckActiveIndex]?.Color">{{decks[deckActiveIndex]?.Title[0]}}</div>
                    <span>{{decks[deckActiveIndex]?.Title}}</span>
                </div>
                <div class="view" (click)="changeView()">
                    <span>{{flashcardView}}</span>
                    <i style="margin-left: 10px;" class="pi pi-chevron-down"></i>
                </div>
                <div class="options">
                    <i class="pi pi-ellipsis-v" style="cursor: pointer;" (click)="displayCardOptions = true"></i>
                    <div class="deck-dropdown" *ngIf="displayCardOptions">
                        <div class="close_dropdown">
                            <i class="pi pi-times" (click)="displayCardOptions = false"></i>
                        </div>
                        <div class="dropdown_content">
                            <div class="item" (click)="displayRescheduleOptions = true; displayCardOptions = false" *ngIf="!isDemo">
                                <i class="fa fa-calendar" style="margin-right: 10px;"></i>
                                <span>Reschedule</span>
                            </div>
                            <div *ngIf="!isReadyDecks" class="item" (click)="openEditFlashcardDialog(decks[deckActiveIndex]?.Flashcards[cardActiveIndex])">
                                <i class="fa fa-pencil-square-o" style="margin-right: 10px;"></i>
                                <span>Edit</span>
                            </div>
                            <div *ngIf="!isReadyDecks" class="item" (click)="deleteDeckFlashcard()">
                                <i class="fa fa-trash-o" style="margin-right: 10px;"></i>
                                <span>Delete</span>
                            </div>
                            <div *ngIf="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.IsSuspended == 1" class="item" (click)="unsuspendFlashcard(decks[deckActiveIndex]?.DeckID, decks[deckActiveIndex]?.Flashcards[cardActiveIndex].FlashcardID)">
                                <i class="fa fa-eye" style="margin-right: 10px;"></i>
                                <span>Unsuspend Card</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="options" *ngIf="displayRescheduleOptions" style="position: absolute; right: 20%; margin-top: 20px;">
                    <div class="reschedule-dropdown deck-dropdown">
                        <div class="close_dropdown">
                            <i class="pi pi-times" (click)="displayRescheduleOptions = false"></i>
                        </div>
                        <div class="dropdown_content">
                            <div class="title">
                                <span [innerHTML]="(!decks[deckActiveIndex]?.Flashcards[cardActiveIndex].NextStudyDate ? 'Scheduled' : 'Scheduled for ' + (decks[deckActiveIndex]?.Flashcards[cardActiveIndex].NextStudyDate | date: 'MMM dd, yyyy') )"></span>
                            </div>
                            <hr>
                            <div class="content">
                                <div class="item">
                                    <input type="radio" value="New Card" [(ngModel)]="rescheduleOption">
                                    <span>Place at end of new card queue</span>
                                </div>
                                <div class="item">
                                    <input type="radio" value="Review" [(ngModel)]="rescheduleOption">
                                    <span>Place in review queue after:</span>
                                </div>
                                <div class="days">
                                    <input type="number" min="1" [(ngModel)]="reviewDays">
                                    <span>days</span>
                                </div>
                            </div>
                            <div class="buttons">
                                <button (click)="displayRescheduleOptions = false">Cancel</button>
                                <button (click)="rescheduleFlashcard(decks[deckActiveIndex]?.DeckID, decks[deckActiveIndex]?.Flashcards[cardActiveIndex].FlashcardID)">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly" *ngIf="flashcardView == 'Single Side View'">
                <div class="flashcard-front-wrapper" style="width: 100%;">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Back' ? '#2196F3' : 'black'}" (click)="updatePosition('Back')" style="cursor: pointer; width: 15%;">Back</span>
                        <span class="flex-display align-center justify-center" [ngStyle]="{'color': position == 'Front' ? '#2196F3' : 'black'}" (click)="updatePosition('Front')" style="cursor: pointer; width: 15%;">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <div class="flashcard-text" *ngIf="position == 'Front'" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Question"></div>
                        <div class="flashcard-text" *ngIf="position == 'Back'" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Answer"></div>
                        <div class="tags" *ngIf="position == 'Back'">
                            <i class="pi pi-tag"></i>
                            <span *ngFor="let tag of decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Tags">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display space-between" *ngIf="flashcardView == 'Double Side View'">
                <div class="flashcard-front-wrapper" style="width: 48%; height: 350px;">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper" style="height: 100%;">
                        <div class="flashcard-text" style="height: 100%;" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Question"></div>
                    </div>
                </div> 
                <div class="flashcard-back-wrapper" style="width: 48%;" [ngStyle]="{'height': position == 'Back' ? '303px': '350px'}">
                    <div class="flashcard-header flex-display align-center justify-end" style="cursor: pointer;" (click)="showAnswer()">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper" style="height: 350px;">
                        <div class="flashcard-text" style="background-color: #d9d9d9; height: 100%;" *ngIf="position == 'Front'"></div>
                        <div class="flashcard-text" style="height: 290px;" [innerHTML]="decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Answer" *ngIf="position == 'Back'"></div>
                        <div class="tags" *ngIf="position == 'Back'">
                            <i class="pi pi-tag"></i>
                            <span *ngFor="let tag of decks[deckActiveIndex]?.Flashcards[cardActiveIndex]?.Tags">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flashcard-footer flex-display align-center space-between" style="flex-direction: column;">
                <div class="flashcard-numbers" *ngIf="!searcher">
                    {{cardActiveIndex + 1}} of {{decks[deckActiveIndex]?.Flashcards?.length}}
                </div>
                <div class="flashcard-buttons">
                    <button (click)="prevCardIndex()" *ngIf="!searcher">
                        Previous
                    </button>
                    <button *ngIf="position == 'Front'" (click)="updatePosition('Back')">
                        Show Answer
                    </button>
                    <button *ngIf="position == 'Back'" (click)="updatePosition('Front')">
                        Hide Answer
                    </button>
                    <button (click)="nextCardIndex()" *ngIf="!searcher">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Edit Flashcard" styleClass="flashcardDialog" [(visible)]="displayEditFlashcard" [modal]="true"
    [draggable]="false" [resizable]="false">
    <div class="elhusseniey-sas-flashcard-root" [ngClass]="(!isFullScreen)?'elhusseniey-sas-flashcard-root':'elhusseniey-sas-flashcard-root-expand'">
        <div class="elhusseniey-sas-flashcard-body">
            <!-- Header of the flashcard block -->
            <div class="elhusseniey-sas-flashcard-header flex-display align-center space-between">
                <div class="aku-elhusseniey-flashcard-heading-wrapper">
                    <h2>Flashcards</h2>
                </div>
                <div class="aku-elhusseniey-flashcard-expand-close-btn-wrapper flex-display align-center ">
                    <div class="aku-elhusseniey-flashcard-max-min-wrapper">
                        <button *ngIf="!isFullScreen" (click)="openFullscreen()" class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-maximize"></i>
                        </button>
                        <button *ngIf="isFullScreen" (click)="closeFullscreen()"  class="screenSize-btn flex-display align-center justify-center">
                            <i class="pi pi-window-minimize"></i>
                        </button>
                    </div>
                    <div class="aku-elhusseniey-flashcard-close-wrapper">
                        <button (click)="displayEditFlashcard = false"  class="closeModal-btn flex-display align-center justify-center">
                            <i class="pi pi-times"></i>
                        </button>
                    </div>
    
                </div>
    
            </div>
            <!-- flashcard main body -->
            <div class="aku-elhusseniey-flashcard-main-wrapper flex-display align-center space-evenly">
                <div class="flashcard-front-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span class="flex-display align-center justify-center">Front</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <ckeditor id="ckEditorFront" [editor]="ClassicEditor" [(ngModel)]="frontEditor"></ckeditor>
                    </div>
                    <!-- <span class="max-warning">Max 2000 characters allowed </span> -->
                </div>
                <div class="flashcard-interchange-button-wrapper flex-display align-center justify-center">
                    <button class="interchange-btn">
                        <i class="fa fa-exchange"></i>
                    </button>
                </div>
                <div class="flashcard-back-wrapper">
                    <div class="flashcard-header flex-display align-center justify-end">
                        <span  class="flex-display align-center justify-center">Back</span>
                    </div>
                    <div class="flashcard-editor-wrapper">
                        <ckeditor id="ckEditorBack" [editor]="ClassicEditor" [(ngModel)]="backEditor"></ckeditor>
                    </div>
                    <!-- <span class="max-warning">Max 2000 characters allowed </span> -->
                </div>
            </div>
            <div class="flashcard-chip-wrapper flex-display align-center ">
                <p-chips [(ngModel)]="card.tags" separator="," placeholder="Tag name, comma seperated">
                </p-chips>
            </div>
            <div class="flashcard-footer flex-display align-center space-between" style="padding: 0 !important;">
                <button class="clear-btn" (click)="displayEditFlashcard = false">
                    Cancel
                </button>
                <button class="apply-btn" (click)="editDeckFlashcard()">
                    Update
                </button>
            </div>
        </div>
    </div>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>

<p-dialog header="Exhibit Display" styleClass="imgDialog" [(visible)]="displayImg" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false" [modal]="true">
    <div class="imgDialog_root">
      <div class="showImage" id="pic">
        <pinch-zoom [limit-zoom]="4">
          <img src={{showImage}}>
        </pinch-zoom>
      </div>
    </div>
</p-dialog>

<p-toast></p-toast>