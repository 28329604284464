import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';

@Component({
  selector: 'app-test-analysis',
  templateUrl: './test-analysis.component.html',
  styleUrls: ['./test-analysis.component.scss']
})
export class TestAnalysisComponent implements OnInit {

  constructor(private adminService: AdminService, private errorHandler:ErrorHandlerService) { }

  ngOnInit(): void {
    
  }

}
