import { Injectable } from '@angular/core';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class QuizGeneratorService {

  baseUrl = BASE_URL;
  userId = localStorage.getItem("Id")
  constructor(private httpClient: HttpClient) { }

  getCategories(){
    return this.httpClient.post(this.baseUrl + '/getAllCategories' ,{userId: this.userId},
    {headers: createAuthorizationHeader()});
  }

  getQuestions(data: any){
    return this.httpClient.post(this.baseUrl + '/generateQuiz' , {data},
    {headers: createAuthorizationHeader()});
  }

  getQuizQuestions(data: any){
    return this.httpClient.post(this.baseUrl + '/getQuizQuestions' , {data},
    {headers: createAuthorizationHeader()});
  }

  getAllQuestionsNumbers(CourseTitle: any){
    return this.httpClient.post(this.baseUrl + '/getAllQuestions',{courseTitle: CourseTitle, userId: this.userId},
    {headers: createAuthorizationHeader()});
  }
  
  getQuestionsCount(data:any)
  {
    return this.httpClient.post(this.baseUrl + '/getQuestionCounts', {data },
    {headers: createAuthorizationHeader()});
  }

  getCategoryQuestions(courses: any){
    return this.httpClient.post(this.baseUrl + '/getCategoryQuestions', {userId: this.userId, courses: courses},
    {headers: createAuthorizationHeader()});
  }

  filterQuestions(data: any){
    return this.httpClient.post(this.baseUrl + '/filterQuestions' , {data},
    {headers: createAuthorizationHeader()});
  }

  generateUsingOpenAI(data: any) {
    return this.httpClient.post(this.baseUrl + '/openai/generate', { data });
  }

  getCategoryStatistics(data: any) {
    return this.httpClient.post(this.baseUrl + '/quiz/getCategoryStatistics', { data });
  }
}
