import { Directive, HostListener, Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild, AfterViewInit } from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {Subscription, timer} from 'rxjs';
import {QuizServiceService} from '../../shared/services/quiz-service.service';
import { ElementRef } from '@angular/core';
import {GlobalService} from '../../shared/services/global.service';
import {Message} from 'primeng/api';
import * as Editor from '../../../assets/ckeditor/build/ckeditor';

import {
  AutoLogout,
  BASE_URL,
  bloodValue,
  CerebrospinalLabValues,
  Serum,
  urineBMI
} from '../../models/globals';
import {question} from '../../models/question';

import {NgForm} from '@angular/forms';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { $ } from 'protractor';
import { LocationStrategy } from '@angular/common';
import { FlashcardService } from 'src/app/shared/services/flashcard.service';



@Pipe({ name: 'noSanitize' })



export class NoSanitizePipe implements PipeTransform {


  constructor(private domSanitizer: DomSanitizer, private elementRef:ElementRef) {
    document.addEventListener('contextmenu', event => event.preventDefault());
        document.onkeydown = function(e) {
          // @ts-ignore
          if(event.keyCode == 123) {
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
          return false;
          }
          if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
          return false;
          }
          }
  }

  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}



@Component({
  selector: 'app-quiz-module',
  templateUrl: './quiz-module.component.html',
  styleUrls: ['./quiz-module.component.scss',
              '../../../assets/stylesheet/toggle-btn.css',
              '../../../assets/stylesheet/radio.css',
              '../../../assets/stylesheet/modal.css',
              '../../../assets/stylesheet/sidebar.css'],
  providers: [MessageService]
})



export class QuizModuleComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[] = [];
  @ViewChild('menuItems') menu!: MenuItem[];
  @ViewChild('feedbackForm') feedbackForm!: NgForm;
  @ViewChild('notesForm') notesForm!: NgForm;
  highlightedQuestionIndexes = []
  mobView = false;
  allQuestions: any;
  currentQuestionIndex: any;
  showQuestionDetails = false;
  AdditionVariable= 1;
  displaySidebar = true;

  currentQuestion: question = {
    q_article_type: '',
    q_correct_answer: '',
    q_desc: '',
    q_difficulty_level: 0,
    q_discipline_type: '',
    q_exam_type: '',
    q_exp: ' ',
    q_id: 0,
    q_last_updated: '',
    q_options: undefined,
    q_status_value: 0,
    q_subject: '',
    q_subject_percent: 0,
    q_success_percent: 0,
    q_system: '',
    q_system_type: '',
    q_topic: '',
    is_marked: 0,
    // POST ID WAS NULL AND GIVIN ERROR
    post_id: 0,
    correct_ans_hits: null,
    checkedOption: null
  };

  safeRanges: any;

  // FOR LAB VALUES
  activeItem!: MenuItem;
  labValueFilter: any;
  isSiRefVals = false;
  openRightSideBar = false;
  activeLabvalue = 0;
  bloodLabValues = bloodValue;
  HematologicLabValues = Serum;
  CerebrospinalLabValues = CerebrospinalLabValues;
  sweatLabValues = urineBMI;
  items!: MenuItem[];

  // For Calculator
  displayCalculatorModal = false;

  // For Notes
  displayModal = false;
  userNotes = '';
  notesValueArray = [{
    questionId: -1,
    note: "Notes will come here"
  }]

  quiz_Status: any;

  // FOr Font Size
  optionSelected = false;

  // SETTINGS
  isBlackTheme = false;
  isLightTheme = false;
  isDefaultTheme = true;
  automaticNightMode =  false;
  confirmAnsOmission = false;
  splitScreenExplanations = false;
  highlightOnlyMode = false;

  // FullScreen
  isFullScreen = false;

  // Getting answers value
  optionValue: any;
  iscorrectAns: any;
  AnswerSubmitted= false;
  CheckOptionsSelected: any;
  AnswerSubmittedValues= [{
    index: -1,
    correct: 0,
    optionIndexSelected: -1,
    Correctanswerindex: -1,
    time: 123
  }];
  SelectedOptionIndex: any;
  CorrectAnswerIndex: any;
  strikedThroughOptions =  [
    {qId: -1, strikedAns: -1}
  ];


  // FOR FOOTER FEEDBACK
  displayFeedbackModal: any;
  userFeedback: any;
  isTechIssue: any;
  feedbackValueArray = [{
    questionNo: -1,
    feedback: "Feedback will come here"
  }]

  // FOR END BLOCK
  courseID: any;
  lessonID: any;

  // Omitted Answer
  OmittedAnswer= false;

  // FOr id
optionValue1 = 'A';
num = 0;
currentFontSize_Ques_Options: any;
quizId: any;
msgs: Message[] = [];

// For TIMER
startTime = new Date('Tue May 25 2021 06:00:00 GMT+0500 (Pakistan Standard Time)');
endTime = new Date('Tue May 25 2021 06:00:00 GMT+0500 (Pakistan Standard Time)');
showTimer =  true;
quizTime: any;
questionTime: any;
totalQuizTime = 1;
timerSubscription: any;
timesUp = false;

displayModal1 =false;
quizTitle: any;
datetime: any;
quizMode: any;
isTimed = false;
singleQuestionTime: any;

SelectedOptionsArray: any;

dotQuestionsArray: any = [];

displayAlert = false;

viewMode = false;

showpen =false;

quizScore: any;

quizloader = false;

testVariable = false;

leftSidebar = false;
isIpad = true;

shownotebookdialog = false;

timeMode = false

timedMode = false

counter = 0

saveTime: any;

newTimeCheck = true

displayFlashcards = false
flashcards = []
selectedFlashcard: any;
showFlashcard = false;
flashcardView = 'Single Side View'
position = 'Front'
displayDropdown = false;

card = {
  "question": "",
  "answer": "",
  "tags": [],
  "deckId": -1,
  "questionId": -1
}

selectedDeck: any;
decks = []
createFlashcard = false;
questionFlashcards = []
showFlashcards = []

displayContinue = false
displayCards = false
existingFlashcard = false

html = ""

displayCardOptions = false
displayEditFlashcard = false

flashcardKeyword = ''
searcher = false
searchedFlashcards: any = []
tempFlashcards: any = []

displayImg = false
showImage = ''

ckEditorFront: any;
ckEditorBack: any;

frontEditor: any;
backEditor: any;

showTable = ''

displayDeck = false;
deck = {
  "userId": localStorage.getItem('Id'),
  "title": "",
  "color": "#62B5FC"
}

deckColors = ['#62B5FC', '#F15856', '#FB7915', '#FFCC33', '#B13EDB',
'#7274E1', '#6E756D', '#BF5D5D', '#FF8BF0', '#36C8A3'] 

  questionsTime: any = []

  isDemo = false;

answersArray: any = [];

highlightingFailed = false;

videoURLs: any = [];
explanationVideoURLs: any = [];

aiLoader = false;
aiText = '';

questionSummary = '';
summaryPopup = false;

referenceLinks = [];
linksPopup = false;

voiceNoteFile = null;
voiceNotePopup = false;
voiceNoteKey = null;
voiceNoteGenerated = null;
voiceCurrentTime = '00:00';
voiceTotalTime = '00:00';
playAudio = false;
@ViewChild('audioElement') audioElement: any;
audioLoader = false;

removeMouseOptions(e:any){
  e.preventDefault()
}
  constructor(private globalService:GlobalService,private elementRef:ElementRef, private router: Router, private route: ActivatedRoute,
              private quizService: QuizServiceService, private sanitizer: DomSanitizer, private confirmationService: ConfirmationService, private adminService: AdminService, private errorHandler: ErrorHandlerService,
              private location: LocationStrategy, private flashcardService: FlashcardService, private messageService: MessageService) {
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
   }
  ngAfterViewInit(): void {
    var spans = document.getElementsByClassName("highlight");
    for(var i=0; i<spans.length; i++){
      spans[i].addEventListener("click", this.deleteText, false);
      spans[i].addEventListener("touchstart", this.deleteText, true);
      spans[i].addEventListener("mouseup", this.deleteText, true);
    }
    this.allQuestions = JSON.parse(localStorage.getItem('AllQuestions'));
    this.removeImageDiv()
  }
  removeImageDiv(){
    var imgButton = document.getElementsByClassName('ql-image');
    for (let i = 0; i < imgButton.length; i++) {
      // @ts-ignore
      imgButton[i].style.display = "none";
    }
  }

   myurl;
   isIOS = false;
  starter = false

  async ngOnInit(){

    if (localStorage.getItem('Id') == '-1') {
      this.isDemo = true
    }
    // Image Click Event //
    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.addingTableListener()

    let view = JSON.parse(localStorage.getItem('viewMode'))
    if (!view) {
      history.pushState(null, null, window.location.href);  
      this.location.onPopState(() => {
        if (window.location.href == 'http://localhost:4200' + '/quiz') {
          history.pushState(null, null, window.location.href);
          this.displayEndBlockAlert(1)
        }
      });  
    }
    if (localStorage.getItem('counter')) {
      this.counter = JSON.parse(localStorage.getItem('counter'))
    }
    if (localStorage.getItem('QuizMode') == 'tutor') {
      this.timeMode = false
    }
    if (localStorage.getItem('QuizMode') == 'exam') {
      this.timeMode = true
    }

    if (JSON.parse(localStorage.getItem('isTimed'))) {
      this.timedMode = true
    }
    else {
      this.timedMode = false
    }

    if (localStorage.getItem('AnswerChanges')) {
      this.answersArray = JSON.parse(localStorage.getItem('AnswerChanges'))
    }

    if(window.innerWidth<750){
      this.mobView = true;
    }
    this.isIpad = navigator.userAgent.match(/iPad/i) != null;
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.platform)||(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    console.log("IS IOS ", this.isIOS);
    this.myurl = this.sanitizer.bypassSecurityTrustUrl('https://res.cloudinary.com/umarnazaket/video/upload/v1632474742/Incorrect_Questions_Error_xlyqlo.mp4');

    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    if(localStorage.getItem('quizScore')){
      this.quizScore = JSON.parse(localStorage.getItem('quizScore'));
    }else{
      this.quizScore = 0;
    }
    if(localStorage.getItem('viewMode')){
      this.viewMode = JSON.parse(localStorage.getItem('viewMode'));
    }
    this.getLocalStorageValues();

    this.quiz_Status = 'suspended';

    // this.getCurrentQuesIndex();
    this.currentQuestionIndex = 0;
    if(localStorage.getItem("CurrentIndex")){
      this.currentQuestionIndex = JSON.parse(localStorage.getItem("CurrentIndex"))
    }
    this.getallQuestions()

    if( this.isTimed == true){
      this.setTimeInDesc();
    }

    this.items = [
      {label: 'Blood'},
      {label: 'Serum'},
      {label: 'Cerebrospinal'},
      {label: 'Urine and BMI'},
    ];
    this.activeItem = this.items[0];

    // this.increaseFontSize()
    this.setQuizTimer();
    setTimeout(() => {
      if (this.AnswerSubmitted) {
        if (this.quizMode == "tutor") {
          clearInterval(this.timerid);
          this.timerSubscription?.unsubscribe();
        }
      }
    }, 1000)
    setTimeout(() => {
      this.SetFontSizeOnNextPrev();
    }, 0);
    setTimeout(() => {
      this.hideDot();
      }, 0);
    this.checkTheme();
    if(localStorage.getItem("CurrentIndex")){
    this.currentQuestionIndex = JSON.parse(localStorage.getItem("CurrentIndex"))
  }

  // let element: HTMLElement = document.getElementsByClassName('size-full')[0] as HTMLElement
  // var imgtag =  document.getElementsByClassName("size-full");
  // element.onclick = function() {
  // };
  setTimeout(() => {
    this.getPercentageOthers()
    this.showLinkedText()
    }, 0);

    setTimeout(function () {   window.scrollTo(0, 1); }, 1000);

    // @ts-ignore
    // await this.subscriptions.push(
    //   this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
    //     if(res.status){
    //     }else{
    //       this.errorHandler.AutoLogout();
    //     }
    //   },
    //     error => {
    //       this.errorHandler.handleError(error);
    //     })
    // );
    if(localStorage.getItem('QuestionwithNotes')){
      this.markednotesIndex = JSON.parse(localStorage.getItem('QuestionwithNotes'));
    }
  }

  isLinkedQuestion = false;
  isFirstQuestion = false;
  showLinkedText() {
    // LINKED QUESTIONS IMPLEMENTATION
    if( !this.allQuestions[this.currentQuestionIndex].linked || this.allQuestions[this.currentQuestionIndex].linked == -1){
      this.isLinkedQuestion = false;
      this.isFirstQuestion = false;
    }else{
      if(this.allQuestions[this.currentQuestionIndex].priority == 0){
        this.isLinkedQuestion = true;
        this.isFirstQuestion = true;
      }else if(this.allQuestions[this.currentQuestionIndex].priority == 1){
        this.isLinkedQuestion = true;
        this.isFirstQuestion = false
      }
    }

  }

  hideDots: any = []
  hideDot(): any{
    var submittedquestions= [];
    if(localStorage.getItem("dotQuestionsArray")){
      submittedquestions = JSON.parse(localStorage.getItem("dotQuestionsArray"))
    }
    const indexes = [];
          for (let i = 0; i < this.allQuestions.length; i++){
            indexes.push(submittedquestions.findIndex(q => q.index === this.allQuestions[i].id));
          }
          var abc = []
          for(let j=0; j<indexes.length; j++){
            if(indexes[j] != -1){
              abc.push(j);
            }
          }
          this.hideDots = abc;
          // abc.forEach(i => {
          //   var dot = i;
          //   dot = dot.toString();
            // if(window.innerWidth < 750 && document.getElementById(`m${dot}`)){
            //   document.getElementById(`m${dot}`).style.visibility = 'hidden';
            // }else{
            //   if(document.getElementById(dot)){
            //     document.getElementById(dot).style.visibility = 'hidden';
            //   }
            // }
          // });
  }
  showDot(i: any){
    var index = this.hideDots.findIndex(q => q == i);
    if(index == -1){
      return true;
    }else{
      return false;
    }
  }

  getLocalStorageValues(): void{
    (localStorage.getItem("inserRuleNum"))? this.num=JSON.parse(localStorage.getItem("inserRuleNum")):this.num = 0
    // Split Screen Explanation
    if(localStorage.getItem("splitScreenExplanations")){
      this.splitScreenExplanations = JSON.parse(localStorage.getItem("splitScreenExplanations"));
      if (this.splitScreenExplanations) {
        setTimeout(() => {
          document.getElementById('explanation_wrapper').style.overflowY = 'scroll';
          document.getElementById('explanation_wrapper').style.maxHeight = '80vh';
          document.getElementById('explanation_wrapper').style.marginLeft = '1rem';

          document.getElementById('question_wrapper').style.overflowY = 'scroll';
          document.getElementById('question_wrapper').style.maxHeight = '80vh';
        }, 10)
      }
    }
    if(localStorage.getItem("highlightOnlyMode")){
      this.highlightOnlyMode = JSON.parse(localStorage.getItem("highlightOnlyMode"));
    }
    // Confirm Answer Omission
    if(localStorage.getItem("confirmAnsOmission")){
      this.confirmAnsOmission = JSON.parse(localStorage.getItem("confirmAnsOmission"));
    }
    // Confirm Show Timer
    if(localStorage.getItem("showTimer")){
      this.showTimer = JSON.parse(localStorage.getItem("showTimer"));
    }
    // Getting Automatic Night Mode
    if(localStorage.getItem("automaticNightmode")){
      this.automaticNightMode = JSON.parse(localStorage.getItem("automaticNightmode"));
    }
    this.setnewTime();
    if(localStorage.getItem('timesup')){
      // @ts-ignore
      if(JSON.parse(localStorage.getItem('timesup')) == true){
        this.displayModal1 = true;
        this.endBlockIndex = 1;
      }
      else{
        localStorage.setItem("timesup", JSON.stringify(false))
      }
    }
    // TIME AND QUIZ TITLE
    var current = new Date();
    this.datetime = current.toLocaleString('en-US');
    var date = current.toLocaleDateString();
    var time = current.toLocaleTimeString();
    if(localStorage.getItem("quizTitle")){
      this.quizTitle = localStorage.getItem("quizTitle");
    }else{
      this.quizTitle = "Custom Quiz "+ time;
    }

    // TIME MODE OR NOT
    if(localStorage.getItem("isTimed")){
      // @ts-ignore
      this.isTimed = JSON.parse(localStorage.getItem("isTimed"));
    }else{
      this.isTimed = false
    }

    // if(this.isTimed == false){
    //   this.showTimer = false;
    // }

    // TUTOR TIME MODE
    if(localStorage.getItem("QuizMode")){
      this.quizMode = localStorage.getItem("QuizMode");
    }else{
      this.quizMode = "tutor"
      localStorage.setItem("QuizMode", this.quizMode);
    }

    // if(this.quizMode == "exam"){
    //   this.isTimed = true;
    //   localStorage.setItem("isTimed", JSON.stringify(this.isTimed));
    // }

    if(this.isTimed == false){
    // Getting or setting Quiz Time
    if(localStorage.getItem('quiz_time')){
      this.quizTime = localStorage.getItem('quiz_time');
    }else{
    this.quizTime = "00:00:00";
    localStorage.setItem('quiz_time',this.quizTime);
    }
  }
    // Getting Quiz ID
    if(localStorage.getItem('QuizId')){
      this.quizId = localStorage.getItem('QuizId');
    }

    // Getting Quiz Theme
    if(localStorage.getItem("QuizTheme")){
      if(this.quizId == localStorage.getItem("QuizId")){
        this.isBlackTheme = JSON.parse(localStorage.getItem("isBlackTheme"))
        this.isDefaultTheme = JSON.parse(localStorage.getItem("isDefaultTheme"))
        this.isLightTheme = JSON.parse(localStorage.getItem("isLightTheme"))
      }
    }

    // Getting Flagged Questions Array
  if(localStorage.getItem("flaggedQuestion")){
    // @ts-ignore
    this.markedflagsIndex = JSON.parse(localStorage.getItem("flaggedQuestion"))
  }
  // Checking if Submitted Answers Array is present in DB
  if(localStorage.getItem('SubmittedQuestions')){
    // @ts-ignore
    this.AnswerSubmittedValues = JSON.parse(localStorage.getItem('SubmittedQuestions'));
  }

    (localStorage.getItem("CourseID"))?this.courseID = localStorage.getItem("CourseID"):"";
    (localStorage.getItem("LessonID"))?this.lessonID = localStorage.getItem("LessonID"): "";

    // Getting FOnt Size
    if(localStorage.getItem("FontSize")){
      this.currentFontSize_Ques_Options = JSON.parse(localStorage.getItem("FontSize"));
    }else{
    this.currentFontSize_Ques_Options = 14;
  }
    // @ts-ignore
    let fontSizeStorage = JSON.parse(localStorage.getItem('FontSize'))
    if(fontSizeStorage && fontSizeStorage.QuizId == this.quizId){
      this.currentFontSize_Ques_Options = fontSizeStorage.fontsize;
    }
  }

  // SETTING TIME IN DESCENDING ORDER TIME MODE.......
  setTimeInDesc(): void{
    var fullTime = this.allQuestions.length*90;
    var totalTime = fullTime+1;

    this.totalQuizTime = 0;
    var hours = Math.floor(totalTime / 60 / 60);
    var minutes = Math.floor(totalTime / 60) - (hours * 60);
    var seconds = totalTime % 60;
    this.time = this.allQuestions.length * 90;
    if(localStorage.getItem("quiz_time")){
    this.quizTime = localStorage.getItem("quiz_time")
  }else{
    localStorage.setItem('quiz_time',('0'+hours).slice(-2)+':'+('0'+minutes).slice(-2)+':'+('0'+seconds).slice(-2));
  }
  }

  ngOnDestroy(): void {
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null
    // for (let i = 0; i < this.num; i++){
      // @ts-ignore

      // if (document.styleSheets[0]?.cssRules[i]){
      //   document.styleSheets[0].removeRule(i);
      //   document.styleSheets[0]?.deleteRule(i);
      // }

      // document.styleSheets[0]?.deleteRule(i);


      // @ts-ignore
      // if (document.styleSheets[0]?.cssRules[i]?.selectorText.startsWith('#options_forQuestions')){
      //   document.styleSheets[0]?.deleteRule(i);
      // }

      // @ts-ignore
      // if (document.styleSheets[0]?.cssRules[i]?.selectorText?.startsWith('#explanation')){
      //   document.styleSheets[0]?.deleteRule(i);
      // }

      // @ts-ignore
      // if (document.styleSheets[0]?.cssRules[i]?.selectorText?.startsWith('#question')){
      //   document.styleSheets[0]?.deleteRule(i);
      // }

    // }

    // keyToRemove = cssRule.cssRules[1].keyText;
    // cssRule.deleteRule(keyToRemove);

    const arr = ['QuestionwithNotes','highlightedQuestionIndexes','prevQuizData','inserRuleNum','quizScore','QUIZTitle' ,'splitScreenExplanations' ,'testResult' ,'showTimer' ,'automaticNightmode' ,'confirmAnsOmission' , 'QuizTheme', 'strikedThroughOptions', 'FontSize', 'AllQuestions', 'CurrentIndex', 'flaggedQuestion', 'SelectedOptionArray', 'viewMode', 'quiz_time', 'FeedbackArray', 'timesup', 'singleQuestionTime', 'SubmittedQuestions'  , 'QuizMode' , 'isTimed', 'isResumed', 'QuizTitle', 'quizmeta', 'counter', 'saveTime', 'SelectedOptionArray', 'questionTime', 'dotQuestionsArray', 'currentQuestionIndex', 'CorrectNumOfAnswers', 'AnswerChanges', 'isFlaggedQuestions', 'highlightOnlyMode'];
    arr.forEach(i => {
      localStorage.removeItem(i);
    });
    clearInterval(this.timerid);
    clearInterval(this.interval);
    this.timerSubscription?.unsubscribe();
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: BeforeUnloadEvent) {

    let highlightedQuestions = []
    if(localStorage.getItem('highlightedQuestionIndexes')){
      highlightedQuestions = JSON.parse(localStorage.getItem('highlightedQuestionIndexes'));
    }else{
      highlightedQuestions = []
    }
    
    let data = {
      userId: localStorage.getItem("Id"),
      quizId: this.quizId,
      quizMode: this.quizMode,
      quizTime: this.quizTime,
      isTimed: this.isTimed,
      currentIndex: this.allQuestions[this.currentQuestionIndex].id,
      isFlagged: localStorage.getItem('isFlaggedQuestions') ? true : false,
      questionIds: this.allQuestions.map((item: any) => item.id),
      quizTitle: this.quizTitle,
      quizScore: this.quizScore,
      questionJson: highlightedQuestions.length > 0 ? highlightedQuestions : [] 
    }

    const arr = ['prevQuizData', 'isFlaggedQuestions', 'highlightedQuestionIndexes', 'studyPlanID', 'studyPlanCourse'];
    arr.forEach(i => {
      localStorage.removeItem(i);
    });

    const blobData = new Blob([JSON.stringify(data)], { type: 'application/json' });

    navigator.sendBeacon(BASE_URL + '/updateQuiz', blobData);
  }

  // THIS FUNCTION IS CALLED WHEN QUIZ IS IN REVIEW MODE ONLY
  GoToPerformanceScreen() :any{
    if (localStorage.getItem('QuizId') == "-1") {
      this.router.navigate(['/dashboard/search']);
    }
    else {
      localStorage.setItem("QuizId", this.quizId);
      localStorage.setItem("quizTitle", this.quizTitle);
      localStorage.setItem("testResult", JSON.stringify(true))
      this.router.navigate(['/dashboard/performancescreen/results']);
    }
  }

  async saveQuiz(OmittedArray: any, i: any): Promise<void>{
    if(localStorage.getItem('Id')=='-1'){
      this.gotoPerformanceScreen(-1, 'Quiz Chapter 01', "it has subject")
    }else{
    if(localStorage.getItem("SelectedOptionArray")){
      this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"));
    }else{
      this.SelectedOptionsArray = []
    }
    var QuestionsIDs = [];
    for(let i=0; i<this.allQuestions.length; i++){
      QuestionsIDs.push(this.allQuestions[i].id)
    }


    if(i == 1){
      this.quiz_Status = 'completed'
    }else{
      this.quiz_Status = 'suspended'
    }
    localStorage.setItem("quizStatus", this.quiz_Status);
    if(localStorage.getItem("QuizMode") == "exam") {
      if(localStorage.getItem("SelectedOptionArray")) {
        this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"))
      }

      let submittedQuestions = []
      if(localStorage.getItem("SubmittedQuestions")) {
        submittedQuestions = JSON.parse(localStorage.getItem("SubmittedQuestions"))

        const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);

        if (exclude(submittedQuestions, this.SelectedOptionsArray).length > 0) {
          this.SelectedOptionsArray = [...this.SelectedOptionsArray, ...exclude(submittedQuestions, this.SelectedOptionsArray)]
        }

        for (let i = 0; i < this.SelectedOptionsArray.length; i++) {
          for (let j = 0; j < submittedQuestions.length; j++) {
            if (this.SelectedOptionsArray[i].index == submittedQuestions[j].index) {
              this.SelectedOptionsArray[i].optionIndexSelected = submittedQuestions[j].optionIndexSelected
              this.SelectedOptionsArray[i].correct = submittedQuestions[j].correct
              break;
            }
          }
        }
      }
    }
    
    if(localStorage.getItem("QuizMode") == "exam"){
      this.quizScore = 0;
    }
    if(this.SelectedOptionsArray && this.SelectedOptionsArray.length>0){
    const index = this.SelectedOptionsArray.findIndex(x => ((x.index == -1)));
      if (index !== -1){
        this.SelectedOptionsArray.splice(index, 1);
      }
      for(let i =0; i<this.SelectedOptionsArray.length; i++){
        if(this.SelectedOptionsArray[i].correct == 1){
          this.quizScore++;
        }
      }
    }


    this.displayAlert = false
    this.displaysuspendAlert = false;

    // Adjusting Time Of Each Question //

    this.questionsTime.forEach((element: any, index: any) => {
      let question = this.SelectedOptionsArray.findIndex((item: any) => item.index == element.id)
      if (question !== -1) {
        this.SelectedOptionsArray[question].time = this.questionsTime[index].time
      }

      let omitted = OmittedArray.findIndex((item: any) => item.index == element.id)
      if (omitted !== -1) {
        OmittedArray[omitted].time = this.questionsTime[index].time
      }
    });

    if (this.SelectedOptionsArray.length > 0) {
      this.answersArray.forEach((element: any, index: any) => {
        let questionIndex = this.SelectedOptionsArray.findIndex((item: any) => item.index == element.QuestionID)
        if (questionIndex !== -1) {
          this.SelectedOptionsArray[questionIndex].stat = this.answersArray[index].Status
        }
  
        let omittedIndex = OmittedArray.findIndex((item: any) => item.index == element.QuestionID)
        if (omittedIndex !== -1) {
          OmittedArray[omittedIndex].stat = 5
        }
      });
    }

    let data = {
      quiz: {
        quizId: this.quizId,
        currentIndex: this.allQuestions[this.currentQuestionIndex].id,
        quizTitle: this.quizTitle,
        quizDate: this.datetime,
        quizScore: this.quizScore,
        quizTotalQuestions: this.allQuestions.length,
        quizStatus: this.quiz_Status,
        quizQuestions: JSON.stringify(QuestionsIDs),
        quizMode: this.quizMode,
        quizTime: this.quizTime,
        isTimed: this.isTimed,
        omittedQuestions: OmittedArray,
        SelectedOptionsArray: this.SelectedOptionsArray
      },
      isResumed: false,
      userId: localStorage.getItem("Id"),
      isFlagged: localStorage.getItem('isFlaggedQuestions') ? true : false,
      questionIds: this.allQuestions.map((item: any) => item.id),
      studyPlanID: localStorage.getItem('studyPlanID') ? localStorage.getItem('studyPlanID') : null,
      studyPlanCourse: localStorage.getItem('studyPlanCourse') ? localStorage.getItem('studyPlanCourse') : null
    }

    localStorage.removeItem('studyPlanID');
    localStorage.removeItem('studyPlanCourse');
    this.timerSubscription?.unsubscribe();
    clearInterval(this.timerid);
    clearInterval(this.interval);

    // await this.updateQuestionStrikeThrough()

    if (!this.isDemo) {
      if(localStorage.getItem('highlightedQuestionIndexes')){
        this.highlightedQuestionIndexes = JSON.parse(localStorage.getItem('highlightedQuestionIndexes'));
      } else {
        this.highlightedQuestionIndexes = []
      }
      
      if (this.highlightedQuestionIndexes.length > 0) {
        var highlightedText = {
          quizId: localStorage.getItem('QuizId'),
          userId: localStorage.getItem('Id'),
          questionJson: this.highlightedQuestionIndexes
        }
  
        this.quizService.insertMultipleHighlightText(highlightedText).subscribe((res: any) => {
        })
      }
    }

    this.subscriptions.push(
      this.quizService.saveQuiz(data).subscribe((res: any) => {
        localStorage.setItem('quizStatus', res.quizStatus)
        localStorage.removeItem('studyPlanPopup');
        if (this.allQuestions[0].subject && this.allQuestions[0].subject.title != "no subject"){
          this.gotoPerformanceScreen(this.quizId, this.quizTitle, "it has subject")
        } else {
          this.gotoPerformanceScreen(this.quizId, this.quizTitle, "null")
        }
      },
      (err: any) => {
        AutoLogout(err);
      })
    );
  }
  }

  updateQuestionStrikeThrough() {
    return new Promise((resolve: any) => {
      let strikeThroughData = {
        'userId': localStorage.getItem('Id'),
        'quizId': localStorage.getItem('QuizId'),
        'options': this.strikedThroughOptions
      }
      this.quizService.updateQuestionStrikeThrough(strikeThroughData).subscribe((res: any) => {
        resolve(res)
      })
    })
  }

  getQuestionStrikeThrough() {
    return new Promise((resolve: any) => {
      let strikeThroughData = {
        'userId': localStorage.getItem('Id'),
        'quizId': localStorage.getItem('QuizId')
      }
      this.quizService.getQuestionStrikeThrough(strikeThroughData).subscribe((res: any) => {
        if (res.strikeThroughQuestions.length > 0) {
          this.strikedThroughOptions = JSON.parse(res.strikeThroughQuestions[0].Options)
          localStorage.setItem('strikedThroughOptions', JSON.stringify(this.strikedThroughOptions))
          resolve(true)
        }
      })
    })
  }

  setnewTime(): any{
    localStorage.setItem('singleQuestionTime', JSON.stringify(Date.now()));
  }

  checkSingleQuestionTime(): any{
    // @ts-ignore
     let PrevTime = JSON.parse(localStorage.getItem('singleQuestionTime'));
    this.singleQuestionTime = Math.floor(Date.now() / 1000) - Math.floor(PrevTime / 1000);
  }

  time: any;
  timerDisplay: any;
  timerid: any;
  setQuizTimer(): void{
    if(!localStorage.getItem('quiz_time')){
      localStorage.setItem('quiz_time','00:00:00');
    }
    // set quiz timer
    if( this.isTimed == true){
    this.totalQuizTime = 0;
  }else{
    this.totalQuizTime = -1;
  }
    this.quizTime = localStorage.getItem('quiz_time') || 0;
    let h = (+this.quizTime.substr(0, 2));
    let m = (+this.quizTime.substr(3, 2));
    let s = +this.quizTime.substr(6, 2);
    this.time = (h * 3600 ) + (m * 60) + s;

    if (this.time == this.totalQuizTime){
      this.timesUp = true;
    }else{
      this.timesUp = false
    }

    if ((  (!this.timesUp)) ){
      this.timerSubscription = timer(0, 1000).subscribe(ec => {
        h = (+this.quizTime.substr(0, 2));
        m = (+this.quizTime.substr(3, 2));
        s = +this.quizTime.substr(6, 2);
        this.time = (h * 3600 ) + (m * 60) + s;
        if ((this.time !== this.totalQuizTime) ){
          if(this.isTimed == false){
            this.time++;
          }
          else{
            if(this.time>0){
            this.time--;
          }
          }
        }

        const timerObject = this.getDisplayTimer(this.time);

        this.timerDisplay = timerObject.hours.digit1 + timerObject.hours.digit2 + ':' +
          timerObject.minutes.digit1 + timerObject.minutes.digit2 + ':' +
          timerObject.seconds.digit1 + timerObject.seconds.digit2;
        this.quizTime = this.timerDisplay;

      });
      this.timerid = setInterval(() => {
        this.watchTimer();
      }, 1000);
    }
  }
  watchTimer(): void{
    this.quizMode = localStorage.getItem("QuizMode")
    if(this.isTimed == true && this.quizMode == 'tutor' && !this.viewMode){
      // GOing in tutor mode
    if (this.time == 0 ){
      localStorage.setItem("timesup", JSON.stringify(true))
      this.timesUp = true;
      this.displayModal1 = true
      this.endBlockIndex = 1;
      return;
    }
  }
  if(this.isTimed == true && this.quizMode == 'exam' && !this.viewMode){
    // Going in Exam Mode
    if (this.time == 0 ){
      localStorage.setItem("timesup", JSON.stringify(true))
      this.timesUp = true;
      this.displayModal1 = true
      this.endBlockIndex = 1;
      this.omitValues();
      return;
    }
  }
  // if(this.isTimed == false && this.quizMode == 'exam' && !this.viewMode){
  //   if (this.time >= (this.allQuestions.length * 90)){
  //     localStorage.setItem("timesup", JSON.stringify(true))
  //     this.timesUp = true;
  //     this.displayModal1 = true
  //     this.endBlockIndex = 1;
  //     this.omitValues();
  //     return;
  //   }
  // }
  }
  showTimerfn(): void{
    this.showTimer = !this.showTimer;
    localStorage.setItem("showTimer", JSON.stringify(this.showTimer));
  }

  getDisplayTimer(time: number): any {
    const hours = '0' + Math.floor(time / 3600);
    const minutes = '0' + Math.floor(time % 3600 / 60);
    const seconds = '0' + Math.floor(time % 3600 % 60);
    localStorage.setItem('quiz_time', this.quizTime);

    return {
      hours: { digit1: hours.slice(-2, -1), digit2: hours.slice(-1) },
      minutes: { digit1: minutes.slice(-2, -1), digit2: minutes.slice(-1) },
      seconds: { digit1: seconds.slice(-2, -1), digit2: seconds.slice(-1) },
    };
  }

  // get all the values as omitted on end of the quiz
  omitValues(): void{
    this.AnswerSubmittedValues = JSON.parse(localStorage.getItem("SubmittedQuestions"));
    for(let i=0; i<this.allQuestions.length; i++){
      let k = 0;
      if(this.AnswerSubmittedValues){
      for(let j=0; j<this.AnswerSubmittedValues.length; j++){
        if(this.allQuestions[i].id == this.AnswerSubmittedValues[j].index){
          k = 1;
        }
      }
    }
      if(k == 0){
      this.AllOptions = this.allQuestions[i].statistics;
      for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
        if(this.AllOptions[this.x] == 1){
          this.CorrectAnswerIndex = this.x;
        }
      }
        this.AnswerSubmittedValues.push({index: this.allQuestions[i].id, correct: -1, optionIndexSelected: -1, Correctanswerindex: this.CorrectAnswerIndex, time: 0});
        localStorage.setItem("SubmittedQuestions", JSON.stringify(this.AnswerSubmittedValues))
        this.hideDot()
      }
    }
  }

  confirmOmission(): void{
    this.confirmAnsOmission = !this.confirmAnsOmission;
    localStorage.setItem("confirmAnsOmission", JSON.stringify(this.confirmAnsOmission));
  }

  getCurrentQuesIndex(): void{
    if(localStorage.getItem("CurrentIndex")){
      this.currentQuestionIndex = localStorage.getItem("CurrentIndex");
    }
    else{
      this.currentQuestionIndex = 0;
      localStorage.setItem("CurrentIndex",this.currentQuestionIndex);
    }
  }



  getallQuestions(): void{
    //
    this.SelectedOptionIndex = -1
    // @ts-ignore
    this.allQuestions = JSON.parse(localStorage.getItem("AllQuestions"));

    if (!localStorage.getItem('questionTime')) {
      for (let i = 0; i < this.allQuestions.length; i++) {
        this.questionsTime.push({
          'id': this.allQuestions[i].id,
          'time': this.allQuestions[i].questionTime ? this.allQuestions[i].questionTime : 0
        })
        localStorage.setItem('questionTime', JSON.stringify(this.questionsTime))
      }
    } else {
      this.questionsTime = JSON.parse(localStorage.getItem('questionTime'))
    }


    // allQuestions[currentQuestionIndex].question
    for (let i=0; i<this.allQuestions.length; i++){

      this.allQuestions[i].question = this.allQuestions[i]?.question?.replace(/style="font-size: small;"/g,'');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg?.replace(/style="font-size: small;"/g,'');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg?.replace(/style="font-size: small;"/g,'');

      this.allQuestions[i].question = this.allQuestions[i]?.question?.replace(/h2/g,'span');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg?.replace(/h2/g,'span');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg?.replace(/h2/g,'span');
      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/h3/g,'span');
      this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/h3/g,'span');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace(/h3/g,'span');

      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<span><sup>/g,'<sup>');
      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<\/sup><\/span>/g,'</sup>');
      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<span><sub>/g,'<sub>');
      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<\/sub><\/span>/g,'</sub>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<span><sup>/g,'<sup>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<\/sup><\/span>/g,'</sup>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<span><sub>/g,'<sub>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<\/sub><\/span>/g,'</sub>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<span><sup>/g,'<sup>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<\/sup><\/span>/g,'</sup>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<span><sub>/g,'<sub>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<\/sub><\/span>/g,'</sub>');

      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<span align="center"><strong>/g,'<strong>');
      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<\/strong><\/span>/g,'</strong>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<span align="center"><strong>/g,'<strong>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<\/strong><\/span>/g,'</strong>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<span align="center"><strong>/g,'<strong>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<\/strong><\/span>/g,'</strong>');

      this.allQuestions[i].question = this.allQuestions[i]?.question.replace(/<td><strong>/g,'<td align="center"><strong>');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i]?.incorrect_msg.replace(/<td><strong>/g,'<td align="center"><strong>');
      this.allQuestions[i].correct_msg = this.allQuestions[i]?.correct_msg.replace(/<td><strong>/g,'<td align="center"><strong>');


      this.allQuestions[i].question = this.allQuestions[i].question.replace(/<br>/g,'');
      this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/<br>/g,'');
      this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace(/<br>/g,'');


      this.allQuestions[i].question = this.allQuestions[i].question.replace(/<\/span>/g,'</span> <br>');
      // this.allQuestions[i].question = this.allQuestions[i].question.replaceAll("<img"," <img class='zoom' ");
      //this.elementRef.nativeElement.querySelector('.wp-image-77216').addEventListener('click', alert("Shahrukh"));

      // this.allQuestions[i].question = this.allQuestions[i].question.replaceAll('/>','/> </pinch-zoom>');
      // this.allQuestions[i].question = this.allQuestions[i].question.replaceAll('<img','<pinch-zoom> <img');

      // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replaceAll('<span',"<span ");
      var replaceString = '</span>'
      this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/<\/span>/g,'</span> <br> ');
      this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/>\s+</g,'><').replace(/<\/span><br><\/sub>/g,'</span></sub>');
      this.allQuestions[i].question = this.allQuestions[i].question.replace("of 2</strong>","of 2</strong> <br>");
      // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replaceAll("<img"," <img class='image_class' ");
      //this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/<img/g,'<img onclick="zoomImg()"');
      // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/<img/g,'<div> <pinch-zoom> <img');
      // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replaceAll("/>",'/> </pinch-zoom> </div>');



      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace('</span>','</span> <br>');
      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replaceAll("<img"," <img class='image_class' ");
      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replaceAll(/<img/g,'<img (click)="zoomImg()"');
      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace(/<img/g,'<pinch-zoom> <img');
      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replaceAll("/>",'/> </pinch-zoom>');

      if(this.allQuestions[i].question.includes('img')){

      }
      if(this.allQuestions[i].incorrect_msg.includes('img')){

    }
      if(this.allQuestions[i].correct_msg.includes('img')){

      }

      if(i==0){
    }
    // let audioStr = "<div>[audio mp3='https://elhusseinyusmleprep.com/wp-content/uploads/2020/11/Egophony_CF11.mp3'][/audio] </div> "


    // this.allQuestions[i].question = this.allQuestions[i].question.concat(audioStr)



    // let videoStr = "<div style='padding: 56.25% 0 0 0; position: relative;'><iframe style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;' src='https://res.cloudinary.com/umarnazaket/video/upload/v1632474742/Incorrect_Questions_Error_xlyqlo.mp4' frameborder='0' allowfullscreen='allowfullscreen'></iframe></div> "

    // this.myurl = this.sanitizer.bypassSecurityTrustScript(videoStr);



    // this.allQuestions[i].question = this.allQuestions[i].question.concat(this.myurl)


      if(this.allQuestions[i].question.includes('wordpress-571428-1890621.cloudwaysapps.com')){
        this.allQuestions[i].question = this.allQuestions[i].question.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');

      }
      if(this.allQuestions[i].options.includes('wordpress-571428-1890621.cloudwaysapps.com')){
        this.allQuestions[i].options = this.allQuestions[i].options.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');
      }
      if(this.allQuestions[i].correct_msg.includes('wordpress-571428-1890621.cloudwaysapps.com')){
        this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');

        // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(h2,'elhusseinyusmleprep.com');

      }
      if(this.allQuestions[i].incorrect_msg.includes('wordpress-571428-1890621.cloudwaysapps.com')){
        this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');

        // this.allQuestions[i].correct_msg = this.allQuestions[i].correct_msg.replace(h2,'elhusseinyusmleprep.com');

      }

      // this.allQuestions[i].incorrect_msg = this.allQuestions[i].incorrect_msg.replace(/h3/g,'span');
      // this.allQuestions[i].question = this.allQuestions[i].question.replace(/<\/span>/g,'</span> <br>');

      // this.allQuestions[i].question = this.allQuestions[i].question.replace('[video','<video controls width="450" height="auto"')
      // this.allQuestions[i].question = this.allQuestions[i].question.replace('mp4=','src=')
      // this.allQuestions[i].question = this.allQuestions[i].question.replace('][/video]','></video>')

      this.allQuestions[i].question = this.allQuestions[i].question.replace('[audio','<span> <audio controls')
      this.allQuestions[i].question = this.allQuestions[i].question.replace('mp3=','src=')
      this.allQuestions[i].question = this.allQuestions[i].question.replace('][/audio]','></audio> </span>')
    }
    // FOR LOOP ENDS HERE

    localStorage.setItem("AllQuestions",JSON.stringify(this.allQuestions));
    // c.content.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');
    // this.allQuestions.replace(/wordpress-571428-1890621.cloudwaysapps.com/g,'elhusseinyusmleprep.com');

    // if(this.allQuestions.length == 0){
    //   this.router.navigate(['/focusboard',this.courseID, this.lessonID ]);
    // }

    // For marked Flags
    let k = -1;
    for(let z =0 ; z< this.markedflagsIndex.length; z++){
      if(this.markedflagsIndex[z].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.flagmarked = true;
        k = 0;
      }
    }
    if(k != 0){
      this.flagmarked = false;
    }
    // Marked Question Ends


    // GETTING ANSWERS DATA FROM BACKEND DATABASE
    if(localStorage.getItem("currentIndexId")){
      if(localStorage.getItem("currentIndexId") == 'undefined' || localStorage.getItem("currentIndexId") == undefined ) {
        currentindexID = this.allQuestions[0].id
    }else{
      var currentindexID= JSON.parse(localStorage.getItem("currentIndexId"));
    }
      for(let i=0; i<this.allQuestions.length; i++){
        if(currentindexID == this.allQuestions[i].id){
          this.currentQuestionIndex = i;
          localStorage.setItem("CurrentIndex", JSON.stringify(i));
          localStorage.removeItem("currentIndexId")
        }
      }
    }
    let data = {
      userId: localStorage.getItem('Id'),
      questionId: this.allQuestions[this.currentQuestionIndex].id
    }
    // this.subscriptions.push(
    //   this.quizService.getAnswerData(data).subscribe((res: any) => {
    //     },
    //     (err: any) => {
    //       AutoLogout(err);
    //     })
    // );

    // Check Question Submitted
      for(this.i = 0 ; this.i <  this.AnswerSubmittedValues.length; this.i++ ){
        if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
          this.SelectedOptionIndex = this.AnswerSubmittedValues[this.i].optionIndexSelected;
          this.CorrectAnswerIndex = this.AnswerSubmittedValues[this.i].Correctanswerindex;
          if(this.SelectedOptionIndex == -1){
            this.iscorrectAns = false;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = true;
          }
          else{
          if(this.AnswerSubmittedValues[this.i].correct == 1){
            this.iscorrectAns = true;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = false;
          }
          else{
            this.iscorrectAns = false;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = false;
          }
        }
        }
    }
    this.getSelectedOptionIndex();
    this.calculateTime()
    localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))

    // To show Notes if there is one for this question
    this.showNotes();

    // To show FEEDBACK if there is one for this question
    this.checkFeedback();

    this.setQuestionTime();

    this.strikeThroughQuestions();

    if (localStorage.getItem('SubmittedQuestions')) {
      let submittedQuestions = JSON.parse(localStorage.getItem('SubmittedQuestions'))
      const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);

      if (submittedQuestions.length > 0) {
        if (exclude(submittedQuestions, this.dotQuestionsArray).length > 0) {
          this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(submittedQuestions, this.dotQuestionsArray)]
        }
      }
    }

    localStorage.setItem('dotQuestionsArray',  JSON.stringify(this.dotQuestionsArray))
    this.hideDot()
  }

  async strikeThroughQuestions() {
    if (localStorage.getItem('strikedThroughOptions')) {
      this.strikedThroughOptions = JSON.parse(localStorage.getItem('strikedThroughOptions'))
      let id = this.allQuestions[this.currentQuestionIndex].id
      let values = this.strikedThroughOptions.filter((item: any) => item.qId == id)
      if (values.length > 0) {
        values.forEach((element: any) => {
          setTimeout(() => {
            document.getElementById(this.getOptionValue1(element.strikedAns))!.style.textDecoration = 'line-through';
          }, 1)
        })
      }
    }
    // } else {
    //   await this.getQuestionStrikeThrough()
    //   let id = this.allQuestions[this.currentQuestionIndex].id
    //   let values = this.strikedThroughOptions.filter((item: any) => item.qId == id)
    //   if (values.length > 0) {
    //     values.forEach((element: any) => {
    //       setTimeout(() => {
    //         document.getElementById(this.getOptionValue1(element.strikedAns))!.style.textDecoration = 'line-through';
    //       }, 1)
    //     })
    //   }
    // }
  }

  nextQuestion(): void{
    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.tableExit()
    this.addingTableListener()

    this.HideHighlightControls()

    const mainBodyArea = document.getElementById('mainBodyArea')
    mainBodyArea.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    this.setnewTime();
    if(this.currentQuestionIndex < this.allQuestions.length-1){
      this.OmittedAnswer= false;
    this.AnswerSubmitted = false;
    this.showQuestionDetails = false;
    this.SelectedOptionIndex = -1;

    this.currentQuestionIndex++;
    localStorage.setItem("CurrentIndex",this.currentQuestionIndex);

    this.flagmarked = false;

    // To show Notes if there is one for this question
    this.showNotes();

    // To show FEEDBACK if there is one for this question
    this.checkFeedback();

    // For marked Flags
    let k = -1;
    for(let z =0 ; z< this.markedflagsIndex.length; z++){
      if(this.markedflagsIndex[z].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.flagmarked = true;
        k = 0;
      }
    }
    if(k != 0){
      this.flagmarked = false;
    }
    // Marked Question Ends


      for(this.i = 0 ; this.i <  this.AnswerSubmittedValues.length; this.i++ ){
        if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
          this.SelectedOptionIndex = this.AnswerSubmittedValues[this.i].optionIndexSelected;
          this.CorrectAnswerIndex = this.AnswerSubmittedValues[this.i].Correctanswerindex;
          if(this.SelectedOptionIndex == -1){
            this.iscorrectAns = false;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = true;
          }
          else{
          if(this.AnswerSubmittedValues[this.i].correct == 1){
            this.iscorrectAns = true;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = false;
          }
          else{
            this.iscorrectAns = false;
            this.showQuestionDetails = true;
            this.AnswerSubmitted = true;
            this.OmittedAnswer = false
          }
        }
        }
    }
  }
  setTimeout(()=> {this.SetFontSizeOnNextPrev()},0);
  this.getSelectedOptionIndex();
  this.calculateTime()
  localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))
  this.getPercentageOthers()
  this.showLinkedText()
  if (this.displayFlashcards) {
    this.getUserFlashcards()
  }
  
  this.timerSubscription?.unsubscribe();
  clearInterval(this.timerid)
  if (!this.AnswerSubmitted || this.quizMode == 'exam') {
    this.setQuizTimer()
  }

  this.setQuestionTime();

  this.strikeThroughQuestions();
  }


  prevQuestion(): void{
    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.tableExit()
    this.addingTableListener()

    this.HideHighlightControls()

    const mainBodyArea = document.getElementById('mainBodyArea')
    mainBodyArea.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest'});
    this.setnewTime();
    if(this.currentQuestionIndex > 0){
      this.OmittedAnswer= false;
    this.SelectedOptionIndex = -1;
    this.AnswerSubmitted = false;
    this.showQuestionDetails = false;
    this.flagmarked = false;
    this.currentQuestionIndex--;
    localStorage.setItem("CurrentIndex",this.currentQuestionIndex);


    // To show Notes if there is one for this question
    this.showNotes();

    // To show FEEDBACK if there is one for this question
    this.checkFeedback();

    // For marked Flags
    let k = -1;
    for(let z =0 ; z< this.markedflagsIndex.length; z++){
      if(this.markedflagsIndex[z].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.flagmarked = true;
        k = 0;
      }
    }
    if(k != 0){
      this.flagmarked = false;
    }

    // Marked Question Ends
    for(this.i = 0 ; this.i <  this.AnswerSubmittedValues.length; this.i++ ){
      if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
        this.SelectedOptionIndex = this.AnswerSubmittedValues[this.i].optionIndexSelected;
        this.CorrectAnswerIndex = this.AnswerSubmittedValues[this.i].Correctanswerindex;
        if(this.SelectedOptionIndex == -1){
          this.iscorrectAns = false;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = true;
        }
        else{
        if(this.AnswerSubmittedValues[this.i].correct == 1){
          this.iscorrectAns = true;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = false;
        }
        else{
          this.iscorrectAns = false;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = false;
        }
      }
      }
    }
  }
  setTimeout(()=> {this.SetFontSizeOnNextPrev()},0);
  this.getSelectedOptionIndex();
  this.calculateTime()
  localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))
  this.getPercentageOthers();
  this.showLinkedText()
  if (this.displayFlashcards) {
    this.getUserFlashcards()
  }
  
  this.timerSubscription?.unsubscribe();
  clearInterval(this.timerid)
  if (!this.AnswerSubmitted || this.quizMode == 'exam') {
    this.setQuizTimer()
  }

  this.setQuestionTime();

  this.strikeThroughQuestions();
  }


  // ************************************************************************************************************
  //           CHECK IF QUESTION HAS NOTE OR FEEDBACK
  // ************************************************************************************************************
  // To show FEEDBACK if there is one for this question

  checkFeedback(): void{
    if(localStorage.getItem("FeedbackArray")){
    // @ts-ignore
    this.feedbackValueArray = JSON.parse(localStorage?.getItem("FeedbackArray"))
  }
    if(this.feedbackValueArray){
    let isFeedBackStored = -1;
  for(this.i=1; this.i<this.feedbackValueArray.length; this.i++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.feedbackValueArray[this.i].questionNo){
      this.userFeedback = this.feedbackValueArray[this.i].feedback;
      isFeedBackStored = 1
    }
    if(isFeedBackStored != 1){
      this.userFeedback = ""
    }
  }
}
}

  i: any;
  j: any;
  // Saving answer values in Database after Submission
  SaveAnswerInDB( optionIndexSelected: any ,Correctanswerindex: any): void{
    let questionTime = 0;
    this.checkSingleQuestionTime();
    let index = this.questionsTime.findIndex((item: any) => item.id === this.allQuestions[this.currentQuestionIndex].id)
    if (index !== -1) {
      questionTime = this.questionsTime[index].time
    } else {
      questionTime = this.singleQuestionTime
    }
    // @ts-ignore
    let correct;
    if(optionIndexSelected == -1){
      correct = -1;
    }
    else{
    if(Correctanswerindex == optionIndexSelected){
      correct = 1;
    }
    else{
      correct = 0;
    }
  }

    let answerStatus = 5;
    let answerIndex = this.answersArray.findIndex((item: any) => item.QuestionID == this.allQuestions[this.currentQuestionIndex].id);
    if (answerIndex !== -1) {
      answerStatus = this.answersArray[answerIndex].Status
    }
    let data = {
      // @ts-ignore
      userId: JSON.parse(localStorage.getItem("Id")),
      questionId: this.allQuestions[this.currentQuestionIndex].id,
      // @ts-ignore
      quizId: JSON.parse(localStorage.getItem("QuizId")),
      answerMeta: {
        index: this.allQuestions[this.currentQuestionIndex].id,
        Correctanswerindex: Correctanswerindex,
        correct: correct,
        optionIndexSelected: optionIndexSelected,
        time: questionTime,
        stat: answerStatus
      }
    }

    this.subscriptions.push(
      this.quizService.submitAnswer(data).subscribe((res: any) => {
          this.getPercentageOthers();
        },
        (err: any) => {
          AutoLogout(err);
        })
    );
  }

  getSelectedOptionIndex(): void{
    if(localStorage.getItem("SelectedOptionArray")){
      this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"));
    }else{
      this.SelectedOptionsArray = []
    }

  for(let k=0; k < this.SelectedOptionsArray.length; k++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.SelectedOptionsArray[k].index){
      this.SelectedOptionIndex = this.SelectedOptionsArray[k].optionIndexSelected;
    }
  }
  }

  // When USer Click submit btn on any question
  onsubmitofQuestion(): void{
    // ******************* Checking Selected Options Array and deleting them *****************
  if(!this.viewMode){
  if(localStorage.getItem("SelectedOptionArray")){
    this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"));
  }else{
    this.SelectedOptionsArray = []
  }

  let isoptionSelected = 0;
  let indexOfselectedOption = -1;
  for(let k=0; k < this.SelectedOptionsArray.length; k++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.SelectedOptionsArray[k].index){
      isoptionSelected = 1;
      indexOfselectedOption = k;
    }
  }

  if(localStorage.getItem('SubmittedQuestions')){
    // @ts-ignore
    this.AnswerSubmittedValues = JSON.parse(localStorage.getItem('SubmittedQuestions'));
  }else{
    this.AnswerSubmittedValues = []
  }

  let alreadySolved = false
  if (indexOfselectedOption != -1) {
    if (this.AnswerSubmittedValues.findIndex(item => item.index == this.SelectedOptionsArray[indexOfselectedOption].index) != -1) {
      alreadySolved = true
    }
  }

  clearInterval(this.interval);

  var currentlySelectedSpliced;
    if(isoptionSelected == 1 && !alreadySolved){
      currentlySelectedSpliced = this.SelectedOptionsArray.splice(indexOfselectedOption, 1);
      localStorage.setItem("SelectedOptionArray", JSON.stringify(this.SelectedOptionsArray));
    }
    console.log(currentlySelectedSpliced);
    if(currentlySelectedSpliced){
      this.CorrectAnswerIndex = currentlySelectedSpliced[0].Correctanswerindex
    }

    this.checkSingleQuestionTime();
    // Checking if Submitted Answers Array is present in DB

    this.j = 0;
    if(this.SelectedOptionIndex != -1 && currentlySelectedSpliced && !alreadySolved){
      this.CorrectAnswerIndex = currentlySelectedSpliced[0].Correctanswerindex;
      this.SelectedOptionIndex = currentlySelectedSpliced[0].optionIndexSelected;
      (currentlySelectedSpliced[0].correct == 1)?this.iscorrectAns = true: this.iscorrectAns=false;
      this.AnswerSubmittedValues.push(currentlySelectedSpliced[0]);
      localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));
      if(localStorage.getItem('Id') != '-1'){
          this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
      }

      if (localStorage.getItem('SubmittedQuestions')) {
        let submittedQuestions = JSON.parse(localStorage.getItem('SubmittedQuestions'))
        const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);

        if (submittedQuestions.length > 0) {
          if (exclude(submittedQuestions, this.dotQuestionsArray).length > 0) {
            this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(submittedQuestions, this.dotQuestionsArray)]
          }
        }
      }
  
      localStorage.setItem('dotQuestionsArray',  JSON.stringify(this.dotQuestionsArray))
      this.hideDot()
    //   // An option is selected
    // if(this.iscorrectAns == true){
    //   // Checking If he has previously saved any Question
    //   if(this.AnswerSubmittedValues){
    //     for(this.i = 0 ; this.i<this.AnswerSubmittedValues.length; this.i++ ){
    //       if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
    //         this.SelectedOptionIndex = this.AnswerSubmittedValues[this.i].optionIndexSelected;
    //         // this.CorrectAnswerIndex = this.AnswerSubmittedValues[this.i].Correctanswerindex;
    //         // currentlySelectedSpliced[0].correct
    //         this.j = 1;
    //       }
    //     }
    //     if(this.j == 0){
    //       // Submitted new Question with correct answer

    //       if(localStorage.getItem('Id') != '-1'){
    //       this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
    //       }
    //     // @ts-ignore
    //     this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 1, optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime})
    //     localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));
    //     this.hideDot()
    //     this.AnswerSubmitted = true;
    //     this.quizScore++;
    //     localStorage.setItem('quizScore', JSON.stringify(this.quizScore));
    //   }
    //   }
    //   else{
    //     // He is solving first Question
    //     // Submitted new Question with correct answer
    //     if(localStorage.getItem('Id') != '-1'){
    //     this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
    //     }
    //     // @ts-ignore
    //     this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 1 , optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
    //     localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));
    //     this.hideDot()
    //     this.AnswerSubmitted = true;
    //     localStorage.setItem('quizScore', JSON.stringify(this.quizScore))
    //   }
    // }
    // else{
    //   // SUBMITTED WRONG ANSWER
    //   this.j =0;
    //   if(this.AnswerSubmittedValues){
    //     // Solving Previously Solved Question
    //   for(this.i = 0 ; this.i<this.AnswerSubmittedValues.length; this.i++ ){
    //     if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
    //       this.j = 1;
    //     }
    //   }
    //   if(this.j == 0){
    //     // Solving new Question with Wrong answer
    //     if(localStorage.getItem('Id') != '-1'){
    //     this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
    //     }
    //   // @ts-ignore
    //   this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 0 , optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
    //   localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));
    //   this.hideDot()
    //   this.AnswerSubmitted = true;
    // }
    // }
    // else{
    //   // Solving New Questions with Wrong Answer
    //   if(localStorage.getItem('Id') != '-1'){
    //   this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
    //   }
    //   // @ts-ignore
    //   this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 0 , optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
    //   localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));
    //   this.hideDot()
    //   this.AnswerSubmitted = true;
    // }
    // }
    this.showQuestionDetails = true;
    this.AnswerSubmitted = true;

    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.addingTableListener()
  }
  else{
    this.j = 0;
    // Checking If this Question was already submitted
    for(this.i = 0 ; this.i<this.AnswerSubmittedValues.length; this.i++ ){
      if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
        this.j = 1;
      }
    }
    if(this.j == 0){
      // solving new Question
      if(this.confirmAnsOmission == true){
        this.confirmationService.confirm({
          message: 'Are you sure that you want to omit this Question?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
              this.onOmissionOFquestion()
          },
          reject: () => {
              this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          }
      });
      }
      else{
        this.onOmissionOFquestion();
    }
  }

}}
this.calculateTime()
this.timerSubscription?.unsubscribe();
clearInterval(this.timerid)
localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))


setTimeout(() => {
  if(document.getElementById('explanationArea')) {
    document.getElementById('explanationArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
  }
}, 100)
  }
  onOmissionOFquestion(): void{
    // No Option was selected and submit was clicked
    this.iscorrectAns = false;
    this.OmittedAnswer = true;
    this.j = 0;
    if(this.AnswerSubmittedValues){
      // Checking If this Question was already submitted
    for(this.i = 0 ; this.i<this.AnswerSubmittedValues.length; this.i++ ){
      if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
        this.j = 1;
      }
    }
    if(this.j == 0){
      this.AllOptions = this.allQuestions[this.currentQuestionIndex].statistics;
    for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
        if(this.AllOptions[this.x] == 1){
          this.CorrectAnswerIndex = this.x;
        }
      }
  if(localStorage.getItem('Id') != '-1'){
      this.SaveAnswerInDB(-1, this.CorrectAnswerIndex);
  }
  // @ts-ignore
  this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: -1 , optionIndexSelected: -1, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
  localStorage.setItem('SubmittedQuestions', JSON.stringify(this.AnswerSubmittedValues));


  if (localStorage.getItem('SubmittedQuestions')) {
    let submittedQuestions = JSON.parse(localStorage.getItem('SubmittedQuestions'))
    const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);

    if (submittedQuestions.length > 0) {
      if (exclude(submittedQuestions, this.dotQuestionsArray).length > 0) {
        this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(submittedQuestions, this.dotQuestionsArray)]
      }
    }
  }

  localStorage.setItem('dotQuestionsArray',  JSON.stringify(this.dotQuestionsArray))
  this.hideDot()
    }
  }
  else{
    this.AllOptions = this.allQuestions[this.currentQuestionIndex].statistics;
    for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
        if(this.AllOptions[this.x] == 1){
          this.CorrectAnswerIndex = this.x;
        }
  }
  if(localStorage.getItem('Id') != '-1'){
  this.SaveAnswerInDB(this.SelectedOptionIndex, this.CorrectAnswerIndex);
  }
  // @ts-ignore
  this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: -1 , optionIndexSelected: -1, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
  }
  this.showQuestionDetails = true;
    this.AnswerSubmitted = true;
    setTimeout(() => {
      this.SetFontSizeOnNextPrev();
    }, 0);
    this.calculateTime()
    localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))
    this.getPercentageOthers();
    this.showLinkedText()

    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.addingTableListener()
  }

  getQuestion(currentindex: any): void{
    setTimeout(() => {
      document.querySelectorAll('#explanationArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
      document.querySelectorAll('#questionArea img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 1500)

    this.addingTableListener()

    this.setnewTime();
    this.currentQuestionIndex = currentindex;
    localStorage.setItem("CurrentIndex",this.currentQuestionIndex);
    this.OmittedAnswer= false;
    this.SelectedOptionIndex = -1;
    this.AnswerSubmitted = false;
    this.showQuestionDetails = false;
    this.flagmarked = false;

    // To show FEEDBACK if there is one for this question
    this.checkFeedback();

    // To show Notes If Any
    this.showNotes();

    // For marked Flags
    let k = -1;
    for(let z =0 ; z < this.markedflagsIndex.length; z++){
      if(this.markedflagsIndex[z].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.flagmarked = true;
        k = 0;
      }
    }
    if(k != 0){
      this.flagmarked = false;
    }
    // Marked Question Ends

    for(this.i = 0 ; this.i <  this.AnswerSubmittedValues.length; this.i++ ){
      if(this.AnswerSubmittedValues[this.i].index == this.allQuestions[this.currentQuestionIndex].id){
        this.SelectedOptionIndex = this.AnswerSubmittedValues[this.i].optionIndexSelected;
        this.CorrectAnswerIndex = this.AnswerSubmittedValues[this.i].Correctanswerindex;
        if(this.SelectedOptionIndex == -1){
          this.iscorrectAns = true;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = true
        }
        if(this.AnswerSubmittedValues[this.i].correct == 1){
          this.iscorrectAns = true;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = false
        }
        else{
          this.iscorrectAns = false;
          this.showQuestionDetails = true;
          this.AnswerSubmitted = true;
          this.OmittedAnswer = false;
        }
      }
    }

    setTimeout(()=> {this.SetFontSizeOnNextPrev()},0);
    this.getSelectedOptionIndex();
  this.calculateTime()
  localStorage.setItem("currentQuestionIndex", JSON.stringify(this.currentQuestionIndex))
  this.getPercentageOthers();
  this.showLinkedText()

  this.timerSubscription?.unsubscribe();
  clearInterval(this.timerid)
  if (!this.AnswerSubmitted || this.quizMode == 'exam') {
    this.setQuizTimer()
  }

  this.setQuestionTime();

  this.strikeThroughQuestions();

  }

  interval: any;

  setQuestionTime() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (!this.viewMode) {
      if (!this.AnswerSubmitted || this.quizMode == 'exam') {
        if (localStorage.getItem('questionTime')) {
          this.questionsTime = JSON.parse(localStorage.getItem('questionTime'))
          let index = this.questionsTime.findIndex((item: any) => item.id === this.allQuestions[this.currentQuestionIndex].id)
          if (index !== -1) {
            this.interval = setInterval(() => {
              this.questionsTime[index].time += 1;
              localStorage.setItem('questionTime', JSON.stringify(this.questionsTime))
            }, 1000) 
          }
        }
      }
    }
    // if (this.quizMode == 'exam') {
    //   if (localStorage.getItem('SubmittedQuestions')) {
    //     let questions = JSON.parse(localStorage.getItem('SubmittedQuestions'));
    //     let index = questions.findIndex((item: any) => item.index === this.allQuestions[this.currentQuestionIndex].id)
    //     if (index !== -1) {
    //       this.interval = setInterval(() => {
    //         questions[index].time += 1;
    //         localStorage.setItem('SubmittedQuestions', JSON.stringify(questions))
    //       }, 1000)
    //     }
    //   }
    // } else {
    //   if (localStorage.getItem('SelectedOptionArray')) {
    //     let questions = JSON.parse(localStorage.getItem('SelectedOptionArray'));
    //     let index = questions.findIndex((item: any) => item.index === this.allQuestions[this.currentQuestionIndex].id)
    //     if (index !== -1) {
    //       this.interval = setInterval(() => {
    //         questions[index].time += 1;
    //         localStorage.setItem('SelectedOptionArray', JSON.stringify(questions))
    //       }, 1000)
    //     }
    //   }
    // }
  }


  // *********************************************** NOTES *************************************************
  // submit notes for current question

  markednotesIndex = [{
    index: -1,
    QuestionId: -1
  }]
  notesIndex: any;
  questionIndex: any;

  saveNotes(note = '', callingfrombtn = false): void{
    if(localStorage.getItem("NotesArray")){
      // @ts-ignore
    this.notesValueArray = JSON.parse(localStorage.getItem("NotesArray"))
    }

    this.questionIndex = -1;
    this.showpen = true;
    for(this.notesIndex=0 ; this.notesIndex<this.markednotesIndex.length; this.notesIndex++){
      if(this.markednotesIndex[this.notesIndex].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.questionIndex = this.notesIndex;
        if(note == '' && this.userNotes == ''){
        this.showpen = false;
      }else{
        this.showpen = true;
      }
      }
    }
    if(this.questionIndex == -1){
    this.markednotesIndex.push({index: this.currentQuestionIndex,
    QuestionId: this.allQuestions[this.currentQuestionIndex].id});
    this.showpen = true;
    localStorage.setItem("QuestionwithNotes",JSON.stringify(this.markednotesIndex))
  }
  else{
    if(this.showpen == false){
    this.markednotesIndex.splice(this.questionIndex, 1);
    localStorage.setItem("QuestionwithNotes",JSON.stringify(this.markednotesIndex))
  }
  }

  // To save and show notes
  this.notesValueArray.push({
    questionId: this.allQuestions[this.currentQuestionIndex].id,
    note: (callingfrombtn)?note:this.userNotes,

  })


  // @ts-ignore
  localStorage.setItem("NotesArray", JSON.stringify(this.notesValueArray));

  // To show Notes if there is one for this question
  this.showNotes();

    let data = {
      userId: localStorage.getItem("Id"),
      questionId: this.allQuestions[this.currentQuestionIndex].id,
      note: (callingfrombtn)?note:this.userNotes,
      tableName: "notes",
      quizId: this.quizId,
      quizName: localStorage.getItem("quizTitle")

    };

    if (!this.isDemo) {
      this.subscriptions.push(
        this.quizService.saveNotes(data).subscribe((res: any) => {
  
          },
          (err: any) => {
            AutoLogout(err);
          })
      );
    }
    this.displayModal  = false;
  }

  showNotes() :any{
    if(localStorage.getItem("NotesArray")){
    // @ts-ignore
    this.notesValueArray = JSON.parse(localStorage.getItem("NotesArray"));
  }
    if(this.notesValueArray){
  let isNoteStored = -1;
  for(this.i=1; this.i<this.notesValueArray.length; this.i++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.notesValueArray[this.i].questionId){
      this.userNotes = this.notesValueArray[this.i].note;
      isNoteStored = 1;
    }
    if(isNoteStored != 1){
      this.userNotes = ""
    }
  }
 }
}

  // Highlight Only Mode //
  changeHighlightMode() {
    this.highlightOnlyMode = !this.highlightOnlyMode;

    localStorage.setItem("highlightOnlyMode",JSON.stringify(this.highlightOnlyMode));
  }

  // Split Screen //
  chngesplitScreenExplanations(): any {
    this.splitScreenExplanations = !this.splitScreenExplanations
      
    localStorage.setItem("splitScreenExplanations",JSON.stringify(this.splitScreenExplanations));

    if (this.splitScreenExplanations) {
      setTimeout(() => {
        document.getElementById('explanation_wrapper').style.overflowY = 'scroll';
        document.getElementById('explanation_wrapper').style.maxHeight = '80vh';
        document.getElementById('explanation_wrapper').style.marginLeft = '1rem';

        document.getElementById('question_wrapper').style.overflowY = 'scroll';
        document.getElementById('question_wrapper').style.maxHeight = '80vh';
      }, 10);
    } else {
      setTimeout(() => {
        document.getElementById('explanation_wrapper').style.overflowY = 'auto';
        document.getElementById('explanation_wrapper').style.maxHeight = 'none';
        document.getElementById('explanation_wrapper').style.marginLeft = '0';

        document.getElementById('question_wrapper').style.overflowY = 'auto';
        document.getElementById('question_wrapper').style.maxHeight = 'none';
      }, 10)
    }
  }

// Set font-size to what user have set previously When clicked next or previous btn on quiz
SetFontSizeOnNextPrev(): void{
  if(localStorage.getItem("FontSize")){
  this.currentFontSize_Ques_Options = JSON.parse(localStorage.getItem("FontSize"))
}else{
  this.currentFontSize_Ques_Options = 16;
}

    // explanation-description
    document.getElementById('questionArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    document.getElementById('optionsArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    if(document.getElementById('explanationArea')){
    document.getElementById('explanationArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    }
      localStorage.setItem('FontSize', JSON.stringify(this.currentFontSize_Ques_Options))
  // document.styleSheets[0].insertRule("#question { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
  // document.styleSheets[0].insertRule("#options_forQuestions { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
  // document.styleSheets[0].insertRule("#explanation-description { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);


  // // @ts-ignore
  // const Element2 = document.getElementById("explanation-description")?.getElementsByTagName("h2");

  // // @ts-ignore
  // const Element1 = document.getElementsByTagName("h2")[0];
  // Element1.setAttribute("id", "question1234");

  // for(let i=1; i<document.getElementsByTagName("h2").length; i++){
  // // @ts-ignore
  // const Element1 = document.getElementsByTagName("h2")[i];
  // Element1.setAttribute("class", "question1234");
  // }

  // const Element = document.getElementById('question');

  // const Questionh2 = document.getElementById('question')?.getElementsByTagName("h2")[0];
  //   // if(Questionh2){
  //   // Questionh2.setAttribute("id", "question1234")
  //   document.styleSheets[0].insertRule("#question { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
  // // }

  // // @ts-ignore
  // const styles = getComputedStyle(Element).fontSize;


  // let currentSize = this.currentFontSize_Ques_Options-1;
  // const adder = this.currentFontSize_Ques_Options;
  //   if(Element2){
  //     for(let i=0; i<Element2.length; i++){
  //       Element2[i].setAttribute("class", "Explanationh2")
  //     }
  //     for(let i=0; i<Element2.length; i++){
  //       document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+adder+"px !important }", this.num++);
  //     }
  //     document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+adder+"px !important }", this.num++);

  //   }
  //   if(Element1.getElementsByTagName("span")[0]){
  //     let questionspan = Element1.getElementsByTagName("span")[0];
  //     questionspan.setAttribute("id", "questionSpan12")
  //     document.styleSheets[0].insertRule("#questionSpan12 { font-size: "+adder+"px !important }", this.num++);
  //     for(let i=1; i<Element1.getElementsByTagName("span").length; i++){
  //       let questionspan = Element1.getElementsByTagName("span")[i];
  //       questionspan.setAttribute("class", "questionSpan12");
  //       document.styleSheets[0].insertRule(".questionSpan12 { font-size: "+adder+"px !important }", this.num++);
  //     }
  //   }
  //   // @ts-ignore
  //   document.getElementById('question').style.fontSize = currentSize + 1 + 'px';

  //   document.styleSheets[0].insertRule("#question1234 { font-size: "+adder+"px !important }", this.num++);
  //   document.styleSheets[0].insertRule(".question1234 { font-size: "+adder+"px !important }", this.num++);
  //   // explanation-description
  //   // document.styleSheets[0].insertRule("#explanation-description { font-size: "+adder+"px !important }", this.num++);
  //   const options = document.getElementsByClassName('question-option-area');
  //   for (let i = 0; i < options.length; i++) {
  //     // @ts-ignore
  //     options[i].style.fontSize = currentSize + 1 + 'px';
  //   }
  //   if (this.optionSelected){
  //     // @ts-ignore
  //     document.getElementById('explanation-description').style.fontSize = currentSize + 1 + 'px';
  //   }
  // localStorage.setItem("inserRuleNum", JSON.stringify(this.num))
}

  // increase font size on quiz screen
  increaseFontSize(): void{
    if(this.currentFontSize_Ques_Options <= 30){
    this.currentFontSize_Ques_Options++;
  }
    // explanation-description
    document.getElementById('questionArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    document.getElementById('optionsArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    if(document.getElementById('explanationArea')){
    document.getElementById('explanationArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    }
    localStorage.setItem('FontSize', JSON.stringify(this.currentFontSize_Ques_Options))


    // this.currentFontSize_Ques_Options++;
    // document.styleSheets[0].insertRule("#question { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);

    // document.styleSheets[0].insertRule("#options_forQuestions { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
    // document.styleSheets[0].insertRule("#explanation-description { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
    // localStorage.setItem("FontSize", this.currentFontSize_Ques_Options);
    // @ts-ignore
  //   const Element2 = document.getElementById("explanation-description")?.getElementsByTagName("h2");


  //   // @ts-ignore
  //   const Element1 = document.getElementsByTagName("h2")[0];
  //   Element1.setAttribute("id", "question1234")


  //   const Element = document.getElementById('question');
  //   const Questionh2 = document.getElementById('question')?.getElementsByTagName("h2")[0];
  //   if(Questionh2){
  //   Questionh2.setAttribute("id", "question1234")
  //   document.styleSheets[0].insertRule("#question1234 { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
  // }

  //   // @ts-ignore
  //   const styles = getComputedStyle(Element).fontSize;

  //   let currentSize = +(styles.substring(0, 2));
  //   const adder = currentSize + 1;
  //   if (currentSize <= 26){
  //     if(Element2){
  //       for(let i=0; i<Element2.length; i++){
  //         Element2[i].setAttribute("class", "Explanationh2")
  //       }
  //       for(let i=0; i<Element2.length; i++){
  //         document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+adder+"px !important }", this.num++);
  //       }
  //       document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+adder+"px !important }", this.num++);

  //     }
  //     if(Element1.getElementsByTagName("span")[0]){
  //       let questionspan = Element1.getElementsByTagName("span")[0];
  //       questionspan.setAttribute("class", "questionSpan12")
  //       document.styleSheets[0].insertRule("#questionSpan12 { font-size: "+adder+"px !important }", this.num++);
  //     }
  //     // @ts-ignore
  //     document.getElementById('question').style.fontSize = currentSize + 1 + 'px';

  //     document.styleSheets[0].insertRule("#question1234 { font-size: "+adder+"px !important }", this.num++);
  //     // explanation-description
  //     // document.styleSheets[0].insertRule("#explanation-description { font-size: "+adder+"px !important }", this.num++);
  //     const options = document.getElementsByClassName('question-option-area');
  //     for (let i = 0; i < options.length; i++) {
  //       // @ts-ignore
  //       options[i].style.fontSize = currentSize + 1 + 'px';
  //     }
  //     if (this.optionSelected){
  //       // @ts-ignore
  //       document.getElementById('explanation-description').style.fontSize = currentSize + 1 + 'px';
  //     }
  //   }
  //   else{
  //     alert('You have reached maximum size limit!');
  //   }
  //   this.currentFontSize_Ques_Options = adder;
  //   let fontSize = {
  //     QuizId: this.quizId,
  //     fontsize: this.currentFontSize_Ques_Options
  //   }
  //   localStorage.setItem('FontSize', JSON.stringify(fontSize))
  // localStorage.setItem("inserRuleNum", JSON.stringify(this.num))
  }
  // decrease font size on quiz screen
  decreaseFontSize(): void{
    if(this.currentFontSize_Ques_Options >= 11){
    this.currentFontSize_Ques_Options--;
  }
    // explanation-description
    document.getElementById('questionArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    document.getElementById('optionsArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    if(document.getElementById('explanationArea')){
    document.getElementById('explanationArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    }
      localStorage.setItem('FontSize', JSON.stringify(this.currentFontSize_Ques_Options))
    // this.currentFontSize_Ques_Options--;
    // document.styleSheets[0].insertRule("#question { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
    // document.styleSheets[0].insertRule("#options_forQuestions { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
    // document.styleSheets[0].insertRule("#explanation-description { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);
    // localStorage.setItem("FontSize", this.currentFontSize_Ques_Options);
  //   const Element2 = document.getElementById("explanation-description")?.getElementsByTagName("h2");
  //   const Element1 = document.getElementsByTagName("h2")[0];
  //   Element1.setAttribute("id", "question1234")
  //   const Element = document.getElementById('question');

  //   const Questionh2 = document.getElementById('question')?.getElementsByTagName("h2")[0];
  //   if(Questionh2){
  //   Questionh2.setAttribute("id", "question1234")

  //   document.styleSheets[0].insertRule("#question1234 { font-size: "+this.currentFontSize_Ques_Options+"px !important }", this.num++);

  // }
  //   // @ts-ignore
  //   const styles = getComputedStyle(Element).fontSize;
  //   const currentSize = +(styles.substring(0, 2));
  //   const subtractor = currentSize - 1;
  //   if (currentSize >= 14){
  //     if(Element2){
  //       for(let i=0; i<Element2.length; i++){
  //         Element2[i].setAttribute("class", "Explanationh2")
  //       }
  //       for(let j=0; j<Element2.length; j++){
  //         document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+subtractor+"px !important }", this.num++);
  //       }
  //       document.styleSheets[0].insertRule(".Explanationh2 { font-size: "+subtractor+"px !important }", this.num++);
  //     }
  //     if(Element1.getElementsByTagName("span")[0]){
  //       let questionspan = Element1.getElementsByTagName("span")[0];
  //       questionspan.setAttribute("id", "questionSpan12")
  //       document.styleSheets[0].insertRule("#questionSpan12 { font-size: "+subtractor+"px !important }", this.num++);
  //     }
  //     // @ts-ignore
  //     document.getElementById('question').style.fontSize = currentSize - 1 + 'px';
  //     document.styleSheets[0].insertRule("#question1234 { font-size: "+subtractor+"px !important }", this.num++);

  //     const options = document.getElementsByClassName('question-option-area');
  //     for (let i = 0; i < options.length; i++) {
  //       // @ts-ignore
  //       options[i].style.fontSize = currentSize - 1 + 'px';
  //     }
  //     if (this.optionSelected){
  //       // @ts-ignore
  //       document.getElementById('explanation-description').style.fontSize = currentSize - 1 + 'px';
  //     }
  //   }
  //   else{
  //     alert('You have reached minimum size limit!');
  //   }
  //   this.currentFontSize_Ques_Options = subtractor;
  //   let fontSize = {
  //     QuizId: this.quizId,
  //     fontsize: this.currentFontSize_Ques_Options
  //   }
  //   localStorage.setItem('FontSize', JSON.stringify(fontSize))
  // localStorage.setItem("inserRuleNum", JSON.stringify(this.num))
  }
  // reset font size to initial value
  resetFontSize(): void{
    this.currentFontSize_Ques_Options = 16;
    // explanation-description
    document.getElementById('questionArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    document.getElementById('optionsArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    if(document.getElementById('explanationArea')){
    document.getElementById('explanationArea').style.fontSize = `${this.currentFontSize_Ques_Options}px`
    }
    localStorage.setItem('FontSize', JSON.stringify(this.currentFontSize_Ques_Options))
    // document.styleSheets[0].insertRule("#question { font-size: 14px !important }", this.num++);
    // document.styleSheets[0].insertRule("#options_forQuestions { font-size: 14px !important }", this.num++);
    // document.styleSheets[0].insertRule("#explanation-description { font-size: 14px !important }", this.num++);
    // localStorage.setItem("FontSize", this.currentFontSize_Ques_Options);
    // const Element2 = document.getElementById("explanation-description")?.getElementsByTagName("h2");
    // if(Element2){
    //   for(let i=0; i<Element2.length; i++){
    //     Element2[i].setAttribute("class", "Explanationh2")
    //   }
    //   for(let j=0; j<Element2.length; j++){
    //     document.styleSheets[0].insertRule(".Explanationh2 { font-size: 14px !important }", this.num++);
    //   }
    // }
    // const Element1 = document.getElementsByTagName("h2")[0];
    // Element1.setAttribute("id", "question1234")
    // // @ts-ignore
    // document.getElementById('question').style.fontSize = '14px';
    // document.styleSheets[0].insertRule("#question1234 { font-size: 14px !important }", this.num++);
    // if(Element1.getElementsByTagName("span")[0]){
    //   let questionspan = Element1.getElementsByTagName("span")[0];
    //   questionspan.setAttribute("id", "questionSpan12")
    //   document.styleSheets[0].insertRule("#questionSpan12 { font-size: 14px !important }", this.num++);
    // }
    // const options = document.getElementsByClassName('question-option-area');
    // for (let i = 0; i < options.length; i++) {
    //   // @ts-ignore
    //   options[i].style.fontSize = '14px';
    // }
    // if (this.optionSelected){
    //   // @ts-ignore
    //   document.getElementById('explanation-description').style.fontSize = '14px';
    // }
    // this.currentFontSize_Ques_Options = 14;
    // let fontSize = {
    //   QuizId: this.quizId,
    //   fontsize: this.currentFontSize_Ques_Options
    // }
    // localStorage.setItem('FontSize', JSON.stringify(fontSize))
    // localStorage.setItem("inserRuleNum", JSON.stringify(this.num))
  }



  //  *************************************** LAB VALUES *****************************************************
  toggleclick(): void{
    this.displaySidebar = !this.displaySidebar;
    this.hideDot();
  }

  //            LAB                  VALUES
  activateMenu(){
    // @ts-ignore
    this.activeItem = this.menu.activeItem;
  }
  toggleLabValues(): void {
    this.openRightSideBar = !this.openRightSideBar;
    if (!this.openRightSideBar){
      // @ts-ignore
      document.getElementById('mainBodyArea').style.marginRight = '0';
      // @ts-ignore
      document.getElementById('labValues').style.width = '0';

    }
    else{
      // @ts-ignore
      document.getElementById('labValues').style.width = '522px';
      // @ts-ignore
      document.getElementById('mainBodyArea').style.marginRight = '23%';
    }
  }


  // search feature in lab values
  searchLabValues(event : any): void {
    // let filter = event.target.value.toLowerCase();
    const nodes = document.getElementsByClassName('csq-labvalues-search');

    if (this.labValueFilter === ''){
      for (let i = 0; i < nodes.length; i++) {
        // @ts-ignore
        nodes[i].classList.remove('csq-hightlight-lab-value');
      }
      return;
    }
    for (let i = 0; i < nodes.length; i++) {
      // @ts-ignore
      if (nodes[i].innerText.toLowerCase().includes(this.labValueFilter.toLowerCase())) {
        // @ts-ignore
        nodes[i].classList.add('csq-hightlight-lab-value');
      }
      else {
        // @ts-ignore
        nodes[i].classList.remove('csq-hightlight-lab-value');
      }
    }

  }

  // **************************** SETTINGS ***************************************
  // ******************** Color Theme **************************
  Quiztheme: any;
  checkTheme(): void{
    // @ts-ignore
    this.Quiztheme = JSON.parse(localStorage.getItem('QuizTheme'));
    if(this.Quiztheme && this.Quiztheme.QuizId == this.quizId){
      if(this.Quiztheme.isLightTheme == true){
        this.isLightTheme = true
      }
      else if(this.Quiztheme.isBlackTheme == true){
        this.isBlackTheme = true;
      }
      else{
        this.Quiztheme.isDefaultTheme = true;
      }
    }
  }
  // switch to black theme for quiz screen
  switchBlackTheme(): void{
    this.isBlackTheme = !this.isBlackTheme;
    this.isDefaultTheme = false;
    this.isLightTheme = false;
    let theme = {
      QuizId: this.quizId,
      isBlackTheme: this.isBlackTheme,
      isDefaultTheme: this.isDefaultTheme,
      isLightTheme: this.isLightTheme
    }
    localStorage.setItem('QuizTheme', JSON.stringify(theme));
  }
  // switch to light theme for quiz screen
  switchLightTheme(): void{
    this.isLightTheme = !this.isLightTheme;
    this.isBlackTheme = false;
    this.isDefaultTheme = false;
    let theme = {
      QuizId: this.quizId,
      isBlackTheme: this.isBlackTheme,
      isDefaultTheme: this.isDefaultTheme,
      isLightTheme: this.isLightTheme
    }
    localStorage.setItem('QuizTheme', JSON.stringify(theme));
  }
  // switch to default theme for quiz screen
  switchDefaultTheme(): void{
    this.isDefaultTheme = !this.isDefaultTheme;
    this.isBlackTheme = false;
    this.isLightTheme = false;
    let theme = {
      QuizId: this.quizId,
      isBlackTheme: this.isBlackTheme,
      isDefaultTheme: this.isDefaultTheme,
      isLightTheme: this.isLightTheme
    }
    localStorage.setItem('QuizTheme', JSON.stringify(theme));
  }
  // turn automatic night mode after the time set by user
  TriggerAutoNightMode(): void{
    this.automaticNightMode = !this.automaticNightMode
    if(this.automaticNightMode === true ){
      this.turnAutomaticNightModeOn();
    }
    localStorage.setItem("automaticNightmode", JSON.stringify(this.automaticNightMode));
  }
  turnAutomaticNightModeOn(): void{
    if(this.automaticNightMode === true){
    const date = new Date();
    const hours = date.getTime();
    this.isBlackTheme = (hours >= this.startTime.getTime()) && (hours <= this.endTime.getTime());
  }
  else{
    // Automatic time mode is off
  }
  }
  // show timer or not
  toggleTimer(): void{
    this.showTimer  = !this.showTimer;
  }


  // *********************** On Screen navbar features *********************** //
  // toggle full screen - feature
  toggleFullScreen(): void{
    if (!this.isFullScreen){
      this.isFullScreen = true;
      this.openFullScreen();
    }
    else{
      this.isFullScreen = false;
    }
  }
  openFullScreen(): void{
    const elem = document.documentElement;
    // @ts-ignore
    var requestFullScreen = elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen || elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);;
    requestFullScreen.call(elem);

    if (elem.requestFullscreen) {
      elem?.requestFullscreen();
    }
  }
  HideHighlightControls(){
    document.getElementById('control').style.display = 'none';
  }
   addTextToNotes = (event) => {
    var text = window?.getSelection()?.toString();
    console.log(text)
    this.saveNotes(this.tempUserNotes,true);
    document.getElementById('control').style.display = 'none';
    this.displayModal = true;
   }
   notebookText: any;
   quizEdit = false;
   addtoNotebook = (event) => {
    this.shownotebookdialog = true;
    var html = "";
    if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
            var container = document.createElement("div");
            for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            html = container.innerHTML;
        }
    }
    this.notebookText = html;
    this.quizEdit = true
    this.HideHighlightControls()
    this.removeImageDiv()
   }
   highlightingTextStarts =(event) => {
    this.highlightRange(this.safeRangeSingle);
    document.getElementById('control').style.display = 'none';
    event.stopPropagation();
  }
  isHighlighting = false;
  safeRangeSingle: any;
  tempUserNotes = ''
  highlightTxt(e:any): void{
    this.tempUserNotes = window.getSelection()?.toString();

    var control = document.getElementById('control');
    if (!this.highlightOnlyMode) {
      document.getElementById('highlightingIcon').addEventListener('pointerdown', this.highlightingTextStarts, true);
      document.getElementById('addingtotext').addEventListener('pointerdown', this.addTextToNotes, true);
      document.getElementById('notebook').addEventListener('pointerdown', this.addtoNotebook, true);
      document.getElementById('newFlashcard').addEventListener('pointerdown', this.addNewFlashcard, true);
      document.getElementById('existingFlashcard').addEventListener('pointerdown', this.addExistingFlashcard, true);
    }

    this.isHighlighting = true;
    var replacingText = '';
    var selection = window?.getSelection();

    var text = window?.getSelection()?.toString();
    if(!text){
      this.HideHighlightControls()
      this.deleteText(e);
    }
      // @ts-ignore
      const userSelection = window.getSelection()?.getRangeAt(0);
      this.safeRanges = this.getSafeRanges(userSelection);
      this.safeRangeSingle = this.safeRanges[0];
        if(text.length>0){

          if (!this.highlightOnlyMode) {
            document.getElementById('controlTable').style.display = 'none';
            let rect = selection.getRangeAt(0).getBoundingClientRect();
            if(this.isIOS){
            control.style.top = `calc(${rect.bottom}px + 0px)`;
            }
            else{
              control.style.top = `calc(${rect.top}px - 48px)`;
            }
            control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`;
            control['text']= text;
            if((document.getElementById('control').style.display == 'inline') && this.isIOS){
              document.getElementById('control').style.display = 'none';
            }else{
              document.getElementById('control').style.display = 'inline';
            }
          }
      }else{
        this.HideHighlightControls()
        this.deleteText(e);
        this.allQuestions = JSON.parse(localStorage.getItem('AllQuestions'));
      }
   
    if (this.highlightOnlyMode) {
      if (selection.toString() !== '') {
        this.highlightRange(this.safeRangeSingle);
      }
    }
  }
  deleteText(e:any){
    let selection;
    if(this.isIOS){
      selection = e.target.innerHTML
    }else{
      selection = e.target.innerHTML
    }

    if(selection) {
      let id = e.target.id
      
      if (id != "") {
        if (id.match(/\d+/)) {
          let number = id.match(/\d+/)[0]
          this.allQuestions = JSON.parse(localStorage.getItem('AllQuestions'));
          this.currentQuestionIndex = JSON.parse(localStorage.getItem('currentQuestionIndex'))
          var replacingText = selection.toString()
          selection = `<label${number} class="highlight" id="${id}">${selection}</label${number}>`
    
          let startPattern = `<label${number} class="highlight" id="${id}">`
          let startRegex = new RegExp(startPattern, "g");
    
          let endPattern = `</label${number}>`
          let endRegex = new RegExp(endPattern, "g");
    
          var qIndex = this.allQuestions[this.currentQuestionIndex]?.question?.indexOf(selection);
          var eIndex = this.allQuestions[this.currentQuestionIndex]?.correct_msg?.indexOf(selection);

          if (this.highlightingFailed) {
            document.getElementById('explanation-course').innerHTML = document.getElementById('explanation-course').innerHTML.replace(startRegex, "")
            document.getElementById('explanation-course').innerHTML = document.getElementById('explanation-course').innerHTML.replace(endRegex, "")

            document.getElementById('optionsArea').innerHTML = document.getElementById('optionsArea').innerHTML.replace(startRegex, "")
            document.getElementById('optionsArea').innerHTML = document.getElementById('optionsArea').innerHTML.replace(endRegex, "")

            this.highlightingFailed = false;
          }

          if(qIndex != -1){
            //@ts-ignore
            this.allQuestions[this.currentQuestionIndex]?.question = this.allQuestions[this.currentQuestionIndex]?.question.replace(startRegex, "")
            //@ts-ignore
            this.allQuestions[this.currentQuestionIndex]?.question = this.allQuestions[this.currentQuestionIndex]?.question.replace(endRegex, "")
    
            //this.allQuestions[this.currentQuestionIndex].question = this.allQuestions[this.currentQuestionIndex].question.replace(selection,replacingText)
          }
          else{
            //@ts-ignore
            this.allQuestions[this.currentQuestionIndex]?.correct_msg = this.allQuestions[this.currentQuestionIndex]?.correct_msg.replace(startRegex, "")
            //@ts-ignore
            this.allQuestions[this.currentQuestionIndex]?.correct_msg = this.allQuestions[this.currentQuestionIndex]?.correct_msg.replace(endRegex, "")
            
            //this.allQuestions[this.currentQuestionIndex].correct_msg = this.allQuestions[this.currentQuestionIndex].correct_msg.replace(selection,replacingText)
          }
    
          var tempAllQuestions = this.allQuestions;
          this.allQuestions = []
          this.allQuestions = tempAllQuestions;
          localStorage.setItem('AllQuestions', JSON.stringify(this.allQuestions))
  
          this.addingImageListener()
          this.addingTableListener()
  
          if(localStorage.getItem('highlightedQuestionIndexes')){
            this.highlightedQuestionIndexes = JSON.parse(localStorage.getItem('highlightedQuestionIndexes'));
          }else{
            this.highlightedQuestionIndexes = []
          }
  
          //@ts-ignore
          var Qindex = this.highlightedQuestionIndexes.findIndex((question: any) => question.questionId === this.allQuestions[this.currentQuestionIndex]?.id);
          if(Qindex == -1){
            this.highlightedQuestionIndexes.push({
              questionText: this.allQuestions[this.currentQuestionIndex]?.question,
              explanationText: this.allQuestions[this.currentQuestionIndex]?.correct_msg, 
              questionId: this.allQuestions[this.currentQuestionIndex]?.id
            })
          } else {
            this.highlightedQuestionIndexes[Qindex] = {
              questionText: this.allQuestions[this.currentQuestionIndex]?.question,
              explanationText: this.allQuestions[this.currentQuestionIndex]?.correct_msg, 
              questionId: this.allQuestions[this.currentQuestionIndex]?.id
            }
          }
  
          localStorage.setItem('highlightedQuestionIndexes', JSON.stringify(this.highlightedQuestionIndexes));
        }
      }
    }
  }
  highlightRange(range1 : any): void{

    // console.log(" %%%% ")
    // const newNode = document.createElement('label');
    // newNode.className = "highlight";
    // // const x = newNode.getAttribute('class');

    // // newNode.setAttribute(
    // //   'style',
    // //   'background-color: yellow; display: inline;'
    // // );


    // range.surroundContents(newNode);

    /// New Highlighter Implementation ///

    var selection = document.getSelection(),
    range = selection.getRangeAt(0).cloneRange();
    var start = range.startContainer;
    var end = range.endContainer;
    var ranges = [];

	  while (start !== end) {
      var startText = start.nodeValue;
      var currentRange = range.cloneRange();
      currentRange.setEnd(start, startText.length);
      ranges.push(currentRange);
      var sibling = start;
      do {
        if (sibling.hasChildNodes()) {
          sibling = sibling.firstChild;
        } else if (sibling.nextSibling) {
          sibling = sibling.nextSibling;
        } else {
          while (!sibling.nextSibling && sibling.parentNode) {
              sibling = sibling.parentNode;
          }
          if (sibling) {
              sibling = sibling.nextSibling;
          }
        }
      } while (sibling !== null && sibling.nodeValue === null);
      if (!sibling) {
        break;
      }
      range.setStart(sibling, 0);
      start = range.startContainer;
	  }

    for (var i = 0; i < ranges.length; i++) {
      const label = document.createElement('label'+this.counter);
      label.className = 'highlight'
      label.setAttribute("id", "highlighter"+this.counter);
      var currentRange1 = ranges[i];
      try {
        currentRange1.surroundContents(label);
      } catch (e) {
        this.highlightingFailed = true;
      }
    }

    const label = document.createElement('label'+this.counter);
    label.className = 'highlight'
    label.setAttribute("id", "highlighter"+this.counter);
    range.surroundContents(label);

    this.counter ++
    localStorage.setItem('counter',  JSON.stringify(this.counter))

    selection.removeAllRanges();

    /// ------------------------------- ///

    var ques = document.getElementById('question1')?.innerHTML.toString();
    var explanation = document.getElementById('explanationArea')?.innerHTML.toString();
    if(explanation){
    this.allQuestions[this.currentQuestionIndex].correct_msg = explanation;
    }
    this.allQuestions[this.currentQuestionIndex].question = ques;

    localStorage.setItem('AllQuestions', JSON.stringify(this.allQuestions))
    if(localStorage.getItem('highlightedQuestionIndexes')){
      this.highlightedQuestionIndexes = JSON.parse(localStorage.getItem('highlightedQuestionIndexes'));
    }else{
      this.highlightedQuestionIndexes = []
    }

    //@ts-ignore
    var Qindex = this.highlightedQuestionIndexes.findIndex((question: any) => question.questionId === this.allQuestions[this.currentQuestionIndex]?.id);
    if(Qindex == -1){
      this.highlightedQuestionIndexes.push({
        questionText: this.allQuestions[this.currentQuestionIndex]?.question,
        explanationText: this.allQuestions[this.currentQuestionIndex]?.correct_msg, 
        questionId: this.allQuestions[this.currentQuestionIndex]?.id
      })
    } else {
      this.highlightedQuestionIndexes[Qindex] = {
        questionText: this.allQuestions[this.currentQuestionIndex]?.question,
        explanationText: this.allQuestions[this.currentQuestionIndex]?.correct_msg, 
        questionId: this.allQuestions[this.currentQuestionIndex]?.id
      }
    }

    localStorage.setItem('highlightedQuestionIndexes', JSON.stringify(this.highlightedQuestionIndexes));

    this.addingImageListener()
    this.addingTableListener()
  }

  // Highlight Feature
  getSafeRanges(dangerous: any): any {
    const a = dangerous.commonAncestorContainer;
    // Starts -- Work inward from the start, selecting the largest safe range
    const s = new Array(0), rs = new Array(0);
    if (dangerous.startContainer !== a) {
      for (let i = dangerous.startContainer; i !== a; i = i.parentNode) {
        s.push(i)
        ;
      }
    }
    if (0 < s.length) { for (let i = 0; i < s.length; i++) {
      const xs = document.createRange();
      if (i) {
        xs.setStartAfter(s[i - 1]);
        xs.setEndAfter(s[i].lastChild);
      }
      else {
        xs.setStart(s[i], dangerous.startOffset);
        xs.setEndAfter(
          (s[i].nodeType === Node.TEXT_NODE)
            ? s[i] : s[i].lastChild
        );
      }
      rs.push(xs);
    }
    }

    // Ends -- basically the same code reversed
    const e = new Array(0);
    const re = new Array(0);
    if (dangerous.endContainer !== a) {
      for (let i = dangerous.endContainer; i !== a; i = i.parentNode) {
        e.push(i)
        ;
    }
      }
    if (0 < e.length) { for (let i = 0; i < e.length; i++) {
      const xe = document.createRange();
      if (i) {
        xe.setStartBefore(e[i].firstChild);
        xe.setEndBefore(e[i - 1]);
      }
      else {
        xe.setStartBefore(
          (e[i].nodeType === Node.TEXT_NODE)
            ? e[i] : e[i].firstChild
        );
        xe.setEnd(e[i], dangerous.endOffset);
      }
      re.unshift(xe);
    }
    }
    let xm;
    // Middle -- the uncaptured middle
    if ((0 < s.length) && (0 < e.length)) {
      xm = document.createRange();
      xm.setStartAfter(s[s.length - 1]);
      xm.setEndBefore(e[e.length - 1]);
    }
    else {
      return [dangerous];
    }

    // Concat
    rs.push(xm);
    // Send to Consoole
    return rs.concat(re);
  }


  // GETTING OPTIONS OF ANSWER
  // utility function to convert numeric options to characters to display and other functionalities
  x: any;
  AllOptions: any;
  getOptionValue(i: any): any{
    // getting Options when selected
    if(localStorage.getItem("SelectedOptionArray")){
      this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"));
    }else{
      this.SelectedOptionsArray = []
    }

    if(localStorage.getItem("DotQuestionsArray")){
      this.dotQuestionsArray = JSON.parse(localStorage.getItem("DotQuestionsArray"));
    }else{
      this.dotQuestionsArray = []
    }
    

  let isoptionSelected = 0;
  let indexOfselectedOption;
  for(let k=0; k < this.SelectedOptionsArray.length; k++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.SelectedOptionsArray[k].index){
      isoptionSelected = 1;
      indexOfselectedOption = k;
    }
  }
    if(isoptionSelected == 1){
      this.SelectedOptionsArray.splice(indexOfselectedOption, 1);
    }

    this.checkSingleQuestionTime();
    this.AllOptions = this.allQuestions[this.currentQuestionIndex].statistics;
    this.CheckOptionsSelected = this.allQuestions[this.currentQuestionIndex].statistics[i];

    console.log(this.AnswerSubmitted)

    if (!this.AnswerSubmitted || this.quizMode == 'exam') {
      let answerIndex = this.answersArray.findIndex((item: any) => item.QuestionID == this.allQuestions[this.currentQuestionIndex].id)
      if (answerIndex !== -1) {
        if(localStorage.getItem("SelectedOptionArray")) {
          let questionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"))
          let questionIndex = questionsArray.findIndex((item: any) => item.index == this.allQuestions[this.currentQuestionIndex].id)
          console.log(questionIndex, questionsArray)
          if (questionIndex !== -1) {
            if (questionsArray[questionIndex].correct == 1 && this.CheckOptionsSelected == 1) {
              this.answersArray[answerIndex].Status = 1
            } else if (questionsArray[questionIndex].correct == 1 && this.CheckOptionsSelected == 0) {
              this.answersArray[answerIndex].Status = 2
            } else if (questionsArray[questionIndex].correct == 0 && this.CheckOptionsSelected == 1) {
              this.answersArray[answerIndex].Status = 3
            } else if (questionsArray[questionIndex].correct == 0 && this.CheckOptionsSelected == 0) {
              this.answersArray[answerIndex].Status = 4
            }
            localStorage.setItem('AnswerChanges', JSON.stringify(this.answersArray))
          }
        }
      } else {
        this.answersArray.push({
          QuestionID: this.allQuestions[this.currentQuestionIndex].id,
          Status: 5
        })
        localStorage.setItem('AnswerChanges', JSON.stringify(this.answersArray))
      }
    }

      // DOING SOMTHING IN TUTOR MODE
    if(this.AnswerSubmitted == false){
      for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
        if(this.AllOptions[this.x] == 1){
          this.CorrectAnswerIndex = this.x;
        }
      }
      if(this.CheckOptionsSelected == 1){
        this.iscorrectAns = true;
        this.SelectedOptionIndex = i;
        this.SelectedOptionsArray.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 1 , optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
        localStorage.setItem("SelectedOptionArray", JSON.stringify(this.SelectedOptionsArray));
      }
      else{
        this.iscorrectAns = false;
        this.SelectedOptionIndex = i;
        this.SelectedOptionsArray.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 0 , optionIndexSelected: this.SelectedOptionIndex, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
        localStorage.setItem("SelectedOptionArray", JSON.stringify(this.SelectedOptionsArray));
      }
  }

if(this.quizMode == 'exam'){
  // DOING SOMETHING IN EXAM MODE

  if(localStorage.getItem("SubmittedQuestions")){
    this.AnswerSubmittedValues = JSON.parse(localStorage.getItem("SubmittedQuestions"));
  }

  let issubmittedAnswer = 0;
  let indexOfanswerSubmitted;
  for(let k=0; k<this.AnswerSubmittedValues.length; k++){
    if(this.allQuestions[this.currentQuestionIndex].id == this.AnswerSubmittedValues[k].index){
      issubmittedAnswer = 1;
      indexOfanswerSubmitted = k;
    }
  }
    if(issubmittedAnswer == 0){
      // Answer not present
      this.set_LocalStorage_ExamMode(i);
    }else{
      this.AnswerSubmittedValues.splice(indexOfanswerSubmitted, 1);
      this.set_LocalStorage_ExamMode(i);
    }


}

this.setQuestionTime()
  }

  set_LocalStorage_ExamMode(i: any): void{
    this.checkSingleQuestionTime()
    if(i == -1){
      this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: -1 , optionIndexSelected: i, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
    }
    else{
      // THIS QUESTION IS NOT PRESENT IN ANSWERSUBMITTEDVALUES
      if(this.CheckOptionsSelected == 1){
        this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 1 , optionIndexSelected: i, Correctanswerindex: i, time: this.singleQuestionTime});
      }
      else{
        // Answer Submitted is wrong
        for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
                if(this.AllOptions[this.x] == 1){
                  this.CorrectAnswerIndex = this.x;
                }
              }
        this.AnswerSubmittedValues.push({index: this.allQuestions[this.currentQuestionIndex].id, correct: 0 , optionIndexSelected: i, Correctanswerindex: this.CorrectAnswerIndex, time: this.singleQuestionTime});
      }
    }

    if (localStorage.getItem('SelectedOptionArray')) {
      let selectedArray = JSON.parse(localStorage.getItem('SelectedOptionArray'))
      selectedArray.forEach((element: any) => {
        let questionIndex = this.AnswerSubmittedValues.findIndex((item: any) => item.index == element.index)
        if (questionIndex !== -1) {
          element.optionIndexSelected = this.AnswerSubmittedValues[questionIndex].optionIndexSelected
          element.correct = this.AnswerSubmittedValues[questionIndex].correct
          element.time = this.AnswerSubmittedValues[questionIndex].time
        }
      })
      localStorage.setItem('SelectedOptionArray', JSON.stringify(selectedArray));
    }

      localStorage.setItem("SubmittedQuestions", JSON.stringify(this.AnswerSubmittedValues));

      const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);
      if (localStorage.getItem('SubmittedQuestions')) {
        let submittedQuestions = JSON.parse(localStorage.getItem('SubmittedQuestions'))
        if (submittedQuestions.length > 0) {
          if (exclude(submittedQuestions, this.dotQuestionsArray).length > 0) {
            this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(submittedQuestions, this.dotQuestionsArray)]
          }
        }
      }
      if (localStorage.getItem('SelectedOptionArray')) {
        let selectedQuestions = JSON.parse(localStorage.getItem('SelectedOptionArray'))
        if (selectedQuestions.length > 0) {
          if (exclude(selectedQuestions, this.dotQuestionsArray).length > 0) {
            this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(selectedQuestions, this.dotQuestionsArray)]
          }
        }
      }
  
      localStorage.setItem('dotQuestionsArray',  JSON.stringify(this.dotQuestionsArray))
      this.hideDot();

  }


  // strikeThroughAns(i: any): void{
  //   const element = document.getElementById(this.getOptionValue(i));
  //   // @ts-ignore
  //   if (element.style.textDecoration === ''){
  //     // @ts-ignore
  //     element.style.textDecoration = 'line-through';

  //     this.strikedThroughOptions.push({qId: this.currentQuestion.q_id, strikedAns : i});
  //   }
  //   else {
  //     // @ts-ignore
  //     element.style.textDecoration = '';
  //     // @ts-ignore
  //     const index = this.strikedThroughOptions.findIndex(x => ((x.qId === this.currentQuestion.q_id) && (x.strikedAns === i)));
  //     if (index !== -1){
  //       this.strikedThroughOptions.splice(index, 1);
  //     }
  //   }
  //   localStorage.setItem('strikedThroughOptions', JSON.stringify(this.strikedThroughOptions));
  // }


  // FOOTER FEEDBACK **********************
  // submit feedback for current question
  submitFeedback(): void{
  // Saving feedback in an array to show it later
  this.feedbackValueArray.push({
    questionNo: this.allQuestions[this.currentQuestionIndex].id,
    feedback: this.userFeedback
  })
  // Storing Feedback array to local host
  localStorage.setItem("FeedbackArray", JSON.stringify(this.feedbackValueArray))
    const data = {
      feedback: this.userFeedback,
      isTechIssue: this.isTechIssue,
      userId: localStorage.getItem("Id"),
      questionId: this.allQuestions[this.currentQuestionIndex].id,
      tableName: "feedbacks"
    };
    this.subscriptions.push(
      this.quizService.saveFeedback(data).subscribe((res: any) => {
        // res here
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
    this.displayFeedbackModal = false;
  }


  // ************************************ MARK QUESTION ********************************************
  flagmarked = false;
  markedflagsIndex = [{
    index: -1,
    QuestionId: -1
  }]
  y: any;
  z: any;
  markQuestion(): void{
    if(localStorage.getItem("flaggedQuestion")){
      // @ts-ignore
      this.markedflagsIndex = JSON.parse(localStorage.getItem("flaggedQuestion"))
    }
    this.z = -1;
    for(this.y=0 ; this.y<this.markedflagsIndex.length; this.y++){
      if(this.markedflagsIndex[this.y].QuestionId == this.allQuestions[this.currentQuestionIndex].id){
        this.z = this.y;
        this.flagmarked = false;
      }
    }
    if(this.z == -1){
    this.markedflagsIndex.push({index: this.currentQuestionIndex,
    QuestionId: this.allQuestions[this.currentQuestionIndex].id});
    this.flagmarked = true;
    localStorage.setItem("flaggedQuestion",JSON.stringify(this.markedflagsIndex))
  }
  else{
    this.markedflagsIndex.splice(this.z, 1);
    localStorage.setItem("flaggedQuestion",JSON.stringify(this.markedflagsIndex))
  }

  // Going to backend
  let data = {
    // @ts-ignore
    userId: JSON.parse(localStorage.getItem("Id")),
    questionId: this.allQuestions[this.currentQuestionIndex].id,
    tableName: "marked_questions",
    isMarked: this.flagmarked
  }
  this.subscriptions.push(
    this.quizService.markUnmarkQuestion(data).subscribe((res: any) => {
        // Res here
      },
      (        err: any) => {
        AutoLogout(err);
      }
    )
  );
  // Backend
  }

  saveOmittedQuestions(i: any): void{
    this.quizloader = true
    var tempOmittedArray = [];
    if(localStorage.getItem("SubmittedQuestions")){
    this.AnswerSubmittedValues = JSON.parse(localStorage.getItem("SubmittedQuestions"));
  }else{
    this.AnswerSubmittedValues = []
  }
  if(localStorage.getItem("SelectedOptionArray")){
    this.SelectedOptionsArray = JSON.parse(localStorage.getItem("SelectedOptionArray"));
  }else{
    this.SelectedOptionsArray = []
  }
    for(let i=0; i<this.allQuestions.length; i++){
      let k =0;
      this.AllOptions = this.allQuestions[i].statistics;
      for(let j=0; j<this.AnswerSubmittedValues.length; j++){
        if(this.allQuestions[i].id == this.AnswerSubmittedValues[j].index){
        k = 1;
      }
      }
      if(this.SelectedOptionsArray && this.SelectedOptionsArray.length>0){
      for(let l=0; l<this.SelectedOptionsArray.length; l++){
        if(this.allQuestions[i].id == this.SelectedOptionsArray[l].index){
        k = 2;
      }
      }
    }

    var tempCorrectAnsIndex;
      for(this.x=0 ; this.x < this.AllOptions.length; this.x++){
        if(this.AllOptions[this.x] == 1){
          tempCorrectAnsIndex = this.x;
        }
      }

      if(k == 0){
        tempOmittedArray.push({index: this.allQuestions[i].id, correct: -1 , optionIndexSelected: -1, Correctanswerindex: tempCorrectAnsIndex, time: 0})
      }
    }

    this.saveQuiz(tempOmittedArray , i);
  }

  endBlockIndex: any;
  displaysuspendAlert = false;
  displayEndBlockAlert(i: any){
    if(i == 1){
      this.displayAlert = true;
    }else{
      this.displaysuspendAlert = true;
    }
    this.endBlockIndex = i;
  }
  onEndBlock(): void{
    this.saveOmittedQuestions(this.endBlockIndex)
    // this.courseID = localStorage.getItem("CourseID");
    // this.lessonID = localStorage.getItem("LessonID");

    // this.router.navigate(['/dashboard/welcome']);
  }

  // STRIKE THROUGH ANSWERS
  getOptionValue1(i: any){
    return String.fromCharCode(this.optionValue1.charCodeAt(0) + i);
  }

  strikeThroughAns(i: any): void{
    const element = document.getElementById(this.getOptionValue1(i));
    // @ts-ignore
    if (element.style.textDecoration === ''){
      // @ts-ignore
      element.style.textDecoration = 'line-through';
      this.strikedThroughOptions.push({qId: this.allQuestions[this.currentQuestionIndex].id, strikedAns : i});
    }
    else {
      // @ts-ignore
      element.style.textDecoration = '';
      // @ts-ignore
      const index = this.strikedThroughOptions.findIndex(x => ((x.qId === this.allQuestions[this.currentQuestionIndex].id) && (x.strikedAns === i)));
      if (index !== -1){
        this.strikedThroughOptions.splice(index, 1);
      }
    }
    localStorage.setItem('strikedThroughOptions', JSON.stringify(this.strikedThroughOptions));
  }

  // Calculate Time For Each submitted Question
  minsTaken: any;
  secsTaken: any;
  hasMins = false;
  calculateTime(): any{

    var submittedQuestions = JSON.parse(localStorage.getItem("SubmittedQuestions"));
    var time;
    if(submittedQuestions){
    for(let i=0; i<submittedQuestions.length; i++){
      if(this.allQuestions[this.currentQuestionIndex].id == this.AnswerSubmittedValues[i].index){
        if (this.viewMode) {
          time = this.AnswerSubmittedValues[i].time
        } else {
          let index = this.questionsTime.findIndex((item: any) => item.id == this.allQuestions[this.currentQuestionIndex].id)
          if (index !== -1) {
            time = this.questionsTime[index].time
          }
        }
      }
    }
    if(time >= 60){
      this.hasMins = true;
      this.minsTaken = time / 60;
      this.minsTaken = Math.floor(this.minsTaken)
      this.secsTaken = time % 60;
    }else{
      this.hasMins = false;
      this.secsTaken = time;
    }
  }
  }

  // Performance Screen
gotoPerformanceScreen(quizId: any, quizTitle: any, subjectName: any): void{
  localStorage.removeItem("quizScore")
  if(subjectName == "null"){
    localStorage.setItem("showsubjectdetails", JSON.stringify(true));
  }else{
    localStorage.setItem("showsubjectdetails", JSON.stringify(true));
  }
  localStorage.setItem("QuizId", quizId);
  localStorage.setItem("quizTitle", quizTitle);

    localStorage.setItem("testResult", JSON.stringify(true))

  if (localStorage.getItem('USMLE_Test')) {
    this.router.navigate(['/dashboard/ai-test-analysis']);
  } else {
    if(this.quizMode == 'exam'){
      // setTimeout(
      //   () => {
          this.router.navigate(['/dashboard/performancescreen/results']);
      //   }, 5000
      // );
    }
    else{
    // setTimeout(
    //   () => {
        this.router.navigate(['/dashboard/performancescreen/results']);
    //   }, 3000
    // );
  }
  }

}

totaloptionSelected = 0;
allOptionsSelected: any;
multiplier =100;
getPercentageOthers(){
  this.videoURLs = [];
  this.explanationVideoURLs = [];
  if (this.allQuestions[this.currentQuestionIndex].question.includes('[vdo id')) {
    this.getQuestionVideoContent(this.allQuestions[this.currentQuestionIndex].question);
  }
  if (this.allQuestions[this.currentQuestionIndex].correct_msg.includes('[vdo id')) {
    this.getExplanationVideoContent(this.allQuestions[this.currentQuestionIndex].correct_msg);
  }
  if (!this.allQuestions[this.currentQuestionIndex].subject) {
    this.getQuestionCategories();
  }
  this.subscriptions.push(
    this.quizService.getPercentageOthers(this.allQuestions[this.currentQuestionIndex].id).subscribe((res: any) => {
        this.allOptionsSelected = res.options;
        this.AllOptions = this.allQuestions[this.currentQuestionIndex].statistics;
        this.totaloptionSelected = 0;
        for(let i=0; i<this.AllOptions.length; i++){
          if(res.options[i]){
            this.totaloptionSelected = this.totaloptionSelected + res.options[i];
          }
        }

      },
      (        err: any) => {
        AutoLogout(err);
      }
    )
  );
}

getQuestionVideoContent(question: any) {
  const data = {
    question: question
  }
  this.quizService.getQuestionVideoContent(data).subscribe((res: any) => {
      if (res.data) {
        if (res.data.videoContent) {
          let videoContent = res.data.videoContent;
          for (let i = 0; i < videoContent.length; i++) {
            this.videoURLs.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/v2/?otp=" + videoContent[i].otp + "&playbackInfo=" + videoContent[i].playbackInfo + "&primaryColor=#4245ef"))
            this.videoCypher()
          }
        }
      }
    },
    (err: any) => {}
  )
}

getExplanationVideoContent(question: any) {
  const data = {
    question: question
  }
  this.quizService.getQuestionVideoContent(data).subscribe((res: any) => {
      if (res.data) {
        if (res.data.videoContent) {
          let videoContent = res.data.videoContent;
          for (let i = 0; i < videoContent.length; i++) {
            this.explanationVideoURLs.push(this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/v2/?otp=" + videoContent[i].otp + "&playbackInfo=" + videoContent[i].playbackInfo + "&primaryColor=#4245ef"))
            this.videoCypher()
          }
        }
      }
    },
    (err: any) => {}
  )
}

// Playing video cypher videos and changing their playback speed
videoCypher() {
  const iframe = document.querySelector("iframe");
  // Creating player instance
  const player = window.VdoPlayer.getInstance(iframe);

  // All DOM API supports on player.video
  player.video.addEventListener("load", function () {
    player.video.availablePlaybackRates = [0.75, 1.0, 1.25, 1.5, 1.75, 2.0];
  });
}

getQuestionCategories(){
  const data = {
    questionId: this.allQuestions[this.currentQuestionIndex].id
  }
  this.subscriptions.push(
    this.quizService.getQuestionCategories(data).subscribe((res: any) => {
        if (res.data.category) {
          this.allQuestions[this.currentQuestionIndex].subject = res.data.category.QSubject;
          this.allQuestions[this.currentQuestionIndex].system = res.data.category.QSystem;
          this.allQuestions[this.currentQuestionIndex].topic = res.data.category.QTopic;
        }
      },
      (err: any) => {
        AutoLogout(err);
      }
    )
  );
}

changeTimed() {
  this.timerSubscription?.unsubscribe();
  clearInterval(this.timerid)

  if (this.timedMode) {
    this.isTimed = true
    localStorage.setItem('isTimed', JSON.stringify(true))
    this.saveTime = this.timerDisplay
    localStorage.setItem('saveTime', this.timerDisplay)  

    this.setQuizTimer();

    let questionTime = this.allQuestions.filter(({ post_id: id1 }) => !this.AnswerSubmittedValues.some(({ index: id2 }) => id2 === id1))
    const timeObject = this.getDisplayTimer(questionTime.length * 90);

    this.timerDisplay = timeObject.hours.digit1 + timeObject.hours.digit2 + ':' +
    timeObject.minutes.digit1 + timeObject.minutes.digit2 + ':' +
    timeObject.seconds.digit1 + timeObject.seconds.digit2
    this.quizTime = this.timerDisplay

    localStorage.setItem('quiz_time', this.quizTime)
  }
  else {
    this.isTimed = false
    localStorage.setItem('isTimed', JSON.stringify(false))  
    if (localStorage.getItem('saveTime')) {
      this.timerDisplay = localStorage.getItem('saveTime')
    } else {
      this.timerDisplay = '00:00:00'
      localStorage.setItem('saveTime', this.timerDisplay)
    }
    this.quizTime = this.timerDisplay

    localStorage.setItem('quiz_time', this.quizTime)

    this.setQuizTimer();
  }
}

changeMode() {
  if (this.timeMode) {
    this.quizMode = 'exam'
    localStorage.setItem('QuizMode', 'exam')
    const exclude = (arr1: any, arr2: any) => arr1.filter((o1: any) => arr2.map((o2: any) => o2.index).indexOf(o1.index) === -1);

    if (exclude(this.SelectedOptionsArray, this.dotQuestionsArray).length > 0) {
      this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(this.SelectedOptionsArray, this.dotQuestionsArray)]
    }

    if (localStorage.getItem('SubmittedQuestions')) {
      let submittedQuestions = JSON.parse(localStorage.getItem('SubmittedQuestions'))
      if (submittedQuestions.length > 0) {
        if (exclude(submittedQuestions, this.dotQuestionsArray).length > 0) {
          this.dotQuestionsArray = [...this.dotQuestionsArray, ...exclude(submittedQuestions, this.dotQuestionsArray)]
        }
      }
    }

    localStorage.setItem('dotQuestionsArray',  JSON.stringify(this.dotQuestionsArray))

    this.hideDot()
  }
  else {
    this.quizMode = 'tutor'
    localStorage.setItem('QuizMode', 'tutor')
  }
  this.setQuestionTime()
  this.timerSubscription?.unsubscribe();
  clearInterval(this.timerid)
  if (!this.AnswerSubmitted || this.quizMode == 'exam') {
    this.setQuizTimer()
  }
}

openFullscreen() {
  this.isFullScreen = true;
}

closeFullscreen() {
  this.isFullScreen = false;
}

getUserFlashcards() {
  return new Promise((resolve, reject) => {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function(e) {
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
        return true;
      }
    }
    this.HideHighlightControls()
    this.flashcards = []
    this.questionFlashcards = []
    this.displayDropdown = false;
    this.createFlashcard = false;
    let data = {
      "userId": localStorage.getItem('Id')
    }

    if (this.isDemo) {
      this.decks = [
        {
          'CardReviews': 0,
          'Color': '#62B5FC',
          'DeckID': 1,
          'LastUsed': null,
          'Learning': 0,
          'NewCards': 0,
          'Title': 'Demo Notes',
          'ToReview': 0,
          'TotalTime': 0,
          'UserID': '-1',
          'Flashcards': [
            {
              'FlashcardID': 1,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['Free Trial', 'Demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            },
            {
              'FlashcardID': 2,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['Free Trial', 'Demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            }
          ]
        }
      ]
      this.decks.forEach(element => {
        element.Flashcards.forEach(flashcard => {
          flashcard.Deck = element.Title
          flashcard.DeckColor = element.Color
          flashcard.Tags = flashcard.Tags
          if (flashcard.QuestionID == this.allQuestions[this.currentQuestionIndex].id) {
            this.questionFlashcards.push(flashcard)
          }
          else {
            this.flashcards.push(flashcard)
          }
          this.tempFlashcards.push(flashcard)
        });
      });
      if (this.decks.length > 0) {
        this.selectedDeck = this.decks[0]
      }
      resolve(true)
    } else {
      this.flashcardService.getUserDeck(data).subscribe((res: any) => {
        this.decks = res.data.decks
        res.data.decks.forEach(element => {
          element.Flashcards.forEach(flashcard => {
            flashcard.Deck = element.Title
            flashcard.DeckColor = element.Color
            flashcard.Tags = JSON.parse(flashcard.Tags)
            if (flashcard.QuestionID == this.allQuestions[this.currentQuestionIndex].id) {
              this.questionFlashcards.push(flashcard)
            }
            else {
              this.flashcards.push(flashcard)
            }
            this.tempFlashcards.push(flashcard)
          });
        });
        if (this.decks.length > 0) {
          this.selectedDeck = this.decks[0]
        }
        resolve(true)
      })
    }
  })
}

addNewCard() {
  if (this.existingFlashcard) {
    this.displayContinue = false
    this.displayCards = true
    this.existingFlashcard = false
    this.openCardDialog(false, this.html, 'New')
  }
  else {
    this.openCardDialog(false, null, 'New')
  }
}

selectFlashcard(index: any, array: any) {
  this.showFlashcards = array
  this.selectedFlashcard = index
  this.searcher = false
  this.flashcardKeyword = ''

  if (this.existingFlashcard) {
    this.displayContinue = false
    this.openCardDialog(true, this.html, 'Existing')
  }
  else {
    this.showFlashcard = true
    this.position = 'Front'
    this.flashcardView = 'Single Side View'
  }
}

changeView() {
  if (this.flashcardView == 'Single Side View') {
    this.flashcardView = 'Double Side View'
  }
  else {
    this.flashcardView = 'Single Side View'
  }
}

prevCardIndex() {
  if (this.selectedFlashcard > 0) {
    this.selectedFlashcard --
    this.position = 'Front'
  }
}

nextCardIndex() {
  if (this.selectedFlashcard < this.showFlashcards.length - 1) {
    this.selectedFlashcard ++
    this.position = 'Front'
  }
}

addDeckFlashcard() {
  this.card.question = this.ckEditorFront.getData()
  this.card.answer = this.ckEditorBack.getData()
  if (this.card.deckId == -1) {
    this.card.deckId = this.selectedDeck.DeckID
  }

  if (this.isDemo) {
    let index = this.decks.findIndex((item: any) => item.DeckID === this.card.deckId)
    let flashcard = {
      'FlashcardID': this.decks[index].Flashcards.length + 1,
      'Question': this.card.question,
      'Answer': this.card.answer,
      'DeckID': this.card.deckId,
      'BuryDate': null,
      'Difficulty': null,
      'IsSuspended': 0,
      'Learning': 0,
      'Mastered': 0,
      'NextStudyDate': null,
      'QuestionID': this.card.questionId,
      'Solved': null,
      'Tags': this.card.tags,
      'TimeSpent': null,
      'UpdatedDate': null,
      'usedCard': null
    }
    this.decks[index].Flashcards.push(flashcard)
    this.decks.forEach(element => {
      element.Flashcards.forEach(flashcard => {
        flashcard.Deck = element.Title
        flashcard.DeckColor = element.Color
        flashcard.Tags = flashcard.Tags
        if (flashcard.QuestionID == this.allQuestions[this.currentQuestionIndex].id) {
          this.questionFlashcards.push(flashcard)
        }
        else {
          this.flashcards.push(flashcard)
        }
        this.tempFlashcards.push(flashcard)
      });
    });
    this.createFlashcard = false
    this.displayFlashcards = true;
  } else {
    if (this.decks.length <= 0) {
      this.decks.push({
        Title: 'Notes',
        Color: '#62B5FC'
      })
      this.addUserDeck()
    } else {
      this.flashcardService.addDeckFlashcard(this.card).subscribe((res: any) => {
        this.card.question = ""
        this.card.answer = ""
        this.card.tags = []
        this.card.deckId = -1
        this.createFlashcard = false
        this.displayFlashcards = true;
        this.getUserFlashcards()
      })
    }
  }
}

addNewUserDeck() {
  if (this.isDemo) {
    this.displayDeck = false
    this.decks.push({
      'CardReviews': 0,
      'Color': this.deck.color,
      'DeckID': this.decks.length + 1,
      'LastUsed': null,
      'Learning': 0,
      'NewCards': 0,
      'Title': this.deck.title,
      'ToReview': 0,
      'TotalTime': 0,
      'UserID': '-1',
      'Flashcards': []
    })
  } else {
    this.flashcardService.addUserDeck(this.deck).subscribe((res: any) => {
      if (!res.error) {
        this.decks.push({
          Title: this.deck.title,
          Color: this.deck.color,
          DeckID: res.data.deckId,
          UserID: localStorage.getItem('Id'),
          Flashcards: []
        })
        this.deck.title = ""
        this.displayDeck = false
      }
    })
  }
}

addUserDeck() {
  let deck = {
    'title': 'Notes',
    'color': '#62B5FC',
    'userId': localStorage.getItem('Id')
  }
  if (this.isDemo) {
    this.displayDeck = false
    this.decks.push({
      'CardReviews': 0,
      'Color': this.deck.color,
      'DeckID': this.decks.length + 1,
      'LastUsed': null,
      'Learning': 0,
      'NewCards': 0,
      'Title': this.deck.title,
      'ToReview': 0,
      'TotalTime': 0,
      'UserID': '-1',
      'Flashcards': []
    })
  } else {
    this.flashcardService.addUserDeck(deck).subscribe((res: any) => {
      if (res.data) {
        this.card.deckId = res.data.deckId
        this.addDeckFlashcard()
      }
    })
  }
}

openCardDialog(status: any, html: any, type: any) {
  this.createFlashcard = true
  this.displayDropdown = false;
  this.showFlashcard = false;
  this.card.question = ""
  this.card.answer = ""
  this.card.tags = []

  if (this.decks.length <= 0) {
    this.selectedDeck = {
      Title: 'Notes',
      Color: '#62B5FC'
    }
  }

  if (type == 'Existing') {
    this.selectedDeck = this.decks[this.decks.findIndex(item => item.DeckID == this.showFlashcards[this.selectedFlashcard].DeckID)]
    this.displayCards = true
    this.card.question = this.showFlashcards[this.selectedFlashcard].Question
    this.card.answer = this.showFlashcards[this.selectedFlashcard].Answer
    this.card.tags = this.showFlashcards[this.selectedFlashcard].Tags

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        editor.setData(this.showFlashcards[this.selectedFlashcard].Question)
      })

      Editor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
        editor.setData(this.showFlashcards[this.selectedFlashcard].Answer)
      })
    }, 100)
  }
  if (type == 'New') {
    this.card.questionId = this.allQuestions[this.currentQuestionIndex].id

    if (this.ckEditorFront) {
      this.ckEditorFront.destroy()
    }
    if (this.ckEditorBack) {
      this.ckEditorBack.destroy()
    }

    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorFront"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorFront = editor
        if (status) {
          editor.setData(html)
        }
      })

      Editor.create(document.querySelector("#ckEditorBack"), {
        toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
      }).then((editor: any) => {
        this.ckEditorBack = editor
      })
    }, 100)
  }
}

addNewFlashcard = async (event) => {
  var html = "";
  if (typeof window.getSelection != "undefined") {
      var sel = window.getSelection();
      if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
              container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
      }
  }

  if (html.includes('<tr>') && !html.includes('<table>')) {
    html = '<table><tbody>' + html + '</tbody></table>'
  }

  await this.getUserFlashcards()

  this.HideHighlightControls()
  this.displayFlashcards = true;
  this.createFlashcard = true
  this.openCardDialog(true, html, 'New')
}

addExistingFlashcard = async (event) => {
  document.addEventListener('contextmenu', event => event.preventDefault());
  document.onkeydown = function(e) {
    if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return true;
    }
  }

  if (typeof window.getSelection != "undefined") {
    var sel = window.getSelection();
    if (sel.rangeCount) {
        var container = document.createElement("div");
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        this.html = container.innerHTML;
    }
  }

  if (this.html.includes('<tr>') && !this.html.includes('<table>')) {
    this.html = '<table><tbody>' + this.html + '</tbody></table>'
  }

  await this.getUserFlashcards()
  this.HideHighlightControls()
  
  this.displayContinue = true

  let timer = 0
  let interval = setInterval(() => {
    if (timer == 5) {
      clearInterval(interval)
      this.displayContinue = false
    }
    timer += 1
  }, 1000)

  this.displayFlashcards = true;
  this.existingFlashcard = true
}

updateHTML(side: any) {
  if (side == 'Front') {
    let existingData = this.ckEditorFront.getData() + '<p>' + this.html + '</p>'
    this.ckEditorFront.setData(existingData)
  }
  if (side == 'Back') {
    let existingData = this.ckEditorBack.getData() + '<p>' + this.html + '</p>'
    this.ckEditorBack.setData(existingData)
  }
  this.displayCards = false
}

closeFlashcards() {
  document.addEventListener('contextmenu', event => event.preventDefault());
  document.onkeydown = function(e) {
    if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
    }
  }
  this.displayCards = false
  this.displayContinue = false
  this.showFlashcard = false
  this.displayDropdown = false
  this.createFlashcard = false
  this.existingFlashcard = false
}

editDeckFlashcard() {
  let data = {
    'deckId': this.showFlashcards[this.selectedFlashcard].DeckID,
    'question': this.ckEditorFront.getData(),
    'answer': this.ckEditorBack.getData(),
    'tags': this.card.tags,
    'flashcardId': this.showFlashcards[this.selectedFlashcard].FlashcardID
  }
  if (this.isDemo) {
    this.existingFlashcard = false
    this.createFlashcard = false
    let index = this.decks.findIndex((item: any) => item.DeckID === data.deckId)
    let flashcardIndex = this.decks[index].Flashcards.findIndex((item: any) => item.FlashcardID === data.flashcardId)
    this.decks[index].Flashcards[flashcardIndex].Question = data.question
    this.decks[index].Flashcards[flashcardIndex].Answer = data.answer
    this.decks[index].Flashcards[flashcardIndex].Tags = data.tags
  } else {
    this.flashcardService.editDeckFlashcard(data).subscribe((res: any) => {
      this.existingFlashcard = false
      this.createFlashcard = false
      this.getUserFlashcards()
    })
  }
}

editUserFlashcard() {
  let data = {
    'deckId': this.showFlashcards[this.selectedFlashcard].DeckID,
    'question': this.ckEditorFront.getData(),
    'answer': this.ckEditorBack.getData(),
    'tags': this.card.tags,
    'flashcardId': this.showFlashcards[this.selectedFlashcard].FlashcardID
  }
  if (this.isDemo) {
    this.existingFlashcard = false
    this.createFlashcard = false
    this.displayEditFlashcard = false
    let index = this.decks.findIndex((item: any) => item.DeckID === data.deckId)
    let flashcardIndex = this.decks[index].Flashcards.findIndex((item: any) => item.FlashcardID === data.flashcardId)
    this.decks[index].Flashcards[flashcardIndex].Question = data.question
    this.decks[index].Flashcards[flashcardIndex].Answer = data.answer
    this.decks[index].Flashcards[flashcardIndex].Tags = data.tags
  } else {
    this.flashcardService.editDeckFlashcard(data).subscribe((res: any) => {
      this.existingFlashcard = false
      this.createFlashcard = false
      this.displayEditFlashcard = false
      this.getUserFlashcards()
    })
  }
}

openEditFlashcardDialog(flashcard: any) {
  this.card.tags = flashcard.Tags
  this.displayEditFlashcard = true
  this.displayFlashcards = false
  this.displayCardOptions = false

  if (this.ckEditorFront) {
    this.ckEditorFront.destroy()
  }
  if (this.ckEditorBack) {
    this.ckEditorBack.destroy()
  }

  setTimeout(() => {
    Editor.create(document.querySelector("#ckEditorFront"), {
      toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
    }).then((editor: any) => {
      this.ckEditorFront = editor
      editor.setData(flashcard.Question)
    })

    Editor.create(document.querySelector("#ckEditorBack"), {
      toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
    }).then((editor: any) => {
      this.ckEditorBack = editor
      editor.setData(flashcard.Answer)
    })
  }, 100)
}

searchFlashcards() {
  if (this.flashcardKeyword == "" || this.flashcardKeyword.length < 3) {
    // this.messageService.add({severity:'error', summary:'Error searching flashcards', detail:'Search keywords cannot be less than 3 characters.'});
  }
  else {
    this.searchedFlashcards = []
    this.decks.forEach((element) => {
      this.searchedFlashcards = [...this.searchedFlashcards, ...element.Flashcards]
    })

    let tempFlashcards = []
    this.searcher = true

    this.searchedFlashcards.forEach((element) => {  
      if ((element.Question.includes(this.flashcardKeyword)) || (element.Answer.includes(this.flashcardKeyword))) {
        tempFlashcards.push(element)
      }
    });

    this.searchedFlashcards = tempFlashcards
  }
}

async imgToExistingFlashcard() {
  this.html = '<p></p><img src="' + this.showImage + '">'
  await this.getUserFlashcards()

  this.displayImg = false
  this.displayContinue = true

  let timer = 0
  let interval = setInterval(() => {
    if (timer == 5) {
      clearInterval(interval)
      this.displayContinue = false
    }
    timer += 1
  }, 1000)

  this.displayFlashcards = true;
  this.existingFlashcard = true
}

async imgToNewFlashcard() {
  let html = '<p></p><img src="' + this.showImage + '">'
  await this.getUserFlashcards()

  this.displayImg = false
  this.displayFlashcards = true;
  this.createFlashcard = true
  this.openCardDialog(true, html, 'New')
}

imgToNotebook() {
  this.shownotebookdialog = true;

  let html = '<p></p><img src="' + this.showImage + '">'

  this.notebookText = html;
  this.quizEdit = true
  this.removeImageDiv()
}

addingImageListener() {
  setTimeout(() => {
    document.querySelectorAll('#explanationArea img').forEach((element) => {
      element.addEventListener('click', (e: any) => {
        this.showImage = e.target.currentSrc
        this.displayImg = true
      })
    }) 
    document.querySelectorAll('#questionArea img').forEach((element) => {
      element.addEventListener('click', (e: any) => {
        this.showImage = e.target.currentSrc
        this.displayImg = true
      })
    }) 
  }, 500)
}

// Table Click Event Implementation //

tableExit() {
  //@ts-ignore
  document.getElementById('tableNewFlashcard').removeAllListeners('pointerdown')
  //@ts-ignore
  document.getElementById('tableExistingFlashcard').removeAllListeners('pointerdown')
  //@ts-ignore
  document.getElementById('tableNotebook').removeAllListeners('pointerdown')
  //@ts-ignore
  document.getElementById('tableExit').removeAllListeners('pointerdown')
  document.getElementById('controlTable').style.display = 'none'
}

async tableToNewFlashcard(table: any) {
  let html = '<p></p><table>' + table + '</table>'
  await this.getUserFlashcards()

  this.tableExit()

  this.displayImg = false
  this.displayFlashcards = true;
  this.createFlashcard = true
  this.openCardDialog(true, html, 'New')
}

async tableToExistingFlashcard(table: any) {
  this.html = '<p></p><table>' + table + '</table>'
  await this.getUserFlashcards()

  this.tableExit()

  this.displayImg = false
  this.displayContinue = true

  let timer = 0
  let interval = setInterval(() => {
    if (timer == 5) {
      clearInterval(interval)
      this.displayContinue = false
    }
    timer += 1
  }, 1000)

  this.displayFlashcards = true;
  this.existingFlashcard = true
}

async tableToNotebook(table: any) {
  this.shownotebookdialog = true;

  let html = '<p></p><table>' + table + '</table>'

  this.tableExit()

  this.notebookText = html;
  this.quizEdit = true
}

addingTableListener() {
  // Table Click Event //
  setTimeout(() => {
    document.querySelectorAll('#explanationArea table').forEach((element) => {
      element.addEventListener('click', (e: any) => {
        const cell = e.target.closest('table');
        this.showTable = cell.innerHTML
        let rect = cell.getBoundingClientRect();
        let control = document.getElementById('controlTable')
        control.style.top = `calc(${rect.top}px - 48px)`;
        control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`;
        control.style.display = 'block'
        document.getElementById('tableNotebook').addEventListener('pointerdown', (event: any) => {
          this.tableToNotebook(this.showTable)
        });
        document.getElementById('tableNewFlashcard').addEventListener('pointerdown', (event: any) => {
          this.tableToNewFlashcard(this.showTable)
        })
        document.getElementById('tableExistingFlashcard').addEventListener('pointerdown', (event: any) => {
          this.tableToExistingFlashcard(this.showTable)
        });
        document.getElementById('tableExit').addEventListener('pointerdown', this.tableExit, true);
      })
    }) 
    document.querySelectorAll('#questionArea table').forEach((element) => {
      element.addEventListener('click', (e: any) => {
        const cell = e.target.closest('table');
        this.showTable = cell.innerHTML
        let rect = cell.getBoundingClientRect();
        let control = document.getElementById('controlTable')
        control.style.top = `calc(${rect.top}px - 48px)`;
        control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`;
        control.style.display = 'block'
        document.getElementById('tableNotebook').addEventListener('pointerdown', (event: any) => {
          this.tableToNotebook(this.showTable)
        });
        document.getElementById('tableNewFlashcard').addEventListener('pointerdown', (event: any) => {
          this.tableToNewFlashcard(this.showTable)
        })
        document.getElementById('tableExistingFlashcard').addEventListener('pointerdown', (event: any) => {
          this.tableToExistingFlashcard(this.showTable)
        });
        document.getElementById('tableExit').addEventListener('pointerdown', this.tableExit, true);
      })
    }) 
  }, 1500)
}

// AI Features //

// Generate AI Summary of the selected text //

generateSummaryUsingAI(type: any) {
  let question = 'Generate a summary of the following html content \n ';
  question += type === 'question' ? this.allQuestions[this.currentQuestionIndex].question : this.allQuestions[this.currentQuestionIndex].correct_msg;
  const data = {
    "question": question,
    "system": "Return the proper and explainable summary in html format. It should be in paragraph form. Summary length should be of 30% of original content."
  }

  this.aiLoader = true;
  this.aiText = 'AI Summary';
  this.quizService.generateUsingOpenAI(data).subscribe(async (res: any) => {
    this.questionSummary = res.response.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '');
    this.messageService.add({severity:'success', summary:'AI Summary Generated', detail:'Your summary is generated successfully.'});

    if (type === 'question') {
      this.allQuestions[this.currentQuestionIndex].summary = this.questionSummary;
    } else if (type === 'explanation') {
      this.allQuestions[this.currentQuestionIndex].explanationSummary = this.questionSummary;
    }
    localStorage.setItem("AllQuestions", JSON.stringify(this.allQuestions));
    this.aiLoader = false;
    this.viewAISummary(type);
  }, (error: any) => {
    this.aiLoader = false;
    this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
  })
}

// Generate AI Reference Links of the selected text //

generateReferenceLinksUsingAI(type: any) {
  let question = 'Generate articles reference links related to the following text: \n ';
  question += type === 'question' ? this.allQuestions[this.currentQuestionIndex].question : this.allQuestions[this.currentQuestionIndex].correct_msg;
  const data = {
    "question": question,
    "system": `Returns the reference links in array json form. e.g. [{ 'link': 'https://link.com' }]. Please follow the instructions: \n 
    1. Generated reference links should be valid websites.
    2. Minimum generated reference links can be 3-5 but maximum generated reference links should not exceed count of 15.
    2. List should not contains duplicate reference links.
    3. Don't include any irrevelant text like (\`\`\`json).`
  }

  this.aiLoader = true;
  this.aiText = 'AI Reference Links';
  this.quizService.generateUsingOpenAI(data).subscribe(async (res: any) => {
    try {
      this.referenceLinks = JSON.parse(res.response)
      if (this.referenceLinks.length > 0) {
        this.messageService.add({severity:'success', summary:'AI Reference Links Generated', detail:'Your reference links are generated successfully.'});

        if (type === 'question') {
          this.allQuestions[this.currentQuestionIndex].referenceLinks = this.referenceLinks;
        } else if (type === 'explanation') {
          this.allQuestions[this.currentQuestionIndex].explanationReferenceLinks = this.referenceLinks;
        }
        localStorage.setItem("AllQuestions", JSON.stringify(this.allQuestions));
        this.viewAIReferenceLinks(type);
      } else {
        this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
      }
      this.aiLoader = false;
    } catch (e) {
      this.aiLoader = false;
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
    }
  }, (error: any) => {
    this.aiLoader = false;
    this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
  })
}

// Generate AI Text-to-Speech of the selected text //

generateTextSpeechUsingAI(type: any) {
  // Create a temporary DOM element to hold the HTML content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = type === 'question' ? this.allQuestions[this.currentQuestionIndex].question : this.allQuestions[this.currentQuestionIndex].correct_msg;

  // Remove all img tags
  const images = tempDiv.getElementsByTagName('img');
  while (images.length > 0) {
      images[0].parentNode.removeChild(images[0]);
  }

  // Remove all table tags
  const tables = tempDiv.getElementsByTagName('table');
  while (tables.length > 0) {
      tables[0].parentNode.removeChild(tables[0]);
  }

  const data = {
    "text": tempDiv.textContent,
  }

  this.aiLoader = true;
  this.aiText = 'AI Text-to-Speech';
  this.quizService.generateVoiceUsingOpenAI(data).subscribe(async (res: any) => {
    this.voiceNoteFile = res.voiceNoteFile;
    this.playAudio = false;
    this.messageService.add({severity:'success', summary:'AI Text-to-Speech Generated', detail:'Your text-to-speech file is generated successfully.'});
    this.aiLoader = false;

    if (type === 'question') {
      this.allQuestions[this.currentQuestionIndex].voiceNoteFile = this.voiceNoteFile;
    } else if (type === 'explanation') {
      this.allQuestions[this.currentQuestionIndex].explanationVoiceNoteFile = this.voiceNoteFile;
    }
    localStorage.setItem("AllQuestions", JSON.stringify(this.allQuestions));
    this.viewAIVoiceNote(type);
  }, (error: any) => {
    this.aiLoader = false;
    if (error.status === 400) {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at most 4096 characters.'});
    } else {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
    }
  })
}

getCloudFrontLink(data: any) {
  return new Promise((resolve: any) => {
    this.quizService.getAWSS3CloudFrontLink(data).subscribe((res: any) => {
      resolve(res.url);
    });
  });
}

executeAudioFile() {
  setTimeout(() => {
    this.voiceCurrentTime = '00:00';
    this.voiceTotalTime = '00:00';

    const audioPlayer = document.getElementById('audioPlayer');
    const seekBar = document.getElementById('seek-bar');

    //@ts-ignore
    seekBar?.removeAllListeners();

    //@ts-ignore
    seekBar.value = 0;

    this.audioElement.nativeElement.removeAllListeners();
    this.audioElement.nativeElement.load();
    this.audioElement.nativeElement.addEventListener('loadedmetadata', () => {
      this.audioLoader = false;
      this.voiceTotalTime = this.formatTime(this.audioElement.nativeElement.duration);
    });

    this.audioElement.nativeElement.addEventListener('waiting', () => {
      this.audioLoader = true;
    });

    this.audioElement.nativeElement.addEventListener('playing', () => {
      this.audioLoader = false;
    });

    this.audioElement.nativeElement.addEventListener('timeupdate', () => {
      //@ts-ignore
      const currentTime = audioPlayer.currentTime;
      //@ts-ignore
      const duration = audioPlayer.duration;

      if (currentTime) {
        this.voiceCurrentTime = this.formatTime(currentTime);
      }

      if (duration) {
        this.voiceTotalTime = this.formatTime(duration);
      }

      if ((currentTime / duration) * 100) {
        //@ts-ignore
        seekBar.value = (currentTime / duration) * 100;
      }

      //@ts-ignore
      if (currentTime >= duration) {
        this.playAudio = false;
        //@ts-ignore
        seekBar.value = 0;
        this.voiceCurrentTime = '00:00';
      }
    })

    seekBar.addEventListener('input', () => {
      //@ts-ignore
      audioPlayer.currentTime = (seekBar.value / 100) * audioPlayer.duration;

      //@ts-ignore
      if (audioPlayer.currentTime) {
        //@ts-ignore
        this.voiceCurrentTime = this.formatTime(audioPlayer.currentTime);
      }

      //@ts-ignore
      if (audioPlayer.duration) {
        //@ts-ignore
        this.voiceTotalTime = this.formatTime(audioPlayer.duration);
      }
    });
  }, 100)
}

formatTime(seconds: any) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${String(minutes.toFixed(0)).padStart(2, '0')}:${String(remainingSeconds.toFixed(0)).padStart(2, '0')}`;
  return formattedTime;
}

playAudioFile() {
  this.audioElement.nativeElement.play();
  this.playAudio = true;
}

pauseAudioFile() {
  this.audioElement.nativeElement.pause();
  this.playAudio = false;
}

viewAISummary(type: any) {
  this.summaryPopup = true;
  this.questionSummary = '';
  if (type === 'question') {
    this.questionSummary = this.allQuestions[this.currentQuestionIndex].summary;
  } else if (type === 'explanation') {
    this.questionSummary = this.allQuestions[this.currentQuestionIndex].explanationSummary;
  }
}

viewAIReferenceLinks(type: any) {
  this.linksPopup = true;
  this.referenceLinks = [];
  if (type === 'question') {
    this.referenceLinks = this.allQuestions[this.currentQuestionIndex].referenceLinks;
  } else if (type === 'explanation') {
    this.referenceLinks = this.allQuestions[this.currentQuestionIndex].explanationReferenceLinks;
  }
}

async viewAIVoiceNote(type: any) {
  this.playAudio = false;
  this.voiceNoteFile = null;
  this.voiceNotePopup = true;
  const file = {
    fileKey: type === 'question' ? this.allQuestions[this.currentQuestionIndex].voiceNoteFile : this.allQuestions[this.currentQuestionIndex].explanationVoiceNoteFile
  };
  this.audioLoader = true;
  this.voiceNoteFile = await this.getCloudFrontLink(file);
  this.executeAudioFile();
}
}
