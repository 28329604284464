import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {CourseService} from "../../shared/services/course.service";
// import {Subscription} from "rxjs";
import {ErrorHandlerService} from "../../shared/services/error-handler.service";
import {Router} from "@angular/router";
import {Course} from "../../models/course";
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { demo_variables } from 'src/app/models/demo_global';

@Component({
  selector: 'app-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.scss']
})
export class AllCoursesComponent implements OnInit, OnDestroy {
  AllcoursesArray: any;
  innerWidth: any;


  // @ts-ignore
  subscriptions: Subscription = [];
  courses: Course[] = [];
  loader = true;
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth > 750){
    document.getElementById('eup-navbar-heading').innerText = 'My Courses';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
}
  constructor(private adminSevice: AdminService,
    private globalService:GlobalService,
    private courseService: CourseService,
              private errorHandler: ErrorHandlerService,
              private router: Router) {


               }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }




  async ngOnInit(){
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'My Courses';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    // @ts-ignore
    this.AllcoursesArray = JSON.parse(localStorage.getItem("coursearray"));
    if(!this.AllcoursesArray && localStorage.getItem('Id') != '-1'){
    this.getData();
    } else{
      if(localStorage.getItem('Id') != '-1'){
      this.AllcoursesArray.forEach((course: any) => {
        this.courses.push(
          {
            courseId: course.ID,
            courseTitle: course.post_title,
            courseIntro: "",
            courseImage: course.courseImage
          }
        )
      });
    }else{
      this.courses.push({
        courseId: demo_variables.smallfinalarray.ID,
        courseTitle: demo_variables.smallfinalarray.post_title,
        courseIntro: '',
        courseImage: demo_variables.smallfinalarray.courseImage
      })
    }
      this.loader = false;
    }

    await this.comapreToken();
  }

  comapreToken(){
    // @ts-ignore
    this.subscriptions.push(
      this.adminSevice.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        // this.loader = false;
        if(res.status){
        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  getData(): void{
    // @ts-ignore
    this.subscriptions.push(
      this.courseService.getCourseDataCount().subscribe((res: any) => {
        console.log(res)
        this.loader = false;
        if(res.status){
          res.data.courseCompletionStatus.forEach((course: any) => {
            this.courses.push(
              {
                courseId: course.courseId,
                courseTitle: course.post_title,
                courseIntro: '',
                courseImage: course.courseImage
              }
            )
            course.ID = course.courseId;
          });
          localStorage.setItem('coursearray', JSON.stringify(res.data.courseCompletionStatus))
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }
  getCourseOutline(Id: any, name: any): void{
    localStorage.setItem('CourseID', Id)
    name = name.replace(/\s/g, '-')
    this.router.navigate(['/dashboard/course-overview', name]);
  }

  ResumeCourse(courseID: any): any{
    let userId =  localStorage.getItem('Id');
    let data = {
      courseId: courseID,
      userId: userId
  }
    // @ts-ignore
    this.subscriptions.push(
      this.courseService.resumeCourse(data).subscribe((res: any) => {
        this.loader = false;
        if(res.status){
          localStorage.setItem('LessonID', res.response[0].lesson_id);
          localStorage.setItem('CourseID',res.response[0].course_id);
          localStorage.setItem('topicID',res.response[0].topic_id);
          var courseTitle = res.response[0].course_title;
          courseTitle = courseTitle.replace(/\s/g, '-')
          courseTitle = courseTitle.replaceAll(" ", '-')
          courseTitle = courseTitle.replace("(", '-')
          courseTitle = courseTitle.replace(")", '-')

          var lessonTitle = res.response[0].lesson_title;
          lessonTitle = lessonTitle.replace(/\s/g, '-')
          lessonTitle = lessonTitle.replaceAll(" ", '-')
          lessonTitle = lessonTitle.replace("(", '-')
          lessonTitle = lessonTitle.replace(")", '-')

          var topicTitle;
          if(res.response[0]?.topic_title){
            topicTitle = res.response[0]?.topic_title;
            topicTitle = topicTitle.replace(/\s/g, '-')
            topicTitle = topicTitle.replaceAll(" ", '-')
            topicTitle = topicTitle.replace("(", '-')
            topicTitle = topicTitle.replace(")", '-')
          }else{
            topicTitle = lessonTitle;
          }

          this.router.navigate(['/focusboard',`${courseTitle}`,`${lessonTitle}`,`${topicTitle}`]);
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );

}


}
