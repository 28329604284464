import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
import { Accordion } from 'primeng/accordion';
import * as Mark from 'mark.js'
import * as Editor from '../../../assets/ckeditor/build/ckeditor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotebookService } from 'src/app/shared/services/notebook.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-notebook',
  templateUrl: './user-notebook.component.html',
  styleUrls: ['./user-notebook.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(400)]),
      transition(':leave', [animate(200, style({ height: 0 }))]),
    ]),
  ]
})
export class UserNotebookComponent implements OnInit, AfterContentInit {
  demo = localStorage.getItem('Id')
  sampleText = 'Welcome to the My Notebook feature of the QBank. With this tool, you can create a customizable, electronic notebook full of high-yield information to review at any time. <br> Here are some helpful tips to get started: <br><ul> <li> Accessing My Notebook: The My Notebook feature is available on the main menu bar of the QBank, and within the practice test interface.</li><br><li> Adding Content to My Notebook: Copy text and images from practice tests directly into your Notebook in just a few easy clicks.</li><br><li> Organizing My Notebook: Click-and-drag the pages within the left navigation panel to adjust the order and easily move pages between sections.</li> </ul><br> <span>We hope you enjoy using My Notebook as you prepare for your exams. Happy studying!</span>'
  @Input() notebookText = 'Welcome to the My Notebook feature of the QBank. With this tool, you can create a customizable, electronic notebook full of high-yield information to review at any time. <br> Here are some helpful tips to get started: <br><ul> <li> Accessing My Notebook: The My Notebook feature is available on the main menu bar of the QBank, and within the practice test interface.</li><br><li> Adding Content to My Notebook: Copy text and images from practice tests directly into your Notebook in just a few easy clicks.</li><br><li> Organizing My Notebook: Click-and-drag the pages within the left navigation panel to adjust the order and easily move pages between sections.</li> </ul><br> <span>We hope you enjoy using My Notebook as you prepare for your exams. Happy studying!</span>';
  @Input() quizEdit = false
  @Input() html = ""
  searchString = "";
  showSave = false;
  qbanks: any = [];
  items = [{label: 'Notes'}]
  isEdit = false;
  originalNotebook: any = []
  noteId: any = -1;
  sectionType: any;
  sectionId: any = -1;
  userNoteId: any = -1;
  deletedType: any;
  deletedSection: any = -1;

  heading = 'Getting Started'
  newNotebook = false;
  
  currentDate: any = new Date()
  updatedDate: any = new Date()

  inputValue: string = '';

  displayDelete = false
  deleteIndexes = []

  @ViewChild('accordion') accordion: Accordion;

  displaySave = false
  changeState = false;

  modifiedIndexes: any = [-1, -1, -1, -1]

  noteUpdated = false

  indexes: any = [-1,-1,-1,-1]
  textPosition = 0
  originalText = ""

  tempDetails: any = []
  tempText = ""
  spacer = false;

  sidebar = false

  searcher = false
  
  table: any;
  quill: any;

  checker = false

  selectedContent = ""
  endContent = ""

  ckEditorNotebook: any
  ckEditorTranslation: any;

  notebookEditor: any;

  editorPosition: any;

  isDemo = false;
  confirmDeletion = '';
  loader = false;
  
  isSwap = false;
  isSave = false;

  newNote = false;

  checked = false;

  textSummary = null;
  summaryGenerated = null;
  isRegenerateSummary = false;
  isExpanded: boolean = false;
  showSeeMoreSummary = false;
  @ViewChild('textContainer') textContainer!: ElementRef;
  summaryOpened = false;

  displayVoiceDelete = false;
  voiceNoteFile = null;
  voiceNoteKey = null;
  voiceNoteGenerated = null;
  voiceCurrentTime = '00:00';
  voiceTotalTime = '00:00';
  playAudio = false;
  @ViewChild('audioElement') audioElement: any;
  audioLoader = false;
  displayUpdatedNotebook = false;

  referenceLinks = null;
  linksGenerated = null;
  isRegenerateLinks = false;
  showSeeMoreLinks = false;
  linksOpened = false;
  showLinks = false;
  tempReferenceLinks = null;

  flashcards = null;

  displayTranslationPopup = false;
  languages: any = [];
  selectedLanguage = null;
  translatedText = null;
  translations: any = [];
  translationOptions: any = [];
  selectedTranslation = null;
  noteTranslations: any = [];
  selectedDeleteLanguage = null;
  displayTranslationDelete = false;
  restoreNotebookText = '';
  notebookUpdated = false;

  aiLoader = false;
  aiText = '';

  constructor(private notebookService: NotebookService, private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router) {}

  async ngOnInit() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function(e) {
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
        return true;
      }
    }

    if (localStorage.getItem('Id') == '-1') {
      this.isDemo = true
    }
    if (this.isDemo) {
      this.setDemoNotebook()
    } else {
      this.loader = true;
      await this.getNotesLanguages();
      this.getUserNotebook()
    }
  }

  ngAfterContentInit(): void {
    if (window.innerWidth <= 750) {
      if (document.getElementById('list')) {
        document.getElementById('list').style.display = 'none'
      }
      if (document.getElementById('data')) {
        document.getElementById('data').style.display = 'flex'
      }
    }
  }

  ngOnChanges(values: any) {
    if (values.quizEdit) {
      if (values.quizEdit.currentValue) {
        this.quizEdit = values.quizEdit.currentValue
      }
    }
  }

  changeNotebook() {
    if (!this.checked) {
      if (this.isDemo) {
        this.setDemoNotebook()
      } else {
        this.loader = true;
        this.getUserNotebook()
      }
    }
  }

  // Setting Demo Notebook - Demo Feature //

  setDemoNotebook() {
    let notebook = {
      'UserID': '-1',
      'LatestNote': [0, -1, -1, -1],
      'Qbanks': [
        {
          'ID': 1,
          'Title': 'Demo Notes',
          'Description': 'Free trial is for testing, saving text is available only for premium users',
          'CreatedDate': 'Jun 22, 2023',
          'UpdatedDate': 'Jun 22, 2023',
          'enable': true,
          'open': false
        }
      ]
    }
    this.qbanks = notebook.Qbanks;
    this.originalNotebook = notebook.Qbanks;
    this.newNotebook = true;

    this.heading = this.qbanks[0].Name
    this.notebookText = this.qbanks[0].Description
    this.indexes = notebook.LatestNote;

    if (this.indexes[0] != -1) {
      this.heading = this.qbanks[this.indexes[0]]?.Name
      this.notebookText = this.qbanks[this.indexes[0]]?.Description
      this.currentDate = this.qbanks[this.indexes[0]]?.CreatedDate
      this.updatedDate = this.qbanks[this.indexes[0]]?.UpdatedDate
      this.modifiedIndexes[0] = this.qbanks[this.indexes[0]]?.ID
    }

    setTimeout(() => {
      if (this.indexes[0] != -1 && this.indexes[1] == -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
        document.getElementById('qbankheader'+this.indexes[0])?.classList.add('active')
        this.items = [{label: this.qbanks[this.indexes[0]].Title}]
      }
    }, 200)
  }

  getNotesLanguages() {
    return new Promise((resolve) => {
      this.notebookService.getNotesLanguages().subscribe(async (res: any) => {
        this.languages = res.data.languages;
        await this.getNotebookTranslations();
        resolve(true)
      })
    })
  }

  getNotebookTranslations() {
    return new Promise((resolve) => {
      const data = {
        userId: localStorage.getItem('Id')
      }
      this.notebookService.getNotebookTranslations(data).subscribe((res: any) => {
        this.translations = res.data.translations;
        resolve(true)
      })
    })
  }

  // Get User Notebook Function //

  getUserNotebook() {
    let data = {
      userId: localStorage.getItem('Id')
    }
    this.notebookService.getUserNotebook(data).subscribe(((res: any) => {
      this.loader = false
      if (res.data.status) {
        if (res.data.notebook) {
          this.qbanks = res.data.notebook.Qbanks;
          this.indexes = JSON.parse(res.data.notebook.LatestNote);
          this.noteId = res.data.notebook.NoteID;

          if (this.quizEdit) {
            this.originalText = this.notebookText
          }

          this.newNotebook = true;
  
          // Reset Notebook Order //
          this.resetOrder()
  
          setTimeout(async () => {
            // If Latest Note is Qbank //
            if (this.indexes[0] != -1 && this.indexes[1] == -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
              this.heading = this.qbanks[this.indexes[0]]?.Title
              this.notebookText = this.qbanks[this.indexes[0]]?.Description
              this.currentDate = this.qbanks[this.indexes[0]]?.CreatedDate
              this.updatedDate = this.qbanks[this.indexes[0]]?.UpdatedDate
              this.modifiedIndexes[0] = this.qbanks[this.indexes[0]]?.ID
              this.userNoteId = this.qbanks[this.indexes[0]]?.ID
              this.sectionType = 'qbank'
              this.textSummary = this.qbanks[this.indexes[0]]?.Summary
              this.summaryGenerated = this.qbanks[this.indexes[0]]?.SummaryGenerated
              this.voiceNoteFile = this.qbanks[this.indexes[0]]?.VoiceNoteFile
              this.voiceNoteGenerated = this.qbanks[this.indexes[0]]?.VoiceNoteGenerated
              this.referenceLinks = this.qbanks[this.indexes[0]]?.ReferenceLinks
              this.linksGenerated = this.qbanks[this.indexes[0]]?.LinkGenerated
              this.flashcards = this.qbanks[this.indexes[0]]?.AIFlashcards

              document.getElementById('qbankheader'+this.indexes[0])?.classList.add('active')
              this.items = [{label: this.qbanks[this.indexes[0]].Title}]
            }
  
            // If Latest Note is Subject //
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
              this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.Title
              this.notebookText = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.Description     
              this.currentDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.CreatedDate
              this.updatedDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.UpdatedDate
              this.modifiedIndexes[1] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.ID
              this.userNoteId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.ID
              this.sectionId = this.qbanks[this.indexes[0]]?.ID
              this.sectionType = 'subject'
              this.textSummary = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.Summary
              this.summaryGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.SummaryGenerated
              this.voiceNoteFile = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.VoiceNoteFile
              this.voiceNoteGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.VoiceNoteGenerated
              this.referenceLinks = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.ReferenceLinks
              this.linksGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.LinkGenerated
              this.flashcards = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.AIFlashcards

              this.qbanks[this.indexes[0]].open = true
              document.getElementById('subjectheader'+this.indexes[0]+this.indexes[1])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Title}]
            }
  
            // If Latest Note is System //
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] == -1) {
              this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.Title
              this.notebookText = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.Description  
              this.currentDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.CreatedDate
              this.updatedDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.UpdatedDate
              this.modifiedIndexes[2] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.ID
              this.userNoteId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.ID
              this.sectionId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.ID
              this.sectionType = 'system'
              this.textSummary = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.Summary
              this.summaryGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.SummaryGenerated
              this.voiceNoteFile = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.VoiceNoteFile
              this.voiceNoteGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.VoiceNoteGenerated
              this.referenceLinks = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.ReferenceLinks
              this.linksGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.LinkGenerated
              this.flashcards = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.AIFlashcards
              
              this.qbanks[this.indexes[0]].open = true
              this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].open = true
              document.getElementById('systemheader'+this.indexes[0]+this.indexes[1]+this.indexes[2])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems.length; i++) {
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.remove('hide')
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Title}]
            }
  
            // If Latest Note is Topic //
            if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] != -1) {
              this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.Title
              this.notebookText = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.Description  
              this.currentDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.CreatedDate
              this.updatedDate = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.UpdatedDate
              this.modifiedIndexes[3] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.ID
              this.userNoteId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.ID
              this.sectionId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.ID
              this.sectionType = 'topic'
              this.textSummary = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.Summary
              this.summaryGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.SummaryGenerated
              this.voiceNoteFile = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.VoiceNoteFile
              this.voiceNoteGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.VoiceNoteGenerated
              this.referenceLinks = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.ReferenceLinks
              this.linksGenerated = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.LinkGenerated
              this.flashcards = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]]?.AIFlashcards

              this.qbanks[this.indexes[0]].open = true
              this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].open = true
              this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].open = true
              document.getElementById('topicheader'+this.indexes[0]+this.indexes[1]+this.indexes[2]+this.indexes[3])?.classList.add('active')
  
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects.length; i++) {
                document.getElementById('subject'+this.indexes[0]+i)?.classList.remove('hide')
                document.getElementById('subject'+this.indexes[0]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems.length; i++) {
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.remove('hide')
                document.getElementById('system'+this.indexes[0]+this.indexes[1]+i)?.classList.add('show')
              }
              for (let i = 0; i < this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics.length; i++) {
                document.getElementById('topic'+this.indexes[0]+this.indexes[1]+this.indexes[2]+i)?.classList.remove('hide')
                document.getElementById('topic'+this.indexes[0]+this.indexes[1]+this.indexes[2]+i)?.classList.add('show')
              }
              this.items = [{label: this.qbanks[this.indexes[0]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Title}, {label: this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].Title}]
            }

            this.setLinksSettings();
            this.setSummarySettings();
            if (this.voiceNoteFile) {
              this.voiceNoteKey = this.voiceNoteFile;
              const file = {
                fileKey: this.voiceNoteKey
              };
              this.audioLoader = true;
              this.voiceNoteFile = await this.getCloudFrontLink(file);
              this.executeAudioFile();
            }
            this.restoreNotebookText = null;
            this.updateTranslations()
  
            if (this.quizEdit) {
              this.runQuill()
            }
          }, 200)
        }
        else {
          if (this.quizEdit) {
            this.originalText = this.notebookText
            this.addNewSection('qbank', [-1, -1, -1, -1]); 
            this.newNotebook = true; 
            this.isEdit = true;
            document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
          }
        }
      }
      else {
        this.messageService.add({severity:'error', summary:'Notebook Failed', detail:'Something went wrong while receiving notes!'});
      }
    }))
  }

  // Add New Section to User Notebook //

  addNewSection(type: any, indexes: any) {
    this.newNote = true;
    this.newNotebook = true; 
    this.isEdit = true;
    this.heading = 'Untitled'
    this.notebookText = '';
    this.textSummary = null;
    this.voiceNoteFile = null;
    this.referenceLinks = null;
    this.flashcards = null;
    this.currentDate = new Date();
    this.updatedDate = new Date();
    this.showSave = true;
    this.runQuill()
    this.indexes = indexes;

    let section = {
      Title: this.heading, 
      Description: this.notebookText, 
      enable: true, 
      CreatedDate: this.currentDate, 
      UpdatedDate: this.updatedDate
    }

    if (type === 'qbank'){
      this.qbanks.push(section)
      this.indexes[0] = this.qbanks.length - 1
    } else if (type === 'subject') {
      if (!this.qbanks[indexes[0]].Subjects) {
        this.qbanks[indexes[0]].Subjects = []
      }
      this.qbanks[indexes[0]].Subjects.push(section)
      this.indexes[1] = this.qbanks[indexes[0]].Subjects.length - 1
    }
    else if (type === 'system') {
      if (!this.qbanks[indexes[0]].Subjects[indexes[1]].Systems) {
        this.qbanks[indexes[0]].Subjects[indexes[1]].Systems = []
      }
      this.qbanks[indexes[0]].Subjects[indexes[1]].Systems.push(section)
      this.indexes[2] = this.qbanks[indexes[0]].Subjects[indexes[1]].Systems.length - 1
    }
    else if (type === 'topic') {
      if (!this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics) {
        this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics = []
      }
      this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics.push(section)
      this.indexes[3] = this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics.length - 1
    }

    setTimeout(() => {
      this.selectNotebookSection(type, section, this.indexes, false)
    }, 200)
  }

  // Select Specific Section of User Notebook //

  async selectNotebookSection(type: any, section: any, indexes: any, clickable: any) {
    this.isExpanded = false;
    if (this.changeState) {
      this.confirm(type, section, indexes, clickable)
    } else {
      this.sectionType = type;

      if (section.ID) {
        this.userNoteId = section.ID;
      } else {
        this.userNoteId = -1;
      }

      this.indexes = indexes;
      this.heading = section.Title;
      this.notebookText = section.Description;
      this.currentDate = section.CreatedDate;
      this.updatedDate = section.UpdatedDate;
      this.textSummary = section.Summary;
      this.summaryGenerated = section.SummaryGenerated;
      this.voiceNoteFile = section.VoiceNoteFile;
      this.voiceNoteKey = section.VoiceNoteFile;
      this.voiceNoteGenerated = section.VoiceNoteGenerated;
      this.referenceLinks = section.ReferenceLinks;
      this.linksGenerated = section.LinkGenerated;
      this.flashcards = section.AIFlashcards;
      this.summaryOpened = false;
      this.linksOpened = false;

      this.setLinksSettings();
      this.setSummarySettings();
      if (this.voiceNoteFile) {
        const file = {
          fileKey: this.voiceNoteKey
        };
        this.audioLoader = true;
        this.voiceNoteFile = await this.getCloudFrontLink(file);
        this.executeAudioFile();
      }
      this.restoreNotebookText = null;
      this.updateTranslations()
      
      this.resetQbanks()

      if (this.sectionType === 'qbank') {
        document.getElementById('qbankheader'+indexes[0])?.classList.add('active')
    
        if (!clickable) {
          this.changeState = true
        }
        else {
          this.changeState = false
        }
  
        if (this.qbanks[indexes[0]].Subjects) {
          for (let i = 0; i < this.qbanks[indexes[0]].Subjects.length; i++) {
            if (document.getElementById('subject'+indexes[0]+i)?.classList.contains('hide')) {
              document.getElementById('subject'+indexes[0]+i)?.classList.remove('hide')
              document.getElementById('subject'+indexes[0]+i)?.classList.add('show')
              this.qbanks[indexes[0]].open = true
            }
            else {
              document.getElementById('subject'+indexes[0]+i)?.classList.add('hide')
              document.getElementById('subject'+indexes[0]+i)?.classList.remove('show')
              this.qbanks[indexes[0]].open = false
            }
          }
        }

        this.items = [{ label: this.qbanks[indexes[0]].Title }]
      } else if (this.sectionType === 'subject') {
        this.sectionId = this.qbanks[indexes[0]]?.ID
        document.getElementById('subjectheader'+indexes[0]+indexes[1])?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[indexes[0]].Subjects.length; i++) {
            if (document.getElementById('subject'+indexes[0]+i)?.classList.contains('hide')) {
              document.getElementById('subject'+indexes[0]+i)?.classList.remove('hide')
              document.getElementById('subject'+indexes[0]+i)?.classList.add('show')
            }
          }
          this.qbanks[indexes[0]].open = true
          this.changeState = true
        }
        else {
          if (this.qbanks[indexes[0]].Subjects[indexes[1]].Systems) {
            for (let i = 0; i < this.qbanks[indexes[0]].Subjects[indexes[1]].Systems.length; i++) {
              if (document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.contains('hide')) {
                document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.remove('hide')
                document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.add('show')
                this.qbanks[indexes[0]].Subjects[indexes[1]].open = true
              }
              else {
                document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.add('hide')
                document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.remove('show')
                this.qbanks[indexes[0]].Subjects[indexes[1]].open = false
              }
            }
          }
          this.changeState = false
        }

        this.items = [{ label: this.qbanks[indexes[0]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Title } ]
      } else if (this.sectionType === 'system') {
        this.sectionId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]]?.ID
        document.getElementById('systemheader'+indexes[0]+indexes[1]+indexes[2])?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[indexes[0]].Subjects[indexes[1]].Systems.length; i++) {
            if (document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.contains('hide')) {
              document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.remove('hide')
              document.getElementById('system'+indexes[0]+indexes[1]+i)?.classList.add('show')
            }
          }
          this.qbanks[indexes[0]].Subjects[indexes[1]].open = true
          this.changeState = true
        }
        else {
          if (this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics) {
            for (let i = 0; i < this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics.length; i++) {
              if (document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.contains('hide')) {
                document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.remove('hide')
                document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.add('show')
                this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].open = true
              }
              else {
                document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.add('hide')
                document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.remove('show')
                this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].open = false
              }
            }
          }
          this.changeState = false
        }

        this.items = [{ label: this.qbanks[indexes[0]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Title }]
      } else if (this.sectionType === 'topic') {
        this.sectionId = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]]?.ID
        document.getElementById('topicheader'+indexes[0]+indexes[1]+indexes[2]+indexes[3])?.classList.add('active')
  
        if (!clickable) {
          for (let i = 0; i < this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics.length; i++) {
            if (document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.contains('hide')) {
              document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.remove('hide')
              document.getElementById('topic'+indexes[0]+indexes[1]+indexes[2]+i)?.classList.add('show')
            }
          }
          this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].open = true
          this.changeState = true
        }
        else {
          this.changeState = false
        }

        this.items = [{ label: this.qbanks[indexes[0]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Title }, { label: this.qbanks[indexes[0]].Subjects[indexes[1]].Systems[indexes[2]].Topics[indexes[3]].Title }]
      }

      if (this.isEdit) {
        this.ckEditorNotebook.setData(this.notebookText.replace(/(<br>)/g, "\n"))
      }
    }

    if (this.searchString != "") {
      this.highlightNotebook()
    }
  }

  // Update User Notebook Function //

  async updateUserNotebook() {
    return new Promise(async (resolve: any) => {
      await this.saveNoteAndUpdateNotebook()

      let sortId = -1;

      if (this.sectionType === 'qbank') {
        sortId = this.indexes[0]
      } else if (this.sectionType === 'subject') {
        sortId = this.indexes[1]
      } else if (this.sectionType === 'system') {
        sortId = this.indexes[2]
      } else if (this.sectionType === 'topic') {
        sortId = this.indexes[3]      
      }

      var data = {
        userId: localStorage.getItem('Id'),
        noteId: this.noteId,
        type: this.sectionType,
        sectionId: this.sectionId,
        sortId: sortId,
        note: {
          id: this.userNoteId,
          title: this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, ''),
          description: this.selectedTranslation ? this.restoreNotebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '') : this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '')
        },
        translatedText: this.selectedTranslation ? this.notebookText : null,
        languageId: this.selectedTranslation ? this.selectedTranslation.LanguageID : null
      }
  
      if (this.isDemo) {
        this.showSave = false;
        this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
        this.isEdit = false
        this.ckEditorNotebook.destroy()
        document.getElementById('notebookdialog').style.border = 'none'
        this.displaySave = false;
        this.changeState = false;
      } else {
        this.isSave = true
        this.notebookService.addUserNotebook(data).subscribe(async (res: any) => {
          if (res.data.status) {
            this.showSave = false;
            this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
            this.isEdit = false
            this.ckEditorNotebook.destroy()
            document.getElementById('notebookdialog').style.border = 'none'
            this.displaySave = false;
            this.changeState = false;
  
            if (this.noteId === -1) {
              this.noteId = res.data.notebookCreated
            }
  
            if (res.data.noteCreated !== -1) {
              this.userNoteId = res.data.noteCreated
              if (this.sectionType === 'qbank') {
                this.qbanks[this.indexes[0]].ID = this.userNoteId
              } else if (this.sectionType === 'subject') {
                this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].ID = this.userNoteId
              } else if (this.sectionType === 'system') {
                this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].ID = this.userNoteId
              } else if (this.sectionType === 'topic') {
                this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].ID = this.userNoteId
              }
            }

            // Updating Modified Indexes for Swapping Implementation //

            if (this.sectionType === 'qbank') {
              this.modifiedIndexes[0] = this.qbanks[this.indexes[0]].ID
            } else if (this.sectionType === 'subject') {
              this.modifiedIndexes[1] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].ID
            } else if (this.sectionType === 'system') {
              this.modifiedIndexes[2] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].ID
            } else if (this.sectionType === 'topic') {
              this.modifiedIndexes[3] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].ID
            }
  
            await this.updateLatestNotebook()
  
            this.isSave = false

            if (this.voiceNoteFile) {
              this.deleteNotebookVoiceNotes();
              this.displayUpdatedNotebook = true;
            }

            if (this.selectedTranslation) {
              await this.getNotebookTranslations();
              this.noteTranslations = this.translations.filter((item: any) => item.NoteID === this.userNoteId && item.NoteType === this.sectionType)
              this.notebookUpdated = false;
              if (this.noteTranslations.length > 0) {
                const languages = this.noteTranslations.map((item: any) => item.LanguageID);
                this.translationOptions = this.languages.filter((item: any) => languages.includes(item.LanguageID))
                const index = this.noteTranslations.findIndex((item: any) => item.LanguageID === this.selectedTranslation.LanguageID);
                if (index !== -1) {
                  const newDate = new Date(this.noteTranslations[index].GeneratedOn).toLocaleString('en-UK');
                  let noteDate = this.formatDate(newDate.substring(0, 10));
                  noteDate = noteDate + 'T' + newDate.substring(12, 20);
                  const notebookDate = new Date(this.updatedDate).toLocaleString('en-UK');
                  let updateDate = this.formatDate(notebookDate.substring(0, 10));
                  updateDate = updateDate + 'T' + notebookDate.substring(12, 20);
                  if (noteDate < updateDate) {
                    this.notebookUpdated = true;
                  }
                }
              }
            }
          }
          else {
            this.isSave = false
            this.messageService.add({severity:'error', summary:'Add Notebook Failed', detail:'Something went wrong while creating new note!'});
          }

          resolve(true)
        })
      }
    })
  }

  // Update User Latest Notebook Function //

  updateLatestNotebook() {
    return new Promise((resolve) => {
      let data = {
        noteId: this.noteId,
        latestNote: this.indexes
      }
      this.notebookService.updateLatestNotebook(data).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  confirm(type: any, section: any, indexes: any, clickable: any) {
    this.confirmationService.confirm({
        message: 'Do you want to save this note?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.changeState = false
          await this.updateUserNotebook()
          setTimeout(() => {
            this.selectNotebookSection(type, section, indexes, clickable)
          }, 1000)
        },
        reject: () => {
          this.cancelEdit(true)
          this.selectNotebookSection(type, section, indexes, clickable)
        }
    });
  }

  dropQbank(event: CdkDragDrop<string[]>) {
    this.isSwap = true
    this.qbanks = this.swapIndexes(this.qbanks, event.previousIndex, event.currentIndex)
    this.indexes[0] = this.qbanks.findIndex((item: any) => item.ID == this.modifiedIndexes[0])
    this.updateNoteSortIds(this.qbanks)
    this.swapUserNotebook(this.qbanks, event.previousIndex, event.currentIndex, 'qbank')
  }

  dropSubject(event: CdkDragDrop<string[]>) {
    this.isSwap = true
    this.qbanks[this.indexes[0]].Subjects = this.swapIndexes(this.qbanks[this.indexes[0]].Subjects, event.previousIndex, event.currentIndex)
    this.indexes[1] = this.qbanks[this.indexes[0]].Subjects.findIndex((item: any) => item.ID == this.modifiedIndexes[1])
    this.updateNoteSortIds(this.qbanks[this.indexes[0]].Subjects)
    this.swapUserNotebook(this.qbanks[this.indexes[0]].Subjects, event.previousIndex, event.currentIndex, 'subject')
  }

  dropSystem(event: CdkDragDrop<string[]>) {
    this.isSwap = true
    this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems = this.swapIndexes(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems, event.previousIndex, event.currentIndex)
    this.indexes[2] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems.findIndex((item: any) => item.ID == this.modifiedIndexes[2])
    this.updateNoteSortIds(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems)
    this.swapUserNotebook(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems, event.previousIndex, event.currentIndex, 'system')
  }

  dropTopic(event: CdkDragDrop<string[]>) {
    this.isSwap = true
    this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics = this.swapIndexes(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics, event.previousIndex, event.currentIndex)
    this.indexes[3] = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics.findIndex((item: any) => item.ID == this.modifiedIndexes[3])
    this.updateNoteSortIds(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics)
    this.swapUserNotebook(this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics, event.previousIndex, event.currentIndex, 'topic')
  }

  swapIndexes(array: any, firstIndex: any, secondIndex: any) {
    let item = array.splice(firstIndex, 1)[0];
    array.splice(secondIndex, 0, item);
    return array;
  }

  updateNoteSortIds(array: any) {
    array.forEach((element: any, index: any) => {
      element.SortId = index;
    });
  }

  async swapUserNotebook(section: any, prevIndex: any, currIndex: any, type: any) {
    section = section.map((item: any) => { return { ID: item.ID, SortId: item.SortId } })
    this.quizEdit = false
    var data = {
      userId: localStorage.getItem('Id'),
      noteId: this.noteId,
      section: section,
      prevIndex: prevIndex,
      currIndex: currIndex,
      indexes: this.indexes,
      type: type
    }

    if (this.isDemo) {
      this.showSave = false;
      this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
      this.isEdit = false
      this.ckEditorNotebook.destroy()
      document.getElementById('notebookdialog').style.border = 'none'
      this.displaySave = false;
      this.changeState = false;
    } else {
      this.notebookService.swapUserNotebook(data).subscribe((res: any)=>{
        this.isSwap = false
        if (res.data.status) {
          this.showSave = false;
          this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
          this.isEdit = false
          if (this.ckEditorNotebook) {
            this.ckEditorNotebook.destroy()
          }
          document.getElementById('notebookdialog').style.border = 'none'
          this.displaySave = false;
          this.changeState = false;
        }
        else {
          this.messageService.add({severity:'error', summary:'Swap Notebook Failed', detail:'Something went wrong while swapping notes!'});
        }
      })
    }
  }

  // Delete Notebook Implementation //

  setDeleteNote(qbank: any, subject: any, system: any, topic: any) {
    this.deleteIndexes = [qbank, subject, system, topic];
    this.confirmDeletion = '';
    this.displayDelete = true
  }

  deleteNote() {
    if (this.confirmDeletion == 'delete') {
      if (this.deleteIndexes[1] == -1) {
        this.deletedType = 'qbank'
        this.deletedSection = this.qbanks[this.deleteIndexes[0]]?.ID
        this.qbanks.splice(this.deleteIndexes[0], 1)
      }
      else if (this.deleteIndexes[2] == -1) {
        this.deletedType = 'subject'
        this.deletedSection = this.qbanks[this.deleteIndexes[0]]?.Subjects[this.deleteIndexes[1]]?.ID
        this.qbanks[this.deleteIndexes[0]].Subjects.splice(this.deleteIndexes[1], 1)
      }
      else if (this.deleteIndexes[3] == -1) {
        this.deletedType = 'system'
        this.deletedSection = this.qbanks[this.deleteIndexes[0]].Subjects[this.deleteIndexes[1]].Systems[this.deleteIndexes[2]]?.ID
        this.qbanks[this.deleteIndexes[0]].Subjects[this.deleteIndexes[1]].Systems.splice(this.deleteIndexes[2], 1)
      }
      else {
        this.deletedType = 'topic'
        this.deletedSection = this.qbanks[this.deleteIndexes[0]].Subjects[this.deleteIndexes[1]].Systems[this.deleteIndexes[2]].Topics[this.deleteIndexes[3]]?.ID
        this.qbanks[this.deleteIndexes[0]].Subjects[this.deleteIndexes[1]].Systems[this.deleteIndexes[2]].Topics.splice(this.deleteIndexes[3], 1)
      }
      this.displayDelete = false

      if (this.qbanks.length <= 0) {
        this.newNotebook = false
      } 
  
      this.deleteUserNotebook()
    }
  }

  async deleteUserNotebook() {
    this.quizEdit = false
    var data = {
      noteId: this.noteId,
      userId: localStorage.getItem('Id'),
      type: this.deletedType,
      sectionId: this.deletedSection
    }
    this.tempDetails = JSON.parse(JSON.stringify(this.qbanks))

    if (this.isDemo) {
      this.showSave = false;
      this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
      this.isEdit = false
      this.ckEditorNotebook.destroy()
      document.getElementById('notebookdialog').style.border = 'none'
      this.displaySave = false;
      this.changeState = false;
    } else {
      this.loader = true;
      this.notebookService.deleteUserNotebook(data).subscribe((res: any) => {
        if (res.data.status) {
          this.showSave = false;
          this.originalNotebook = JSON.parse(JSON.stringify(this.qbanks))
          this.isEdit = false

          if (document.getElementById('notebookdialog')) {
            document.getElementById('notebookdialog')!.style.border = 'none'
          }
          this.displaySave = false;
          this.changeState = false;

          if (this.qbanks.length > 0) {
            this.indexes = [0, -1, -1, -1]
            this.selectNotebookSection('qbank', this.qbanks[0], this.indexes, true)
          }

          if (this.ckEditorNotebook) {
            this.ckEditorNotebook?.destroy()
          }

          this.loader = false;
        }
        else {
          this.loader = false;
          this.messageService.add({severity:'error', summary:'Delete Notebook Failed', detail:'Something went wrong while deleting note!'});
        }
      })
    }
  }

  // Search Highlighting Feature //

  highlight(qbank: any, subject: any, system: any, topic: any, marked: any) {
    setTimeout(() => {
      let context: any;
      if (qbank != -1 && subject == -1 && system == -1 && topic == -1) {
        context = document.querySelector(".text"+qbank)
      }
      if (qbank != -1 && subject != -1 && system == -1 && topic == -1) {
        context = document.querySelector(".text"+qbank+subject)
      }
      if (qbank != -1 && subject != -1 && system != -1 && topic == -1) {
        context = document.querySelector(".text"+qbank+subject+system)
      }
      if (qbank != -1 && subject != -1 && system != -1 && topic != -1) {
        context = document.querySelector(".text"+qbank+subject+system+topic)
      }
      var instance = new Mark(context);
      var options = {
        "className": 'search'
      }
      instance.unmark(options)
      if (marked) {
        instance.mark(this.searchString, options)
      }
    }, 100)
  }

  highlightNotebook() {
    setTimeout(() => {
      let context = document.querySelector(".notebookText")
      var instance = new Mark(context);
      var options = {
        "className": 'search'
      }
      instance.unmark(options)
      instance.mark(this.searchString, options)
    }, 100)
  }

  // Search Notebook Implementation //

  async onSearchChange() {
    if (this.searchString != "") {
      this.searcher = true
      for (let i = 0; i < this.qbanks.length; i++) {
        if (this.qbanks[i].Title.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].Description.toLowerCase().includes(this.searchString.toLowerCase())) {
          this.qbanks[i].enable = true
          this.heading = this.qbanks[i].Title
          this.notebookText = this.qbanks[i].Description
          this.currentDate = this.qbanks[i].CreatedDate
          this.updatedDate = this.qbanks[i].UpdatedDate
          this.textSummary = this.qbanks[i].Summary
          this.summaryGenerated = this.qbanks[i].SummaryGenerated
          this.voiceNoteFile = this.qbanks[i].VoiceNoteFile
          this.voiceNoteGenerated = this.qbanks[i].VoiceNoteGenerated
          this.referenceLinks = this.qbanks[i].ReferenceLinks
          this.linksGenerated = this.qbanks[i].LinkGenerated
          this.flashcards = this.qbanks[i].AIFlashcards
          this.items = [{label: this.qbanks[i].Title}]
          this.highlight(i, -1, -1, -1, true)
        }
        else {
          this.qbanks[i].enable = false
          this.highlight(i, -1, -1, -1, false)
        }
        this.qbanks[i].open = false
        document.getElementById('qbankheader'+i)?.classList.remove('active')

        if (this.qbanks[i].Subjects) {
          for (let j = 0; j < this.qbanks[i].Subjects.length; j++) {
            if (this.qbanks[i].Subjects[j].Title.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].Subjects[j].Description.toLowerCase().includes(this.searchString.toLowerCase())) {
              this.qbanks[i].enable = true
              this.qbanks[i].open = true
              this.qbanks[i].Subjects[j].enable = true
              this.heading = this.qbanks[i].Subjects[j].Title
              this.notebookText = this.qbanks[i].Subjects[j].Description
              this.currentDate = this.qbanks[i].Subjects[j].CreatedDate
              this.updatedDate = this.qbanks[i].Subjects[j].UpdatedDate
              this.textSummary = this.qbanks[i].Subjects[j].Summary
              this.summaryGenerated = this.qbanks[i].Subjects[j].SummaryGenerated
              this.voiceNoteFile = this.qbanks[i].Subjects[j].VoiceNoteFile
              this.voiceNoteGenerated = this.qbanks[i].Subjects[j].VoiceNoteGenerated
              this.referenceLinks = this.qbanks[i].Subjects[j].ReferenceLinks
              this.linksGenerated = this.qbanks[i].Subjects[j].LinkGenerated
              this.flashcards = this.qbanks[i].Subjects[j].AIFlashcards
              this.items = [{label: this.qbanks[i].Title}, {label: this.qbanks[i].Subjects[j].Title}]
              this.highlight(i, j, -1, -1, true)
              setTimeout(() => {
                document.getElementById('subject'+i+j)?.classList.add('show')
                document.getElementById('subject'+i+j)?.classList.remove('hide')
              }, 200)
            }
            else {
              this.qbanks[i].Subjects[j].enable = false
              this.highlight(i, j, -1, -1, false)
              setTimeout(() => {
                document.getElementById('subject'+i+j)?.classList.add('hide')
                document.getElementById('subject'+i+j)?.classList.remove('show')
              }, 200)
            }
            this.qbanks[i].Subjects[j].open = false
            document.getElementById('subjectheader'+i+j)?.classList.remove('active')

            if (this.qbanks[i].Subjects[j].Systems) {
              for (let k = 0; k < this.qbanks[i].Subjects[j].Systems.length; k++) {
                if (this.qbanks[i].Subjects[j].Systems[k].Title.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].Subjects[j].Systems[k].Description.toLowerCase().includes(this.searchString.toLowerCase())) {
                  this.qbanks[i].enable = true
                  this.qbanks[i].Subjects[j].enable = true
                  this.qbanks[i].open = true
                  this.qbanks[i].Subjects[j].open = true
                  this.qbanks[i].Subjects[j].Systems[k].enable = true
                  this.heading = this.qbanks[i].Subjects[j].Systems[k].Title
                  this.notebookText = this.qbanks[i].Subjects[j].Systems[k].Description
                  this.currentDate = this.qbanks[i].Subjects[j].Systems[k].CreatedDate
                  this.updatedDate = this.qbanks[i].Subjects[j].Systems[k].UpdatedDate
                  this.textSummary = this.qbanks[i].Subjects[j].Systems[k].Summary
                  this.summaryGenerated = this.qbanks[i].Subjects[j].Systems[k].SummaryGenerated
                  this.voiceNoteFile = this.qbanks[i].Subjects[j].Systems[k].VoiceNoteFile
                  this.voiceNoteGenerated = this.qbanks[i].Subjects[j].Systems[k].VoiceNoteGenerated
                  this.referenceLinks = this.qbanks[i].Subjects[j].Systems[k].ReferenceLinks
                  this.linksGenerated = this.qbanks[i].Subjects[j].Systems[k].LinkGenerated
                  this.flashcards = this.qbanks[i].Subjects[j].Systems[k].AIFlashcards
                  this.items = [{label: this.qbanks[i].Title}, {label: this.qbanks[i].Subjects[j].Title}, {label: this.qbanks[i].Subjects[j].Systems[k].Title}]
                  this.highlight(i, j, k, -1, true)
                  setTimeout(() => {
                    document.getElementById('subject'+i+j)?.classList.add('show')
                    document.getElementById('subject'+i+j)?.classList.remove('hide')
                    document.getElementById('system'+i+j+k)?.classList.add('show')
                    document.getElementById('system'+i+j+k)?.classList.remove('hide')
                  }, 200)
                }
                else {
                  this.qbanks[i].Subjects[j].Systems[k].enable = false
                  this.highlight(i, j, k, -1, false)
                  setTimeout(() => {
                    document.getElementById('system'+i+j+k)?.classList.add('hide')
                    document.getElementById('system'+i+j+k)?.classList.remove('show')
                  }, 200)
                }
                this.qbanks[i].Subjects[j].Systems[k].open = false
                document.getElementById('systemheader'+i+j+k)?.classList.remove('active')

                if (this.qbanks[i].Subjects[j].Systems[k].Topics) {
                  for (let m = 0; m < this.qbanks[i].Subjects[j].Systems[k].Topics.length; m++) {
                    if (this.qbanks[i].Subjects[j].Systems[k].Topics[m].Title.toLowerCase().includes(this.searchString.toLowerCase()) || this.qbanks[i].Subjects[j].Systems[k].Topics[m].Description.toLowerCase().includes(this.searchString.toLowerCase())) {
                      this.qbanks[i].enable = true
                      this.qbanks[i].Subjects[j].enable = true
                      this.qbanks[i].Subjects[j].Systems[k].enable = true
                      this.qbanks[i].open = true
                      this.qbanks[i].Subjects[j].open = true
                      this.qbanks[i].Subjects[j].Systems[k].open = true
                      this.qbanks[i].Subjects[j].Systems[k].Topics[m].enable = true
                      this.heading = this.qbanks[i].Subjects[j].Systems[k].Topics[m].Title
                      this.notebookText = this.qbanks[i].Subjects[j].Systems[k].Topics[m].Description
                      this.currentDate = this.qbanks[i].Subjects[j].Systems[k].Topics[m].CreatedDate
                      this.updatedDate = this.qbanks[i].Subjects[j].Systems[k].Topics[m].UpdatedDate
                      this.textSummary = this.qbanks[i].Subjects[j].Systems[k].Topics[m].Summary
                      this.summaryGenerated = this.qbanks[i].Subjects[j].Systems[k].Topics[m].SummaryGenerated
                      this.voiceNoteFile = this.qbanks[i].Subjects[j].Systems[k].Topics[m].VoiceNoteFile
                      this.voiceNoteGenerated = this.qbanks[i].Subjects[j].Systems[k].Topics[m].VoiceNoteGenerated
                      this.referenceLinks = this.qbanks[i].Subjects[j].Systems[k].Topics[m].ReferenceLinks
                      this.linksGenerated = this.qbanks[i].Subjects[j].Systems[k].Topics[m].LinkGenerated
                      this.flashcards = this.qbanks[i].Subjects[j].Systems[k].Topics[m].AIFlashcards
                      this.items = [{label: this.qbanks[i].Title}, {label: this.qbanks[i].Subjects[j].Title}, {label: this.qbanks[i].Subjects[j].Systems[k].Title}, {label: this.qbanks[i].Subjects[j].Systems[k].Topics[m].Title}]
                      this.highlight(i, j, k, m, true)
                      setTimeout(() => {
                        document.getElementById('subject'+i+j)?.classList.add('show')
                        document.getElementById('subject'+i+j)?.classList.remove('hide')
                        document.getElementById('system'+i+j+k)?.classList.add('show')
                        document.getElementById('system'+i+j+k)?.classList.remove('hide')
                        document.getElementById('topic'+i+j+k+m)?.classList.add('show')
                        document.getElementById('topic'+i+j+k+m)?.classList.remove('hide')
                      }, 200)
                    }
                    else {
                      this.qbanks[i].Subjects[j].Systems[k].Topics[m].enable = false
                      this.highlight(i, j, k, m, false)
                      setTimeout(() => {
                        document.getElementById('topic'+i+j+k+m)?.classList.remove('show')
                        document.getElementById('topic'+i+j+k+m)?.classList.add('hide')
                      }, 200)
                    }
                    this.qbanks[i].Subjects[j].Systems[k].Topics[m].open = false
                    document.getElementById('topicheader'+i+j+k+m)?.classList.remove('active')
                  }
                }
              }
            }
          }
        }
      }

      this.setLinksSettings();
      this.setSummarySettings();
      if (this.voiceNoteFile) {
        this.voiceNoteKey = this.voiceNoteFile
        const file = {
          fileKey: this.voiceNoteKey
        };
        this.audioLoader = true;
        this.voiceNoteFile = await this.getCloudFrontLink(file);
        this.executeAudioFile();
      }
      this.restoreNotebookText = null;
      this.updateTranslations()
    }
    else {
      this.getUserNotebook()
    }
    this.highlightNotebook()
  }

  cancelSearch() {
    this.searchString = ""
    this.searcher = false
    this.onSearchChange()
  }

  // Cancel Notebook Edit //

  cancelEdit(isConfirmed: any) {
    this.changeState = false
    this.showSave = false
    this.isEdit = false
    this.ckEditorNotebook.destroy()
    document.getElementById('notebookdialog').style.border = 'none' 

    if (this.indexes[0] != -1 && this.indexes[1] == -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
      this.heading = this.qbanks[this.indexes[0]].Title
    }
    if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] == -1 && this.indexes[3] == -1) {
      this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Title
    }
    if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] == -1) {
      this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Title
    }
    if (this.indexes[0] != -1 && this.indexes[1] != -1 && this.indexes[2] != -1 && this.indexes[3] != -1) {
      this.heading = this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].Title
    }

    if (this.newNote) {
      if (this.indexes[1] == -1) {
        this.qbanks.splice(this.indexes[0], 1)
      }
      else if (this.indexes[2] == -1) {
        this.qbanks[this.indexes[0]].Subjects.splice(this.indexes[1], 1)
      }
      else if (this.indexes[3] == -1) {
        this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems.splice(this.indexes[2], 1)
      }
      else { 
        this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics.splice(this.indexes[3], 1)
      }
      this.newNote = false;

      if (!isConfirmed) {
        this.resetOrder()
        if (this.qbanks.length > 0) {
          this.indexes = [0, -1, -1, -1]
          this.selectNotebookSection('qbank', this.qbanks[0], this.indexes, true)
        }     
      }
    }
  }

  // Update Notebook Text (Quill Implementation) //

  updateText() {
    if (this.quizEdit) {
      if (this.html.includes('<tr>') && !this.html.includes('<table>')) {
        this.html = '<table><tbody>' + this.html + '</tbody></table>'
      }
      var viewFragment = this.ckEditorNotebook.data.processor.toView(this.html);
      var modelFragment = this.ckEditorNotebook.data.toModel(viewFragment);
      this.ckEditorNotebook.model.insertContent(modelFragment, this.editorPosition);
      let control = document.getElementById('selection')
      control.style.display = 'none'
      this.quizEdit = false
      this.notebookText = this.notebookText.replace(/(\n)/g, "<br>")
    }
  }

  // Handle Notebook Text Selection (Quill Implementation) //

  handleSelection(event: any) {
    if (this.quizEdit) {
      this.tempText = ""

      this.tempText = this.notebookText.replace(/<(?!br\s*\/?)[^>]+>/g, "")
      this.tempText = this.tempText.replace(/(&nbsp;)/g, " ")

      this.selectedContent = this.tempText.slice(event[0], event[1])
      this.endContent = this.tempText.slice(event[1], this.tempText.length)

      this.textPosition = event[1]
      let rect = window.getSelection().getRangeAt(0).getBoundingClientRect();
      if (rect.top != 0 && !isNaN(event[1])) {
        let control = document.getElementById('selection')
        control.style.top = `calc(${rect.top}px - 55px)`;
        control.style.left = `calc(${rect.left}px + calc(${rect.width}px / 2) - 140px)`;
      }
      else {
        let control = document.getElementById('selection')
        control.style.top = `350px`;
      }
    }
  }

  // Mobile Responsiveness //

  openNotebook() {
    this.sidebar = !this.sidebar
    if (this.sidebar) {
      document.getElementById('list').style.display = 'flex'
      document.getElementById('data').style.display = 'none'
    }
    else {
      document.getElementById('list').style.display = 'none'
      document.getElementById('data').style.display = 'flex'
    }
  }

  translationQuill() {
    // Destroy already selected CKEditor //
    if (this.ckEditorTranslation) {
      if (this.ckEditorTranslation?.state != 'destroyed') {
        this.ckEditorTranslation.destroy() 
      }
    }

    // Initializing and Configuring new CKEditor //
    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorTranslation"), {
      }).then((editor: any) => {
        this.ckEditorTranslation = editor
        if (this.translatedText) {
          editor.setData(this.translatedText.replace(/(<br>)/g, "\n"))
          this.ckEditorTranslation.setData(this.translatedText)
        } else {
          editor.enableReadOnlyMode('ckEditorTranslation')
        }
      })
    }, 100)
  }

  // Run Quill //

  runQuill() {
    this.isEdit = true
    this.showSave = true

    // Destroy already selected CKEditor //
    if (this.ckEditorNotebook) {
      if (this.ckEditorNotebook?.state != 'destroyed') {
        this.ckEditorNotebook.destroy() 
      }
    }

    // Initializing and Configuring new CKEditor //
    setTimeout(() => {
      Editor.create(document.querySelector("#ckEditorNotebook"), {
      }).then((editor: any) => {
        this.ckEditorNotebook = editor
        editor.setData(this.notebookText.replace(/(<br>)/g, "\n"))
        editor.model.document.selection.on('change', (event) => {
          if (event.source._ranges.length > 0) {
            this.handleSelection(event.source._ranges[0].start.path)
            this.editorPosition = editor.model.document.selection.getFirstPosition();
          }
        })
        
        setTimeout(() => {
          document.getElementById('notebookdialog').style.border = '1px solid #ccced1'
        }, 100)
      })
    }, 100)
  }

  // Reset Notebook Functionalities //

  resetQbanks() {
    for (let i = 0; i < this.qbanks.length; i++) {
      document.getElementById('qbankheader'+i)?.classList.remove('active')
      if (this.qbanks[i].Subjects) {
        for (let j = 0; j < this.qbanks[i].Subjects.length; j++) {
          document.getElementById('subjectheader'+i+j)?.classList.remove('active')
          if (this.qbanks[i].Subjects[j].Systems) {
            for (let k = 0; k < this.qbanks[i].Subjects[j].Systems.length; k++) {
              document.getElementById('systemheader'+i+j+k)?.classList.remove('active')
              if (this.qbanks[i].Subjects[j].Systems[k].Topics) {
                for (let m = 0; m < this.qbanks[i].Subjects[j].Systems[k].Topics.length; m++) {
                  document.getElementById('topicheader'+i+j+k+m)?.classList.remove('active')
                }
              }
            }
          }
        }
      }
    }
  }

  resetOrder() {
    for (let i = 0; i < this.qbanks.length; i++) {
      this.qbanks[i].open = false
      this.qbanks[i].enable = true
      if (this.qbanks[i].Subjects) {
        for (let j = 0; j < this.qbanks[i].Subjects.length; j++) {
          this.qbanks[i].Subjects[j].open = false
          this.qbanks[i].Subjects[j].enable = true
          if (this.qbanks[i].Subjects[j].Systems) {
            for (let k = 0; k < this.qbanks[i].Subjects[j].Systems.length; k++) {
              this.qbanks[i].Subjects[j].Systems[k].open = false
              this.qbanks[i].Subjects[j].Systems[k].enable = true
              if (this.qbanks[i].Subjects[j].Systems[k].Topics) {
                for (let m = 0; m < this.qbanks[i].Subjects[j].Systems[k].Topics.length; m++) {
                  this.qbanks[i].Subjects[j].Systems[k].Topics[m].open = false
                  this.qbanks[i].Subjects[j].Systems[k].Topics[m].enable = true
                }
              }
            }
          }
        }
      }
    }
  }

  // Save User Note and Update User Notebook //

  saveNoteAndUpdateNotebook() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.notebookText = this.ckEditorNotebook.getData()
        const newDate = new Date().toLocaleString('en-UK');
        this.updatedDate = this.formatDate(newDate.substring(0, 10));
        this.updatedDate = this.updatedDate + 'T' + newDate.substring(12, 20);        
        this.isRegenerateSummary = true;
        this.isRegenerateLinks = true;
     
        if (this.indexes[1] === -1) {
          this.qbanks[this.indexes[0]].UpdatedDate = this.updatedDate;
          this.qbanks[this.indexes[0]].Description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].Title = this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
        } else if (this.indexes[2] === -1) {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].UpdatedDate = this.updatedDate;
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Title = this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
        } else if (this.indexes[3] === -1) {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].UpdatedDate = this.updatedDate;
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Title = this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
        } else {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].UpdatedDate = this.updatedDate;
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].Description = this.notebookText.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].Title = this.heading.replace(/["]/g, "'").replace(/[\n]/g, '').replace(/[\r]/g, '').replace(/[\t]/g, '');
        }

        this.showSave = true;
        this.newNote = false;
        
        resolve(true)
      }, 100)
    })
  }

  ngOnDestroy(): void {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function(e) {
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
        return false;
      }
    }
  }

  generateSummaryUsingAI() {
    let question = 'Generate a summary of the following html content \n ';
    question += this.notebookText;
    const data = {
      "question": question,
      "system": "Return the proper and explainable summary in html format. It should be in paragraph form. Summary length should be of 30% of original content."
    }
    if (this.notebookText === '') {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at least 1 character.'});
    } else {
      this.aiLoader = true;
      this.aiText = 'AI Summary';
      this.notebookService.generateUsingOpenAI(data).subscribe(async (res: any) => {
        this.textSummary = res.response.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '');
        const newDate = new Date().toLocaleString('en-UK');
        this.summaryGenerated = this.formatDate(newDate.substring(0, 10));
        this.summaryGenerated = this.summaryGenerated + 'T' + newDate.substring(12, 20);
        this.setSummarySettings();
        this.summaryOpened = true;
        await this.updateNotebookSummary();
        this.messageService.add({severity:'success', summary:'AI Summary Generated', detail:'Your summary is generated successfully.'});
        this.aiLoader = false;
      }, (error: any) => {
        this.aiLoader = false;
        this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
      })
    }
  }

  updateNotebookSummary() {
    return new Promise((resolve: any) => {
      const data = {
        table: this.sectionType + 's',
        summary: this.textSummary,
        noteId: this.userNoteId
      }
      this.notebookService.updateNotebookSummary(data).subscribe((res: any) => {
        if (this.sectionType === 'qbank') {
          this.qbanks[this.indexes[0]].Summary = this.textSummary
          this.qbanks[this.indexes[0]].SummaryGenerated = this.summaryGenerated
        } else if (this.sectionType === 'subject') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Summary = this.textSummary
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].SummaryGenerated = this.summaryGenerated
        } else if (this.sectionType === 'system') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Summary = this.textSummary
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].SummaryGenerated = this.summaryGenerated
        } else if (this.sectionType === 'topic') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].Summary = this.textSummary
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].SummaryGenerated = this.summaryGenerated
        }
        resolve(res);
      })
    })
  }

  generateTextSpeechUsingAI() {
    // Create a temporary DOM element to hold the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.notebookText;

    // Remove all img tags
    const images = tempDiv.getElementsByTagName('img');
    while (images.length > 0) {
        images[0].parentNode.removeChild(images[0]);
    }

    // Remove all table tags
    const tables = tempDiv.getElementsByTagName('table');
    while (tables.length > 0) {
        tables[0].parentNode.removeChild(tables[0]);
    }

    const data = {
      "text": tempDiv.textContent,
    }

    if (data.text === '') {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at least 1 character.'});
    } else {
      this.aiLoader = true;
      this.aiText = 'AI Text-to-Speech';
      this.notebookService.generateVoiceUsingOpenAI(data).subscribe(async (res: any) => {
        this.voiceNoteFile = res.voiceNoteFile;
        const newDate = new Date().toLocaleString('en-UK');
        this.voiceNoteGenerated = this.formatDate(newDate.substring(0, 10));
        this.voiceNoteGenerated = this.voiceNoteGenerated + 'T' + newDate.substring(12, 20);
        this.playAudio = false;
        await this.updateNotebookVoiceNotes();
        this.messageService.add({severity:'success', summary:'AI Text-to-Speech Generated', detail:'Your text-to-speech file is generated successfully.'});
        this.aiLoader = false;
      }, (error: any) => {
        this.aiLoader = false;
        if (error.status === 400) {
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at most 4096 characters.'});
        } else {
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
        }
      })
    }
  }

  updateNotebookVoiceNotes() {
    return new Promise((resolve: any) => {
      const data = {
        table: this.sectionType + 's',
        voiceNoteFile: this.voiceNoteFile,
        noteId: this.userNoteId
      }
      this.notebookService.updateNotebookVoiceNotes(data).subscribe(async (res: any) => {
        if (this.sectionType === 'qbank') {
          this.qbanks[this.indexes[0]].VoiceNoteFile = this.voiceNoteFile
          this.qbanks[this.indexes[0]].VoiceNoteGenerated = this.voiceNoteGenerated
        } else if (this.sectionType === 'subject') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].VoiceNoteFile = this.voiceNoteFile
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].VoiceNoteGenerated = this.voiceNoteGenerated
        } else if (this.sectionType === 'system') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].VoiceNoteFile = this.voiceNoteFile
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].VoiceNoteGenerated = this.voiceNoteGenerated
        } else if (this.sectionType === 'topic') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].VoiceNoteFile = this.voiceNoteFile
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].VoiceNoteGenerated = this.voiceNoteGenerated
        }

        if (this.voiceNoteFile) {
          const file = {
            fileKey: this.voiceNoteFile
          };
          this.audioLoader = true;
          this.voiceNoteFile = await this.getCloudFrontLink(file);
          this.executeAudioFile();
        }
        resolve(res);
      })
    })
  }

  generateReferenceLinksUsingAI() {
    let question = 'Generate articles reference links related to the following text: \n ';
    question += this.notebookText;
    const data = {
      "question": question,
      "system": `Returns the reference links in array json form. e.g. [{ 'link': 'https://link.com' }]. Please follow the instructions: \n 
      1. Generated reference links should be valid websites.
      2. Minimum generated reference links can be 3-5 but maximum generated reference links should not exceed count of 15.
      2. List should not contains duplicate reference links.
      3. Don't include any irrevelant text like (\`\`\`json).`
    }

    if (this.notebookText === '') {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at least 1 character.'});
    } else {
      this.referenceLinks = null;
      this.aiLoader = true;
      this.aiText = 'AI Reference Links';
      this.notebookService.generateUsingOpenAI(data).subscribe(async (res: any) => {
        try {
          this.referenceLinks = JSON.parse(res.response)
          if (this.referenceLinks.length > 0) {
            const newDate = new Date().toLocaleString('en-UK');
            this.linksGenerated = this.formatDate(newDate.substring(0, 10));
            this.linksGenerated = this.linksGenerated + 'T' + newDate.substring(12, 20);
            this.setLinksSettings();
            this.linksOpened = true;
            await this.updateNotebookReferenceLinks();
            this.messageService.add({severity:'success', summary:'AI Reference Links Generated', detail:'Your reference links are generated successfully.'});
          } else {
            this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
          }
          this.aiLoader = false;
        } catch (e) {
          this.aiLoader = false;
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
        }
      }, (error: any) => {
        this.aiLoader = false;
        this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
      })
    }
  }

  updateNotebookReferenceLinks() {
    return new Promise((resolve: any) => {
      const data = {
        table: this.sectionType + 's',
        referenceLinks: this.referenceLinks,
        noteId: this.userNoteId
      }
      this.notebookService.updateNotebookReferenceLinks(data).subscribe((res: any) => {
        if (this.sectionType === 'qbank') {
          this.qbanks[this.indexes[0]].ReferenceLinks = this.referenceLinks
          this.qbanks[this.indexes[0]].LinkGenerated = this.linksGenerated
        } else if (this.sectionType === 'subject') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].ReferenceLinks = this.referenceLinks
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].LinkGenerated = this.linksGenerated
        } else if (this.sectionType === 'system') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].ReferenceLinks = this.referenceLinks
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].LinkGenerated = this.linksGenerated
        } else if (this.sectionType === 'topic') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].ReferenceLinks = this.referenceLinks
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].LinkGenerated = this.linksGenerated
        }
        resolve(res);
      })
    })
  }

  generateFlashcardsUsingAI() {
    let question = 'Generate flashcards related to the following text: \n ';
    question += this.notebookText;
    const data = {
      "question": question,
      "system": `Returns the flashcards in array json form. e.g. [{ 'question': 'question', 'answer': 'answer', 'tags': ['tag 1', 'tag 2'] }]. Please follow the instructions: \n 
      1. Generated flashcards count should depend on the provided text length. Minimum flashcards can be 3-5.
      2. If length of provided text is less than 1000 words then generate maximum 10 flashcards.
      3. If length of provided text is greater than 1000 words then generate maximum 15 flashcards.
      4. Generated per flashcard tags count should not exceed more than 5 tags.
      5. Don't include any irrevelant text like (\`\`\`json).`
    }
    if (this.notebookText === '') {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at least 1 character.'});
    } else {
      this.aiLoader = true;
      this.aiText = 'AI Flashcards';
      this.notebookService.generateUsingOpenAI(data).subscribe(async (res: any) => {
        try {
          this.flashcards = JSON.parse(res.response)
          if (this.flashcards.length > 0) {
            await this.updateNotebookFlashcards();
            this.messageService.add({severity:'success', summary:'AI Flashcards Generated', detail:'Your flashcards are generated successfully.'});
          } else {
            this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
          }
          this.aiLoader = false;
        } catch (e) {
          this.aiLoader = false;
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
        }
      }, (error: any) => {
        this.aiLoader = false;
        this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
      })
    }
  }

  updateNotebookFlashcards() {
    return new Promise((resolve: any) => {
      const data = {
        table: this.sectionType + 's',
        title: this.heading,
        color: '#62B5FC',
        flashcards: this.flashcards,
        noteId: this.userNoteId,
        userId: localStorage.getItem('Id')
      }
      this.notebookService.updateNotebookFlashcards(data).subscribe((res: any) => {
        this.flashcards = res.data.deckId;
        if (this.sectionType === 'qbank') {
          this.qbanks[this.indexes[0]].AIFlashcards = res.data.deckId;
        } else if (this.sectionType === 'subject') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].AIFlashcards = res.data.deckId;
        } else if (this.sectionType === 'system') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].AIFlashcards = res.data.deckId;
        } else if (this.sectionType === 'topic') {
          this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].AIFlashcards = res.data.deckId;
        }
        resolve(res);
      })
    })
  }

  accessFlashcards() {
    sessionStorage.setItem('notebookFlashcards', this.flashcards);
    this.router.navigateByUrl('/dashboard/flashcard');
  }

  getCloudFrontLink(data: any) {
    return new Promise((resolve: any) => {
      this.notebookService.getAWSS3CloudFrontLink(data).subscribe((res: any) => {
        resolve(res.url);
      });
    });
  }
  
  executeAudioFile() {
    setTimeout(() => {
      this.voiceCurrentTime = '00:00';
      this.voiceTotalTime = '00:00';

      const audioPlayer = document.getElementById('audioPlayer');
      const seekBar = document.getElementById('seek-bar');

      //@ts-ignore
      seekBar?.removeAllListeners();

      //@ts-ignore
      seekBar.value = 0;

      this.audioElement.nativeElement.removeAllListeners();
      this.audioElement.nativeElement.load();
      this.audioElement.nativeElement.addEventListener('loadedmetadata', () => {
        this.audioLoader = false;
        this.voiceTotalTime = this.formatTime(this.audioElement.nativeElement.duration);
      });

      this.audioElement.nativeElement.addEventListener('waiting', () => {
        this.audioLoader = true;
      });

      this.audioElement.nativeElement.addEventListener('playing', () => {
        this.audioLoader = false;
      });

      this.audioElement.nativeElement.addEventListener('timeupdate', () => {
        //@ts-ignore
        const currentTime = audioPlayer.currentTime;
        //@ts-ignore
        const duration = audioPlayer.duration;

        if (currentTime) {
          this.voiceCurrentTime = this.formatTime(currentTime);
        }

        if (duration) {
          this.voiceTotalTime = this.formatTime(duration);
        }

        if ((currentTime / duration) * 100) {
          //@ts-ignore
          seekBar.value = (currentTime / duration) * 100;
        }

        //@ts-ignore
        if (currentTime >= duration) {
          this.playAudio = false;
          //@ts-ignore
          seekBar.value = 0;
          this.voiceCurrentTime = '00:00';
        }
      })

      seekBar.addEventListener('input', () => {
        //@ts-ignore
        audioPlayer.currentTime = (seekBar.value / 100) * audioPlayer.duration;

        //@ts-ignore
        if (audioPlayer.currentTime) {
          //@ts-ignore
          this.voiceCurrentTime = this.formatTime(audioPlayer.currentTime);
        }

        //@ts-ignore
        if (audioPlayer.duration) {
          //@ts-ignore
          this.voiceTotalTime = this.formatTime(audioPlayer.duration);
        }
      });
    }, 100)
  }

  deleteNotebookVoiceNotes() {
    const data = {
      table: this.sectionType + 's',
      noteId: this.userNoteId
    }
    this.loader = true;
    this.notebookService.deleteNotebookVoiceNotes(data).subscribe((res: any) => {
      this.voiceNoteFile = null;
      this.loader = false;
      this.displayVoiceDelete = false;
      if (this.sectionType === 'qbank') {
        this.qbanks[this.indexes[0]].VoiceNoteFile = null
      } else if (this.sectionType === 'subject') {
        this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].VoiceNoteFile = null
      } else if (this.sectionType === 'system') {
        this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].VoiceNoteFile = null
      } else if (this.sectionType === 'topic') {
        this.qbanks[this.indexes[0]].Subjects[this.indexes[1]].Systems[this.indexes[2]].Topics[this.indexes[3]].VoiceNoteFile = null
      }
    })
  }

  openTranslationPopup() {
    if (this.translationOptions.length === 3) {
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'You can translate a maximum of 3 languages only.'});
    } else {
      this.selectedLanguage = null;
      this.translatedText = null;
      if (this.selectedTranslation) {
        this.notebookText = this.restoreNotebookText;
      }
      this.translationQuill();
      this.displayTranslationPopup = true;
    }
  }

  openExistingTranslationPopup(value: any) {
    this.selectedLanguage = value;
    this.translatedText = this.noteTranslations.filter((item: any) => item.LanguageID === this.selectedLanguage.LanguageID).map((item: any) => item.TranslatedText)[0];
    this.translationQuill();
    this.displayTranslationPopup = true;
  }

  generateTranslationUsingAI() {
    if (this.selectedTranslation) {
      this.notebookText = this.restoreNotebookText
    }
    let question = `Translate the provided html text into ${this.selectedLanguage.Title} language. Text: \n`;
    const data = {
      "data": this.notebookText,
      "question": question,
      "system": `Please follow the instructions: \n 
      1. It should translate the html text excluding img urls and html tags.
      2. Translation should keep the html tags persistent.
      3. Don't include any irrevelant text like (\`\`\`html or \`\`\`).`
    }

    if (this.notebookText === '') {
      this.displayTranslationPopup = false;
      this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'String should have at least 1 character.'});
    } else {
      this.aiLoader = true;
      this.aiText = 'AI Text Translation';
      this.notebookService.generateUsingOpenAI(data).subscribe(async (res: any) => {
        try {
          this.translatedText = res.response;
          this.ckEditorTranslation.setData(this.translatedText)
          this.ckEditorTranslation.disableReadOnlyMode('ckEditorTranslation')
          this.aiLoader = false;
        } catch (e) {
          this.displayTranslationPopup = false;
          this.aiLoader = false;
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
        }
      }, (error: any) => {
        if (error.status === 502) {
          this.displayTranslationPopup = false;
          this.aiLoader = false;
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'The text in the notebook is too long to translate!'});
        } else {
          this.displayTranslationPopup = false;
          this.aiLoader = false;
          this.messageService.add({severity:'error', summary:'AI Generation Failed', detail:'Something went wrong while generating AI statistics!'});
        }
      })
    }
  }

  updateNotebookTranslations() {
    return new Promise((resolve: any) => {
      const data = {
        noteId: this.userNoteId,
        userId: localStorage.getItem('Id'),
        languageId: this.selectedLanguage.LanguageID,
        translatedText: this.ckEditorTranslation.getData(),
        type: this.sectionType
      }
      this.loader = true;
      this.notebookService.updateNotebookTranslations(data).subscribe(async (res: any) => {
        this.displayTranslationPopup = false;
        this.messageService.add({severity:'success', summary:'AI Text Translation Saved', detail:`Your notebook\'s translation in ${this.selectedLanguage.Title} language has been successfully saved.`});
        await this.getNotebookTranslations();
        if (this.restoreNotebookText) {
          this.notebookText = this.restoreNotebookText;
        }
        this.updateTranslations();
        this.loader = false;
        resolve(res);
      })
    })
  }

  openDeleteTranslationPopup(language: any) {
    this.selectedDeleteLanguage = language;
    this.displayTranslationDelete = true;
  }

  deleteNotebookTranslations() {
    const data = {
      userId: localStorage.getItem('Id'),
      noteId: this.userNoteId,
      languageId: this.selectedDeleteLanguage.LanguageID,
      type: this.sectionType
    }
    this.loader = true;
    this.notebookService.deleteNotebookTranslations(data).subscribe((res: any) => {
      this.loader = false;
      this.displayTranslationDelete = false;
      this.translations.splice(this.translations.findIndex((item: any) => item.NoteID === this.userNoteId && item.LanguageID === this.selectedDeleteLanguage.LanguageID), 1)
      if (this.restoreNotebookText) {
        this.notebookText = this.restoreNotebookText;
      }
      this.updateTranslations();
    })
  }

  updateTranslations() {
    this.selectedTranslation = null;
    this.noteTranslations = [];
    this.translationOptions = [];
    this.noteTranslations = this.translations.filter((item: any) => item.NoteID === this.userNoteId && item.NoteType === this.sectionType)
    this.notebookUpdated = false;
    if (this.noteTranslations.length > 0) {
      const languages = this.noteTranslations.map((item: any) => item.LanguageID);
      this.translationOptions = this.languages.filter((item: any) => languages.includes(item.LanguageID))
      if (this.selectedTranslation) {
        const index = this.noteTranslations.findIndex((item: any) => item.LanguageID === this.selectedTranslation.LanguageID);
        if (index !== -1) {
          const newDate = new Date(this.noteTranslations[index].GeneratedOn).toLocaleString('en-UK');
          let noteDate = this.formatDate(newDate.substring(0, 10));
          noteDate = noteDate + 'T' + newDate.substring(12, 20);
          const notebookDate = new Date(this.updatedDate).toLocaleString('en-UK');
          let updateDate = this.formatDate(notebookDate.substring(0, 10));
          updateDate = updateDate + 'T' + notebookDate.substring(12, 20);
          if (noteDate < updateDate) {
            this.notebookUpdated = true;
          }
        }
      }
    }
  }

  activeLanguageIndex(value: any, type: any) {
    if (type === 'translation') {
      if (!this.selectedTranslation) {
        this.restoreNotebookText = this.notebookText;
      }
      this.selectedTranslation = value;
      this.notebookText = this.noteTranslations.filter((item: any) => item.LanguageID === this.selectedTranslation.LanguageID).map((item: any) => item.TranslatedText)[0];
    } else {
      this.selectedTranslation = null;
      this.notebookText = this.restoreNotebookText;
    }

    this.notebookUpdated = false;
    if (this.noteTranslations.length > 0 && this.selectedTranslation) {
      const index = this.noteTranslations.findIndex((item: any) => item.LanguageID === this.selectedTranslation.LanguageID);
      if (index !== -1) {
        const newDate = new Date(this.noteTranslations[index].GeneratedOn).toLocaleString('en-UK');
        let noteDate = this.formatDate(newDate.substring(0, 10));
        noteDate = noteDate + 'T' + newDate.substring(12, 20);
        const notebookDate = new Date(this.updatedDate).toLocaleString('en-UK');
        let updateDate = this.formatDate(notebookDate.substring(0, 10));
        updateDate = updateDate + 'T' + notebookDate.substring(12, 20);
        if (noteDate < updateDate) {
          this.notebookUpdated = true;
        }
      }
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  setSummarySettings() {
    if (this.summaryGenerated < this.updatedDate) {
      this.isRegenerateSummary = true;
    } else {
      this.isRegenerateSummary = false;
    }

    setTimeout(() => {
      if (this.textContainer) {
        const lineHeight = parseFloat(getComputedStyle(this.textContainer.nativeElement).lineHeight);
        const maxHeight = (lineHeight * 3) + 1;
        const currentHeight = this.textContainer.nativeElement.scrollHeight;        
        this.showSeeMoreSummary = currentHeight > maxHeight;
      }
    }, 10)
  }

  setLinksSettings() {
    this.showLinks = false;
    if (this.referenceLinks) {
      try {
        this.referenceLinks = JSON.parse(this.referenceLinks)
      } catch (e) {}

      if (this.referenceLinks.length > 3) {
        this.showLinks = true;
        this.tempReferenceLinks = JSON.parse(JSON.stringify(this.referenceLinks))
        this.tempReferenceLinks.length = 3
      }
    }
    if (this.linksGenerated < this.updatedDate) {
      this.isRegenerateLinks = true;
    } else {
      this.isRegenerateLinks = false;
    }
  }

  formatDate(inputDate: string) {
    const [day, month, year] = inputDate.split('/');
    return `${year}-${month}-${day}`;
  }

  formatTime(seconds: any) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(minutes.toFixed(0)).padStart(2, '0')}:${String(remainingSeconds.toFixed(0)).padStart(2, '0')}`;
    return formattedTime;
  }

  playAudioFile() {
    this.audioElement.nativeElement.play();
    this.playAudio = true;
  }

  pauseAudioFile() {
    this.audioElement.nativeElement.pause();
    this.playAudio = false;
  }
}
