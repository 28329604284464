import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-usmle-criteria',
  templateUrl: './usmle-criteria.component.html',
  styleUrls: ['./usmle-criteria.component.scss']
})
export class UsmleCriteriaComponent implements OnInit {
  @Output() backToHome = new EventEmitter<any>();

  usmleCriteria = [
    {
      weightage: '15%-20%',
      subjects: ['Microbiology', 'Embryology', 'Pathology', 'Behavioral science', 'Obstetrics & Gynecology', 'Genetics']
    },
    {
      weightage: '15%-20%',
      subjects: ['Immunology', 'Anatomy', 'Pathophysiology', 'Internal Medicine', 'Surgery', 'Biostatistics']
    },
    {
      weightage: '60%-70%',
      subjects: ['Biochemistry', 'Physiology', 'Pharmacology', 'Pediatrics', 'Psychiatry', 'Histology']
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  backToMainPage() {
    this.backToHome.emit()
  }
}
