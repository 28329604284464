<div style="height: 100vh;" *ngIf="loader">
<app-overlay ></app-overlay>
</div>

<div class="eup-cs-root" *ngIf="!loader">
  <div class="eup-cs-parent">
    <div class="eup-cs-sub-parent">
      <div class="eup-cs-grid-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 p-0">
              <div class="eup-cs-header-image-wrapper">
                <div class="eup-cs-header-image">
<!--                  <img src="../../../assets/images/header.jpg" alt="">-->
                </div>
              </div>
            </div>
          </div>


          <div class="row p-5 d-flex justify-content-center" *ngIf="courses.length === 0">
            <div class="eup-cs-no-data-placeholder">
              <p-card>
                <h6>You have not registered in any of the courses</h6>
              </p-card>
            </div>
          </div>
          <div class="row p-2" *ngIf="courses.length> 0">
            <div class="eup-cs-courses-cards-wrapper">
              <div class="eup-cs-courses-card-single-wrapper col-12 col-sm-6 col-md-4" *ngFor="let course of courses; let i = index" >
                <div class="eup-cs-courses-card-content-wrapper">
                  <div class="eup-cs-courses-card-image-wrapper" (click)="getCourseOutline(course.courseId, course.courseTitle)">
                    <div class="eup-cs-courses-card-image" >
                      <img [src]="course.courseImage" alt="" width="100%!important">
                    </div>
                  </div>
                  <div class="eup-cs-courses-card-text-wrapper">
                    <div class="eup-cs-courses-card-text">
                      <div class="eup-cs-courses-card-course-title" (click)="getCourseOutline(course.courseId, course.courseTitle)">
                        <h6>{{course.courseTitle}}</h6>
                      </div>
                      <div class="eup-cs-courses-card-course-description">
                        <p>{{course.courseIntro}}</p>
                      </div>
                      <div (click)=ResumeCourse(course.courseId)>
                        <button class="btn btn-primary">Resume</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
