import { Component, HostListener, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';

import {QuizServiceService} from '../../../shared/services/quiz-service.service';
import {Observable, of, Subscription, timer} from 'rxjs';
import {AutoLogout} from '../../../models/globals';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { QuizGeneratorService } from 'src/app/shared/services/quiz-generator.service';
import { demo_variables } from 'src/app/models/demo_global';
import { Router } from '@angular/router';


@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.scss']
})
export class TestResultsComponent implements OnInit {
  percentageMultiplier = 100;
  averageCorrectOthers: any;
  
  subscriptions: Subscription[] = [];

  loader = true;
  
  prevTestData: any;
  pastPagesTempArray= [{name: "test"}];
  pastPagesArray!: any[];

    totalRecords!: number;

    cols!: any[];

    loading!: boolean;
    
    representatives!: any[];

    NumofRows!: any;

    selectedNum = 40;
    duplicateSelectedNum = 40;


    quizId: any;
    quizTitle: any;
    user_Id: any;
    quizMode: any;

    allDataforQuestions: any;
    percentageOthersArray: any;
    testDetails: any;

    yourScore: any;
    noDataAvailable = false;

    innerWidth: any;
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup-navbar-heading').innerText=''
  }
}

  constructor(private quizgeneratorService: QuizGeneratorService,private errorHandler: ErrorHandlerService,private adminService: AdminService,private quizService: QuizServiceService, private router: Router) { }

  async ngOnInit(){
    
    this.loader = true
    if(localStorage.getItem("showsubjectdetails")){
      this.noDataAvailable = JSON.parse(localStorage.getItem("showsubjectdetails"));
    }else{
    this.noDataAvailable = true;
  }
    this.quizId = localStorage?.getItem("QuizId")
    this.quizTitle = localStorage?.getItem("quizTitle")
    this.user_Id = localStorage?.getItem("Id")
    if(localStorage.getItem('Id')== '-1'){
      console.log('Hello')
      this.loader = false;
      this.getDemoValues()
    }else{
    this.getQuizResult();
    }

    this.NumofRows = [
      {numOfRows: 40},
      {numOfRows: 60},
      {numOfRows: 80},
      {numOfRows: 100}
  ];
    

  this.loading = false;

  // @ts-ignore
  // await this.subscriptions.push(
  //   this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
  //     if(res.status){
  //     }else{
  //       this.errorHandler.AutoLogout();
  //     }
  //   },
  //     error => {
  //       this.errorHandler.handleError(error);
  //     })
  // );
  // await this.getAllCategories();
  }



  // GETTING RESULTS FOR GENERATE QUIZ SCREEN
  AllCategories: any;
  totalQuestion: any
  subjects: any;
  systems: any;
  topics: any;
  courses: any;
  async getAllCategories(){
    this.totalResponseFromGetQuestions = [];
    // @ts-ignore
    this.subscriptions.push(
      this.quizgeneratorService.getCategories().subscribe(async (res: any) => {
        if(res.status){
          localStorage.setItem("getCategories", JSON.stringify(res))
          // this.AllCategories = res;
          // this.totalQuestion = res.totalQuestions;
          for(let i = 0; i< res.Categories.length; i++){
          //   if(res.Categories[i].parentCategory == "subjects"){
          //     this.subjects = res.Categories[i].subCategories;
          //   }
          //   else if(res.Categories[i].parentCategory == "systems"){
          //     this.systems = res.Categories[i].subCategories;
          //   }
          //   else if(res.Categories[i].parentCategory == "topics"){
          //     this.topics = res.Categories[i].subCategories;
          //   }
           if(res.Categories[i].parentCategory == "q-courses"){
              this.courses = res.Categories[i].subCategories;
            }
          }

          for(let i=0; i<this.courses.length; i++){
            this.selectedCourse = this.courses[i];
            await this.getQuestionNumbers();
          }
          
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  subjectQuestionNumberarray;
  selectedCourse;
  systemQuestionNumberarray;
  topicQuestionNumberarray;
  totalResponseFromGetQuestions = [];
  async getQuestionNumbers(){
    // @ts-ignore
    this.subscriptions.push(
      this.quizgeneratorService.getAllQuestionsNumbers(this.selectedCourse).subscribe((res: any) => {
        this.totalResponseFromGetQuestions.push(res)
        // this.subjectQuestionNumberarray = [];
        // this.systemQuestionNumberarray = [];
        // this.topicQuestionNumberarray = [];
        // this.subjects = [];
        // this.systems = [];
        // this.topics = [];
        // for(let i=0; i<res.subjects.length; i++){
        //   this.subjects.push(res.subjects[i].Title);
        //   this.subjectQuestionNumberarray.push({title: res.subjects[i].Title,questions: res.subjects[i].questions})
        // }
        // for(let i=0; i<res.systems.length; i++){
        //   this.systems.push(res.systems[i].Title);
        //   this.systemQuestionNumberarray.push({title: res.systems[i].Title,questions: res.systems[i].questions})
        // }
        // for(let i=0; i<res.topics.length; i++){
        //   this.topics.push(res.topics[i].Title);
        //   this.topicQuestionNumberarray.push({title: res.topics[i].Title,questions: res.topics[i].questions})
        // }
        // // this.selectedCategories = []
        localStorage.setItem("getQuestionNUmbers", JSON.stringify(this.totalResponseFromGetQuestions));
        
      }))
  }

  getDemoValues(){
    this.bestSubject = 'Pharmacology';
    this.worstSubject = 'Medicine';
    this.omittedSubject = 'Biochemistry';
    this.averageCorrectOthers = 53.6
    this.yourScore = 65
    this.allDataforQuestions = demo_variables.allDataForQuestions;
    this.testDetails = demo_variables.testDetails;
    this.percentageOthersArray = demo_variables.percentageOthersArray
  }

bestSubject: any;
worstSubject: any;
omittedSubject: any;
  getQuizResult(): any{
    var dataused = []
    var data = [1234]
    var solvedquestionsdata;
    var fullquizstats;
    var eachQuestiondata;
    this.subscriptions.push(
      this.quizService.getQuizResults(this.quizId, this.user_Id).subscribe((res: any) => {
          this.bestSubject = res.bestSubject;
          this.worstSubject = res.worstSubject;
          this.omittedSubject = res.omittedSubject
          this.testDetails = res.testDetails[0];

          let testResult = []
          let ids = JSON.parse(this.testDetails.QuestionIDS)
          res.testResults.forEach((element: any) => {
            if (ids.includes(element.QuestionID)) {
              testResult.push(element)
            }
          });
          res.testResults = testResult;

          this.allDataforQuestions = res.testResults;
          var testResults = res.testResults;
          var tempTestResults = []
          var sortedQuestions = JSON.parse(res.testDetails[0].QuestionIDS);

          for(let i=0; i<sortedQuestions.length; i++){
            for(let j=0; j<testResults.length; j++){
              if(sortedQuestions[i] == testResults[j].QuestionID){
                tempTestResults.push(testResults[j]);
              }
            }
          }

          this.allDataforQuestions = tempTestResults;




          var percentageArray = []
          var sum=0;

          var CorrectHits = 0;
          var CorrectNumOfAnswers = 0
          for(let i=0; i<testResults.length; i++){
            if (testResults[i].QuestionStatus==1){
              CorrectNumOfAnswers+=1;
            }
            sum=0;
            if(testResults[i].PercetangeOthers && testResults[i].PercetangeOthers != "1"){
              if(typeof(testResults[i].PercetangeOthers) == 'string'){
            testResults[i].PercetangeOthers = JSON.parse(testResults[i].PercetangeOthers);
          }
            var keys = Object.keys(testResults[i].PercetangeOthers);
            CorrectHits = 0;
            for(let j=0; j<keys.length; j++){
              
              if (keys[j] == testResults[i].CorrectOption){
                CorrectHits = testResults[i].PercetangeOthers[keys[j]];
              }
              sum += testResults[i].PercetangeOthers[keys[j]]
            }
            var percentage = ((CorrectHits/sum)*100).toFixed(1);
            percentageArray.push(percentage);
          }else{
            percentageArray.push("0.0")
          }
          }

          localStorage.setItem("CorrectNumOfAnswers", JSON.stringify(CorrectNumOfAnswers))
          this.percentageOthersArray = percentageArray;

          var sumPercentageOthers = 0;
          for(let i =0; i<this.percentageOthersArray.length; i++){
            sumPercentageOthers= sumPercentageOthers + parseFloat(this.percentageOthersArray[i]);
          }

          if(testResults.length>0){
          this.averageCorrectOthers = (sumPercentageOthers/this.percentageOthersArray.length).toFixed(1);
          this.yourScore = ((CorrectNumOfAnswers/ JSON.parse(res.testDetails[0].QuestionIDS).length)*100).toFixed(1)
        }else{
          this.averageCorrectOthers = '0.0';
          this.yourScore = '0.0'
        }
        console.log("********* ",this.testDetails)
        // this.yourScore = this.testDetails.QuizScore; 
        if(this.yourScore > 100){
          this.yourScore = '100.0';
        }
          
         this.loader = false;
         
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }
  
  selectNum(a: any): void{
    // this.selectedNum = this.duplicateSelectedNum;
    this.selectedNum = a.numOfRows;
  }

  getPrevTestData(): void{
    this.loading = false;
  }

  // LAZY DEFAULT FUNCTIONS
  loadCustomers(event: LazyLoadEvent) {  
        this.loading = false;
}

  reviewQuiz(index: any): any {
    let viewMode = true;
    if(localStorage.getItem('Id') != '-1') {
      this.loader = true;
      this.subscriptions.push(
      this.quizService.resumePrevQuizzes(this.quizId, viewMode).subscribe((res: any) => {
        var quizScore;
        var quizTitle;
        var quizMode;
        var quiz_time;
        var quizId;
        var isTimed = false;

        var allQuestions = [];
        var submittedQuestions = [];
        var flaggedQuestions = [];
        var notes = [{note: 'dummy data' , questionId: -1}];

        quizScore = res.data?.previousQuizzes[0]?.quiz_meta?.quizScore;
        quizTitle = res.data?.previousQuizzes[0]?.quiz_meta?.quizTitle;
        quizMode = res.data?.previousQuizzes[0]?.quiz_meta?.quizMode;
        quiz_time = res.data?.previousQuizzes[0]?.quiz_meta?.quizTime;
        quizId = res.data?.previousQuizzes[0]?.quiz_meta?.quizId;
        isTimed = res.data?.previousQuizzes[0]?.quiz_meta?.isTimed;

        for (let i =0; i<res.data?.questions?.length; i++) {
          // Getting Flagged Questions
          if(res.data?.questions[i].marked != -1){
            flaggedQuestions.push({QuestionId: res.data?.questions[i]?.marked, index: i})
          }

          // Getting already submitted Questions
          if(res.data?.questions[i]?.submitData && res.data?.questions[i]?.submitData != null){
            submittedQuestions.push(JSON.parse(res.data?.questions[i]?.submitData));
          }

          // Getting Notes
          if(res.data?.questions[i]?.notes && res.data?.questions[i]?.notes != null){
            notes.push({note: res.data?.questions[i]?.notes , questionId: res.data?.questions[i]?.post_id});
          }

          // getting all Questions that have options

          allQuestions.push({answer_data: res.data?.questions[i]?.answer_data, answer_type: res.data?.questions[i]?.answer_type, correct_msg: res.data?.questions[i]?.correct_msg, id: res.data?.questions[i]?.id, incorrect_msg: res.data?.questions[i]?.incorrect_msg, ld_Correct_Percentage: res.data?.questions[i]?.ld_Correct_Percentage, ld_hits_details: res.data?.questions[i]?.ld_hits_details, options: res.data?.questions[i]?.options, postId: res.data?.questions[i]?.post_id, quiz_id: res.data?.questions[i]?.quiz_id , statistics: res.data?.questions[i]?.statistics , subjects: res.data?.questions[i]?.subjects , systems: res.data?.questions[i]?.systems , topics: res.data?.questions[i]?.topics , question: res.data?.questions[i]?.question, linked: res.data?.questions[i]?.linked , priority: res.data?.questions[i]?.priority, UWorldID: res.data?.questions[i]?.UWorldID, questionTime: res.data?.questions[i].submitData ? JSON.parse(res.data?.questions[i].submitData).time : 0})
        }

        localStorage.setItem("AllQuestions",JSON.stringify(allQuestions));
        localStorage.setItem("quizScore", res.data.previousQuizzes[0].quiz_meta.quizScore);
        localStorage.setItem("SubmittedQuestions", JSON.stringify(submittedQuestions));
        localStorage.setItem("NotesArray", JSON.stringify(notes));
        localStorage.setItem("quizTitle", quizTitle);
        localStorage.setItem("QuizMode",quizMode);
        localStorage.setItem("quiz_time",quiz_time);
        localStorage.setItem("QuizId",quizId);
        localStorage.setItem("isTimed",JSON.stringify(isTimed));
        localStorage.setItem("isResumed", JSON.stringify(1));
        localStorage.setItem("viewMode", JSON.stringify(viewMode));
        localStorage.setItem("currentQuestionIndex", JSON.stringify(index));
        localStorage.setItem("CurrentIndex", JSON.stringify(index));
        localStorage.setItem("flaggedQuestion", JSON.stringify(flaggedQuestions));

        const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
        arr.forEach(i => {
          localStorage.removeItem(i);
        });
        this.router.navigate(['/quiz']);
        this.loader = false;
      },
      (err: any) => {
        AutoLogout(err);
      })
    );
  } else {
    localStorage.setItem("AllQuestions",JSON.stringify(demo_variables.allQuestions));
    localStorage.setItem("quizScore", '0');
    localStorage.setItem('SubmittedQuestions', JSON.stringify(demo_variables.SubmittedQuestions))
    localStorage.setItem("NotesArray", JSON.stringify([]));
    localStorage.setItem("quizTitle", 'Demo Quiz');
    localStorage.setItem("QuizMode",'tutor');
    localStorage.setItem("QuizId", '-1');
    localStorage.setItem("isTimed",JSON.stringify(false));
    localStorage.setItem("isResumed", JSON.stringify(1));
    localStorage.setItem("viewMode", JSON.stringify(viewMode));
    localStorage.setItem("flaggedQuestion", JSON.stringify([]));
    localStorage.setItem("currentQuestionIndex", JSON.stringify(index));
    localStorage.setItem("CurrentIndex", JSON.stringify(index));

    const arr = ['qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
    arr.forEach(i => {
      localStorage.removeItem(i);
    });
    this.router.navigate(['/quiz']);
  }
}

}
