<div class="eup_flashcards">
  <div class="courses">
    <h2>Ready Decks</h2>
    <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID" [style]="{ width: '100%' }"></p-dropdown>
  </div>
  <hr>
  <button (click)="module = 'browse'" [ngStyle]="{'color' : module == 'browse' ? '#2196F3' : 'black'}">Browse</button>
  <button (click)="module = 'study'" [ngStyle]="{'color' : module == 'study' ? '#2196F3' : 'black'}">Study</button>

  <app-browse *ngIf="module == 'browse'" [courseId]="selectedCourse"></app-browse>
  <app-study *ngIf="module == 'study'" [courseId]="selectedCourse"></app-study>
</div>
