import { Component, OnDestroy, OnInit } from '@angular/core';
import {ChartOptions, ChartType} from "chart.js";
import {Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet} from "ng2-charts";
import { CourseService } from 'src/app/shared/services/course.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-analysis-chart',
  templateUrl: './analysis-chart.component.html',
  styleUrls: ['./analysis-chart.component.scss']
})
export class AnalysisChartComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription = [];
  
  pointersColorClasses = ['eup-green-pointer','eup-red-pointer','eup-blue-pointer','eup-purple-pointer'];
  chartType: ChartType = 'pie';
 
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  chartColors: Array<any> = [
    {
      backgroundColor: [
        '#61ce87',
        '#e14f6a',
        '#389cf2',
        '#7583f0'
      ]
    }
  ];
  pieChartLabels1: Label[] = [['Total Questions'], ['Used  Questions'], ['Unused Questions']];
  pieChartLabels: Label[] = [['Correct to Correct'], ['Correct to Incorrect'], ['Incorrect to Correct'], ['Incorrect to Incorrect']];
  pieChartData1: SingleDataSet = [];
  pieChartData: SingleDataSet = [20, 16, 36, 40];
  pieChartType: ChartType = 'pie';
  pieChartLegend = false;
  pieChartPlugins = [];
  cardLabels = [
    {label: 'Correct to Correct', value: 12, bgClass: 'eup-green-card'},
    {label: 'Correct to Incorrect', value: 2, bgClass: 'eup-red-card'},
    {label: 'Incorrect to Correct', value: 14, bgClass: 'eup-blue-card'},
    {label: 'Incorrect to Incorrect', value: 20, bgClass: 'eup-purple-card'},
  ];

  cardLabels1 = [
    {label: 'Total Questions', value: 12, bgClass: 'eup-blue-card'},
    {label: 'Incorrect Questions', value: 2, bgClass: 'eup-red-card'},
    {label: 'Correct Questions', value: 14, bgClass: 'eup-green-card'},
    {label: 'Omitted Questions', value: 2, bgClass: 'eup-green-card'}
  ];

  totalQues: any;
  correctQues: any;
  incorrectQues: any;
  omittedQues: any;

  contentLoader = false;
  quizId: any;
  loader = false;

  constructor(private adminService: AdminService,private courseService: CourseService, private errorHandler: ErrorHandlerService) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  async ngOnInit(){
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

    if(localStorage.getItem('Id')== '-1'){
      this.getDemoValues()
    }
    else{
      this.getNumbers();
    }
    
  }
  getDemoValues(){
    this.totalQues = 5;
        this.incorrectQues = 3;
        this.correctQues = 2

        this.pieChartData1 = []
        this.pieChartData1.push(5);
        this.pieChartData1.push(3);
        this.pieChartData1.push(2);
        this.cardLabels1[0].value = 5;
        this.cardLabels1[1].value = 3;
        this.cardLabels1[2].value = 2;
        this.cardLabels1[3].value = 4;
        
        this.pieChartData = [];
        this.pieChartData.push(1);
        this.pieChartData.push(1);
        this.pieChartData.push(2);
        this.pieChartData.push(1);
        
        this.cardLabels[0].value = 1;
        this.cardLabels[1].value = 1;
        this.cardLabels[2].value = 2;
        this.cardLabels[3].value = 1;
  }

  getNumbers(): void{
    this.loader = true
    this.contentLoader = true;
    this.quizId = localStorage?.getItem("QuizId")
    // @ts-ignore
    this.subscriptions.push(
      this.courseService.getNumbers(this.quizId).subscribe((res: any) => {
        this.totalQues = res.totalQuestions;
        this.incorrectQues = res.totalIncorrectQuestions;
        this.correctQues = res.totalCorrectQuestions
        this.omittedQues = res.totalOmittedQuestions

        this.pieChartData1 = []
        this.pieChartData1.push(this.totalQues);
        this.pieChartData1.push(this.incorrectQues);
        this.pieChartData1.push(this.correctQues);
        this.cardLabels1[0].value = this.totalQues;
        this.cardLabels1[1].value = this.incorrectQues;
        this.cardLabels1[2].value = this.correctQues;
        this.cardLabels1[3].value = this.omittedQues;
        
        this.pieChartData = [];
        this.pieChartData.push(res.totalCorrect_Correct);
        this.pieChartData.push(res.totalCorrect_Incorrect);
        this.pieChartData.push(res.totalIncorrect_Correct);
        this.pieChartData.push(res.totalIncorrect_Incorrect);
        
        this.cardLabels[0].value = res.totalCorrect_Correct;
        this.cardLabels[1].value = res.totalCorrect_Incorrect;
        this.cardLabels[2].value = res.totalIncorrect_Correct;
        this.cardLabels[3].value = res.totalIncorrect_Incorrect;

        this.loader = false;
        
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }
}
