<div class="ymp-graphs-root">
    <div class="ymp-graphs-parent">
      <div class="ymp-graphs-sub-parent">
        <p-progressBar mode="indeterminate" [style]="{'height': '6px','width':'40%','top':'50%','left':'30%','z-index':'20004'}"  *ngIf="loader "></p-progressBar>
        <div class="ymp-graphs-grid-wrapper ">
          <div class="p-grid p-d-flex p-justify-center p-ai-center" *ngIf="noQuizExists">
            <div class="p-col-6">
              <p-card>
                <h2>No Reports Avaiable</h2>
                <div class="p-mt-2">
                  <p>You have no previous quiz reports.</p>
                  <p>Let's start by creating a new one</p>
                  <p  class="p-mt-3">
                    <a routerLink="/home/createQuiz">Create Test</a>
                  </p>
                </div>
              </p-card>
            </div>
          </div>
  
  
          <div class="grid" *ngIf="!noQuizExists">
            <div class="p-col-12">
              <p-card>
                <p-header >
                  <h3 class="p-p-3 p-pb-0">Performance by Test</h3>
                  <div class="ymp-grey-border">
                  </div>
                </p-header>
                <p-chart type="line" [data]="data" width="75vw" height="50vh" [options]="options"></p-chart>
              </p-card>
  
            </div>
            <div class="p-col-12">
              <p-card>
                <p-header >
                  <h3 class="p-p-3 p-pb-0">Performance by Date</h3>
                  <div class="ymp-grey-border">
                  </div>
                </p-header>
                <p-chart type="line" [data]="performanceByDateDate" width="75vw" height="50vh" [options]="options"></p-chart>
              </p-card>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
    