<div class="overallPerformance_root">
  <div class="overallPerformance_parent">

    <div class="mb-4" *ngIf="subscribedCourses && subscribedCourses.length>1">
      <div class="text-center mb-4">
          <h5>See the data of individual course by clicking here.</h5>
      </div>
      <div class="course_animated_buttons ">
          <div class="col-md-3 col-sm-3 col-xs-6" (click)="handleSingleCourse(subscribedCourses[0].courseID)"> <a class="btn btn-sm animated-button thar-three">{{subscribedCourses[0].courseName}}</a> </div>
          <div class="col-md-3 col-sm-3 col-xs-6" (click)="handleSingleCourse(subscribedCourses[1].courseID)"> <a class="btn btn-sm animated-button thar-four">{{subscribedCourses[1].courseName}}</a> </div>
      </div>
      
    </div>

    <!-- Percentile Rank -->
    <div class="row" *ngIf="!peerLoader">
      <div class="tests_details" style="min-width: 95%;">
        <div class="comparison" style="min-width: 90%; margin-left: auto; margin-right: auto;">
          <div class="peer1">
            <div class="eup-ov-cards-heading ">
              <h5>Percentile Rank</h5>
            </div>
            <hr/>
            <div class="card">
              <app-bell-chart [userRank]="userRank" [peerRank]="peerRank" [xUserValue]="xUserValue" [xPeerValue]="xPeerValue" [yUserValue]="yUserValue" [avgQuizScore]="avgQuizScore" [avgPeerQuizScore]="avgPeerQuizScore"></app-bell-chart>
              <div class="tooptip">
                <i
                  class="fa fa-info-circle"
                  [pTooltip]="'You got a score that is higher than ' + userRank +  '% of course subscribers and ' + (100 - userRank) + '% of course subscribers got a score that is higher than your score.'" tooltipPosition="right"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loader" style="margin-top: 4rem; margin-bottom: 5rem;" *ngIf="loader">
      <app-page-loader></app-page-loader>
    </div>

    <!-- BEST WORST SUBJECTS -->
    <div class="tests_details" *ngIf="!categoryLoader">
      <div class="left_wrapper">
        <div class="ml-0 pl-3 mb-4 text-weight-bold heading_answer_changes eup-ov-cards-heading">
          <h5> {{selectedCategory}}s </h5>
        </div>
        <div class="test_stats_left">
            <div class="single_card">
                <div class="top_number eup-green-card">{{bestSubject}}</div>
                <div class="bottom_des">Best {{selectedCategory}}</div>
            </div>
            <div class="single_card">
                <div class="top_number eup-red-card ">{{worstSubject}}</div>
                <div class="bottom_des">Worst {{selectedCategory}}</div>
            </div>
        </div>

      </div>
    </div>
  
   <!--   Peer Ranking By Categories   -->

   <div class="tests_details" *ngIf="!categoryLoader">
    <div class="left_wrapper">
      <div class="ml-0 pl-3 mb-4 text-weight-bold heading_answer_changes eup-ov-cards-heading">
        <h5> Peer Ranking By Categories </h5>
      </div>
      <div class="barchart_search">
        <div class="rank_labels">
          <div class="single_label">
            <div class="circle" style="background-color: #D2D5DD;"></div>
            <span>Your Ranking</span>
          </div>
          <div class="single_label">
            <div class="circle" style="background-color: #61ce87;"></div>
            <span>Peer Ranking</span>
          </div>
        </div>
        <input type="text" placeholder="Search {{selectedCategory}}..." (keyup)="searchCategories($event)">
      </div>
      <div class="barchart_options" *ngIf="allCategories.length > 0">
        <span [ngClass]="{'active': selectedCategory == 'Subject'}" (click)="changeCategory('Subject')">Subjects</span>
        <span [ngClass]="{'active': selectedCategory == 'System'}" (click)="changeCategory('System')">Systems</span>
        <span [ngClass]="{'active': selectedCategory == 'Topic'}" (click)="changeCategory('Topic')">Topics</span>
      </div>
      <p-chart type="bar" #myChart [data]="barData" [options]="barOptions" height="17rem"></p-chart>
      <div class="barchart_pagination" *ngIf="allCategories.length > 0">
        <p-paginator [rows]="10" [totalRecords]="tempCategories.length" (onPageChange)="paginateCategories($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
