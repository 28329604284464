import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/shared/services/global.service';
import { demo_variables } from 'src/app/models/demo_global';

@Component({
  selector: 'app-performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss']
})
export class PerformanceChartComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription[] = [];
  data: any;
  performanceByDateDate: any;
  options;
  loader = false;
  noQuizExists = false;
  innerWidth: any;

  constructor(private globalService: GlobalService,private adminService: AdminService ,private quizService: QuizServiceService, private errorHandler: ErrorHandlerService ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  async ngOnInit(){
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Performance Charts';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }

    this.options = {
      legend: {display: false}
    }
//     this.data = {
//       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//       datasets: [
//           {
//               label: 'Second Dataset',
//               data: [28, 48, 40, 19, 86, 27, 90],
//               fill: false,
//               // borderDash: [5, 5],
//               borderColor: '#66BB6A'
//           },
//           {
//               label: 'Third Dataset',
//               data: [12, 51, 62, 33, 21, 62, 45],
//               fill: true,
//               borderColor: '#FFA726',
//               backgroundColor: 'rgba(255,167,38,0.2)'
//           }
//       ]
//   };

//   this.performanceByDateDate = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     datasets: [
//         {
//             label: 'Second Dataset',
//             data: [28, 48, 40, 19, 86, 27, 90],
//             fill: false,
//             borderDash: [5, 5],
//             borderColor: '#66BB6A'
//         },
//         {
//             label: 'Third Dataset',
//             data: [12, 51, 62, 33, 21, 62, 45],
//             fill: true,
//             borderColor: '#FFA726',
//             backgroundColor: 'rgba(255,167,38,0.2)'
//         }
//     ]
// };
if(localStorage.getItem('Id')!='-1'){
this.getPerformanceGraphs();
}else{
  this.getDemoValues()
}


await this.compareToken();


    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
  }

  getDemoValues(){
    var scores = demo_variables.scores;
    var quizIds = demo_variables.quizIds;
    var quizDates = demo_variables.quizDates;
    this.data = {
      labels: quizIds,
      datasets: [
          {
              label: 'Score',
              data: scores,
              fill: false,
              // borderDash: [5, 5],
              borderColor: '#E0ECFE'
          }
      ]
  };

  this.performanceByDateDate = {
    labels: quizDates,
    datasets: [
        {
            label: "Score",
            data: scores,
            fill: false,
            // borderDash: [5, 5],
            borderColor: '#E0ECFE'
        }
    ]
};
  }
  

  compareToken(){
    // @ts-ignore
this.subscriptions.push(
  this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
    if(res.status){
      
    }else{
      this.errorHandler.AutoLogout();
    }
  },
    error => {
      this.errorHandler.handleError(error);
    })
);
  }

  
getPerformanceGraphs(): any{
  this.subscriptions.push(
    this.quizService.getPerformanceByDateandTest().subscribe((res: any) => {
      
      let scores  = [];
        let quizIds = [];
        let quizDates = [];
        res.data.quizzes.map(quiz => quiz.score = ((quiz.score/quiz.questions)*100).toFixed(1))
        res.data.quizzes.map(item => scores.push(item.score));
        res.data.quizzes.map(item => quizIds.push(item.id));
        res.data.quizzes.map(item => quizDates.push(item.date));
        this.data = {
          labels: quizIds,
          datasets: [
              {
                  label: 'Score',
                  data: scores,
                  fill: false,
                  // borderDash: [5, 5],
                  borderColor: '#E0ECFE'
              }
          ]
      };
    
      this.performanceByDateDate = {
        labels: quizDates,
        datasets: [
            {
                label: "Score",
                data: scores,
                fill: false,
                // borderDash: [5, 5],
                borderColor: '#E0ECFE'
            }
        ]
    };
    },
      error => {
        
        this.errorHandler.handleError(error);
      })
  );
}


}


