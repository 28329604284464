

<form name="sci-calc" #form="ngForm">
    <div class="p-pb-2">
      <input id="display" [(ngModel)]="display" name="display" value="0" size="28" maxlength="25">
    </div>
    <div class="p-d-flex  p-flex-md-row  calculator-row">
      <div class="p-mb-2 p-mr-2 btn btnTop p-ai-center p-jc-center p-d-flex" (click)="display=  0 ">C</div>
      <div class="p-mb-2 p-mr-2 btn btnTop p-ai-center p-jc-center p-d-flex" (click)="deleteChar()"><&#45;&#45;</div>
      <div class="p-mb-2 p-mr-2 btn btnTop p-ai-center p-jc-center p-d-flex" (click)="checkNum()? compute():null ">=</div>
      <div class="p-mb-2 p-mr-2 btn btnMath  p-ai-center p-jc-center p-d-flex" (click)="addChar('3.14159265359')">&#960;</div>
      <div class="p-mb-2 p-mr-2 btn btnOpps p-ai-center p-jc-center p-d-flex" (click)=" percent()">%</div>
  
    </div>
    <div class="p-d-flex  p-flex-md-row ">
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('7')">7</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('8')">8</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('9')">9</div>
      <div class="p-mb-2 p-mr-2 btn btnMath p-ai-center p-jc-center p-d-flex"  (click)="checkNum()?exp():null">x&#94;</div>
      <div class="p-mb-2 p-mr-2 btn btnOpps p-ai-center p-jc-center p-d-flex"  (click)="addChar('/')">/</div>
  
    </div>
    <div class="p-d-flex  p-flex-md-row ">
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('4')">4</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('5')">5</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('6')">6</div>
      <div class="p-mb-2 p-mr-2 btn btnMath p-ai-center p-jc-center p-d-flex"  (click)="checkNum()?ln():null">ln</div>
      <div class="p-mb-2 p-mr-2 btn btnOpps p-ai-center p-jc-center p-d-flex"  (click)="addChar('*')">*</div>
    </div>
    <div class="p-d-flex  p-flex-md-row ">
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('1')">1</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('2')">2</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('3')">3</div>
      <div class="p-mb-2 p-mr-2 btn btnMath p-ai-center p-jc-center p-d-flex"  (click)="checkNum()?sqrt():null">&radic;</div>
      <div class="p-mb-2 p-mr-2 btn btnOpps p-ai-center p-jc-center p-d-flex"  (click)="addChar('-')">-</div>
    </div>
    <div class="p-d-flex  p-flex-md-row ">
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="changeSign()">&#177;</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('0')">0</div>
      <div class="p-mb-2 p-mr-2 btn btnNum p-ai-center p-jc-center p-d-flex"  (click)="addChar('&#46;')">&#46;</div>
      <div class="p-mb-2 p-mr-2 btn btnMath p-ai-center p-jc-center p-d-flex"  (click)="checkNum()?square():null">x&#50;</div>
      <div class="p-mb-2 p-mr-2 btn btnOpps p-ai-center p-jc-center p-d-flex"  (click)="addChar('+')">+</div>
    </div>
  </form>
  