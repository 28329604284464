
  <!-- MODAL BOX -->
  <!-- <div class="col-sm-12" style="height: 70vh;" *ngIf="loader"> -->
    <app-overlay-loader *ngIf="loader"></app-overlay-loader>
  <!-- <p-dialog header="Data Notification" [(visible)]="!noDataAvailable" [modal]="false" [closable]="false" [style]="{width: '50vw'}" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <p class="p-m-0">No Data Present for this Quiz. </p>
      <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" routerLink="/dashboard/previoustest" label="Go Back" class="p-button-text"></p-button>
          <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button>
      </ng-template>
</p-dialog> -->

<!-- </div> -->


<!-- BEST WORST SUBJECTS -->
<div class="tests_details">
    <div class="left_wrapper">
      <div class="ml-0 pl-3 mb-4 text-weight-bold heading_answer_changes eup-ov-cards-heading">
        <h5> Subjects </h5> 
      </div>
      <div class="test_stats_left">
          <div class="single_card">
              <div class="top_number eup-green-card">{{bestSubject}}</div>
              <div class="bottom_des">Best Subject</div>
          </div>
          <div class="single_card">
              <div class="top_number eup-red-card ">{{worstSubject}}</div>
              <div class="bottom_des">Worst Subject</div>
          </div>
          <div class="single_card">
            <div class="top_number eup-blue-card ">{{omittedSubject}}</div>
            <div class="bottom_des">Most Omitted Subject</div>
        </div>
      </div>
      
    </div>
  </div>

<div class="useractivityParent" *ngIf="!loader">
    
    
    <div class="useractivity_left_parent">
    <div class="useractivity_table">

<p-table id="userActivityTable" #dt [value]="allDataforQuestions"  [scrollable]="true"   (onLazyLoad)="loadCustomers($event)"
    [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [loading]="loading"  [rows]="selectedNum" [totalRecords]="allDataforQuestions.length" [loading]="loading" [globalFilterFields]="['name','status','subjects','mode']" dataKey="name">

    <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
            <h5 class="p-m-0"><div class="Dropdown_columnNumber">
                <span class="dropdown_text">Show </span> 
                <p-dropdown [options]="NumofRows" [(ngModel)]="NumofRows.numOfRows" optionLabel="numOfRows" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown> 
                <span class="dropdown_text"> entries</span>
                </div></h5>
            <span class="p-input-icon-left mr-3">
                <div class="right_score_wrapper">
                    <div class="Scorebar"> 
                        <div class="progre mt-1 mr-2" style="width: 100px">
                            <p-progressBar [value]="yourScore" [style]="{'height': '22px'}"></p-progressBar>
                          </div>
                    </div>
                    <div class="your_score">Your Score <b>{{yourScore}}%</b> </div>
                    <div class="avg_score">Average Score {{averageCorrectOthers}}%</div>
                    <div class="right_mode">
                        <div class="mode_text">
                            Mode
                        </div>
                        <div class="mode_type_text">
                            {{testDetails.QuizMode}}
                        </div>
                    </div>
                </div>
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header" styleClass="myTable" class="some-custom-class-name">
        <tr>
            <th pSortableColumn="id" [ngStyle]="{'width':'55px'}"></th>
            <th pSortableColumn="id" [ngStyle]="{'width':'120px'}">ID </th>
            <th pSortableColumn="subjects" [ngStyle]="{'width':'130px'}">SUBJECTS <p-sortIcon field="subjects"></p-sortIcon></th>
            <th pSortableColumn="systems" [ngStyle]="{'width':'130px'}">SYSTEMS <p-sortIcon field="systems"></p-sortIcon></th>
            <th pSortableColumn="topics" [ngStyle]="{'width':'210px'}">TOPICS <p-sortIcon field="topics"></p-sortIcon></th>
            <th pSortableColumn="score" [ngStyle]="{'width':'180px'}">% CORRECT OTHERS <p-sortIcon field="score"></p-sortIcon></th>
            <th pSortableColumn="time" [ngStyle]="{'width':'140px'}">TIME SPENT <p-sortIcon field="time"></p-sortIcon></th>
            <th pSortableColumn="time" [ngStyle]="{'width':'40px'}"></th>
            
        </tr>
        
    </ng-template>
    <ng-template pTemplate="body" let-test let-i="rowIndex">
        <tr>
            
            <td [ngStyle]="{'width':'55px'}"> 
                <i class="fa fa-minus-circle" style="color: royalblue;" *ngIf="test.QuestionStatus == -1"></i> 
                <i class="fa fa-check greencolor" *ngIf="test.QuestionStatus == 1"></i>
                <i class="fa fa-times redcolor" *ngIf="test.QuestionStatus == 0"></i>
            </td>
            <td [ngStyle]="{'width':'120px'}">{{ i + 1 }} - {{test.UWorldID ? test.UWorldID : test.QuestionID}}</td>
            <td class="subjects" [ngStyle]="{'width':'130px'}" pTooltip="{{test.QSubject}}">{{test.QSubject}}</td>
            <td class="systems" [ngStyle]="{'width':'130px'}" pTooltip="{{test.QSystem}}">{{test.QSystem}}</td>
            <td class="topics" [ngStyle]="{'width':'210px'}" pTooltip="{{test.Topic}}">{{test.Topic}}</td>
            <td class="score" [ngStyle]="{'width':'180px'}"> {{ percentageOthersArray[i] }}%</td>
            <td class="time" [ngStyle]="{'width':'140px'}"><i class="fa" ></i> {{test.QuestionTime}} sec</td>
            <td class="time" [ngStyle]="{'width':'40px'}" pTooltip="Review Test"><i class="fa fa-chevron-right" style="font-size: 16px; cursor: pointer; color: #5b5b5b;" (click)="reviewQuiz(i)"></i></td>
        </tr>
    </ng-template>
</p-table>
</div>
</div>

</div>