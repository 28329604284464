


<!-- <app-overlayfullscreen *ngIf="loader"></app-overlayfullscreen> -->
<div>
    <p-dialog header="Data Notification" [(visible)]="!showTestDetails" [modal]="false" [closable]="false" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p class="p-m-0">No Data Present for this Quiz. </p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" routerLink="/dashboard/previoustest" label="Go Back" class="p-button-text"></p-button>
            <!-- <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button> -->
        </ng-template>
  </p-dialog>
  </div>

  <div class="progressbar_dynamic" *ngIf="loader">
    <!-- <p-progressBar [style]="{'height': '23px', 'width':'50%','top':'50%','left':'30%','z-index':'20004'}" [value]="value"></p-progressBar> -->
    <app-overlay-loader *ngIf="loader"></app-overlay-loader>
  </div>


<div class="mb-5" *ngIf="!loader">
<p-treeTable [value]="files1" [columns]="cols" [scrollable]="true">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field" style="width: 165px;">
                {{col.header}}
                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index" style="width: 165px;">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <span *ngIf="i == 0" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
                <span *ngIf="i != 0">{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>


<br>
<p-treeTable [value]="files3" [columns]="cols1" [scrollable]="true">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ttSortableColumn]="col.field" style="width: 165px;">
                {{col.header}}
                <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index" style="width: 165px;">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <span *ngIf="i == 0" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</span>
                <span *ngIf="i != 0">{{rowData[col.field]}}</span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>
</div>