import {
  Component,
  OnInit,
  VERSION,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  AfterViewInit
} from '@angular/core';
import { CourseService } from '../../shared/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { QuizServiceService } from '../../shared/services/quiz-service.service';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { Location } from '@angular/common';
// import {VdoPlayer} from 'vdoPlayer'
import {Window} from "../../models/lesson"
import { DomSanitizer } from '@angular/platform-browser';


declare global {
  interface Window {
    VdoPlayer: any;
  }
}

@Component({
  selector: 'app-focusboard',
  templateUrl: './focusboard.component.html',
  styleUrls: ['./focusboard.component.scss'],
})
export class FocusboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('testDiv', { static: false })
  private testDiv: ElementRef<HTMLDivElement>;
  // @ts-ignore
  subscriptions: Subscription = [];
  isTestDivScrolledIntoView: boolean;
  private _vps!: ViewportScroller;

  Window: Window;

  demo = false;
  loader = false;
  topicloader = true;
  quizloader = false;

  displayObject = false;

  selected = true;
  btnTextNext = '';
  btnTextPrev = '';

  isopen = true;
  topicIndexincrement: any;
  topicindex: any;
  topicId: any;
  lessonQuizzes: any;

  topic: any;

  lessondescription: any;

  courseId: any;
  lessonId: any;
  topicsarray: any;
  quizzes: any;
  sidebar: any;
  maincontent: any;
  lessonName: any;
  lessonNumber: any = 1;
  // tryingContent: any;
  coursearray: any;
  percentage1: any;
  courseTitle: any;

  Questionsforquiz: any;

  CourseDetailsLocalTemp: any;

  CurrentQuestionIndex: any;
  AllQuestions: any;
  AllAnswers: any;
  Topic_Title: any;
  Show_Questions_Data = false;

  innerWidth: any;

  inputElement: any;

  videoURL: any;

  constructor(
    private rendererFactory: RendererFactory2,
    private globalService: GlobalService,
    private router: Router,
    private courseService: CourseService,
    private quizService: QuizServiceService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private adminService: AdminService,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {
    this.script = ""
        this.renderer = rendererFactory.createRenderer(null, null);
    if (!localStorage.getItem('token')) {
      router.navigate(['']);
    }
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'C'.charCodeAt(0)){
        return false;
      }
      if(e.ctrlKey && e.keyCode == 'V'.charCodeAt(0)){
        return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
  }


  ngOnDestroy(): void {
    // Destroying all API Calls
    this.subscriptions.forEach((item) => item.unsubscribe());
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null;
  }
  removeMouseOptions(e:any){e.preventDefault()}

  //  FOR POP UP OF QUIZ EXAM MODE
  TestMode: any[] = [
    { name: 'Tutor Mode', key: true },
    { name: 'Exam Mode', key: false },
  ];
  selectedMode: any;

  async ngOnInit() {
    if(localStorage.getItem('Id')=='-1'){
      this.demo = true;
    }
    // For Tawk IO
    this.globalService.tawkIO(
      localStorage.getItem('UserName'),
      localStorage.getItem('email')
    );
    this.selectedMode = this.TestMode[0];
    localStorage?.removeItem('practiceQuestionindex');
    localStorage?.removeItem('flashcardQuestionindex');

    // Getting username yo show on top
    this.getuserdetails();
    // @ts-ignore
    this.courseId = +localStorage.getItem('CourseID');
    localStorage.setItem('CourseID', this.courseId);
    // @ts-ignore
    this.coursearray = JSON.parse(localStorage.getItem('coursearray'));
    if (localStorage.getItem('topicID')) {
      this.topicId = localStorage.getItem('topicID');
    }
    // @ts-ignore
    this.lessonId = +localStorage.getItem('LessonID');
    localStorage.setItem('LessonID', this.lessonId);

    // Getting course title and it's completed percentage
    this.coursearray.forEach((lesson: any) => {
      if (lesson.ID == this.courseId) {
        this.percentage1 = lesson.value.toFixed(2);
        this.courseTitle = lesson.post_title;
      }
    });

    // this.getLessondetails()
    // Lesson Details

    if (localStorage.getItem('lessonName')) {
      this.lessonName = +localStorage.getItem('lessonName');
    }
    if (localStorage.getItem('lessonNumber')) {
      this.lessonNumber = localStorage.getItem('lessonNumber');
    }

    // CHECKING IF COURSE DETAILS ARE STORED IN LOCAL Storgae
    if (
      localStorage.getItem('CourseDetailsAPIResult') != null &&
      localStorage.getItem('CourseDetailsAPIResult') != 'undefined'
    ) {
      if (localStorage.getItem('CourseDetailsAPIResult')) {
        // @ts-ignore
        this.CourseDetailsLocalTemp = JSON?.parse(
          localStorage.getItem('CourseDetailsAPIResult')
        );
        // Checking whether the course details which are saved in local Storage are of the same course or not.
        if (this.CourseDetailsLocalTemp.course[0].ID === this.courseId) {
          this.lessonQuizzes = this.CourseDetailsLocalTemp.quizzes;
          this.lessondescription = this.CourseDetailsLocalTemp.courseDetails;
          let lessondetails;
          for (let i = 0; i < this.lessondescription.length; i++) {
            if (
              this.lessondescription[i].lesson[0].ID ==
              localStorage.getItem('LessonID')
            ) {
              this.lessonNumber = i + 1;
              lessondetails = this.lessondescription[i];
            }
          }
          this.getTopicDetails();
        } else {
          this.getcoursedetails();
        }
      } else {
        this.getcoursedetails();
      }
    } else {
      this.getcoursedetails();
    }

    this.getCompletedTopicValue();

    // Comparing token
    await this.logoutUser();
  }
  // Table search implemented here
  table: any;
  searchFunction(e: any) {
    var filter = e.target.value;
    filter = filter.toUpperCase();
    var table;
    for(let i=0; i<100; i++){
      let j=0;
      if(document.getElementById(`tablepress-${i}`)){
        j=1;
      }
      if(j==1){
        table = document.getElementById(`tablepress-${i}`)
        break;
      }
    }


    var tr = table.getElementsByTagName('tr');

    var td, txtValue, td1, txtValue1, td2, td3, txtValue3, td4, txtValue2;
    for (var i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName('td')[0];
      td1 = tr[i].getElementsByTagName('td')[1];
      td2 = tr[i].getElementsByTagName('td')[2];
      td3 = tr[i].getElementsByTagName('td')[3];
      if (td) {
        txtValue = td.textContent || td.innerText;
        txtValue1 = (td1)?td1.textContent || td1.innerText: '';
        txtValue2 = (td2)?td2.textContent || td2.innerText:'';
        txtValue3 = (td3)?td3.textContent || td3.innerText:''
        if (txtValue.toUpperCase().indexOf(filter) > -1 || txtValue1.toUpperCase().indexOf(filter) > -1 || txtValue2.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = 'table-row';
        } else {
          // var deleterow = tr[i]
          tr[i].setAttribute('style', 'display: none !important');
        }

      }
    }
  }

  logoutUser() {
    // @ts-ignore
    this.subscriptions.push(
      this.adminService
        .compareToken(JSON.parse(localStorage.getItem('Id')))
        .subscribe(
          (res: any) => {
            // this.loader = false;
            if (res.status) {
            } else {
              this.errorHandler.AutoLogout();
            }
          },
          (error) => {
            this.errorHandler.handleError(error);
          }
        )
    );
  }

  knobValue = 60;
  knobArray = [];
  // Getting knob value of completed courses
  getCompletedTopicValue(): any {
    this.knobArray = [];
    for (let i = 0; i < this.lessondescription?.length; i++) {
      if (this.lessondescription[i].topics.length == 0) {
        // It doesn't have Topics
        if (this.lessondescription[i]?.lesson[0].isCompleted) {
          this.knobArray.push('100');
          this.completeLesson(this.lessondescription[i].lesson[0].ID);
        } else {
          this.knobArray.push('0');
        }
      } else {
        // It has Topics
        let numberOfTopicsCompleted = 0;
        for (let j = 0; j < this.lessondescription[i].topics.length; j++) {
          if (this.lessondescription[i].topics[j].topic.isCompleted) {
            numberOfTopicsCompleted++;
          }
        }
        let percentage = (
          (numberOfTopicsCompleted / this.lessondescription[i].topics.length) *
          100
        ).toFixed();
        if (percentage == '100') {
          this.completeLesson(this.lessondescription[i].lesson[0].ID);
        }
        this.knobArray.push(percentage);
      }
    }
  }

  // Stopping video on scroll of page
  stopvideoNum = 1;
  onElementScroll($event) {
    if (this.testDiv) {
      const rect = this.testDiv.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTestDivScrolledIntoView = topShown && bottomShown;
    }
    if (this.isTestDivScrolledIntoView) {
      this.stopvideoNum = 1;
    } else {
      if (this.stopvideoNum == 1) {
        this.stopVideo();
      } else {
        setTimeout(function () {
          this.stopvideoNum = 1;
        }, 1000);
      }
    }
  }

  showData(topicID: any, lessonName1: any, lessonNumber1: any, LessonID: any): void {
    this.topicId = topicID;
    localStorage.setItem('LessonID', LessonID);
    localStorage.setItem('topicID', topicID);
    localStorage.setItem('lessonName', lessonName1);
    localStorage.setItem('lessonNumber', lessonNumber1);
    this.lessonName = lessonName1;
    this.lessonNumber = lessonNumber1;
    // if(topicID == -1){
    //   this.getLessondetails(this.lessonId)
    // }else{
    this.getTopicDetails();
    // }
  }

  // titleb(par: any){
  // }

  containsTable = false;
  getLessondetails(lessonId: any): void {
    // this.route.snapshot.paramMap.get('lid') = lessonId
    this.topicloader = true;
    this.userId = localStorage.getItem('Id');
    this.courseService
      .getLessonDetails(this.courseId, lessonId, this.userId, -1, localStorage.getItem('UserName'))
      .subscribe((res: any) => {
        if (res.status) {
          this.topicloader = false;
          if (res.lesson[0].real_post_content.includes('id="tablepress-5"')) {
            this.containsTable = true;
          } else {
            this.containsTable = false;
          }
          if (res.lesson[0].real_post_content.includes('class="tablepress')) {
            this.containsTable = true;
          } else {
            this.containsTable = false;
          }

          res.lesson[0].real_post_content =
            res.lesson[0].real_post_content.replace(
              /<table>/g,
              '<div class="table_div" id="tablepress-5"><table>'
            );
            res.lesson[0].real_post_content =
            res.lesson[0].real_post_content.replace(
              '</table>/',
              '</div><table>'
            );

          res.lesson[0].real_post_content =
            res.lesson[0].real_post_content.replace(
              /class="tablepress/g,
              'class="tablepress table table-striped'
            );
          res.lesson[0].real_post_content =
            res.lesson[0].real_post_content.replace(
              /<table id="tablepress-5/g,
              '<table id="tablepress-5'
            );
          res.lesson[0].real_post_content =
            res.lesson[0].real_post_content.replace(
              /class="row-hover"/g,
              'class="row-hover" id="row-hover"'
            );
          // @ts-ignore
          document.getElementById('embedBox')?.innerHTML =
            res.lesson[0].real_post_content;
          if(res.videoContent == -1){
            // @ts-ignore
          //  document.getElementById('embedBox')?.innerText =
          //  res.topic[0].real_post_content;
          this.videoURL = null
          }
          else{
            let videoContent = res.videoContent;
            this.otp = videoContent.otp;
            this.playbackinfo = videoContent.playbackInfo
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/v2/?otp=" + this.otp + "&playbackInfo=" + this.playbackinfo + "&primaryColor=#4245ef")
            this.videoCypher(this.otp , this.playbackinfo)
          }
          this.Topic_Title =
            this.lessondescription[this.lessonNumber - 1].lesson[0].post_title;
          this.Show_Questions_Data = false;
          this.lessonName = res.lesson[0].post_title;
          localStorage.setItem('lessonName', res.lesson[0].post_title);

          var tempTopicTitle = this.Topic_Title;
          this.lessonName = this.lessonName.replace(/\s/g, '-');
          this.lessonName = this.lessonName.replace('(', '-');
          this.lessonName = this.lessonName.replace(')', '-');
          this.lessonName = this.lessonName.replaceAll('+', '-');
          this.lessonName = this.lessonName.replaceAll("'", '');
          this.lessonName = this.lessonName.replaceAll(',', '');

          this.location.replaceState(
            `/focusboard/${this.lessonName}/${this.lessonName}`
          );
          let temp_url = `/focusboard/${this.lessonName}/${this.lessonName}`;
          this.globalService.tawkIO(
            localStorage.getItem('UserName'),
            localStorage.getItem('email'),
            temp_url
          );

          // this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))

          // this.router.navigate(['/focusboard',this.courseTitle, this.lessonName]);

          // @ts-ignore
          // document.getElementById("lessondescriptionid")?.innerHTML= res.topics[0].post_content;

          this.table = document.getElementById('tablepress-5');
        } else {
        }
        this.logoutUser();
      });
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  topicdata: any;
  stopVideo() {
    if (this.stopvideoNum == 1) {
      if (document.querySelectorAll('iframe')) {
        document.querySelectorAll('iframe').forEach((v) => {
          v.src = v.src;
        });
        document.querySelectorAll('video').forEach((v) => {
          v.pause();
        });

        this.stopvideoNum++;
        //  this.setNum();
      }
    }
  }

  // setNum(): void{
  //   setTimeout(function(){
  //     this.stopvideoNum = 1;
  //        }, 0);
  // }

  allFlashCardsData: any;
  otp: any;
  playbackinfo: any;
  async getTopicDetails() {
    await this.logoutUser();
    // @ts-ignore
    document.getElementById('lessondescriptionid')?.innerHTML = '';
    // @ts-ignore
    document.getElementById('embedBox')?.innerHTML = '';

    this.CurrentQuestionIndex = -1;

    if (this.topicId == -1) {
      this.lessonId =
        this.lessondescription[this.lessonNumber - 1].lesson[0].ID;
      localStorage.setItem('LessonID', this.lessonId);
      localStorage.setItem('topicID', '-1');
      document.getElementById('table_Area').innerHTML = '';
      this.getLessondetails(this.lessonId);
    } else {
      this.topicloader = true;

      document.getElementById('table_Area').innerHTML ='';
      // Flascard display none
      this.Show_Questions_Data = false;
      // Table display none
      this.Topic_Title = '';
      this.topicId = localStorage.getItem('topicID');
      this.lessonId =
        this.lessondescription[this.lessonNumber - 1].lesson[0].ID;
      let data = {
        userId: localStorage.getItem('Id'),
        courseId: this.courseId,
        lessonId: this.lessonId,
        topicId: this.topicId,
        username: localStorage.getItem('UserName')
      };

      this.courseService.getTopicDetails(data).subscribe(
        (res: any) => {
          this.topicloader = false;


          this.topicdata = res.topic[0].post_video_content;
          // @ts-ignore
          document.getElementById('lessondescriptionid')?.innerHTML =
            res.topic[0].real_post_content;

            if (res.topic[0].real_post_content.includes('class="tablepress')) {
              this.containsTable = true;
            } else {
              this.containsTable = false;
            }
            res.topic[0].real_post_content =
              res.topic[0].real_post_content.replace(
                /<table>/g,
                '<div class="table_div" id="tablepress-5"><table>'
              );
              res.topic[0].real_post_content =
              res.topic[0].real_post_content.replace(
                '</table>/',
                '</div><table>'
              );

            res.topic[0].real_post_content =
              res.topic[0].real_post_content.replace(
                /class="tablepress/g,
                'class="tablepress table table-striped'
              );
            res.topic[0].real_post_content =
              res.topic[0].real_post_content.replace(
                /<table id="tablepress-5/g,
                '<table id="tablepress-5'
              );
            res.topic[0].real_post_content =
              res.topic[0].real_post_content.replace(
                /class="row-hover"/g,
                'class="row-hover" id="row-hover"'
              );

          if (res.topic[0].post_video_content) {
            // @ts-ignore
            document.getElementById('embedBox')?.innerHTML =
              res.topic[0].post_video_content;
          }
          if(res.topic[0].real_post_content.includes('sdm_download_button_box_default')){
            // @ts-ignore
           document.getElementById('embedBox')?.innerHTML =
           res.topic[0].real_post_content;
          }
          if(res.videoContent == -1){
            // @ts-ignore
          //  document.getElementById('embedBox')?.innerText =
          //  res.topic[0].real_post_content;
          this.videoURL = null
          }
          else{
            let videoContent = res.videoContent;
            this.otp = videoContent.otp;
            this.playbackinfo = videoContent.playbackInfo
            this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vdocipher.com/v2/?otp=" + this.otp + "&playbackInfo=" + this.playbackinfo + "&primaryColor=#4245ef")
            this.videoCypher(this.otp , this.playbackinfo)
          }


          this.Topic_Title = res.topic[0].post_title;
          var tempTopicTitle = this.Topic_Title;
          tempTopicTitle = tempTopicTitle.replace(/\s/g, '-');
          tempTopicTitle = tempTopicTitle.replace(/[\])}[{(]/g, '-');
          // tempTopicTitle = tempTopicTitle.replace(")", '-')
          tempTopicTitle = tempTopicTitle.replaceAll('+', '-');
          this.lessonName = localStorage.getItem('lessonName');
          this.lessonName = this.lessonName?.replace(/\s/g, '-');
          this.lessonName = this.lessonName?.replaceAll("'", '');
          this.lessonName = this.lessonName.replaceAll('&', '');
          this.lessonName = this.lessonName.replace(/[\])}[{(]/g, '');
          tempTopicTitle = tempTopicTitle.replaceAll("'", '');
          tempTopicTitle = tempTopicTitle.replace(/[\])}[{(]/g, '');

          this.location.replaceState(
            `/focusboard/${this.lessonName}/${tempTopicTitle}`
          );
          let temp_url = `/focusboard/${this.lessonName}/${tempTopicTitle}`;
          this.globalService?.tawkIO(
            localStorage.getItem('UserName'),
            localStorage.getItem('email'),
            temp_url
          );

          //  res.topic[0].post_content.replace(/input/g,'img')

          if (
            res.topic[0].post_content.startsWith('[qwiz style') ||
            res.topic[0].post_content.includes('[qwiz style') ||
            res.topic[0].post_content.includes('[/qwiz]') ||
            res.topic[0].real_post_content.includes('qwiz_wrapper')
          ) {
            this.Show_Questions_Data = true;
            this.displayObject = false;
            // if (res.topic[0].real_post_content.includes('<table')) {
            //   this.containsTable = true;
            // } else {
            //   this.containsTable = false;
            // }

            res.topic[0].real_post_content =
            res.topic[0].real_post_content.replace(
              /<table>/g,
              '<div id="table_div" ><table>'
            );
            res.topic[0].real_post_content =
            res.topic[0].real_post_content.replace(
              '</table>',
              '</table></div>'
            );


            // @ts-ignore
            document.getElementById('lessondescriptionid').innerHTML =
              res.topic[0].real_post_content;
            const tablehtml =
              document.getElementById('table_div');
            if (tablehtml) {
              // @ts-ignore
              document.getElementById('table_Area').appendChild(tablehtml)

            } else {
              // @ts-ignore
              document.getElementById('table_Area').innerHTML = '';
            }
            const allQuestions =
              document.getElementsByClassName('questionStem');
            const allBtns = document.getElementsByClassName(
              'questionButtonSubmit'
            );
            const allAns = document.getElementsByClassName('questionAnswers');
            this.AllQuestions = allQuestions;
            this.TotalQuestionLength = this.AllQuestions.length;
            this.AllAnswers = allAns;
            let flashCards = [];
            for (let i = 0; i < allQuestions.length; i++) {
              const obj = {
                question: allQuestions[i],
                btn: allBtns[i],
                answer: allAns[i],
              };
              flashCards.push(obj);
            }
            this.allFlashCardsData = flashCards;

            this.CurrentQuestionIndex = -1;
            // @ts-ignore
            // document.getElementById('Question_Flashcard')?.innerHTML = `<div style="color: #0056b3; font-size: 18px!important">Master This session in 5 Minutes</div>`

            // for(let i=0; i<flashCards.length; i++){
            // //   let test1 = flashCards[i].toString()
            // //   test1 = test1.replace(/-/g,"</br>-");
            //}
          } else {

            this.Show_Questions_Data = false;

            if(res.topic[0].real_post_content.includes('vdo.add')){

            }else{
            // @ts-ignore
            document.getElementById('embedBox')?.innerHTML =
              res.topic[0].real_post_content;
            }
              document.getElementById('table_Area').innerHTML ='';
          }
          this.table = document.getElementById('tablepress-5');




          res.topic[0].real_post_content =
            res.topic[0].real_post_content.replace(
              /<table>/g,
              '<div id="table_div" ><table>'
            );
            res.topic[0].real_post_content =
            res.topic[0].real_post_content.replace(
              '</table>',
              '</table></div>'
            );
          // @ts-ignore
          document.getElementById('lessondescriptionid').innerHTML =
          res.topic[0].real_post_content;
        const tablehtml =
          document.getElementById('table_div');
        if (tablehtml) {
          // @ts-ignore
          document.getElementById('table_Area').appendChild(tablehtml)

        } else {
          // @ts-ignore
          document.getElementById('table_Area').innerHTML = '';
        }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
    }
  }
  private renderer: Renderer2;
  script;


  // Playing video cypher videos and chnging there playback speed
  videoCypher(otp , playbackinfo) {
    // this.script = this.renderer.createElement('script');
    // this.script.text = `(function(v,i,d,e,o){v[o]=v[o]||{}; v[o].add = v[o].add || function V(a){
    //   (v[o].d=v[o].d||[]).push(a);};if(!v[o].l) { v[o].l=1*new Date();
    //   a=i.createElement(d); m=i.getElementsByTagName(d)[0]; a.async=1; a.src=e;
    //   m.parentNode.insertBefore(a,m);}})
    //   (window,document,"script",
    //   "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js","vdo");
    //   vdo.add({
    //     otp: "${otp}",
    //     playbackInfo: "${playbackinfo}",
    //     theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
    //     container: document.querySelector( "#embedBox" ),
    //     plugins: [{
    //       name: 'keyboard',
    //       options: {
    //         bindings: {
    //           'Space': (player) => (player.status === 1) ? player.pause() : player.play(),
    //           'Up' : (player) => player.setVolume(player.volume + 0.2),
    //           'Down' : (player) => player.setVolume(player.volume - 0.2),
    //           'Left': (player) => player.seek(player.currentTime - 5),
    //           'Right': (player) => player.seek(player.currentTime + 5),
    //         },
    //       }
    //     }]

    //   });
    //   vdo.addEventListener('load', () => {
    //     vdo.availablePlaybackRates = [0.75, 1.0, 1.25, 1.5, 1.75, 2.0]
    //   });
    //   `;
    //   this.renderer.appendChild(document.querySelector( "#embedBox" ), this.script);


    const iframe = document.querySelector("iframe");
    // Creating player instance
    const player = window.VdoPlayer.getInstance(iframe);

    // All DOM API supports on player.video
    player.video.addEventListener("load", function () {
      player.video.availablePlaybackRates = [0.75, 1.0, 1.25, 1.5, 1.75, 2.0];
    });

    // // Up down right left key functions working
      // var video = new window.VdoPlayer({
      //   otp: `${otp}`,
      //   playbackInfo: `${playbackinfo}`,
      //   theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      //   container: document.querySelector("#embedBox"),
      //   plugins: [{
      //           name: 'keyboard',
      //           options: {
      //             bindings: {
      //               'Space': (player) => (player.status === 1) ? player.pause() : player.play(),
      //               'Up' : (player) => player.setVolume(player.volume + 0.2),
      //               'Down' : (player) => player.setVolume(player.volume - 0.2),
      //               'Left': (player) => player.seek(player.currentTime - 5),
      //               'Right': (player) => player.seek(player.currentTime + 5),
      //             },
      //           }
      //         }]
      // });
      // video.addEventListener("load", () => {
      //   video.availablePlaybackRates = [0.75, 1.0, 1.25, 1.5, 1.75, 2.0];
      // });
  }
  ngAfterViewInit(): void {

  }

  // Starting Flashcard Quiz
  startQuiz(): void {
    this.practiceQuestionsIndexarray = [-1];
    this.TotalQuestionLength = this.AllQuestions.length;
    this.displayObject = false;

    var testQues = this.AllQuestions[0].innerHTML;
    testQues = testQues.replace(/- /g, '<br> - ');
    for (let num = 0; num <= 8; num++) {
      let firstNum = `${num}. `;
      let secondNum = `${num}- `;
      let firstReplace = `<br> ${num}. `;
      let secondReplace = `<br> ${num}- `;
      testQues = testQues.replace(firstNum, firstReplace);
      testQues = testQues.replace(secondNum, secondReplace);
    }

    // testQues = testQues.replace(/- /g,"<br> - ")
    document.getElementById('Question_Flashcard').innerHTML = testQues;

    var testAns = this.AllAnswers[0].innerText;
    testAns = testAns.replace(/- /g, '<br> - ');

    for (let num = 0; num <= 8; num++) {
      let firstNum = `${num}. `;
      let secondNum = `${num}- `;
      let firstReplace = `<br> ${num}. `;
      let secondReplace = `<br> ${num}- `;
      testAns = testAns.replace(firstNum, firstReplace);
      testAns = testAns.replace(secondNum, secondReplace);
      const testAns1 = testAns.split("[", 1);
      testAns = testAns1[0];
    }
    // @ts-ignore
    document.getElementById('Answer_Flascard').innerHTML = testAns;
    // question = question.toString().replace(/-/g,"</br>-")
    this.CurrentQuestionIndex = 0;
    this.practiceQuestionindex = 0;
    var Question_Flashcard_Element =
      document.getElementById('Question_Flashcard');
    var Question_Flashcard_Heading =
      Question_Flashcard_Element.getElementsByTagName('span')[0];

    if (Question_Flashcard_Heading.style.color === 'rgb(255, 0, 0)') {
      Question_Flashcard_Heading.innerHTML =
        Question_Flashcard_Heading.innerHTML + '<br>';
      Question_Flashcard_Heading.setAttribute(
        'class',
        'Question_Flashcard_Heading'
      );
      document.styleSheets[0].insertRule(
        '.Question_Flashcard_Heading { font-size: 16px !important }'
      );
    }
  }

  TotalQuestionLength: any;
  currentQuestionLength: any;
  // If its last question in Flashcard
  lastFalshcardQuestion(): void {
    if (localStorage.getItem('flashcardQuestionindex')) {
      this.CurrentQuestionIndex =
        JSON.parse(localStorage.getItem('flashcardQuestionindex')) - 1;
    }
    if (localStorage.getItem('practiceQuestionindex')) {
      this.practiceQuestionindex =
        JSON.parse(localStorage.getItem('practiceQuestionindex')) - 1;
    }
    this.NextFlashcardQuestion();
  }

  // Going to next flashcrad question
  NextFlashcardQuestion(): void {
    if (localStorage.getItem('flashcardQuestionindex')) {
      if (
        this.CurrentQuestionIndex + 1 >
        JSON.parse(localStorage.getItem('flashcardQuestionindex'))
      ) {
        localStorage.setItem(
          'flashcardQuestionindex',
          JSON.stringify(this.CurrentQuestionIndex + 1)
        );
      } else {
      }
    } else {
      localStorage.setItem(
        'flashcardQuestionindex',
        JSON.stringify(this.CurrentQuestionIndex + 1)
      );
    }
    if (this.CurrentQuestionIndex < this.AllQuestions.length - 1) {
      this.CurrentQuestionIndex++;

      var test = this.AllQuestions[this.CurrentQuestionIndex].innerHTML;
      test = test.replace(/- /g, '<br> - ');

      for (let num = 0; num <= 8; num++) {
        let firstNum = `${num}. `;
        let secondNum = `${num}- `;
        let firstReplace = `<br> ${num}. `;
        let secondReplace = `<br> ${num}- `;
        test = test.replace(firstNum, firstReplace);
        test = test.replace(secondNum, secondReplace);
      }
      // @ts-ignore
      document.getElementById('Question_Flashcard').innerHTML = test;


      var Question_Flashcard_Element =
        document.getElementById('Question_Flashcard');

      var Question_Flashcard_Heading =
        Question_Flashcard_Element.getElementsByTagName('span')[0];

      if (Question_Flashcard_Heading?.style.color === 'rgb(255, 0, 0)') {
        Question_Flashcard_Heading.innerHTML =
          Question_Flashcard_Heading.innerHTML + '<br>';
        Question_Flashcard_Heading.setAttribute(
          'class',
          'Question_Flashcard_Heading'
        );
        document.styleSheets[0].insertRule(
          '.Question_Flashcard_Heading { font-size: 16px !important }'
        );
      }

      // @ts-ignore
        var testAnswer = this.AllAnswers[this.CurrentQuestionIndex].innerHTML;
        testAnswer = testAnswer.replace(/- /g, '<br> - ');
        for (let num = 0; num <= 8; num++) {
          let firstNum = `${num}. `;
          let secondNum = `${num}- `;
          let firstReplace = `<br> ${num}. `;
          let secondReplace = `<br> ${num}- `;
          testAnswer = testAnswer.replace(firstNum, firstReplace);
          testAnswer = testAnswer.replace(secondNum, secondReplace);
          const testAns1 = testAnswer.split("[", 1);
          testAnswer = testAns1[0];
        }
        // @ts-ignore
        document.getElementById('Answer_Flascard').innerHTML = testAnswer;
      this.displayObject = false;
    } else {
      // Showing Questions which are saved for practice
      localStorage.setItem(
        'practiceQuestionindex',
        JSON.stringify(this.practiceQuestionindex - 1)
      );
      this.practiceQuestionindex++;

      localStorage.setItem(
        'practiceQuestionindex',
        JSON.stringify(this.practiceQuestionindex)
      );

      this.TotalQuestionLength =
        this.AllQuestions.length +
        (this.practiceQuestionsIndexarray.length - 1);
      this.CurrentQuestionIndex++;


      let IndexOfPracticeQuestion =
        this.practiceQuestionsIndexarray[this.practiceQuestionindex];
      // -3 then at end make -2
      if (
        this.practiceQuestionindex < this.practiceQuestionsIndexarray.length
      ) {
        this.displayObject = false;

        var testQues = this.AllQuestions[IndexOfPracticeQuestion].innerHTML;
        testQues = testQues.replace(/- /g, '<br> - ');
        for (let num = 0; num <= 8; num++) {
          let firstNum = `${num}. `;
          let secondNum = `${num}- `;
          let firstReplace = `<br> ${num}. `;
          let secondReplace = `<br> ${num}- `;
          testQues = testQues.replace(firstNum, firstReplace);
          testQues = testQues.replace(secondNum, secondReplace);
        }
        // @ts-ignore
        document.getElementById('Question_Flashcard').innerHTML = testQues;
        var testAnswer = this.AllAnswers[IndexOfPracticeQuestion].innerHTML;
        testAnswer = testAnswer.replace(/- /g, '<br> - ');
        for (let num = 0; num <= 8; num++) {
          let firstNum = `${num}. `;
          let secondNum = `${num}- `;
          let firstReplace = `<br> ${num}. `;
          let secondReplace = `<br> ${num}- `;
          testAnswer = testAnswer.replace(firstNum, firstReplace);
          testAnswer = testAnswer.replace(secondNum, secondReplace);
          const testAns1 = testAnswer.split("[", 1);
          testAnswer = testAns1[0];
        }
        // @ts-ignore
        document.getElementById('Answer_Flascard').innerHTML = testAnswer;
      } else {
        this.CurrentQuestionIndex = -2;
        // @ts-ignore
        document.getElementById(
          'Question_Flashcard'
        ).innerHTML = `<div style="color: #0056b3; font-size: 18px!important">Congratulations, You're done. </div>`;
        this.displayObject = false;
        // @ts-ignore
        document.getElementById('Answer_Flascard').innerHTML = '';
        localStorage?.removeItem('practiceQuestionindex');
        localStorage?.removeItem('flashcardQuestionindex');
      }
    }
  }

  practiceQuestionsIndexarray = [-1];
  practiceQuestionindex = 0;
  // If user has clicked on Need Practice btn on flashcard
  saveForPractice(): void {
    this.TotalQuestionLength++;
    this.practiceQuestionsIndexarray.push(this.CurrentQuestionIndex);
    this.NextFlashcardQuestion();
  }

  // Get Previous Flashcard question
  PrevFlashcardQuestion(): void {
    if (this.CurrentQuestionIndex > 0) {
      this.CurrentQuestionIndex--;
      var testQues = this.AllQuestions[this.CurrentQuestionIndex].innerHTML;
      testQues = testQues.replace(/- /g, '<br> - ');
      for (let num = 0; num <= 8; num++) {
        let firstNum = `${num}. `;
        let secondNum = `${num}- `;
        let firstReplace = `<br> ${num}. `;
        let secondReplace = `<br> ${num}- `;
        testQues = testQues.replace(firstNum, firstReplace);
        testQues = testQues.replace(secondNum, secondReplace);
      }
      // @ts-ignore
      document.getElementById('Question_Flashcard').innerHTML = testQues;

      var testAns = this.AllAnswers[this.CurrentQuestionIndex].innerHTML;
      testAns = testAns.replace(/- /g, '<br> - ');
      for (let num = 0; num <= 8; num++) {
        let firstNum = `${num}. `;
        let secondNum = `${num}- `;
        let firstReplace = `<br> ${num}. `;
        let secondReplace = `<br> ${num}- `;
        testAns = testAns.replace(firstNum, firstReplace);
        testAns = testAns.replace(secondNum, secondReplace);
        const testAns1 = testAns.split("[", 1);
        testAns = testAns1[0];
      }
      // @ts-ignore
      document.getElementById('Answer_Flascard').innerHTML = testAns;
      this.displayObject = false;
    }
    // @ts-ignore
    document.getElementById('Question_Flashcard').innerHTML = testQues;

    var Question_Flashcard_Element =
      document.getElementById('Question_Flashcard');
    var Question_Flashcard_Heading =
      Question_Flashcard_Element.getElementsByTagName('span')[0];

      if (Question_Flashcard_Heading?.style.color === 'rgb(255, 0, 0)') {
      Question_Flashcard_Heading.innerHTML =
        Question_Flashcard_Heading.innerHTML + '<br>';
      Question_Flashcard_Heading.setAttribute(
        'class',
        'Question_Flashcard_Heading'
      );
      document.styleSheets[0].insertRule(
        '.Question_Flashcard_Heading { font-size: 16px !important }'
      );
    }
  }

  // If its First Flashcard Question
  FirstFlashcardQuestion(): void {
    this.CurrentQuestionIndex = 0;
    var testQues = this.AllQuestions[0].innerHTML;
    testQues = testQues.replace(/- /g, '<br> - ');
    for (let num = 0; num <= 8; num++) {
      let firstNum = `${num}. `;
      let secondNum = `${num}- `;
      let firstReplace = `<br> ${num}. `;
      let secondReplace = `<br> ${num}- `;
      testQues = testQues.replace(firstNum, firstReplace);
      testQues = testQues.replace(secondNum, secondReplace);
    }
    // testQues = testQues.replace(/- /g,"<br> - ")
    document.getElementById('Question_Flashcard').innerHTML = testQues;

    var testAns = this.AllAnswers[0].innerText;
    testAns = testAns.replace(/- /g, '<br> - ');
    for (let num = 0; num <= 8; num++) {
      let firstNum = `${num}. `;
      let secondNum = `${num}- `;
      let firstReplace = `<br> ${num}. `;
      let secondReplace = `<br> ${num}- `;
      testAns = testAns.replace(firstNum, firstReplace);
      testAns = testAns.replace(secondNum, secondReplace);
      const testAns1 = testAns.split("[", 1);
      testAns = testAns1[0];
    }
    // @ts-ignore
    document.getElementById('Answer_Flascard').innerHTML = testAns;

    // @ts-ignore
    document.getElementById('Question_Flashcard').innerHTML = testQues;

    var Question_Flashcard_Element =
      document.getElementById('Question_Flashcard');
    var Question_Flashcard_Heading =
      Question_Flashcard_Element.getElementsByTagName('span')[0];

    if (Question_Flashcard_Heading.style.color === 'rgb(255, 0, 0)') {
      Question_Flashcard_Heading.innerHTML =
        Question_Flashcard_Heading.innerHTML + '<br>';
      Question_Flashcard_Heading.setAttribute(
        'class',
        'Question_Flashcard_Heading'
      );
      document.styleSheets[0].insertRule(
        '.Question_Flashcard_Heading { font-size: 16px !important }'
      );
    }
    this.displayObject = false;
  }

  // Getting course details if not saved on local storage
  getcoursedetails(): any {
    this.loader = true;
    this.courseService.getCourseDetails(this.courseId).subscribe(
      (res: any) => {
        this.loader = false;
        if (res.status) {
          let CompleteApiData = res;
          localStorage.setItem(
            'CourseDetailsAPIResult',
            JSON.stringify(CompleteApiData)
          );
          this.lessonQuizzes = res.quizzes;
          this.lessondescription = res.courseDetails;

          let lessondetails;
          for (let i = 0; i < this.lessondescription.length; i++) {
            if (
              this.lessondescription[i].lesson[0].ID ==
              localStorage.getItem('LessonID')
            ) {
              this.lessonNumber = i + 1;
              lessondetails = this.lessondescription[i];
            }
          }
          // this.location.replaceState(`/focusboard/${this.courseTitle}/${this.lessonName}`)
          this.getTopicDetails();
        } else {
          this.router.navigate(['/dashboard/courses']);
        }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }
  // onclick Of lesson it gets called to get Topic details
  // getLessondetails(lessonId: any): void{
  //   this.courseService.getLessonDetails(this.courseId,lessonId).subscribe((res: any) => {
  //     if(res.status){
  //       this.topicsarray = res.topics;
  //       this.quizzes = res.quizzes;

  //       // @ts-ignore
  //       // document.getElementById("lessondescriptionid")?.innerHTML= res.topics[0].post_content;
  //   }
  //   else{
  //   }
  //   });
  // }

  // Going to Next Lesson on Click of TOP NAVBAR.
  async topnextLesson() {
    await this.logoutUser();
    this.lessonNumber = localStorage.getItem('lessonNumber');
    this.lessonName = localStorage.getItem('lessonName');
    this.topicId = localStorage.getItem('topicID');
    if (this.topicId == -1) {
      this.topicId = 0;
    }
    if (this.lessonNumber < this.lessondescription.length) {
      if (this.lessondescription[this.lessonNumber].topics.length == 0) {
        this.topicId = -1;
      } else {
        this.topic = this.lessondescription[this.lessonNumber].topics;
        localStorage.setItem('topicID', this.topic[0].topic.ID);
      }
      this.lessonName =
        this.lessondescription[this.lessonNumber].lesson[0].post_title;
      this.lessonNumber++;
      localStorage.setItem('lessonName', this.lessonName);
      localStorage.setItem('lessonNumber', this.lessonNumber);
      this.getTopicDetails();
    }
  }
  // Going to Previous Lesson on Click of TOP NAVBAR.
  async topPrevLesson() {
    await this.logoutUser();
    this.lessonNumber = localStorage.getItem('lessonNumber');
    this.lessonName = localStorage.getItem('lessonName');
    this.topicId = localStorage.getItem('topicID');
    if (this.lessonNumber != 1) {
      if (this.lessondescription[this.lessonNumber - 2].topics != 0) {
        this.topic = this.lessondescription[this.lessonNumber - 2].topics;
        localStorage.setItem('topicID', this.topic[0].topic.ID);
      } else {
        this.topicId = -1;
      }
      this.lessonName =
        this.lessondescription[this.lessonNumber - 2].lesson[0].post_title;
      this.lessonNumber = this.lessonNumber - 1;
      localStorage.setItem('lessonName', this.lessonName);
      localStorage.setItem('lessonNumber', this.lessonNumber);
      this.getTopicDetails();
    }
  }

  // onclick of next topic btn
  async nextTopic() {
    await this.logoutUser();
    //   if(this.lessondescription[this.lessonNumber].topics[this.topicindex].quizzes.length > 0) {
    //
    //  }
    //  else{

    // @ts-ignore
    this.topic = this.lessondescription[this.lessonNumber - 1].topics;
    this.topicIndexincrement = 0;
    this.topic.forEach((topic: any) => {
      if (topic.topic.ID == this.topicId) {
        this.topicindex = this.topicIndexincrement;
      }
      this.topicIndexincrement++;
    });
    if (this.topicindex + 1 < this.topic.length) {
      localStorage.setItem('topicID', this.topic[this.topicindex + 1].topic.ID);
      this.getTopicDetails();
    } else {
      if (
        this.lessonNumber < this.lessondescription.length &&
        this.lessondescription[this.lessonNumber].topics.length > 0
      ) {
        this.topic = this.lessondescription[this.lessonNumber].topics;
        this.lessonName =
          this.lessondescription[this.lessonNumber].lesson[0].post_title;
        this.lessonNumber++;
        localStorage.setItem('lessonName', this.lessonName);
        localStorage.setItem('lessonNumber', this.lessonNumber);
        localStorage.setItem('topicID', this.topic[0].topic.ID);
        this.topicId = this.topic[0].topic.ID;
        this.getTopicDetails();
      } else {
        this.lessonName =
          this.lessondescription[this.lessonNumber].lesson[0].post_title;
        this.lessonNumber++;
        localStorage.setItem('lessonName', this.lessonName);
        localStorage.setItem('lessonNumber', this.lessonNumber);
        localStorage.setItem('topicID', '-1');
        this.topicId = -1;
        this.getTopicDetails();
      }
    }
    // }
  }
  // onclick of previous topic btn.
  async previousTopic() {
    await this.logoutUser();
    // @ts-ignore
    this.topic = this.lessondescription[this.lessonNumber - 1].topics;
    this.topicIndexincrement = 0;
    this.topic.forEach((topic: any) => {
      if (topic.topic.ID == this.topicId) {
        this.topicindex = this.topicIndexincrement;
      }
      this.topicIndexincrement++;
    });

    if (this.topicindex > 0) {

      localStorage.setItem('topicID', this.topic[this.topicindex - 1].topic.ID);
      this.topicId = this.topic[this.topicindex - 1].topic.ID;
      this.getTopicDetails();
    } else {
      if (
        this.lessonNumber != 1 &&
        this.lessondescription[this.lessonNumber - 2].topics.length > 0
      ) {

        this.topic = this.lessondescription[this.lessonNumber - 2].topics;
        this.lessonName =
          this.lessondescription[this.lessonNumber - 2].lesson[0].post_title;
        this.lessonNumber = this.lessonNumber - 1;
        localStorage.setItem('lessonName', this.lessonName);
        localStorage.setItem('lessonNumber', this.lessonNumber);
        localStorage.setItem('topicID', this.topic[0].topic.ID);
        this.topicId = this.topic[0].topic.ID;
        this.getTopicDetails();
      } else {
        this.topic = this.lessondescription[this.lessonNumber - 2].topics;
        this.lessonName =
          this.lessondescription[this.lessonNumber - 2].lesson[0].post_title;
        this.lessonNumber = this.lessonNumber - 1;
        localStorage.setItem('lessonName', this.lessonName);
        localStorage.setItem('lessonNumber', this.lessonNumber);
        localStorage.setItem('topicID', '-1');
        this.topicId = -1;
        this.getTopicDetails();
      }
    }
  }

  hamburgerclicked(): void {
    // @ts-ignore
    // $("#sidebar").toggle("slow");
    this.isopen = !this.isopen;
  }
  modeModel = false;
  tutorMode = true;
  timeMode = false;
  QuizId: any;
  quizTitle: any;
  getQuizMode(quizId: any, quizName: any): any {
    this.QuizId = quizId;
    this.quizTitle = quizName;
    this.modeModel = true;
  }
  async completeCourseQuiz(quizId: any) {
    await this.logoutUser();
    var data = {
      topicId: quizId,
      courseId: this.courseId,
      userId: this.userId,
    };
    this.courseService.completeCourseQuiz(data).subscribe(
      (res: any) => {
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }

  // If user clicks on quiz getting quiz details
  async getQuiz(quizId: any, quizName: any) {
    await this.completeCourseQuiz(quizId);
    this.tutorMode = this.selectedMode.key;
    this.tutorMode == true
      ? localStorage.setItem('QuizMode', 'tutor')
      : localStorage.setItem('QuizMode', 'exam');
    this.timeMode == true
      ? localStorage.setItem('isTimed', JSON.stringify(true))
      : localStorage.setItem('isTimed', JSON.stringify(false));
    this.quizloader = true;
    localStorage.setItem('QuizId', quizId);
    localStorage.setItem('quizTitle', quizName);
    // localStorage.setItem("QUIZTitle", quizName)
    this.quizService.getQuizDetails(quizId).subscribe(
      (res: any) => {
        this.Questionsforquiz = res.data;

        this.quizloader = false;
        // if(this.Questionsforquiz.length == 0){
        //   // Will show message or do something when Quiz doesn't have any Questions
        // }
        // else{
        const arr = ['SelectedOptionArray', 'SubmittedQuestions', 'singleQuestionTime', 'currentQuestionIndex', 'CurrentIndex', 'dotQuestionsArray', 'quiz_time', 'timesup', 'AnswerChanges', 'qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
        arr.forEach(i => {
          localStorage.removeItem(i);
        });
        localStorage.setItem(
          'AllQuestions',
          JSON.stringify(this.Questionsforquiz)
        );
        this.router.navigate(['/quiz']);
        // }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }
  back(): void {
    this.router.navigate(['/dashboard/course-overview/', this.courseId]);
  }

  allLessonsIds = [];
  alltopicIds = [];
  userId = localStorage.getItem('Id');
  topicCompleteloader = false;
  // TopicCompletedIds = [{topicIDs: [-1],
  // lessonId: -1}
  // ];

  // Completing a topic, If user clicks complete topic btn
  completeLesson(lessonID): void {
    let Ids = {
      userId: this.userId,
      topicId: -1,
      lessonId: lessonID,
      courseId: this.courseId,
      allTopicsIds: this.alltopicIds,
      allLessonIds: this.allLessonsIds,
    };
    this.courseService.completeTopic(Ids).subscribe(
      (res: any) => {
        if (res.status === true) {
          this.topicCompleteloader = false;
          this.percentage1 = res.courseUpdatedStats.toFixed(2);
          // this.percentage1 = res.courseUpdatedStats
          this.coursearray.forEach((lesson: any) => {
            if (lesson.ID == this.courseId) {
              lesson.value = res.courseUpdatedStats;
            }
          });
          localStorage.setItem('coursearray', JSON.stringify(this.coursearray));
        }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }

  TopicCompleted(): void {
    // let correctCourseID = 0;
    //   if(this.TopicCompletedIds[0].lessonId == this.lessonId){
    //     correctCourseID = 1;
    //     if(localStorage.getItem("CompletedTopicsIds")){
    //       // @ts-ignore
    //       this.TopicCompletedIds = JSON.parse(localStorage.getItem("CompletedTopicsIds"))
    //     }
    //   }
    //   else{
    //     this.TopicCompletedIds = [{topicIDs: [-1],
    //       lessonId: -1}
    //       ];
    //   }


    // This is to perform topic related functions
    if(!this.demo){
    this.topicCompleteloader = true;
    }
    // GETTING ALL LESSON ID's
    for (let k = 0; k < this.lessondescription.length; k++) {
      // @ts-ignore
      this.allLessonsIds.push(this.lessondescription[k].lesson[0].ID);
    }

    let thisTopicIndex = -1;
    // GETTING ALL TOPIC ID's
    if (this.lessondescription[this.lessonNumber - 1].topics.length > 0) {
      for (
        let l = 0;
        l < this.lessondescription[this.lessonNumber - 1].topics.length;
        l++
      ) {
        // @ts-ignore
        this.alltopicIds.push(
          this.lessondescription[this.lessonNumber - 1].topics[l].topic.ID
        );
        if (
          this.lessondescription[this.lessonNumber - 1].topics[l].topic.ID ==
          this.topicId
        ) {
          thisTopicIndex = l;
        }
      }
      // @ts-ignore
      let getAllCourseDetail = JSON.parse(
        localStorage.getItem('CourseDetailsAPIResult')
      );
      getAllCourseDetail.courseDetails[this.lessonNumber - 1].topics[
        thisTopicIndex
      ].topic.isCompleted = true;
      localStorage.setItem(
        'CourseDetailsAPIResult',
        JSON.stringify(getAllCourseDetail)
      );
      this.lessondescription = getAllCourseDetail.courseDetails;
      if(!this.demo){
      this.getCompletedTopicValue();
      }

      // Going to Next Topic
      // this.nextTopic();
    } else {
      // No topic in lesson
      // @ts-ignore
      let getAllCourseDetail1 = JSON.parse(
        localStorage.getItem('CourseDetailsAPIResult')
      );
      getAllCourseDetail1.courseDetails[
        this.lessonNumber - 1
      ].lesson[0].isCompleted = true;
      localStorage.setItem(
        'CourseDetailsAPIResult',
        JSON.stringify(getAllCourseDetail1)
      );
      this.lessondescription = getAllCourseDetail1.courseDetails;
      if(!this.demo){
      this.getCompletedTopicValue();
      }
      this.alltopicIds = [];
      // Going to next Lesson
      // this.topnextLesson()
    }

    this.courseId = JSON.parse(localStorage.getItem('CourseID'));
    this.lessonId = this.lessondescription[this.lessonNumber - 1].lesson[0].ID;
    this.topicId = JSON.parse(localStorage.getItem('topicID'));
    let Ids = {
      userId: this.userId,
      topicId: this.topicId,
      lessonId: this.lessonId,
      courseId: this.courseId,
      allTopicsIds: this.alltopicIds,
      allLessonIds: this.allLessonsIds,
    };

    if(!this.demo){
    this.courseService.completeTopic(Ids).subscribe(
      (res: any) => {
        if (res.status === true) {
          this.topicCompleteloader = false;
          this.percentage1 = res.courseUpdatedStats.toFixed(2);
          // this.percentage1 = res.courseUpdatedStats
          this.coursearray.forEach((lesson: any) => {
            if (lesson.ID == this.courseId) {
              lesson.value = res.courseUpdatedStats;
            }
          });
          localStorage.setItem('coursearray', JSON.stringify(this.coursearray));
        }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }

    this.nextTopic();

    // Show Tick After Topic Is completed
    //   let istopicCompleted = false;
    //   for(let i = 0; i<this.TopicCompletedIds[0].topicIDs.length; i++){
    //     if(this.TopicCompletedIds[0].topicIDs[i]==this.topicId){
    //       istopicCompleted = true;
    //     }

    // }
    //   if(istopicCompleted === false){
    //     this.TopicCompletedIds[0].topicIDs.push(this.topicId);
    //   }

    //   localStorage.setItem("CompletedTopicsIds", JSON.stringify(this.TopicCompletedIds))


  }

  // SHOW NAME OF USER ON RIGHT TOP BAR
  UserName: any;
  getuserdetails(): void {
    this.authService.getuserdetails().subscribe(
      (res: any) => {
        if (res.status) {
          this.UserName = res.user.displayName;
          // this.UserName= res.user.loginName;
          // this.user.user_email = res.user.userEmail;
        } else {
          // Something Went Wrong
        }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }


  logout(): void {
    this.errorHandler.AutoLogout();
  }


}
