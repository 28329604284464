import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ChatService } from 'src/app/shared/services/chat.service';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import crypto from 'crypto-js'
@Component({
  selector: 'app-userchat',
  templateUrl: './userchat.component.html',
  styleUrls: ['./userchat.component.scss'],
  providers: [MessageService]
})
export class UserchatComponent implements OnInit, OnDestroy {
  demo = localStorage.getItem('Id')
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  search: any;
  messages = [];
  record: any;
  recording = false;
  url: any;
  error: any;
  recordingStream: any;
  aiLoader = false;
  userText = ''
  id = localStorage.getItem('Id');
  newMessage: any;
  allMessages: any = []
  failedQuery = false;
  askAdmin = false;
  fileUploading = false;
  audioUploading = false;
  fileType = 'text';
  @ViewChild('myInput') myInput!: ElementRef;

  constructor(public datepipe: DatePipe, private chatService: ChatService, private domSanitizer: DomSanitizer, private messageService: MessageService) { }
  
  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit(): void {
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Chat';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    this.chatService.startChat(JSON.parse(localStorage.getItem('Id')));
    this.getUserChat();
    this.chatService.shareData()

    setTimeout(() => {
      document.getElementById('text_chat').addEventListener('keypress', function (e) {
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          return false;
        }
      })
    }, 100)
  }

  // This function is used to scroll the user chat to the bottom //
  scrollToBottom(smooth: boolean = false): void {
    try {
      if (this.myScrollContainer) {
        const scrollContainer = this.myScrollContainer.nativeElement;
        requestAnimationFrame(() => {
          if (smooth) {
            scrollContainer.scrollTo({
              top: scrollContainer.scrollHeight,
              behavior: 'smooth',
            });
          } else {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      }
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  onFileUpload(event:any){
    this.askAdmin = false;
    this.failedQuery = false;
    const formData = new FormData();
    if (event.target.files.length > 0) {
      if (event.target.files[0].size <= 25000000) {
        formData.append('chatDocument', event.target.files[0]);
        this.fileUploading = true;
        this.scrollToBottom(true);
        this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
          this.fileUploading = false;
          var date = new Date()
          var data = {
            userId: this.id,
            name: localStorage.getItem('UserName'),
            message: res.data.chatFile,
            date: date,
            type: 'file',
            isAIBot: false,
            askAIBot: true,
            askAdmin: false
          }
          this.allMessages.push(data);
          var newdata = {
            userId: JSON.parse(localStorage.getItem('Id')),
            messages: this.allMessages,
            currentUser: localStorage.getItem("Id")
          }
  
          let userQuery = {
            question: res.data.chatFile,
            newdata,
            type: 'file'
          }
          
          this.messages = JSON.parse(JSON.stringify(this.allMessages));
          this.scrollToBottom(true);
          this.fileType = 'file';

          if (event.target.files[0].type.includes('image')) {
            this.aiLoader = true;
            this.chatService.generateAnswerChatbot(userQuery).subscribe((res: any) => {
              this.aiLoader = false;
              if (res.data) {
                if (res.data.answer.includes('Not Found')) {
                  this.allMessages[this.allMessages.length - 1].askAIBot = false;
                  newdata = {
                    userId: JSON.parse(localStorage.getItem('Id')),
                    messages: this.allMessages,
                    currentUser: localStorage.getItem("Id")
                  }
                  this.chatService.sendMessage({message: res.data.chatFile, type: 'file', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
                    console.log("ACKNOWLEDGEMENT ", cb);
                  });
                } else {
                  if (res.data.AIData) {
                    this.allMessages.push(res.data.AIData);
                    this.messages = JSON.parse(JSON.stringify(this.allMessages));
                    this.refreshMessages();
                    this.scrollToBottom(true);
                  }
                }
              } else {
                this.failedQueryToAdmin();
              }
            })
          } else {
            this.failedQueryToAdmin();
          }
        })
      }
      else {
        this.messageService.add({severity:'error', summary:'File Uploading Failed', detail:'The max file upload limit is 25 mbs'});
      }
    }
  }
  getUserChat(){
    this.chatService.getUserChat().subscribe((res: any) => {
      if (res.data.status) {
        res.data.chat.Messages = res.data.chat.Messages.replace(/[\\]/g, '').replace(/[\t]/g, '').replace(/[\n]/g, '').replace(/[\r]/g, '')
        this.messages = JSON.parse(res.data.chat.Messages)
        this.allMessages = JSON.parse(JSON.stringify(this.messages))
      }
      this.scrollToBottom(true);
      this.chatService.getNewMessage().subscribe((data: any) => {
        if (data.roomName != data.userId && data.userId != -2) {
          this.failedQuery = false;
          this.askAdmin = false;
          var bytes  = crypto.AES.decrypt(data.message, 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3');
          data.message = bytes.toString(crypto.enc.Utf8);
          this.messages.push({
            'userId': sessionStorage.getItem('Sender'),
            'name': localStorage.getItem('UserName'),
            'message': data.message,
            'date': new Date().toISOString(),
            'type': data.type
          })
          this.allMessages = JSON.parse(JSON.stringify(this.messages))
        }
      })
    })
  }

  sendChat(){
    this.askAdmin = false;
    this.failedQuery = false;
    let userMessage = this.userText
    this.userText = '';
    var date = new Date()
    var data = {
      userId: this.id,
      name: localStorage.getItem('UserName'),
      message: userMessage,
      date: date,
      type: 'text',
      isAIBot: false,
      askAIBot: true,
      askAdmin: false
    }
    this.allMessages.push(data);
    var newdata = {
      userId: JSON.parse(localStorage.getItem('Id')),
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }

    let userQuery = {
      question: userMessage,
      newdata,
      type: 'text'
    }
    this.aiLoader = true;
    this.myInput.nativeElement.blur();
    this.messages = JSON.parse(JSON.stringify(this.allMessages));
    this.scrollToBottom(true);
    this.chatService.generateAnswerChatbot(userQuery).subscribe((res: any) => {
      this.aiLoader = false;
      this.fileType = 'text';
      if (res.data) {
        if (res.data.answer.includes('Not Found')) {
          this.allMessages[this.allMessages.length - 1].askAIBot = false;
          newdata = {
            userId: JSON.parse(localStorage.getItem('Id')),
            messages: this.allMessages,
            currentUser: localStorage.getItem("Id")
          }
          this.chatService.sendMessage({message: userMessage, type: 'text', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
            console.log("ACKNOWLEDGEMENT ", cb);
          });
        } else {
          if (res.data.AIData) {
            this.allMessages.push(res.data.AIData);
            this.messages = JSON.parse(JSON.stringify(this.allMessages));
            this.refreshMessages();
            this.scrollToBottom(true);
          }
        }
      } else {
        this.failedQueryToAdmin();
      }
    })
  }

  failedQueryToAdmin() {
    this.failedQuery = true;
    this.scrollToBottom(true);
    this.allMessages[this.allMessages.length - 1].askAIBot = false;
    this.messages[this.messages.length - 1].askAIBot = false;
    const userMessage = this.allMessages[this.allMessages.length - 1].message
    const newdata = {
      userId: JSON.parse(localStorage.getItem('Id')),
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }
    this.chatService.sendMessage({message: userMessage, type: this.fileType, roomName: JSON.parse(localStorage.getItem('Id')), userId: this.allMessages[this.allMessages.length - 1].userId, data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });
    setTimeout(() => {
      this.forwardQueryToAdmin();
    }, 250)
  }

  forwardQueryToAdmin() {
    this.askAdmin = true;
    this.allMessages[this.allMessages.length - 1].askAdmin = true;
    this.messages[this.messages.length - 1].askAdmin = true;
    let newdata = {
      userId: JSON.parse(localStorage.getItem('Id')),
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }
    this.chatService.sendMessage({message: null, type: this.fileType, roomName: JSON.parse(localStorage.getItem('Id')), userId: this.allMessages[this.allMessages.length - 1].userId, data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });
  }

  refreshMessages() {
    this.allMessages[this.allMessages.length - 2].askAIBot = false;
    this.messages[this.messages.length - 2].askAIBot = false;
    let userMessage = this.allMessages[this.allMessages.length - 2].message
    let newdata = {
      userId: JSON.parse(localStorage.getItem('Id')),
      messages: this.allMessages,
      currentUser: localStorage.getItem("Id")
    }
    this.chatService.sendMessage({message: userMessage, type: this.fileType, roomName: JSON.parse(localStorage.getItem('Id')), userId: this.allMessages[this.allMessages.length - 2].userId, data: newdata}, cb => {
      console.log("ACKNOWLEDGEMENT ", cb);
    });

    setTimeout(() => {
      this.allMessages[this.allMessages.length - 1].askAIBot = false;
      this.messages[this.messages.length - 1].askAIBot = false;
      userMessage = this.allMessages[this.allMessages.length - 1].message
      newdata = {
        userId: JSON.parse(localStorage.getItem('Id')),
        messages: this.allMessages,
        currentUser: localStorage.getItem("Id")
      }
      if (this.messages[this.messages.length - 1].isAIBot) {
        this.chatService.sendMessage({message: userMessage, type: 'text', roomName: JSON.parse(localStorage.getItem('Id')), userId: this.allMessages[this.allMessages.length - 1].userId, data: newdata}, cb => {
          console.log("ACKNOWLEDGEMENT ", cb);
        });
      }
      
      this.scrollToBottom(true);
    }, 250)
  }

  ngOnDestroy(): void {
    this.chatService.closeChat()
    this.chatService.closeShareData()
    this.stopAudio(this.recordingStream)
  }

  // Audio File Implementation //

  initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: any) {
    this.recordingStream = stream
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1,
      sampleRate: 50000,
    };
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }

  processRecording(blob: any) {
    this.url = URL.createObjectURL(blob);
    this.sendAudioFile(blob);
  }

  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';  
  }

  stopAudio(stream: any) {
    if (stream) {
      for (let i = 0; i < stream.getTracks().length; i++) {
        stream.getTracks()[i].stop()
      }
    }
  }
    
  sendAudioFile = file => {
    this.askAdmin = false;
    this.failedQuery = false;
    this.stopAudio(this.recordingStream)
    const formData = new FormData();
    formData.append('chatDocument', new File([file], 'audio.wav', { type: 'audio/wav' }));
    this.audioUploading = true;
    this.scrollToBottom(true);
    this.chatService.uploadChatDocument(formData).subscribe((res: any) => {
      this.audioUploading = false;
      var date = new Date()
      var data = {
        userId: this.id,
        name: localStorage.getItem('UserName'),
        message: res.data.chatFile,
        date: date,
        type: 'audio',
        isAIBot: false,
        askAIBot: true,
        askAdmin: false
      }
      this.allMessages.push(data);
      var newdata = {
        userId: JSON.parse(localStorage.getItem('Id')),
        messages: this.allMessages,
        currentUser: localStorage.getItem("Id")
      }

      let userQuery = {
        question: res.data.chatFile,
        newdata,
        type: 'audio'
      }
      
      this.aiLoader = true;
      this.messages = JSON.parse(JSON.stringify(this.allMessages));
      this.scrollToBottom(true);
      this.chatService.generateAnswerChatbot(userQuery).subscribe((res: any) => {
        this.aiLoader = false;
        this.fileType = 'audio';
        if (res.data) {
          if (res.data.answer.includes('Not Found')) {
            this.allMessages[this.allMessages.length - 1].askAIBot = false;
            newdata = {
              userId: JSON.parse(localStorage.getItem('Id')),
              messages: this.allMessages,
              currentUser: localStorage.getItem("Id")
            }
            this.chatService.sendMessage({message: res.data.chatFile, type: 'file', roomName: JSON.parse(localStorage.getItem('Id')), userId: JSON.parse(localStorage.getItem('Id')), data: newdata}, cb => {
              console.log("ACKNOWLEDGEMENT ", cb);
            });
          } else {
            if (res.data.AIData) {
              this.allMessages.push(res.data.AIData);
              this.messages = JSON.parse(JSON.stringify(this.allMessages));
              this.refreshMessages();
              this.scrollToBottom(true);
            }
          }
        } else {
          this.failedQueryToAdmin();
        }
      })
    })
  };
}
