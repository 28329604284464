import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-calculator',
  templateUrl: './score-calculator.component.html',
  styleUrls: ['./score-calculator.component.scss']
})
export class ScoreCalculatorComponent implements OnInit , OnDestroy{

  numberofanswers:any;
  result = 0;
  sectionValue="Step 1 - NBME25"
  sectiontext="Each Question On NBME 25 Is Worth 1.113 Points"
  heading=false;
  percentage = 0;
  sectionImg="../../../assets/images/Picture 1.png"

  filterOptions=[
   {
       'name':'Step 1 - NBME25',
       'firstvalue':-1.113,
       'secondvalue':277.4,
       'text':'Each Question On NBME 25 Is Worth 1.113 Points',
       'img':'../../../assets/images/Picture 1.png'

   },
   {
        'name':'Step 1 - NBME26',
        'firstvalue':-1.1275,
        'secondvalue':276.38,
        'text':'Each Question On NBME 26 Is Worth 1.1275 Points',
        'img':'../../../assets/images/Picture 2.png'

   },
   {
        'name':'Step 1 - NBME27',
        'firstvalue':-1.1211,
        'secondvalue':276.53,
        'text':'Each Question On NBME 27 Is Worth 1.1211 Points',
        'img':'../../../assets/images/Picture 3.png'

   },
   {
        'name':'Step 1 - NBME28',
        'firstvalue':-1.1701,
        'secondvalue':278.36,
        'text':'Each question on nbme 28 is worth 1.1701 Points',
        'img':'../../../assets/images/Picture 4.png'


   },
   {
        'name':'Step 1 - NBME29',
        'firstvalue':-1.1522,
        'secondvalue':276.92,
        'text':'Each question on nbme 29 is worth 1.1522 Points',
        'img':'../../../assets/images/Picture 5.png'

   },
   {
        'name':'Step 1 - NBME30',
        'firstvalue':-1.1435,
        'secondvalue':277.21,
        'text':'Each question on nbme 30 is worth 1.1435 Points',
        'img':'../../../assets/images/Picture 6.png'

   },
   {
    'name':'Step 1 - NBME31',
    'firstvalue':-1.1435,
    'secondvalue':277.21,
    'text':'Each question on nbme 31 is worth 1.1435 Points',
    'img': null

    },
   {
        'name':'Step 1 - UWSA 1',
        'firstvalue':1.8458,
        'secondvalue':107.6,
        'text':'',
        'img':'../../../assets/images/uwsa.png'

   },
   {
     'name':'Step 1 - UWSA 2',
     'firstvalue':1.8458,
     'secondvalue':107.6,
     'text':'',
     'img':'../../../assets/images/uwsa.png'

   },
   {
    'name':'Step 1 - UWSA 3',
    'firstvalue':1.8458,
    'secondvalue':107.6,
    'text':'',
    'img':'../../../assets/images/uwsa.png'

   },
   {
        'name':'Step 2 CK - NBME 9',
        'firstvalue':-0.9,
        'secondvalue':295,
        'text':'',
        'img':null
   },
   {
        'name':'Step 2 CK - NBME 10',
        'firstvalue':-0.9,
        'secondvalue':293,
        'text':'',
        'img':null
   },
   {
        'name':'Step 2 CK - NBME 11',
        'firstvalue':-0.8,
        'secondvalue':287,
        'text':'',
        'img':null
   },
   {
     'name':'Step 2 CK - NBME 12',
     'firstvalue':-0.8,
     'secondvalue':286,
     'text':'',
     'img':null
   },
   {
    'name':'Step 2 CK - NBME 13',
    'firstvalue':-0.9,
    'secondvalue':295,
    'text':'',
    'img':null
  },
  {
    'name':'Step 2 CK - NBME 14',
    'firstvalue':-0.9,
    'secondvalue':293,
    'text':'',
    'img':null
  },
  {
      'name': 'Step 2 CK - UWSA 1',
      'firstvalue': -1.0956,
      'secondvalue': 293.59,
      'text':'',
      'img':null
   },
   {
      'name': 'Step 2 CK - UWSA 2',
      'firstvalue': -1.0273,
      'secondvalue': 292.02,
      'text':'',
      'img':null
   },
   {
    'name': 'Step 2 CK - UWSA 3',
    'firstvalue': -1.0956,
    'secondvalue': 293.59,
    'text':'',
    'img':null
  }

  ]
  constructor() {
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
   }
  ngOnDestroy(): void {
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null
  }

  ngOnInit(): void {
  }
  removeMouseOptions(e: any){
    e.preventDefault()
  }
  setSection(item:any)
  {
    this.result=0;
    this.numberofanswers=0;
    this.sectionValue =item.name;
    this.sectiontext = item.text;
    this.sectionImg = item.img;
    if(this.sectionValue =="Step 1 - UWSA 1" || this.sectionValue =="Step 1 - UWSA 2" || this.sectionValue =="Step 1 - UWSA 3")
    {
      this.heading = true;
     }
     else{
      this.heading=false
     }
  }

  calculateScore(){
    for(let i=0;i<this.filterOptions.length;i++)
    {
      if(this.sectionValue==this.filterOptions[i].name)
      {
        this.result=Math.round(this.filterOptions[i].firstvalue*this.numberofanswers+this.filterOptions[i].secondvalue);
      }
      if(this.sectionValue=="Step 1 - UWSA 1"&&this.filterOptions[i].name=="Step 1 - UWSA 1" || this.sectionValue=="Step 1 - UWSA 2"&&this.filterOptions[i].name=="Step 1 - UWSA 2" || this.sectionValue=="Step 1 - UWSA 3"&&this.filterOptions[i].name=="Step 1 - UWSA 3")
      {
        this.percentage = (this.numberofanswers / 160) * 100;
        this.result=Math.round(this.filterOptions[i].firstvalue*this.percentage+this.filterOptions[i].secondvalue);

      }

    }

  }
}
