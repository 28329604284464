import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  val = 0.0;
  display: any;

  @ViewChild('form', { static: false }) public form!: NgForm;

  constructor() { 
    console.log(this.form);
  }

  ngOnInit(): void {
  }

  addChar(character: any) {
    if(this.display == null || this.display == "0")
      this.display = character
    else
      this.display += character
  }

   cos() {
    this.display = Math.cos(this.display);
  }

   sin() {
    this.display = Math.sin(this.display);
  }

   tan() {
    this.display = Math.tan(this.display);
  }

   sqrt() {
    this.display = Math.sqrt(this.display);
  }

   ln() {
    this.display = Math.log(this.display.value);
  }

   exp() {
    this.display = Math.exp(this.display);
  }

   deleteChar() {
    this.display = this.display.substring(0, this.display.length - 1)
  }
   percent() {
    this.val = this.display;
    this.display = this.display + "%";
  }

   changeSign() {
    if(this.display.substring(0, 1) == "-")
      this.display = this.display.substring(1, this.display.length)
    else
      this.display = "-" + this.display
  }

   compute() {
    this.display = eval(this.display);
  }


   square() {
     this.display = eval(this.display) * eval(this.display);
  }

   checkNum() {
    for (var i = 0; i < this.display.length; i++) {
      var ch = this.display.charAt(i);
      if (ch < "0" || ch > "9") {
        if (ch != "/" && ch != "*" && ch != "+" && ch != "-" && ch != "."
          && ch != "(" && ch!= ")" && ch != "%") {
          alert("invalid entry!")
          return false
        }
      }
    }
    return true
  }

}
