import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ready-flashcard-module',
  templateUrl: './ready-flashcard-module.component.html',
  styleUrls: ['./ready-flashcard-module.component.scss']
})
export class ReadyFlashcardModuleComponent implements OnInit {
  module = 'browse'
  courses: any = []
  selectedCourse = null;
  
  constructor() { }

  ngOnInit(): void {
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Flashcards';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    this.courses = JSON.parse(localStorage.getItem('subscriptions'))
    if (this.courses.length > 0) {
      this.selectedCourse = this.courses[0].courseID
    }
  }
}
