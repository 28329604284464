import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL, createAuthorizationHeader } from "../../models/globals";

@Injectable({
  providedIn: 'root'
})
export class FlashcardService {

  baseUrl = BASE_URL;

  constructor(private httpClient: HttpClient) { }

  addUserDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/addUserDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  addDeckFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/addDeckFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  getUserDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/getUserDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  getReadyDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/getReadyDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  getAIDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/getAIDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  getDeckFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/getDeckFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  addStudyFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/addStudyFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  resetStudyFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/resetStudyFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  editUserDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/editUserDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  editDeckFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/editDeckFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteUserDeck(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteUserDeck', { data },
    {headers: createAuthorizationHeader()});
  }

  deleteDeckFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/deleteDeckFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  shuffleDeckFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/shuffleDeckFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  buryFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/buryFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  suspendFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/suspendFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  unsuspendFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/unsuspendFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  rescheduleFlashcard(data: any) {
    return this.httpClient.post(this.baseUrl + '/rescheduleFlashcard', { data },
    {headers: createAuthorizationHeader()});
  }

  updateDeckSettings(data: any) {
    return this.httpClient.post(this.baseUrl + '/updateDeckSettings', { data },
    {headers: createAuthorizationHeader()});
  }

  generateAIDecks(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/generateAIDecks', { data },
    {headers: createAuthorizationHeader()});
  }
}
