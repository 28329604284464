<!-- <script src="node_modules/chart.js/src/chart.js"></script> -->


<div class="eup-db-root">
    <div class="eup-db-parent">
      <div class="eup-db-subparent">
        <div class="eup-db-grid-wrapper">

          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 p-0">
                <div class="eup-db-top-bar-wrapper">
                  <div class="eup-db-top-bar">
                    <div class="eup-db-top-bar-heading" style="display: flex; flex-direction: row;">
                      <h4 id="eup-navbar-heading">Dashboard</h4>

                      <i class="fa fa-bars fa-2x ml-3 hamburger" style="color: #FFFFFF;" (click)="hamburgerclicked()"></i>

                    </div>


                    <div class="eup-db-top-bar-user-profile-icon">
                      <i class="fa fa-commenting-o fa-2x mr-3" (click)="visibleSidebar2 = true"></i>
                      <div class="eup_username">{{userName}}</div>
                      <div>

                        <i class="fa fa-user-circle-o" routerLink="/admin/profile"></i>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 p-0">
                <div class="eup-db-content-wrapper">
                  <div class="eup-db-content-left-parent" [ngClass]="[isopen == true ? 'flex-20' : 'flex-0']" id="sidebar">
                   <div class="eup-db-side-bar-logo">
                     <img src="../../assets/images/logo-edit.png" alt="">
                   </div>
                    <div class="eup-db-side-bar-navigation-wrapper">
                      <ul class="eup-db-side-bar-navigation">
                        <li class="eup-db-side-bar-nav-item" [routerLink]="item.link" *ngFor="let item of sidebar">
                          <i class="{{item.icon}} eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : '']"></i>
                          <span class="eup-db-side-bar-link-text" >{{ item.title }}</span>
                        </li>

                        <li class="eup-db-side-bar-nav-item" (click)="logout()">
                          <i class="fa fa-sign-out eup-db-side-bar-link-icon" [ngClass]="[isopen == true ? '' : ' pl-1']"></i>
                          <span class="eup-db-side-bar-link-text" >Logout</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="eup-db-content-right-parent" [ngClass]="[isopen == true ? 'flex-80' : 'flex-100']" id="rightContent">
                    <router-outlet></router-outlet>
                  </div>

                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>



  <!-- SIDEBAR  -->
  <p-sidebar [(visible)]="visibleSidebar2" position="right" [baseZIndex]="10000000" [style]="{background:'#007991', color:'#FFFFFF'}">
    <div style="height: 5vh;"></div>
    <!-- <h1 style="font-weight:normal">Right Sidebar</h1> -->
    <div class="to-searchbar">
    <h6>NOTIFICATIONS</h6>
    <i class="fa fa-search"></i>
    </div>
    <div class="dropdown-rightsidebar">
    <!-- <p-dropdown [options]="representatives" [(ngModel)]="UserName" optionLabel="name" (onChange)="selectNum(UserName)"></p-dropdown> -->
    </div>
    <div class="main_content">
        <div class="messages_dates_time">

            <div *ngFor="let notification of allNotifications">
    <div class="date_send_message" >
        {{notification.date}}
    </div>

    <div class="messages">
        <div class="message">
            <div class="text">
                {{notification.request}}
            </div>
            <!-- <div class="time">
                17:20
            </div> -->
        </div>
        <div class="delete_Admin" (click)="deleteAdminNotification(notification.req_id)">
          <button class="delete_admin_notification">Delete</button>
        </div>
    </div>
</div>


</div>

    <div class="bottom_write_message">
        <input type="text" [(ngModel)]="newNotification">
        <div class="sendbtn text-center" (click)="sendNotification()">
            <i class="fa fa-paper-plane"></i>
        </div>
    </div>
</div>
    <!-- <p-button type="button" (click)="visibleSidebar2 = false" label="Save" styleClass="p-button-success"></p-button>
    <p-button type="button" (click)="visibleSidebar2 = false" label="Cancel" styleClass="p-button-secondary"></p-button> -->
</p-sidebar>
