import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.scss'],
  providers: [MessageService]
})
export class InstructorsComponent implements OnInit {

  instructors: any = [];
  rows = 5;
  NumofRows = [
    {numOfRows: 5},
    {numOfRows: 10},
    {numOfRows: 15},
    {numOfRows: 20},
    {numOfRows: 25}
  ];
  addInstructorModal = false;
  editInstructorModal = false;
  deleteInstructorModal = false;
  userForm: any;
  editForm: any;
  permissions: any = [
    {
      title: 'Chat',
      status: false
    }
  ]
  selectedInstructor: any = null;
  loader = false;
  modalLoader = false;
  emailExists = false;

  constructor(private fb: FormBuilder, private messageService: MessageService, private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      fullName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
    this.editForm = this.fb.group({
      fullName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
    });
    this.getInstructors();
  }

  selectNum(a: any): void{
    this.rows = a.numOfRows;
  }

  getInstructors() {
    this.loader = true;
    this.adminService.getInstructors().subscribe((res: any) => {
      this.loader = false;
      if (res.data.status) {
        this.instructors = res.data.instructors;
        this.instructors.forEach((element: any) => {
          try {
            element.Permissions = JSON.parse(element.Permissions);
          } catch (e) {}
        })
      }
    })
  }

  openAddModal() {
    this.addInstructorModal = true;
    this.userForm.reset();
    setTimeout(() => {
      this.permissions.forEach((element: any, index: any) => {
        element.status = false;
        //@ts-ignore
        document.getElementById('checkbox' + index).checked = false;
      });
    }, 50)
  }

  addInstructor() {
    const data = {
      fullName: this.userForm.controls['fullName'].value,
      email: this.userForm.controls['email'].value,
      password: this.userForm.controls['password'].value,
      permissions: this.permissions.filter((item: any) => item.status).map((item: any) => item.title)
    }
    this.modalLoader = true;
    this.adminService.addInstructor(data).subscribe((res: any) => {
      this.modalLoader = false;
      this.addInstructorModal = false;
      if (res.data.status) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Instructor Added Successfully'});
        this.getInstructors();
      }
    }, (error: any) => {
      this.modalLoader = false;
      this.addInstructorModal = false;
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Adding Instructor'});
    })
  }

  openEditModal(index: any) {
    this.emailExists = false;
    this.editInstructorModal = true;
    this.editForm.reset();
    setTimeout(() => {
      this.permissions.forEach((element: any, index: any) => {
        element.status = false;
        //@ts-ignore
        document.getElementById('checkbox' + index).checked = false;
      });

      this.selectedInstructor = this.instructors[index];
      this.editForm.controls['fullName'].setValue(this.instructors[index].FullName);
      this.editForm.controls['email'].setValue(this.instructors[index].Email);

      for (let i = 0; i < this.instructors[index].Permissions.length; i++) {
        const permissionIndex = this.permissions.findIndex((item: any) => item.title === this.instructors[index].Permissions[i])
        if (permissionIndex !== -1) {
          this.permissions[permissionIndex].status = true;
          //@ts-ignore
          document.getElementById('checkbox' + permissionIndex).checked = true;
        }
      }
    }, 50)
  }

  checkDuplicateInstructor() {
    this.emailExists = false;
    if (this.selectedInstructor.Email === this.editForm.controls['email'].value) {
      this.editInstructor();
    } else {
      const data = {
        email: this.editForm.controls['email'].value
      }
      this.modalLoader = true;
      this.adminService.checkDuplicateInstructor(data).subscribe((res: any) => {
        this.modalLoader = false;
        if (res.data.status) {
          this.editInstructor();
        } else {
          this.emailExists = true;
        }
      }, (error: any) => {
        this.modalLoader = false;
        this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Editing Instructor'});
      })
    }
  }

  editInstructor() {
    this.emailExists = false;
    const data = {
      instructorId: this.selectedInstructor.InstructorID,
      fullName: this.editForm.controls['fullName'].value,
      email: this.editForm.controls['email'].value,
      permissions: this.permissions.filter((item: any) => item.status).map((item: any) => item.title)
    }
    this.modalLoader = true;
    this.adminService.editInstructor(data).subscribe((res: any) => {
      this.modalLoader = false;
      this.editInstructorModal = false;
      if (res.data.status) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Instructor Edited Successfully'});
        this.getInstructors();
      }
    }, (error: any) => {
      this.modalLoader = false;
      this.editInstructorModal = false;
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Editing Instructor'});
    })
  }

  openDeleteModal(index: any) {
    this.selectedInstructor = this.instructors[index];
    this.deleteInstructorModal = true;
  }

  deleteInstructor() {
    const data = {
      instructorId: this.selectedInstructor.InstructorID
    }
    this.modalLoader = true;
    this.adminService.deleteInstructor(data).subscribe((res: any) => {
      this.modalLoader = false;
      this.deleteInstructorModal = false;
      if (res.data.status) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Instructor Deleted Successfully'});
        this.getInstructors();
      }
    }, (error: any) => {
      this.modalLoader = false;
      this.deleteInstructorModal = false;
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Deleting Instructor'});
    })
  }

  setPermission(event: any, index: any) {
    if (event.target.checked) {
      this.permissions[index].status = true;
    } else {
      this.permissions[index].status = false;
    }
  }
}
