<div class="login_wrapper">
<div class="login">
	<h1>Link Questions</h1>
    <form method="post">
    	<input type="number" name="u" [(ngModel)]="firstQuestionId" placeholder="Question ID" required="required" />
        <input type="number" name="p" [(ngModel)]="secondQuestionId" placeholder="Question ID" required="required" />
        <div class="message" [ngClass]="(messageColor?'green':'red')">{{message}}</div>
        <button type="submit" (click)="linkQuestions()" class="btn btn-block btn-large">Link</button>
    </form>
</div>

<div class="table">
<p-table [value]="linkedQuestions" responsiveLayout="scroll" [paginator]='true' [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="10">
    <ng-template pTemplate="header">
        <tr>
            <th>#</th>
            <th>Question ID</th>
            <th>Linked ID</th>
            <th>Unlink Questions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-question let-i="rowIndex">
        <tr>
            <td>{{i+1}}</td>
            <td>{{question.QuestionID}}</td>
            <td>{{question.LinkedID}}</td>
            <td class="text-center"><i (click)="unlinkQuesions(question)" class="pi pi-trash"></i></td>
        </tr>
    </ng-template>
</p-table>
</div>

</div>



