import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { TreeNode } from 'primeng/api';
import { CourseService } from 'src/app/shared/services/course.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { demo_variables } from 'src/app/models/demo_global';

@Component({
  selector: 'app-analysis-table',
  templateUrl: './analysis-table.component.html',
  styleUrls: ['./analysis-table.component.scss']
})
export class AnalysisTableComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription = [];

  files1: TreeNode[];

  files2: TreeNode[];

  files3: TreeNode[];

  files4: TreeNode[];

  cols: any[];

  cols1: any[];

  quizId: any;

  loader = false;
  showTestDetails = true;

  constructor(private adminService: AdminService, private quizService: QuizServiceService, private courseService: CourseService, private errorHandler: ErrorHandlerService) { }
  ngOnDestroy(): void {
    
      this.subscriptions.forEach(item => item.unsubscribe());
    
  }

  async ngOnInit(){
    // this.quizService.getFilesystem().then(files => (this.files1 = files));
    // this.quizService.getFilesystem().then(files => {
    //   this.files2 = files;
      // this.files2.push({
      //   data: {
      //     name: 'Documents',
      //     size: '100kb',
      //     type: 'Link'
      //   }
      // });
    // });

    this.cols = [
      { field: 'name', header: 'SUBJECTS' },
      { field: 'questions', header: 'TOTAL Q' },
      { field: 'correct', header: 'CORRECT' },
      { field: 'incorrect', header: 'INCORRECT' },
      { field: 'omitted', header: 'OMITTED' }
    ];

    this.cols1 = [
      { field: 'name', header: 'SYSTEMS' },
      { field: 'questions', header: 'TOTAL Q' },
      { field: 'correct', header: 'CORRECT' },
      { field: 'incorrect', header: 'INCORRECT' },
      { field: 'omitted', header: 'OMITTED' }
    ];

    if(localStorage.getItem("showsubjectdetails")){
      this.showTestDetails = JSON.parse(localStorage.getItem("showsubjectdetails"))
    }else{
      this.showTestDetails = true;
    }

    if(localStorage.getItem('Id') != '-1'){
    if(this.showTestDetails){
    await this.getSubjectTabledata();
    await this.getSystemTableData();
    }
  }else{
    this.showDemoData();
  }
    // @ts-ignore
    // await this.subscriptions.push(
    //   this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
    //     if(res.status){
    //     }else{
    //       this.errorHandler.AutoLogout();
    //     }
    //   },
    //     error => {
    //       this.errorHandler.handleError(error);
    //     })
    // );
  }


  showDemoData(){
    this.files2 = demo_variables.getTestAnalysisBySubject;
    this.files1 = demo_variables.getTestAnalysisBySubject;
    this.files3 = demo_variables.getTestAnalysisBySystem;
    this.files4 = demo_variables.getTestAnalysisBySystem;
  }



  value = 0;

  getSubjectTabledata(){

    this.value = 0;
    var num = 0;
    let interval = setInterval(() => {
      this.value = this.value + 10 + num++;
      if (this.value >= 100) {
          this.value = 100;
          clearInterval(interval);
      }
  }, 1400);


    this.loader = true
    this.quizId = localStorage?.getItem("QuizId")
    
    this.subscriptions.push(
      this.courseService.getTestAnalysisbySubject(this.quizId).subscribe((res: any) => {
        this.value = 100;
        this.files1 = res.data.data;
        this.files2 = res.data.data;
        localStorage.setItem('getTestAnalysisbySubject', JSON.stringify(this.files2))
        this.loader = false
      },
        error => {
          
        this.loader = false
          this.errorHandler.handleError(error);
        })
    );
  }

  getSystemTableData(){
    this.loader = true;
    this.quizId = localStorage?.getItem("QuizId")
    this.subscriptions.push(
      this.courseService.getTestAnalysisbySystem(this.quizId).subscribe((res: any) => {
        this.files3 = res.data.data;
        this.files4 = res.data.data;
        localStorage.setItem('getTestAnalysisbySystem', JSON.stringify(this.files3))
        this.loader = false;
      },
        error => {
          
        this.loader = false
          this.errorHandler.handleError(error);
        })
    );
  }
  
  }


