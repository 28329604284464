<!--<div class="col-sm-12 top mb-5">-->
<!--  <div class="text-center">-->
<!--    <img-->
<!--      class="topimg mt-3"-->
<!--      src="../../assets/images/logo.png"-->
<!--      alt=""-->
<!--      srcset=""-->
<!--    />-->
<!--  </div>-->
<!--  <h3 class="logintxt text-light">dashboard</h3>-->
<!--&lt;!&ndash;  <button class="btn logoutbtn" (click)="logout()">Logout</button>&ndash;&gt;-->
<!--</div>-->

<!-- <div class="col-sm-12">
      <div class="col-12 offset-0 col-sm-12 offset-sm-0 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div style="display: block; width: 100%;" >
            <canvas baseChart
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType">
            </canvas>
          </div>
      </div>
      <div class="col-sm-3">
          <ul>
          </ul>
      </div>
    </div> -->

<div class="eup-ov-root p-3">
  <div class="eup-ov-parent">
    <div class="eup-ov-sub-parent">
      <div class="eup-ov-grid-wrapper">
        <div class="container">
        <!--          Cards wrapper  -->
          <div class="row">
            <div class="col-md-12">
              <div class="eup-ov-cards-heading ">
                <h5>Statistics Overview</h5>
              </div>
              <div class="eup-ov-cards-wrapper col-sm-12">
                <!-- card 1 -->
                <!-- <div class="eup-ov-single-card col-sm-6 col-md-4">
                  <div class="eup-green-card">
                    <p-card styleClass="eup-green-card">
                     
                      <div class="eup-ov-card-value text-center"> <p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{totalcourses}} </span></div>
                      <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Total Courses</span> </div>
                    </p-card>
                  </div>
                </div> -->
                <!-- card 2 -->
                <div class="eup-ov-single-card col-sm-5 offset-sm-1">
                  <div class="eup-red-card">
                    <p-card styleClass="eup-red-card">
                      <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{(enrolledcourses)?enrolledcourses:1}} </span> </div>
                      <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Enrolled Courses</span>  </div>
                    </p-card>
                  </div>
                </div>
                <!-- card 3 -->
                <div class="eup-ov-single-card col-sm-5 ">
                  <div class="eup-blue-card">
                    <p-card styleClass="eup-blue-card">
                      <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{completedcouses?completedcouses:0}} </span> </div>
                      <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Completed Courses</span>  </div>
                    </p-card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="course_animated_buttons mt-3" *ngIf="subscribedCourses && subscribedCourses.length>1">
            <!--  -->
            <!--  -->
            <div class="col-md-3 col-sm-3 col-xs-6" (click)="individualCourseData(subscribedCourses[0]?.courseID)"> <a class="btn btn-sm animated-button thar-three"> {{subscribedCourses[0]?.courseName}} </a> </div>
            <div class="col-md-3 col-sm-3 col-xs-6" (click)="individualCourseData(subscribedCourses[1]?.courseID)"> <a class="btn btn-sm animated-button thar-four"> {{subscribedCourses[1]?.courseName}} </a> </div>
        </div>
        <!--          Charts wrapper    -->
          <div class="row eup-ov-charts-wrapper">
            <div class="col-md-10  col-lg-6 eup-ov-left-chart mt-2 mb-2">
              
              <div class="eup-ov-chart eup_left_chart">

                <div class="row ">
                  <div class="col-sm-12 ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
                    <h5>QBank Usage</h5> 
                  </div>
                  <div class="col-md-6 ">
                    <div class="eup-ov-data-chart">
                      <canvas
                        baseChart
                        [data]="pieChartData1"
                        [colors]="chartColors"
                        [labels]="pieChartLabels1"
                        [chartType]="pieChartType"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins"
                        [legend]="pieChartLegend"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="eup-ov-data-chart-pointers eup_left_pointers">
                      <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels1;let i = index;">
                        <div class="chart_headings">
                        <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
                        <p class="chart_labels">  {{label.label}}</p>
                      </div>
                      <div class="chart_number">
                        <div class="number {{pointersColorClasses[i]}}">
                         {{label.value}}  
                        </div>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-md-10 col-lg-6 eup-ov-right-chart mt-2 mb-2 ">
              <div class="eup-ov-chart pb-5">
                <div class="row ">
                  <!-- <div class="col-md-6 ">
                    <div class="eup-ov-data-chart">
                      <canvas
                        baseChart
                        [data]="pieChartData"
                        [colors]="chartColors"
                        [labels]="pieChartLabels"
                        [chartType]="pieChartType"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins"
                        [legend]="pieChartLegend"
                      >
                      </canvas>
                    </div>
                  </div> -->
                  <div class="col-md-12 ">
                    <div class="eup-ov-data-chart-pointers">
                      <div class="ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
                        <h5> Your Score </h5> 
                      </div>
                      <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels;let i = index;">
                        <div class="chart_headings">
                          <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
                          <p class="chart_labels">{{label.label}}</p>
                        </div>
                        <div class="chart_number">
                          <div class="number {{pointersColorClasses[i]}}">
                              {{label.value}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- THE KNOB CHARTS WILL COME HERE -->
          <div class="knob_chart_parent">
            <div class="knob_chart_wrapper">
              <div class="left_chart"> 
                <div class="eup-ov-cards-heading ">
                  <h5>Total Correct</h5>
                </div>
                <div class="left_chart_wrapper">
                  <p-knob [(ngModel)]="totalCorrectPercentage" [strokeWidth]="7" [readonly]="true" valueTemplate="{value}%" [size]="170" valueColor="#61ce87" rangeColor="#c9c9c9"></p-knob>
                </div>   
              </div>
              <div class="right_chart"> 
                <div class="eup-ov-cards-heading ">
                  <h5>Total Used</h5>
                </div>
                <div class="right_chart_wrapper">
                  <p-knob [(ngModel)]="totalUsedPercentage" [strokeWidth]="7" [readonly]="true" valueTemplate="{value}%" [size]="170" valueColor="#389cf2" rangeColor="#c9c9c9"></p-knob>
                </div>
              </div>
            </div>
          </div>
          
        <!--        Table wrapper   -->

          <div class="row">
            <div class="col-sm-12  mb-5 eup-ov-courses-table-wrapper">
              <div
                style="
                  /*width: 85%;*/
                  margin-left: auto;
                  margin-right: auto;
                  border-radius: 8px;
                  border: #389cf2 solid;
                "
              >
                <div
                  class="text-light pt-3 pb-3"
                  style="
                    display: flex;
                    flex-direction: row;
                    align-content: space-between;
                    background-color: #389cf2;
                    border: 0px;
                    max-height: 70px;
                  "
                >
                  <div class="ml-4 text-light">
                    <i class="pi pi-sort-amount-down" style="font-size: 1rem;"></i>
                    <span style="font-size: 12px; margin-left: 5px"> SORT </span>
                  </div>
                  <div class="ml-auto mr-4 text-light">
                    <input
                      style="border: 0px; border-radius: 8px"
                      type="text"
                      class="mr-3"
                      placeholder="Search Course"
                      id="searchcoursebar"
                      [(ngModel)]="searchText"
                      (keyup)="searchCourse()"
                    />
                    <i class="pi pi-search" style="font-size: 1rem;" ></i>
                  </div>
                </div>

                <!-- 1 Row -->
                <div class="pb-2 pt-2" style="border-bottom: #9b9595 1px solid;" *ngFor="let course of smallfinalarray; index as i" >
                  <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">
      <!-- <span style="width: 30%; color: #9b9595;">{{course.ID}}
    </span> -->
                    <div class="progress" style="width: 40%; margin-left: auto;" *ngIf="!contentLoader">
                      <div  class="progress-bar" role="progressbar" style="width: {{course.value}}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">
      <span style="width: 30%;">
         <p-skeleton styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> 
        <span *ngIf="contentLoader == false">{{course.post_title}}</span>
    </span>
                    <div style="width: 40%; margin-left: auto;">
                      <div *ngIf="contentLoader">
                        <p-skeleton styleClass="p-mb-2"></p-skeleton>
                       <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton>
                      </div>
                      <!-- <div *ngIf="!contentLoader"> {{(course.value).toFixed(2)}}% Complete</div> -->
                      <div *ngIf="!contentLoader"> {{(course.value).toFixed(2)}}% Complete</div>
                    </div>
                  </div>
                </div>
                

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!--<div-->
<!--  class="pl-3"-->
<!--  style="-->
<!--    display: flex;-->
<!--    flex-direction: row;-->
<!--    flex-wrap: wrap;-->
<!--    align-content: space-between;-->
<!--  "-->
<!--&gt;-->
<!--  <div class="col-12 col-sm-6 col-md-5 col-lg-5 mt-sm-5 offset-md-1">-->
<!--    <div style="display: flex; flex-direction: row; flex-wrap: wrap">-->
<!--      <div style="width: 52px">-->
<!--        <span-->
<!--          style="-->
<!--            min-width: 8px;-->
<!--            max-height: 4px;-->
<!--            background: #007991;-->
<!--            color: #007991;-->
<!--          "-->
<!--          >caque</span-->
<!--        >-->
<!--      </div>-->
<!--      <div style="width: 250px">-->
<!--        <span class="datahead" style="color: #212529 ;">-->
<!--          Incorrectly Answered Questions-->
<!--        </span>-->
<!--      </div>-->
<!--      <div style="width: 12px">-->
<!--        <span style="color: #9b9595"> {{ pieChartData[1] }} </span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <br />-->
<!--    <div style="display: flex; flex-direction: row; flex-wrap: wrap">-->
<!--      <div style="width: 52px">-->
<!--        <span-->
<!--          style="-->
<!--            min-width: 8px;-->
<!--            max-height: 4px;-->
<!--            background: #61ce70;-->
<!--            color: #61ce70;-->
<!--          "-->
<!--          >caque</span-->
<!--        >-->
<!--      </div>-->
<!--      <div style="width: 250px">-->
<!--        <span class="datahead" style="color: #212529 ;">-->
<!--          Correctly Answered Questions-->
<!--        </span>-->
<!--      </div>-->
<!--      <div style="width: 12px">-->
<!--        <span style="color: #9b9595"> {{ pieChartData[0] }} </span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <br />-->
<!--    <div style="display: flex; flex-direction: row; flex-wrap: wrap">-->
<!--      <div style="width: 52px">-->
<!--        <span style="min-width: 8px; max-height: 4px; color: #fff"></span>-->
<!--      </div>-->
<!--      <div style="width: 250px">-->
<!--        <span class="datahead" style="color: #212529 ;"> Total Questions </span>-->
<!--      </div>-->
<!--      <div style="width: 12px">-->
<!--        <span style="color: #9b9595"> {{ pieChartData[0] }} </span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <br />-->
<!--    <div style="display: flex; flex-direction: row; flex-wrap: wrap">-->
<!--      <div style="width: 52px">-->
<!--        <span style="min-width: 8px; max-height: 4px; color: #fff"></span>-->
<!--      </div>-->
<!--      <div style="width: 250px">-->
<!--        <span class="datahead" style="color: #212529 ;"> Used Questions </span>-->
<!--      </div>-->
<!--      <div style="width: 12px">-->
<!--        <span style="color: #9b9595"> {{ pieChartData[1] }} </span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="col-12 col-sm-6 col-md-6 col-lg-4 offset-lg-2">-->
<!--    <div>-->
<!--      <canvas-->
<!--        baseChart-->
<!--        [data]="pieChartData"-->
<!--        [colors]="chartColors"-->
<!--        [labels]="pieChartLabels"-->
<!--        [chartType]="pieChartType"-->
<!--        [options]="pieChartOptions"-->
<!--        [plugins]="pieChartPlugins"-->
<!--        [legend]="pieChartLegend"-->
<!--      >-->
<!--      </canvas>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!-- Table starts Here -->
<!--<div class="col-sm-12  mb-5">-->
<!--  <div-->
<!--    style="-->
<!--      width: 85%;-->
<!--      margin-left: auto;-->
<!--      margin-right: auto;-->
<!--      border-radius: 8px;-->
<!--      border: #389cf2 solid;-->
<!--    "-->
<!--  >-->
<!--    <div-->
<!--      class="text-light pt-3 pb-3"-->
<!--      style="-->
<!--        display: flex;-->
<!--        flex-direction: row;-->
<!--        align-content: space-between;-->
<!--        background-color: #389cf2;-->
<!--        border: 0px;-->
<!--      "-->
<!--    >-->
<!--      <div class="ml-4 text-light">-->
<!--        <fa-icon [icon]="sortAmount"></fa-icon>-->
<!--        <span style="font-size: 12px; margin-left: 5px"> SORT </span>-->
<!--      </div>-->
<!--      <div class="ml-auto mr-4 text-light">-->
<!--        <input-->
<!--          style="border: 0px; border-radius: 8px"-->
<!--          type="text"-->
<!--          class="mr-3"-->
<!--          placeholder="  Search Course"-->
<!--        />-->
<!--        <fa-icon [icon]="search"></fa-icon>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; 1 Row &ndash;&gt;-->
<!--    <div class="pb-2 pt-2" style="border-bottom: #9b9595 1px solid;">-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%; color: #9b9595;">Semester 8-->
<!--    </span>-->
<!--    <div class="progress" style="width: 40%; margin-left: auto;">-->
<!--      <div style="background-color: #007991;" class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--    </div>-->
<!--    </div>-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%;">Mobile Application Development-->
<!--    </span>-->
<!--    <div style="width: 40%; margin-left: auto;">-->
<!--      <div> 25% Complete</div>-->
<!--    </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash; Next Row &ndash;&gt;-->
<!--  <div class="pb-2 pt-2" style="border-bottom: #9b9595 1px solid;">-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%; color: #9b9595;">Semester 8-->
<!--    </span>-->
<!--    <div class="progress" style="width: 40%; margin-left: auto;">-->
<!--      <div style="background-color: #007991;" class="progress-bar" role="progressbar" style="width: 35%" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--    </div>-->
<!--    </div>-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%;">Mobile Application Development-->
<!--    </span>-->
<!--    <div style="width: 40%; margin-left: auto;">-->
<!--      <div> 35% Complete</div>-->
<!--    </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash; 3rd Row &ndash;&gt;-->
<!--  <div class="pb-2 pt-2" style="border-bottom: #9b9595 1px solid;">-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%; color: #9b9595;">Semester 8-->
<!--    </span>-->
<!--    <div class="progress" style="width: 40%; margin-left: auto;">-->
<!--      <div style="background-color: #007991;" class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--    </div>-->
<!--    </div>-->
<!--    <div class="col-sm-12" style="display: flex; flex-direction: row; align-content: space-between; flex-wrap: wrap;">-->
<!--      <span style="width: 30%;">Mobile Application Development-->
<!--    </span>-->
<!--    <div style="width: 40%; margin-left: auto;">-->
<!--      <div> 75% Complete</div>-->
<!--    </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  </div>-->
<!--</div>-->
