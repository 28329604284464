import { Injectable } from '@angular/core';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";
import {HttpClient} from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = BASE_URL;

  private url = BASE_URL + ':8000';
  // private url = BASE_URL;
  public socket;
  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public currentUser: any;
  messages: any = []

  shareSocket;
  public sharemessage$: BehaviorSubject<string> = new BehaviorSubject('');

  getUserId(){
    return localStorage.getItem('Id');
  }

  constructor(private httpClient: HttpClient) {}
  
  // Used for angular internal connection //

  shareData() {
    this.shareSocket = io(this.url, {
      transports: ['websocket'],
      secure: true,
      auth: {
        userId: -1
      }
    });
  }

  closeShareData() {
    this.shareSocket.disconnect()
    this.shareSocket = null
  }

  // sendShareMessage = ({message, type, roomName, userId, data}, cb) => {
  //   if (this.shareSocket) {
  //     this.shareSocket.emit('sharemessage', { message, type, roomName, userId: userId }, cb);
  //   }
  // }

  getShareMessage = () => {
    this.shareSocket.on('sharemessage', (data: any) => {
      this.sharemessage$.next(data);
    });
    return this.sharemessage$.asObservable()
  }

  // --------------------------------- //

  startChat(userId: any) {
    this.socket = io(this.url, {
      transports: ['websocket'],
      secure: true,
      auth: {
        userId: userId
      }
    });
  }

  closeChat() {
    this.socket.disconnect()
    this.socket = null
  }

  sendMessage = ({message, type, roomName, userId, data}, cb) => {
    if (this.socket) {
      this.updateUserChat(data).subscribe((res: any) => {
        this.socket.emit('message', { message, type, roomName, userId: userId }, cb);
        roomName = -1
        this.shareSocket.emit('sharemessage', { message, type, roomName, userId: userId }, cb);
      })
    }
  }

  sendAdminMessage = ({message, type, roomName, userId, name, data}, cb) => {
    if (this.socket) {
      this.updateUserChat(data).subscribe((res: any) => {
        this.socket.emit('message', { message, type, roomName, userId: userId, name: name }, cb);
        roomName = -1
        this.shareSocket.emit('sharemessage', { message, type, roomName, userId: userId }, cb);
      })
    }
  }

  getNewMessage = () => {
    this.message$ = new BehaviorSubject('')
    this.socket.on('message', (data: any) => {
      this.currentUser = data.userId
      sessionStorage.setItem('Sender', this.currentUser)
      this.message$.next(data);
    });

    return this.message$.asObservable();
  };

  getUserChat(){
    return this.httpClient.post(this.baseUrl + '/getUserChat',{data: {userId: this.getUserId()}},
    {headers: createAuthorizationHeader()});
  }

  updateUserChat(data: any){
    return this.httpClient.post(this.baseUrl + '/updateUserChat',{data: data},
    {headers: createAuthorizationHeader()});
  }

  getUserNotebook(){
    return this.httpClient.post(this.baseUrl + '/getUserNotebook',{data: {userId: this.getUserId()}},
    {headers: createAuthorizationHeader()});
  }

  addUserNotebook(data: any){
    return this.httpClient.post(this.baseUrl + '/addUserNotebook',{data: data},
    {headers: createAuthorizationHeader()});
  }

  deleteUserNotebook(data: any){
    return this.httpClient.post(this.baseUrl + '/deleteUserNotebook',{data: data},
    {headers: createAuthorizationHeader()});
  }

  swapUserNotebook(data: any){
    return this.httpClient.post(this.baseUrl + '/swapUserNotebook',{data: data},
    {headers: createAuthorizationHeader()});
  }

  uploadChatDocument(data: any){
    return this.httpClient.post(this.baseUrl + '/uploadChatDocument', data,
    {headers: createAuthorizationHeader()})
  }

  getUpdatedChats(){
    return this.httpClient.get(this.baseUrl + '/getUpdatedChats',
    {headers: createAuthorizationHeader()})
  }

  updateUnreadMessages(data: any){
    return this.httpClient.post(this.baseUrl + '/updateUnreadMessages', {data: data},
    {headers: createAuthorizationHeader()})
  }

  generateAnswerChatbot(data: any){
    return this.httpClient.post(this.baseUrl + '/new/generateAnswerChatbot', {data: data},
    {headers: createAuthorizationHeader()})
  }

  getSingleUserChat(userId: any){
    return this.httpClient.post(this.baseUrl + '/getUserChat',{data: {userId: userId}},
    {headers: createAuthorizationHeader()});
  }
}
