import { Injectable } from '@angular/core';
import {BASE_URL, createAuthorizationHeader} from "../../models/globals";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  baseUrl = BASE_URL;
  constructor(private httpClient: HttpClient) { }

  
  getUserId(){
    return localStorage.getItem('Id');
  }

  getUserIdForReporting(){
    if(localStorage.getItem("userIDForReport") ){
      return localStorage.getItem("userIDForReport")
    }else{
      return localStorage.getItem('Id');
    }
  }

  getCourseDataCount(){
    return this.httpClient.get(this.baseUrl + `/getCourseDataCounts?userId=${this.getUserId()}`,
      {headers: createAuthorizationHeader()});
  }

  getAllCourses(){
    return this.httpClient.get(this.baseUrl + `/getAllCourses?userId=${this.getUserId()}`,
      {headers: createAuthorizationHeader()});
  }

  getCourseDetails(courseId: any){
    return this.httpClient.get(this.baseUrl + `/getCourseDetails?courseId=${courseId}&userId=${this.getUserId()}`,
      {headers: createAuthorizationHeader()});
  }

  getLessonDetails(courseId: any, lessonId: any, userId: any, topicId: any, username:any){
    return this.httpClient.post(this.baseUrl + '/getLessonDetails', {courseId,lessonId, userId, topicId, username},
    {headers: createAuthorizationHeader()});
  }

  getTopicDetails(data: any){
    return this.httpClient.post(this.baseUrl + `/getTopicDetails?topicId`, {data},
    {headers: createAuthorizationHeader()});
  }

  completeTopic(Ids: any){
    return this.httpClient.post(this.baseUrl + '/completeTopic', {Ids},
    {headers: createAuthorizationHeader()});
  }

  resumeCourse(data: any){
    return this.httpClient.post(this.baseUrl + '/resumeCourse', {data},
    {headers: createAuthorizationHeader()});
  }

  getNumbers(quizId: any, courseId = -1){
    console.log(courseId);
    return this.httpClient.post(this.baseUrl + '/getCountAllQuestions',{quiz:{userId: this.getUserId(), quizId: quizId} , courseId},
    {headers: createAuthorizationHeader()});
  }

  getTestAnalysisbySubject(quizId: any){
    console.log(quizId)
    return this.httpClient.post(this.baseUrl + '/getTestAnalysisBySubjects',{quiz:{userId: this.getUserId(), quizId: quizId}},
    {headers: createAuthorizationHeader()});
  }

  getTestAnalysisbySystem(quizId: any){
    return this.httpClient.post(this.baseUrl + '/getTestAnalysisBySystems',{quiz:{userId: this.getUserId(), quizId: quizId}},
    {headers: createAuthorizationHeader()});
  }

  getReviewsNotesOfQuiz(quizId: any){
    return this.httpClient.post(this.baseUrl + '/getNotesandfeedbacks',{quiz:{userId: this.getUserId(), quizId: quizId}},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceBySubjects(courseId: any){
    return this.httpClient.post(this.baseUrl + '/getPerformanceBySubjects',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceBySystems(courseId: any){
    return this.httpClient.post(this.baseUrl + '/getPerformanceBySystems',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceByTopics(courseId: any){
    return this.httpClient.post(this.baseUrl + '/getPerformanceByTopics',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceStats(courseId){
    return this.httpClient.post(this.baseUrl + '/getPerformanceStats',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  studyPartnerRequest(data: any){
    return this.httpClient.post(this.baseUrl + '/raiseStudyPartnerRequest',{data},
    {headers: createAuthorizationHeader()});
  }
  deleteStudyPartnerRequest(data: any){
    return this.httpClient.post(this.baseUrl + '/deleteStudyPartnerRequest',{data},
    {headers: createAuthorizationHeader()});
  }
  getStudyPartnerRequest(){
    return this.httpClient.get(this.baseUrl + '/getStudyPartnerRequest',
    {headers: createAuthorizationHeader()});
  }
  getCourseQuizStatus(quizId: any){
    return this.httpClient.post(this.baseUrl + '/getQuizStatus',{quizId, userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }

  completeCourseQuiz(data: any){
    return this.httpClient.post(this.baseUrl + '/completeCourseQuiz',{data: data},
    {headers: createAuthorizationHeader()});
  }

  resetData(courseId: any){
    return this.httpClient.post(this.baseUrl + '/resetContent',{userId: this.getUserId(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  checkStudyPartner(){
    return this.httpClient.post(this.baseUrl + '/verifyStudyPartnerRequest',{userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }

  resetFlashcards(){
    return this.httpClient.post(this.baseUrl + '/resetFlashcards',{userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }

  resetNotebook(){
    return this.httpClient.post(this.baseUrl + '/resetNotebook',{userId: this.getUserId()},
    {headers: createAuthorizationHeader()});
  }

  getPerformanceCategoriesGraph(category: any){
    return this.httpClient.post(this.baseUrl + '/getPerformanceCategoriesGraph',{data: {userId: this.getUserId(), category: category}},
    {headers: createAuthorizationHeader()});
  }

  getOverallPerformanceStats(courseId){
    return this.httpClient.post(this.baseUrl + '/getOverallPerformanceStats',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }

  getPeerPerformanceStats(courseId){
    return this.httpClient.post(this.baseUrl + '/getPeerPerformanceStats',{userId: this.getUserIdForReporting(), courseId: courseId},
    {headers: createAuthorizationHeader()});
  }
}

