import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flashcard-module',
  templateUrl: './flashcard-module.component.html',
  styleUrls: ['./flashcard-module.component.scss']
})
export class FlashcardModuleComponent implements OnInit {

  module = 'browse'
  selectedCourse = -1;
  
  constructor() { }

  ngOnInit(): void {
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Flashcards';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
  }

}
