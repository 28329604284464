import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FlashcardService } from 'src/app/shared/services/flashcard.service';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.scss'],
  providers: [MessageService]
})
export class StudyComponent implements OnInit {

  keyword = ""
  decks: any = []
  tempDecks: any = []

  selectedDeck = null
  displayFlashcards = false
  isFullScreen = false;
  displayImg = false;
  showImage = '';

  displayCardOptions = false

  displaySettings = false
  activeIndex = 0
  options = ['New Cards', 'Reviews', 'General']

  deckActiveIndex = 0;
  cardActiveIndex = 0;
  position = 'Front'

  values = [
    { 
      "value": '<1m',
      "text": "Again"
    },
    { 
      "value": '1d',
      "text": "Hard"
    },
    { 
      "value": '5d',
      "text": "Easy"
    },
    { 
      "value": '15d',
      "text": "Very Easy"
    }
  ]

  solved = false
  flashcardView = 'Single Side View'
  timer = 0
  interval: any;
  totalTime = 0
  viewStats = false;

  totalTimeSpent = null
  totalStudyCards = 0
  totalStudyMins = 0
  avgStudyMins = 0
  totalFlashcards = 0
  totalDueCards = 0
  totalCardReviews = 0
  avgReviews = 0
  avgTime = 0
  avgAnswer = 0
  avgMins = 0

  maxNewCards = 1
  maxReviewCards = 1
  showTimer = false
  usedNewCards = 0
  usedReviewCards = 0
  usedNewDate = null
  usedReviewDate = null
  order = 'Show new cards in order added'
  allOrders = ['Show new cards in order added', 'Show new cards in random order']

  settingDeck: any;

  displayTimer: any;
  
  errorMessage = ""

  isDemo = false;
  loader = false;

  @Input() courseId: any = null;
  @Input() aiDecks: any = false;
  @Input() aiDecksGenerated: any = false;
  isReadyDecks = false;

  constructor(private flashcardService: FlashcardService, private confirmationService: ConfirmationService, private messageService: MessageService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (localStorage.getItem('Id') == '-1') {
      this.isDemo = true
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isDemo) {
      this.decks = [
        {
          'CardReviews': 0,
          'Color': '#62B5FC',
          'DeckID': 1,
          'LastUsed': null,
          'Learning': 0,
          'NewCards': 2,
          'Title': 'Demo Notes',
          'ToReview': 0,
          'TotalTime': 0,
          'UserID': '-1',
          'Settings': {
            "maxNewCards": 200,
            "maxReviewCards": 200,
            "usedNewCards": 0,
            "usedReviewCards": 0,
            "usedNewDate": '00-00-0000 00:00:00',
            "usedReviewDate": '00-00-0000 00:00:00',
            "orderCards": 'Show new cards in order added',
            "showTimer": false,
          },
          'Flashcards': [
            {
              'FlashcardID': 1,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['medicine', 'demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            },
            {
              'FlashcardID': 2,
              'Question': 'Free trial is for testing, saving text is available only for premium users',
              'Answer': '<p>Free trial is for testing, saving text is available only for premium users</p>',
              'DeckID': 1,
              'BuryDate': null,
              'Difficulty': null,
              'IsSuspended': 0,
              'Learning': 0,
              'Mastered': 0,
              'NextStudyDate': null,
              'QuestionID': null,
              'Solved': null,
              'Tags': ['medicine', 'demo'],
              'TimeSpent': null,
              'UpdatedDate': null,
              'usedCard': null
            }
          ]
        }
      ]
    } else {
      let isAIDecks = false;
      if (changes['aiDecksGenerated']) {
        if (changes['aiDecksGenerated'].currentValue) {
          this.getAIDecks();
        }
      }
      if (changes['courseId'] && this.aiDecks) {
        isAIDecks = true;
        this.isReadyDecks = true;
        this.getAIDecks();
      } else {
        if (changes['courseId'] && !isAIDecks) {
          if (changes['courseId'].currentValue) {
            if (this.courseId !== -1) {
              this.isReadyDecks = true;
              this.getReadyDecks()
            } else {
              this.getUserDeck()
            }
          }
        }
      }
    }
  }

  getAIDecks() {
    let data = {
      "userId": localStorage.getItem('Id'),
      "courseId": this.courseId
    }
    this.loader = true
    this.flashcardService.getAIDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {

        if (decks.Settings) {
          decks.Settings = JSON.parse(decks.Settings)
        }
        else {
          decks.Settings = {
            "maxNewCards": 200,
            "maxReviewCards": 200,
            "usedNewCards": 0,
            "usedReviewCards": 0,
            "usedNewDate": '00-00-0000 00:00:00',
            "usedReviewDate": '00-00-0000 00:00:00',
            "orderCards": 'Show new cards in order added',
            "showTimer": false,
          }
        }
        this.maxNewCards = decks.Settings.maxNewCards
        this.maxReviewCards = decks.Settings.maxReviewCards
        this.showTimer = decks.Settings.showTimer
        this.usedNewCards = decks.Settings.usedNewCards
        this.usedReviewCards = decks.Settings.usedReviewCards
        this.usedNewDate = decks.Settings.usedNewDate
        this.usedReviewDate = decks.Settings.usedReviewDate
        this.order = decks.Settings.orderCards

        // Updating New Cards and Review Settings If Current Date is New Date //

        if (decks.Settings.usedNewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedNewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedNewCards = 0
          decks.Settings.usedNewDate = '00-00-0000 00:00:00'
        }

        if (decks.Settings.usedReviewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedReviewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedReviewCards = 0
          decks.Settings.usedReviewDate = '00-00-0000 00:00:00'
        }

        this.decks[index].Flashcards.forEach((flashcards) => {
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })

        decks.Flashcards = decks.Flashcards.filter((item: any) => item.Mastered == 0 && item.IsSuspended == 0 && (!item.BuryDate || item.BuryDate.substring(0, 10) <= new Date().toISOString().substring(0, 10)))

        let tempFlashcards: any = [], newCount = decks.Settings.usedNewCards, reviewCount = decks.Settings.usedReviewCards

        decks.NewCards = 0
        decks.ToReview = 0

        // New Cards and Review Settings Implementation //

        decks.Flashcards.forEach(element => {
          if (element.Difficulty) {
            if (element.ToReview == 1) {
              if (decks.Settings.usedReviewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedReviewDate == '00-00-0000 00:00:00') {
                if (this.maxReviewCards > reviewCount) {
                  decks.ToReview += 1
                  tempFlashcards.push(element)
                }
              }
              reviewCount++
            }
            else {
              tempFlashcards.push(element)
            }
          }
          if (!element.Difficulty || element.Difficulty == 'null') {
            if (decks.Settings.usedNewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedNewDate == '00-00-0000 00:00:00') {
              if (this.maxNewCards > newCount) {
                decks.NewCards += 1
                tempFlashcards.push(element)
              }
            }
            newCount++
          }
        });

        if (tempFlashcards.length > 0) {
          if (this.order == 'Show new cards in random order') {
            tempFlashcards.sort((a: any, b: any) => 0.5 - Math.random())
          }

          decks.Flashcards = []
          decks.Flashcards = [...decks.Flashcards, ...tempFlashcards]
        }
      })

      this.tempDecks = JSON.parse(JSON.stringify(this.decks))
    })
  }

  getReadyDecks() {
    let data = {
      "userId": localStorage.getItem('Id'),
      "courseId": this.courseId
    }
    this.loader = true
    this.flashcardService.getReadyDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {

        if (decks.Settings) {
          decks.Settings = JSON.parse(decks.Settings)
        }
        else {
          decks.Settings = {
            "maxNewCards": 200,
            "maxReviewCards": 200,
            "usedNewCards": 0,
            "usedReviewCards": 0,
            "usedNewDate": '00-00-0000 00:00:00',
            "usedReviewDate": '00-00-0000 00:00:00',
            "orderCards": 'Show new cards in order added',
            "showTimer": false,
          }
        }
        this.maxNewCards = decks.Settings.maxNewCards
        this.maxReviewCards = decks.Settings.maxReviewCards
        this.showTimer = decks.Settings.showTimer
        this.usedNewCards = decks.Settings.usedNewCards
        this.usedReviewCards = decks.Settings.usedReviewCards
        this.usedNewDate = decks.Settings.usedNewDate
        this.usedReviewDate = decks.Settings.usedReviewDate
        this.order = decks.Settings.orderCards

        // Updating New Cards and Review Settings If Current Date is New Date //

        if (decks.Settings.usedNewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedNewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedNewCards = 0
          decks.Settings.usedNewDate = '00-00-0000 00:00:00'
        }

        if (decks.Settings.usedReviewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedReviewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedReviewCards = 0
          decks.Settings.usedReviewDate = '00-00-0000 00:00:00'
        }

        this.decks[index].Flashcards.forEach((flashcards) => {
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })

        decks.Flashcards = decks.Flashcards.filter((item: any) => item.Mastered == 0 && item.IsSuspended == 0 && (!item.BuryDate || item.BuryDate.substring(0, 10) <= new Date().toISOString().substring(0, 10)))

        let tempFlashcards: any = [], newCount = decks.Settings.usedNewCards, reviewCount = decks.Settings.usedReviewCards

        decks.NewCards = 0
        decks.ToReview = 0

        // New Cards and Review Settings Implementation //

        decks.Flashcards.forEach(element => {
          if (element.Difficulty) {
            if (element.ToReview == 1) {
              if (decks.Settings.usedReviewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedReviewDate == '00-00-0000 00:00:00') {
                if (this.maxReviewCards > reviewCount) {
                  decks.ToReview += 1
                  tempFlashcards.push(element)
                }
              }
              reviewCount++
            }
            else {
              tempFlashcards.push(element)
            }
          }
          if (!element.Difficulty || element.Difficulty == 'null') {
            if (decks.Settings.usedNewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedNewDate == '00-00-0000 00:00:00') {
              if (this.maxNewCards > newCount) {
                decks.NewCards += 1
                tempFlashcards.push(element)
              }
            }
            newCount++
          }
        });

        if (tempFlashcards.length > 0) {
          if (this.order == 'Show new cards in random order') {
            tempFlashcards.sort((a: any, b: any) => 0.5 - Math.random())
          }

          decks.Flashcards = []
          decks.Flashcards = [...decks.Flashcards, ...tempFlashcards]
        }
      })

      this.tempDecks = JSON.parse(JSON.stringify(this.decks))
    })
  }

  getUpdatedStats() {
    if (this.isDemo) {
      this.totalTimeSpent = null
      this.totalStudyCards = 1
      this.totalStudyMins = 1
      this.avgStudyMins = 1
      this.totalFlashcards = 1
      this.totalDueCards = 1
      this.totalCardReviews = 1
      this.avgAnswer = 1
      this.avgMins = 1

      this.viewStats = true
    } else {
      this.totalTimeSpent = null
      this.totalStudyCards = 0
      this.totalStudyMins = 0
      this.avgStudyMins = 0
      this.totalFlashcards = 0
      this.totalDueCards = 0
      this.totalCardReviews = 0
      this.avgAnswer = 0
      this.avgMins = 0
      
      let date = new Date();
      date.setDate(date.getDate() + 1)

      this.loader = true;

      if (this.courseId !== -1) {
        let data = {
          "userId": localStorage.getItem('Id'),
          "courseId": this.courseId
        }
        if (this.aiDecks) {
          this.flashcardService.getAIDeck(data).subscribe((res: any) => {
            this.loader = false;
            if (res.data.decks.length > 0) {
              this.totalTimeSpent = res.data.totalTime
              this.totalCardReviews = res.data.totalReviews
              this.avgReviews = res.data.avgReviews
              this.avgTime = res.data.avgTime
              this.avgAnswer = res.data.avgAnswer
              if (this.avgAnswer > 0) {
                this.avgMins = parseInt((60 / this.avgAnswer).toFixed(0))
              }
        
              res.data.decks.forEach((decks, index) => {
                
                res.data.decks[index].Flashcards.forEach((flashcards) => {
                  if (flashcards.UpdatedDate) {
                    if (flashcards.UpdatedDate.substring(0, 10) == new Date().toISOString().substring(0, 10)) {
                      this.totalStudyCards += 1
                      this.totalStudyMins += flashcards.TimeSpent
                      this.totalFlashcards += 1
                    }
                  }
      
                  if (flashcards.NextStudyDate) {
                    if (flashcards.NextStudyDate.substring(0, 10) == date.toISOString().substring(0, 10)) {
                      this.totalDueCards += 1
                    }
                  }
                })
              })
      
              if (this.totalFlashcards > 0) {
                this.avgStudyMins = parseInt((this.totalStudyMins / this.totalFlashcards).toFixed(0))
              }
      
              this.totalStudyMins = Math.ceil(this.totalStudyMins / 60)
        
              if (parseInt(this.totalTimeSpent) > 0) {
                this.totalTimeSpent = this.toTime(this.totalTimeSpent)
              } 
              else {
                this.totalTimeSpent = null
              }
        
              this.avgTime = Math.ceil(this.avgTime / 60)
            }
      
            this.viewStats = true
          })
        } else {
          this.flashcardService.getReadyDeck(data).subscribe((res: any) => {
            this.loader = false;
            if (res.data.decks.length > 0) {
              this.totalTimeSpent = res.data.totalTime
              this.totalCardReviews = res.data.totalReviews
              this.avgReviews = res.data.avgReviews
              this.avgTime = res.data.avgTime
              this.avgAnswer = res.data.avgAnswer
              if (this.avgAnswer > 0) {
                this.avgMins = parseInt((60 / this.avgAnswer).toFixed(0))
              }
        
              res.data.decks.forEach((decks, index) => {
                
                res.data.decks[index].Flashcards.forEach((flashcards) => {
                  if (flashcards.UpdatedDate) {
                    if (flashcards.UpdatedDate.substring(0, 10) == new Date().toISOString().substring(0, 10)) {
                      this.totalStudyCards += 1
                      this.totalStudyMins += flashcards.TimeSpent
                      this.totalFlashcards += 1
                    }
                  }
      
                  if (flashcards.NextStudyDate) {
                    if (flashcards.NextStudyDate.substring(0, 10) == date.toISOString().substring(0, 10)) {
                      this.totalDueCards += 1
                    }
                  }
                })
              })
      
              if (this.totalFlashcards > 0) {
                this.avgStudyMins = parseInt((this.totalStudyMins / this.totalFlashcards).toFixed(0))
              }
      
              this.totalStudyMins = Math.ceil(this.totalStudyMins / 60)
        
              if (parseInt(this.totalTimeSpent) > 0) {
                this.totalTimeSpent = this.toTime(this.totalTimeSpent)
              } 
              else {
                this.totalTimeSpent = null
              }
        
              this.avgTime = Math.ceil(this.avgTime / 60)
            }
      
            this.viewStats = true
          })
        }
      } else {
        let data = {
          "userId": localStorage.getItem('Id')
        }
        this.flashcardService.getUserDeck(data).subscribe((res: any) => {
          this.loader = false;
          if (res.data.decks.length > 0) {
            this.totalTimeSpent = res.data.totalTime
            this.totalCardReviews = res.data.totalReviews
            this.avgReviews = res.data.avgReviews
            this.avgTime = res.data.avgTime
            this.avgAnswer = res.data.avgAnswer
            if (this.avgAnswer > 0) {
              this.avgMins = parseInt((60 / this.avgAnswer).toFixed(0))
            }
      
            res.data.decks.forEach((decks, index) => {
              
              res.data.decks[index].Flashcards.forEach((flashcards) => {
                if (flashcards.UpdatedDate) {
                  if (flashcards.UpdatedDate.substring(0, 10) == new Date().toISOString().substring(0, 10)) {
                    this.totalStudyCards += 1
                    this.totalStudyMins += flashcards.TimeSpent
                    this.totalFlashcards += 1
                  }
                }
    
                if (flashcards.NextStudyDate) {
                  if (flashcards.NextStudyDate.substring(0, 10) == date.toISOString().substring(0, 10)) {
                    this.totalDueCards += 1
                  }
                }
              })
            })
    
            if (this.totalFlashcards > 0) {
              this.avgStudyMins = parseInt((this.totalStudyMins / this.totalFlashcards).toFixed(0))
            }
    
            this.totalStudyMins = Math.ceil(this.totalStudyMins / 60)
      
            if (parseInt(this.totalTimeSpent) > 0) {
              this.totalTimeSpent = this.toTime(this.totalTimeSpent)
            } 
            else {
              this.totalTimeSpent = null
            }
      
            this.avgTime = Math.ceil(this.avgTime / 60)
          }
    
          this.viewStats = true
        })
      }
    }
  }

  getUserDeck() {
    let date = new Date();
    date.setDate(date.getDate() + 1)

    let data = {
      "userId": localStorage.getItem('Id')
    }
    this.loader = true
    this.flashcardService.getUserDeck(data).subscribe((res: any) => {
      this.loader = false
      this.decks = res.data.decks
      this.decks.forEach((decks, index) => {

        if (decks.Settings) {
          decks.Settings = JSON.parse(decks.Settings)
        }
        else {
          decks.Settings = {
            "maxNewCards": 200,
            "maxReviewCards": 200,
            "usedNewCards": 0,
            "usedReviewCards": 0,
            "usedNewDate": '00-00-0000 00:00:00',
            "usedReviewDate": '00-00-0000 00:00:00',
            "orderCards": 'Show new cards in order added',
            "showTimer": false,
          }
        }
        this.maxNewCards = decks.Settings.maxNewCards
        this.maxReviewCards = decks.Settings.maxReviewCards
        this.showTimer = decks.Settings.showTimer
        this.usedNewCards = decks.Settings.usedNewCards
        this.usedReviewCards = decks.Settings.usedReviewCards
        this.usedNewDate = decks.Settings.usedNewDate
        this.usedReviewDate = decks.Settings.usedReviewDate
        this.order = decks.Settings.orderCards

        // Updating New Cards and Review Settings If Current Date is New Date //

        if (decks.Settings.usedNewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedNewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedNewCards = 0
          decks.Settings.usedNewDate = '00-00-0000 00:00:00'
        }

        if (decks.Settings.usedReviewDate.substring(0, 10) != new Date().toISOString().substring(0, 10) && decks.Settings.usedReviewDate != '00-00-0000 00:00:00') {
          decks.Settings.usedReviewCards = 0
          decks.Settings.usedReviewDate = '00-00-0000 00:00:00'
        }
        
        this.decks[index].Flashcards.forEach((flashcards) => {
          flashcards.Tags = JSON.parse(flashcards.Tags)
          flashcards.Question = this.sanitizer.bypassSecurityTrustHtml(flashcards.Question)
        })

        decks.Flashcards = decks.Flashcards.filter((item: any) => item.Mastered == 0 && item.IsSuspended == 0 && (!item.BuryDate || item.BuryDate.substring(0, 10) <= new Date().toISOString().substring(0, 10)))

        let tempFlashcards: any = [], newCount = decks.Settings.usedNewCards, reviewCount = decks.Settings.usedReviewCards

        decks.NewCards = 0
        decks.ToReview = 0

        // New Cards and Review Settings Implementation //

        decks.Flashcards.forEach(element => {
          if (element.Difficulty) {
            if (element.ToReview == 1) {
              if (decks.Settings.usedReviewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedReviewDate == '00-00-0000 00:00:00') {
                if (this.maxReviewCards > reviewCount) {
                  decks.ToReview += 1
                  tempFlashcards.push(element)
                }
              }
              reviewCount++
            }
            else {
              tempFlashcards.push(element)
            }
          }
          if (!element.Difficulty || element.Difficulty == 'null') {
            if (decks.Settings.usedNewDate.substring(0, 10) == new Date().toISOString().substring(0, 10) || decks.Settings.usedNewDate == '00-00-0000 00:00:00') {
              if (this.maxNewCards > newCount) {
                decks.NewCards += 1
                tempFlashcards.push(element)
              }
            }
            newCount++
          }
        });

        if (tempFlashcards.length > 0) {
          if (this.order == 'Show new cards in random order') {
            tempFlashcards.sort((a: any, b: any) => 0.5 - Math.random())
          }

          decks.Flashcards = []
          decks.Flashcards = [...decks.Flashcards, ...tempFlashcards]
        }
      })

      this.tempDecks = JSON.parse(JSON.stringify(this.decks))
    })
  }

  toTime(seconds: any) {
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 3600 % 60);

    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  resetDeckPerformance() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reset your progress?',
      accept: () => {
        this.decks[this.selectedDeck.Index].Settings = {
          "maxNewCards": 200,
          "maxReviewCards": 200,
          "usedNewCards": 0,
          "usedReviewCards": 0,
          "usedNewDate": '00-00-0000 00:00:00',
          "usedReviewDate": '00-00-0000 00:00:00',
          "orderCards": 'Show new cards in order added',
          "showTimer": false
        }
        let data = {
          "userId": localStorage.getItem('Id'),
          "deckId": this.decks[this.selectedDeck.Index].DeckID,
          "settings": this.decks[this.selectedDeck.Index].Settings,
          "learning": 0
        }
        this.flashcardService.resetStudyFlashcard(data).subscribe((res: any) => {
          if (this.courseId !== -1) {
            if (this.aiDecks) {
              this.getAIDecks();
            } else {
              this.getReadyDecks()
            }
          } else {
            this.getUserDeck()
          }
          this.selectedDeck = null
        })  
      },
      reject: () => {
      }
    })
  }

  selectSetting(deck: any) {
    this.settingDeck = deck
    this.maxNewCards = deck.Settings.maxNewCards
    this.maxReviewCards = deck.Settings.maxReviewCards
    this.showTimer = deck.Settings.showTimer
    this.usedNewCards = deck.Settings.usedNewCards
    this.usedReviewCards = deck.Settings.usedReviewCards
    this.usedNewDate = deck.Settings.usedNewDate
    this.usedReviewDate = deck.Settings.usedReviewDate
    this.order = deck.Settings.orderCards
  }

  selectDeck(deck: any, index: any) {
    this.selectedDeck = deck
    this.selectedDeck.Index = index
  }

  openFullscreen() {
    this.isFullScreen = true;
  }

  closeFullscreen() {
    this.isFullScreen = false;
  }

  openFlashcard(deckIndex: any, cardIndex: any) {
    this.solved = false
    this.position = 'Front'
    this.displayFlashcards = true
    this.deckActiveIndex = deckIndex
    this.cardActiveIndex = cardIndex
    this.displayTimer = '00:00'
    this.setImageDisplay();
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.timer += 1
      this.totalTime += 1
      this.displayTimer = new Date(this.timer * 1000).toISOString().slice(14, 19);
    }, 1000)
  }

  updatePosition(position: any) {
    this.position = position;
    this.setImageDisplay();
  }

  updateDeckPerformance(value: any) {
    this.position = 'Back'

    if (!this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty) {
      this.decks[this.deckActiveIndex].NewCards -= 1
    }

    this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].usedCard = -1

    if (value.text == 'Again') {
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview == 1) {
        this.decks[this.deckActiveIndex].ToReview -= 1
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview = 0
      }

      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty != 'Again') {
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning += 1
      }

      this.solved = false
      this.position = 'Front'
      this.decks[this.deckActiveIndex].LastUsed = new Date()
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty = value.text

      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate = null

      this.addStudyFlashcard(value.text, false)
    }
    if (value.text == 'Hard') {
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview == 1) {
        this.decks[this.deckActiveIndex].ToReview -= 1
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview = 0
      }

      this.solved = false
      this.position = 'Front'
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning = 0
      this.decks[this.deckActiveIndex].LastUsed = new Date()
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Mastered = 1
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty = value.text

      let date = new Date();
      date.setDate(date.getDate() + 1)
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate = date


      this.addStudyFlashcard(value.text, false)
    }
    if (value.text == 'Easy') {
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview == 1) {
        this.decks[this.deckActiveIndex].ToReview -= 1
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview = 0
      }

      this.solved = false
      this.position = 'Front'
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning = 0
      this.decks[this.deckActiveIndex].LastUsed = new Date()
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Mastered = 1
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty = value.text

      let date = new Date();
      date.setDate(date.getDate() + 5)
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate = date


      this.addStudyFlashcard(value.text, false)
    }
    if (value.text == 'Very Easy') {
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview == 1) {
        this.decks[this.deckActiveIndex].ToReview -= 1
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview = 0
      }

      this.solved = false
      this.position = 'Front'
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning = 0
      this.decks[this.deckActiveIndex].LastUsed = new Date()
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Mastered = 1
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty = value.text

      let date = new Date();
      date.setDate(date.getDate() + 15)
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate = date


      this.addStudyFlashcard(value.text, false)
    }
  }

  addStudyFlashcard(value: any, status: any) {
    if (!status) {
      this.decks[this.deckActiveIndex].Learning = 0
      this.decks[this.deckActiveIndex].Flashcards.forEach((element: any) => {
        this.decks[this.deckActiveIndex].Learning += element.Learning
      });
    }

    this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Solved = this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Solved ? this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Solved + 1 : 1

    let data = {
      "userId": localStorage.getItem('Id'),
      "flashcardId": this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].FlashcardID,
      "nextStudyDate": (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate == null ? '0000-00-00 00:00:00' : this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].NextStudyDate.toISOString().substring(0, 10)),
      "deckId": this.decks[this.deckActiveIndex].DeckID,
      "learning": this.decks[this.deckActiveIndex].Learning,
      "mastered": this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Mastered,
      "toReview": this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].ToReview,
      "difficulty": this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Difficulty,
      "timeSpent": this.timer,
      "totalTime": this.totalTime,
      "solved": this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Solved
    }

    if (this.isDemo) {
      if (value == 'Easy' || value == 'Hard' || value == 'Very Easy') {
        this.decks[this.deckActiveIndex].Flashcards.splice(this.cardActiveIndex, 1)
      }
      if (status) {
        this.decks[this.deckActiveIndex].Flashcards.splice(this.cardActiveIndex, 1)
      }
      if (this.decks[this.deckActiveIndex].Flashcards.length <= 0) {
        this.displayFlashcards = false
      }
      else {
        this.nextCardIndex()
      }
    } else {
      this.flashcardService.addStudyFlashcard(data).subscribe((res: any) => {
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Settings = res.data.settings
  
        this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].TimeSpent = this.timer
        clearInterval(this.interval)
        this.timer = 0
        this.totalTime = 0
        if (value == 'Easy' || value == 'Hard' || value == 'Very Easy') {
          this.decks[this.deckActiveIndex].Flashcards.splice(this.cardActiveIndex, 1)
        }
        if (status) {
          this.decks[this.deckActiveIndex].Flashcards.splice(this.cardActiveIndex, 1)
        }
        if (this.decks[this.deckActiveIndex].Flashcards.length <= 0) {
          this.displayFlashcards = false
        }
        else {
          this.nextCardIndex()
        }
      })
    }
  }

  nextCardIndex() {
    if (this.cardActiveIndex < this.decks[this.deckActiveIndex].Flashcards.length - 1) {
      this.cardActiveIndex ++
    }
    else {
      this.cardActiveIndex = 0
    }
    this.displayTimer = '00:00'
    this.interval = setInterval(() => {
      this.timer += 1
      this.totalTime += 1
      this.displayTimer = new Date(this.timer * 1000).toISOString().slice(14, 19);
    }, 1000)
  }

  searchDecks() {
    this.decks = this.tempDecks.filter((item: any) => item.Title.toLowerCase().includes(this.keyword.toLowerCase()))
  }

  showAnswer() {
    this.position = 'Back'
    this.solved = true
    this.setImageDisplay();
  }

  changeView() {
    if (this.flashcardView == 'Single Side View') {
      this.flashcardView = 'Double Side View'
    }
    else {
      this.flashcardView = 'Single Side View'
    }
    this.setImageDisplay();
  }

  buryFlashcard(deck: any, card: any) {
    let date = new Date();
    date.setDate(date.getDate() + 1)

    let data = {
      "flashcardId": card,
      "deckId": deck,
      "userId": localStorage.getItem('Id'),
      "buryDate": date
    }
    this.flashcardService.buryFlashcard(data).subscribe((res: any) => {
      this.messageService.add({severity:'success', summary:'Flashcard Status Updated', detail:'Your flashcard is buried for 1 day.'});

      if (!this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].usedCard) {
        this.decks[this.deckActiveIndex].NewCards -= 1
      }
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].BuryDate = date
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning == 1) {
        this.decks[this.deckActiveIndex].Learning -= 1
      }
      this.displayCardOptions = false
      this.addStudyFlashcard('Again', true)
    })
  }

  suspendFlashcard(deck: any, card: any) {
    let data = {
      "flashcardId": card,
      "deckId": deck,
      "userId": localStorage.getItem('Id'),
    }
    this.flashcardService.suspendFlashcard(data).subscribe((res: any) => {
      this.messageService.add({severity:'success', summary:'Flashcard Status Updated', detail:'Your flashcard is suspended.'});

      if (!this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].usedCard) {
        this.decks[this.deckActiveIndex].NewCards -= 1
      }
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].usedCard = -1
      this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].IsSuspended = 1
      if (this.decks[this.deckActiveIndex].Flashcards[this.cardActiveIndex].Learning == 1) {
        this.decks[this.deckActiveIndex].Learning -= 1
      }
      this.displayCardOptions = false
      this.addStudyFlashcard('Again', true)
    })
  }

  closeDialog() {
    clearInterval(this.interval)
    this.timer = 0
    this.totalTime = 0
  }

  changeIndex(index: any) {
    this.activeIndex = index 
    this.errorMessage = ""
  }

  applySettings() {
    this.errorMessage = ""
    if (this.maxNewCards <= 0 || this.maxReviewCards <= 0) {
      this.errorMessage = 'One or more fields have invalid values, they can only contain numbers greater than zero.'
    }
    else {
      this.settingDeck.Settings = {
        "maxNewCards": this.maxNewCards,
        "maxReviewCards": this.maxReviewCards,
        "orderCards": this.order,
        "showTimer": this.showTimer,
        "usedNewCards": this.usedNewCards,
        "usedReviewCards": this.usedReviewCards,
        "usedNewDate": this.usedNewDate,
        "usedReviewDate": this.usedReviewDate
      }
      let data = {
        "deckId": this.settingDeck.DeckID,
        "userId": localStorage.getItem('Id'),
        "settings": this.settingDeck.Settings
      }
      this.flashcardService.updateDeckSettings(data).subscribe((res: any) => {
        this.displaySettings = false
        if (this.courseId !== -1) {
          if (this.aiDecks) {
            this.getAIDecks();
          } else {
            this.getReadyDecks()
          }
        } else {
          this.getUserDeck()
        }
      })
    }
  }

  setImageDisplay() {
    // Image Click Event //
    setTimeout(() => {
      document.querySelectorAll('.flashcard-editor-wrapper img').forEach((element) => {
        element.addEventListener('click', (e: any) => {
          this.showImage = e.target.currentSrc
          this.displayImg = true
        })
      }) 
    }, 500)
  }
}
