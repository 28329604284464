<app-overlay-loader *ngIf="loader"></app-overlay-loader>

<div class="row eup-ov-charts-wrapper" *ngIf="!loader">
  <div class="col-md-8 offset-md-2 col-lg-6 eup-ov-left-chart mt-2 mb-2">
    <div class="eup-ov-chart eup_left_chart">
      <div class="row ">
        <div class="col-md-6 ">
          <div class="eup-ov-data-chart">
            <canvas
              baseChart
              [data]="pieChartData1"
              [colors]="chartColors"
              [labels]="pieChartLabels1"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [plugins]="pieChartPlugins"
              [legend]="pieChartLegend"
            >
            </canvas>
          </div>
        </div>
        <div class="col-md-6">
          <div class="eup-ov-data-chart-pointers eup_left_pointers">
            <div class="ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
              <h5> YOUR SCORE </h5> 
            </div>
            <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels1;let i = index;">
              <div class="chart_headings">
              <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
              <p class="chart_labels">  {{label.label}}</p>
            </div>
            <div class="chart_number">
              <div class="number {{pointersColorClasses[i]}}">
               {{label.value}}  
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="col-md-8 offset-md-2 col-lg-6  eup-ov-right-chart mt-2 mb-2">
    <div class="eup-ov-chart ">
      <div class="row ">
        <div class="col-md-6 ">
          <div class="eup-ov-data-chart">
            <canvas
              baseChart
              [data]="pieChartData"
              [colors]="chartColors"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [plugins]="pieChartPlugins"
              [legend]="pieChartLegend"
            >
            </canvas>
          </div>
        </div>
        <div class="col-md-6 ">
          <div class="eup-ov-data-chart-pointers">
            <div class="ml-3 text-weight-bold heading_answer_changes eup-ov-cards-heading">
              <h5> ANSWER CHANGES </h5> 
            </div>
            <div class="eup-ov-chart-pointer " *ngFor="let label of cardLabels;let i = index;">
              <div class="chart_headings">
                <!-- <div class="{{pointersColorClasses[i]}} eup-ov-chart-pointer-color "></div> -->
                <p class="chart_labels">{{label.label}}</p>
              </div>
              <div class="chart_number">
                <div class="number {{pointersColorClasses[i]}}">
                    {{label.value}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>