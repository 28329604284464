<div class="eup-pf-root">
  <div class="eup-pf-parent">
    <div class="eup-pf-subparent">
        <!-- Top Background -->
        <div class="topbg" >
          <div class="profile-icon text-center">  <i  class=" fa fa-user-circle-o fa-4x"></i> </div>
        </div>
        <!-- Main Content -->
        <div class="main-content">
            <div class="text-center name-heading" *ngIf="isProfile">
                Hello {{name}} !

            </div>
            <div class="form pl-3 pr-3" *ngIf="isProfile">
              <form>
                  <div class="row">
                    <div class="col-sm-12 col-md-6 mt-4">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" name="name" [(ngModel)]='name' readonly>
                    </div>
                    <div class="col-sm-12 col-md-6 mt-4">
                      <label for="loginname">Login Name</label>
                      <input type="text" class="form-control" id="loginname" name="loginname" [(ngModel)]='loginName' readonly>
                    </div>
                  </div>
                  <div class="row ">
                      <div class="col-sm-12 col-md-6 mt-4">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" name="email" [(ngModel)]='user.user_email' readonly>
                      </div>
                      <div class="col-sm-12 col-md-6 mt-4">
                          <label for="password">Password</label>
                        <input type="password" #passworrd="ngModel" id="passworrd" class="form-control" name="passworrd" minlength="3" [(ngModel)]='user.user_pass' required>
                      </div>
                    </div>
                    <div class=" col-sm-12 h5 base-color1 mt-3">{{message}}</div>
                    <div class="row mt-4 text-center">
                        <button class="btn btn-primary" (click)="changePass()">Update</button>
                    </div>
                </form>
            </div>
            <hr>
            <div class="mt-3 text-center name-heading" *ngIf="userSubscriptionDetails && !isProfile" >
              Subscription Details
            </div>
            <div class="subscription_table mt-3 mb-5" *ngIf="!isProfile">
              <p-table [value]="userSubscriptionDetails" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                  <tr>
                    <th [ngStyle]="{'width':'120px'}">Subscription Id</th>
                    <th [ngStyle]="{'width':'220px'}">Subscription Title</th>
                    <th [ngStyle]="{'width':'220px'}">Start Date</th>
                    <th [ngStyle]="{'width':'220px'}">End Date</th>
                    <th [ngStyle]="{'width':'220px'}">Renew</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-subscription>
                  <tr>
                    <td [ngStyle]="{'width':'120px'}">{{subscription?.SubscriptionID}}</td>
                    <td [ngStyle]="{'width':'220px'}">{{subscription?.Title}}</td>
                    <td [ngStyle]="{'width':'220px'}">{{subscription?.StartDate | date}}</td>
                    <td [ngStyle]="{'width':'220px'}">{{(subscription.EndDate)?(subscription?.EndDate | date):'No End Date'}}</td>
                    <td [ngStyle]="{'width': '220px'}"><button class="btn btn-primary" *ngIf="isRenewed" (click)="renewSubscription(subscription)">Renew</button></td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
        </div>

        <!-- Subscription Details -->
        <!-- <div class="subscription_wrapper">
          <div class="subscription_heading">ABCDEF</div>
        </div> -->
     </div>
  </div>
</div>


<p-dialog [draggable]="false"  [(visible)]="subscriptionPopup">
<ng-template pTemplate="header">
  <div class="header"><i class="pi pi-credit-card " style="margin-right: 5px;"></i> PERSONAL DETAILS</div>
</ng-template>
<form #form = "ngForm">
  <div class="row" *ngIf="!isVerified">
    <label for="">First Name</label>
    <input class="full_row" type="text" name="firstname" placeholder="Enter First Name" [(ngModel)]="personalDetails.firstname" #firstname = "ngModel" required pattern="^[A-Za-z0-9\s]+$">
    <div *ngIf="firstname.errors?.required && firstname.dirty" class="error">
      <span>First Name is required</span>
    </div>
    <div *ngIf="firstname.errors?.pattern" class="error">
      <span>Please enter valid first name</span>
    </div>
  </div>
  <div class="row" *ngIf="!isVerified">
    <label for="">Last Name</label>
    <input class="full_row" type="text" name="lastname" placeholder="Enter Last Name" [(ngModel)]="personalDetails.lastname" #lastname = "ngModel" required pattern="^[A-Za-z0-9\s]+$">
    <div *ngIf="lastname.errors?.required && lastname.dirty" class="error">
      <span>Last Name is required</span>
    </div>
    <div *ngIf="lastname.errors?.pattern" class="error">
      <span>Please enter valid last name</span>
    </div>
  </div>
  <div class="row" *ngIf="!isVerified">
    <label for="">Email Address</label>
    <input class="full_row" type="email" name="email" placeholder="Enter Email Address" [(ngModel)]="personalDetails.email" #email = "ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
    <div *ngIf="email.errors?.required && email.dirty" class="error">
      <span>Email Address is required</span>
    </div>
    <div *ngIf="email.errors?.pattern" class="error">
      <span>Please enter valid email address</span>
    </div>
  </div>
  <div class="row" *ngIf="!isVerified">
    <label for="">Phone Number</label>
    <input class="full_row" type="text" name="phone" placeholder="Enter Phone Number" [(ngModel)]="personalDetails.phone" #phone = "ngModel" required>
    <div *ngIf="phone.errors?.required && phone.dirty" class="error">
      <span>Phone Number is required</span>
    </div>
    <div *ngIf="phone.errors?.pattern" class="error">
      <span>Please enter valid phone number</span>
    </div>
  </div>
  <div class="buttons">
    <div class="text-center singleButton" *ngIf="!isVerified">
      <button class="btn btn-primary" style="margin-bottom: 10px;" [disabled] = "form.invalid" (click)="payNow('opay')">Pay By Opay</button>
      <div class="price">(2500 EGP/one year)</div>
    </div>
    <div class="text-center singleButton" *ngIf="!isVerified">
      <button class="btn btn-primary" style="margin-bottom: 10px;" [disabled] = "form.invalid" (click)="payNow('paymob')">Pay By Paymob</button>
      <div class="price">(2500 EGP/one year)</div>
    </div>
  </div>
</form>
</p-dialog>

<p-dialog header="Renew Instructions" [(visible)]="termsModel" [modal]="true" [closable]="false" [style]="{width: '70vw'}" [baseZIndex]="10000"
[draggable]="false" [resizable]="false">
<div class="p-m-0 p"> <div class="mb-4"> Please Read and Agree to our Terms & Conditions </div> <div class="arabic_text">  <div> ‎الاشتراك يتيح لك حريه الوصول الي كل محتوي العضويه علي الموقع لمده سنتين ونصف بالنسبه لكورسات الاونلاين والاوفلاين و ٤ سنوات بالنسبه لكورس الكومبو</div> <br><br><div class="second_line mb-3">‎في حاله الاحتياج الي تجديد العضويه يمكنك تجديد العضويه من خلال الضغط علي زر تجديد العضويه بالاسفل ويكون تجديد سنوي بمبلغ بسيط يمثل ٢٥٪؜ من سعر الكورس لمده عام</div><br><br><div class="second_line mb-3">‎في حاله التجديد يتم تطبيق نفس سياسه الموقع والتي تم الموافقه عليها اثناء التسجيل وهو ان الاكونت شخصي للمشترك فقط وغير مسموح بمشاركه بيانات الاكونت مع اي شخص او بيعه واي مخالفه لسياسه الموقع يتم ايقاف الاكونت نهائيا علي الموقع</div>ِ</div></div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="acceptInstruction()" label="Accept" class="p-button-text"></p-button>
      <!-- <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button> -->
  </ng-template>
</p-dialog>

<app-overlayfullscreen *ngIf="loader"></app-overlayfullscreen>


<p-dialog header="Password Alert" [draggable]="false" [(visible)]="passwordPopup">
<div class="changePass">
  Do you want to change the password?
</div>
<ng-template pTemplate="footer">
  <p-button icon="pi pi-check" (click)="changePassOnDB()" label="OK" class="p-button-text"></p-button>
  <!-- <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button> -->
</ng-template>
</p-dialog>
