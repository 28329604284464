import { Component, Input, OnInit ,Renderer2 } from '@angular/core';
@Component({
  selector: 'app-bell-chart',
  templateUrl: './bell-chart.component.html',
  styleUrls: ['./bell-chart.component.scss']
})
export class BellChartComponent implements OnInit {
  
    @Input() userRank = 0;
    @Input() peerRank = 0;

    @Input() xUserValue = 0
    @Input() yUserValue = 0

    @Input() xPeerValue = 0

    @Input() avgQuizScore = 0
    @Input() avgPeerQuizScore = 0

  dataset = [6, 11]
  
  w = 600
  h = 250

  xScale:any;
  yScale:any;

  lineChart: any;
  constructor(public renderer: Renderer2) { }

  ngOnInit(): void {
  }

}
