import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-loader',
  templateUrl: './ai-loader.component.html',
  styleUrls: ['./ai-loader.component.scss']
})
export class AiLoaderComponent implements OnInit {
  @Input() loaderText = '';

  constructor() { }

  ngOnInit(): void {
  }

}
