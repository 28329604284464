import { Component, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { AutoLogout } from 'src/app/models/globals';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  // @ts-ignore
  subscriptions: Subscription = [];

  displayModal = false;
  editNoteValue: any;
  editNoteId: any;
  notes = [
    {id: 1233, data: 'Graphic Design, as a skill has become a necessary survival tool in the modern world. Over the past decade, the expansion in design-related jobs has been astronomical and Adobe design software is leading this revolution.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 1234.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 345.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Graphic Design, as a skill has become a necessary survival tool in the modern world. Over the past decade, the expansion in design-related jobs has been astronomical and Adobe design software is leading this revolution.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 89.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Graphic Design, as a skill has become a necessary survival tool in the modern world. Over the past decade, the expansion in design-related jobs has been astronomical and Adobe design software is leading this revolution.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 235145.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for erew4352.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 43225.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for q4432345.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for qw44tert.', qID: 2348, System: 'MAcOS'},
    {id: 1233, data: 'Hello World this is data you are looking for 34t3tertwet.', qID: 2348, System: 'MAcOS'}
  ]

  NumofRows = [
    {numOfRows: 5},
    {numOfRows: 10},
    {numOfRows: 15},
    {numOfRows: 20},
    {numOfRows: 25}
];

  selectedNumber: any;

  constructor( private quizService: QuizServiceService) { }

  ngOnInit(): void {
    document.getElementById('eup-navbar-heading').innerText = 'Notes';
    this.selectedNumber = 5
    this.getNotes()
  }

  getNotes(){
    this.subscriptions.push(
      this.quizService.getFeedbacks().subscribe((res: any) => {
          this.notes = res.feedbacks;
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  selectNum(num: any): void{
    this.selectedNumber = num.numOfRows;
  }

  
  viewNote(note: any){
    this.editNoteValue = note.feedback;
    this.editNoteId = note.ideup_user_feedbacks;
    this.displayModal = true;
  }

  


}
