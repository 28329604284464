<div class="eup_ps_root">
    <app-overlay-loader *ngIf="loader"></app-overlay-loader>
    <div class="eup_popup_screen">
<p-dialog header="Reviews" [(visible)]="displayReview" [draggable]="false" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngFor="let feedback of userFeedback">
        <p>{{feedback.feedback}}</p>
    </div>
    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check"  label="Go Back" styleClass="p-button-text"></p-button> -->
    </ng-template>
</p-dialog>

<p-dialog header="Notes" [(visible)]="displayNotes"  [draggable]="false" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngFor="let note of userNotes">
        <p>{{note.notes}}</p>
    </div>
    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayBasic2=false" label="Ok" styleClass="p-button-text"></p-button> -->
    </ng-template>
</p-dialog>
<p-dialog header="Questions" [(visible)]="displayQuestions"  [draggable]="false" [style]="{width: '40vw'}" [baseZIndex]="10000">
    <div class="mb-3" style="word-wrap: break-word;">
        <p *ngFor="let question of quizQuestions; let i = index; let last = last;">{{question}}{{(!last)?',':''}}</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="copyText()" label="Copy" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
    </div>
    <div class="eup-ps-parent">
        <div class="eup_ps_wrapper">
            <div class="eup_parentbar">
                <div class="left_bar">
                    <div class="top_tabs">
                        <p routerLink="/dashboard/performancescreen/results" (click)="changeTestResult(1)" [ngClass]="testResult == true ? 'showtab': ''">Test Results</p>
                        <p routerLink="/dashboard/performancescreen/analysis" (click)="changeTestResult(2)" [ngClass]="testResult == true ? '': 'showtab'">Test Analysis</p>
                    </div>
                </div>
                <div class="center_test_name_options">
                    <div class="test_name_wrapper">
                        <h5 class="test_name" [pTooltip]="quizTitle">Test Name: {{quizTitle}}</h5>
                        <h5 class="test_id">ID: {{quizId}}</h5>
                    </div>
                </div>
                <div class="rigtbar_btns">
                    <div class="right_btns_wrapper">
                        <button  (click)="displayQuestions = !displayQuestions" class="btn_review btn mr-2">Questions_List</button>
                        <button  (click)="resumeQuiz(1)" *ngIf="userMembership && quizStatus != 'suspended'" class="btn_review btn">Review</button>
                        <button (click)="showNotesPopup()" class="btn_notes btn mr-2 mb-1">Notes</button>
                        <button  (click)="resumeQuiz(2)" class="btn_review btn" *ngIf="quizStatus == 'suspended' && userMembership">Resume</button>
                    </div>
                </div>
            </div>

            <!-- Both Tables will come here -->
            <router-outlet></router-outlet>

        </div>
    </div>
</div>