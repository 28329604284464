import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlayfullscreen',
  templateUrl: './overlayfullscreen.component.html',
  styleUrls: ['./overlayfullscreen.component.scss']
})
export class OverlayfullscreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
