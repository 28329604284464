import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { QuizServiceService } from '../shared/services/quiz-service.service';

@Component({
  selector: 'app-search-questions',
  templateUrl: './search-questions.component.html',
  styleUrls: ['./search-questions.component.scss'],
  providers: [MessageService]
})
export class SearchQuestionsComponent implements OnInit {

  questions: any = [];
  keyword = ""
  selectedNum = 40
  total = 0
  loader = false
  value = 0
  subjects: any = []
  systems: any = []
  topics: any = []
  subject: any = []
  system: any = []
  topic: any = []
  tempQuestions: any = []

  isMenuOpened = "";
  showFilter = false;

  noFilter = false
  courses: any = [];
  selectedCourse = null;

  constructor(private quizService: QuizServiceService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    if (this.quizService.questions.length > 0) {
      if (JSON.parse(localStorage.getItem('search'))) {
        this.keyword = localStorage.getItem('keyword')
        localStorage.removeItem('search')
        localStorage.removeItem('keyword')
        this.questions = this.quizService.questions
      }
    }
    if(window.innerWidth>750){
      document.getElementById('eup-navbar-heading').innerText = 'Search';
    }else{
      document.getElementById('eup-navbar-heading').innerText = '';
    }
    this.courses = JSON.parse(localStorage.getItem('subscriptions'))
    if (this.courses.length > 0) {
      this.selectedCourse = this.courses[0].courseID
    }
  }

  show() {
    this.showFilter = !this.showFilter;
  }

  apply() {
    this.showFilter = false;
    this.filterCategories()
  }

  clear() {
    this.subject = []
    this.system = []
    this.topic = []
    this.questions = JSON.parse(JSON.stringify(this.tempQuestions))
  }

  toggleMenu(value: any): void {
    if (this.isMenuOpened == value) {
      this.isMenuOpened = ""
    }
    else {
      this.isMenuOpened = value
    }
  }
  
  filterCategories() {
    this.questions = []
    
    for (let i = 0; i < this.subject.length; i++) {
      this.questions = [...this.questions, ...this.tempQuestions.filter(item => item.subject == this.subject[i])]
    }

    if (this.system.length > 0) {
      let newQuestions = JSON.parse(JSON.stringify(this.questions))
      this.questions = []
      for (let i = 0; i < this.system.length; i++) {
        this.questions = [...this.questions, ...newQuestions.filter(item => item.system == this.system[i])]
      }
    }

    if (this.topic.length > 0) {
      let newQuestions = JSON.parse(JSON.stringify(this.questions))
      this.questions = []
      for (let i = 0; i < this.topic.length; i++) {
        this.questions = [...this.questions, ...newQuestions.filter(item => item.topic == this.topic[i])]
      }
    }

  }

  searchQuestions() {
    if (this.keyword == "" || this.keyword.length < 3) {
      this.messageService.add({severity:'error', summary:'Error searching questions', detail:'Search keywords cannot be less than 3 characters.'});
    }
    else {
      let data = {
        "keyword": this.keyword,
        "userId": localStorage.getItem('Id'),
        "courseId": this.selectedCourse
      }

      this.subject = []
      this.system = []
      this.topic = []

      this.subjects = []
      this.systems = []
      this.topics = []

      this.loader = true
      this.quizService.searchQuestions(data).subscribe((res: any) => {
        this.questions = res.questions
        this.tempQuestions = res.questions

        res.questions.forEach(element => {
          this.subjects.push({
            "ID": element.subjectID,
            "post_title": element.subject
          })
        });
        this.subjects = [...new Map(this.subjects.map((obj: any) => [JSON.stringify(obj), obj])).values()]

        res.questions.forEach(element => {
          this.systems.push({
            "ID": element.systemID,
            "post_title": element.system
          })
        });
        this.systems = [...new Map(this.systems.map((obj: any) => [JSON.stringify(obj), obj])).values()]

        res.questions.forEach(element => {
          this.topics.push({
            "ID": element.topicID,
            "post_title": element.topic
          })
        });
        this.topics = [...new Map(this.topics.map((obj: any) => [JSON.stringify(obj), obj])).values()]

        this.noFilter = true
        this.loader = false
      })
    }
  }

  viewQuestions(index: any) {
    localStorage.setItem('search', JSON.stringify(true))
    localStorage.setItem('keyword', this.keyword)
    this.quizService.questions = this.questions

    localStorage.setItem('viewMode', JSON.stringify(true));
    localStorage.setItem('QuizId', '-1');
    localStorage.setItem(
      'flaggedQuestion',
      JSON.stringify([])
    );
    localStorage.setItem('quizTitle', 'Custom');
    localStorage.setItem(
      'AllQuestions',
      JSON.stringify(this.questions)
    );
    localStorage.setItem("currentQuestionIndex", JSON.stringify(index));
    localStorage.setItem("CurrentIndex", JSON.stringify(index));

    let submittedQuestions = []
    for (let i = 0; i < this.questions.length; i++) {
      submittedQuestions.push({
        "index": this.questions[i].id,
        "correct": 1,
        "optionIndexSelected": this.questions[i].statistics.findIndex((item: any) => item == 1),
        "Correctanswerindex": this.questions[i].statistics.findIndex((item: any) => item == 1),
        "stat": 5,
        "time": 0
      })
    }
    localStorage.setItem("SubmittedQuestions", JSON.stringify(submittedQuestions));
    this.router.navigate(['/quiz']);
  }
}
