import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AuthService } from '../../shared/services/auth.service';
import {ErrorHandlerService} from "../../shared/services/error-handler.service";
import { BASE_URL } from 'src/app/models/globals';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  subscriptionPopup = false;
  passwordPopup = false;
  personalDetails = {
    firstname: "",
    lastname: "",
    email: "",
    phone: ""
  }
  isVerified = false;
  verificationCode = ""
  userId = localStorage.getItem('Id');
  membershipId: any;
name: any;
  loginName: any;
  user=  {
    ID: localStorage.getItem('Id'),
    user_email: '',
    user_pass: ''
  }
  transactionId: any;
  transactionCode: any;
  userSubscriptionDetails: any;
  message=''
  innerWidth: any;
  isProfile: any = false;
  isRenewed = true;
  // isProfile: any = false;
  termsModel = false;
  loader = false
  isPassChanged = false;

  constructor(private adminService: AdminService,private route:ActivatedRoute, private globalService: GlobalService,private authService: AuthService, private errorHandler: ErrorHandlerService, private router: Router) { }

  async ngOnInit(){
    if (this.route.snapshot.queryParams['opay'] == 'true') {
      this.opayTransactionStatus()
    }

    if (this.route.snapshot.queryParams['success'] == 'true') {
      this.updateUserSubscription()
    }
    if (this.route.snapshot.queryParams['success'] == 'false') {
      alert('Transaction Failed')
      this.router.navigateByUrl('/dashboard/profile/0')
    }

    var id = this.route.snapshot.params.id;
    (id == 1)?this.isProfile = true: this.isProfile = false;
    this.route.params.subscribe(routeParams => {
      (routeParams.id == 1)?this.isProfile = true: this.isProfile = false;
    });
    this.getUserSubscription();
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Profile';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
  if(this.userId == '-1'){
    this.name='Demo'
    this.loginName = 'Demo'
    this.user.user_email = 'demo@gmail.com'

  }else{
    this.getuserdetails();
  }
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
      this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        if(res.status){
        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
  }
  changePass(): void {
    if( this.userId != '-1' && this.user.user_pass && this.user.user_pass.length>2 ){
      this.passwordPopup = true;
  }else{
    this.isPassChanged = true
  }
  }
  changePassOnDB(){
    if(this.userId != '-1'){
      this.authService.updatePass(this.user).subscribe((res: any) => {
        if(res.status){
          this.message = "Password Updated"
          this.passwordPopup = false;
      }
      else{
      }
      });
      }
  }
  getUserSubscription(){
    this.authService.getUserSubscription().subscribe((res: any) => {
      console.log(res);
      this.userSubscriptionDetails = res.subscriptions;
      this.userSubscriptionDetails = this.userSubscriptionDetails.sort((a, b) => b.StartDate - a.StartDate);
      this.isRenewed = res.isRenewed
    })
  }
  getuserdetails(): void {
    this.authService.getuserdetails().subscribe((res: any) => {
      if(res.status){
        this.name = res.user.displayName;
        this.loginName= res.user.loginName;
        this.user.user_email = res.user.userEmail;
    }
    else{
    }
    },
    error => {
      this.errorHandler.handleError(error);
    })
  }

  renewSubscription(subscription: any) {
    this.termsModel = true
    this.membershipId = subscription.MembershipID
    localStorage.setItem('membershipId', this.membershipId)
  }

  paymobOrder(token: any) {
    let data = {
      "auth_token": token,
      "amount_cents": 250000,
      "currency": "EGP",
      "delivery_needed": "false",
      "items": [{
          "amount_cents": 250000,
          "name": "Subscription Renewal",
          "quantity": "1"
      }],
      "shipping_data": {
          "city": "NA",
          "country": "NA",
          "email": this.personalDetails.email,
          "first_name": this.personalDetails.firstname,
          "last_name": this.personalDetails.lastname,
          "phone_number": this.personalDetails.phone,
          "postal_code": "NA",
          "state": "NA",
          "street": "NA"
      }
    }
    this.authService.paymobOrderCreation(data).subscribe((res: any) => {
      this.paymobPayment(res.id, token)
    })
  }

  paymobPayment(orderId: any, token: any) {
    let data = {
      "auth_token": token,
      "amount_cents": "250000",
      "expiration": 3600,
      "order_id": orderId,
      "billing_data": {
        "apartment": "NA",
        "email": this.personalDetails.email,
        "floor": "NA",
        "first_name": this.personalDetails.firstname,
        "street": "NA",
        "building": "NA",
        "phone_number": this.personalDetails.phone,
        "shipping_method": "NA",
        "postal_code": "NA",
        "city": "NA",
        "country": "NA",
        "last_name": this.personalDetails.lastname,
        "state": "NA"
      },
      "currency": "EGP",
      "integration_id": 2375554,
      "lock_order_when_paid": "false"
    }
    this.authService.paymobPaymentKey(data).subscribe((res: any) => {
      this.acceptPayment(res.token)
    })
  }

  acceptPayment(token: any) {
    this.loader = false
    window.location.href = `https://accept.paymobsolutions.com/api/acceptance/iframes/13895?payment_token=${token}`
  }

  acceptInstruction() {
    this.termsModel = false
    this.subscriptionPopup = true
  }

  payNow(method: any){
    this.loader = true
    let data = {
      "userId": localStorage.getItem('Id'),
      "email": this.user.user_email,
      "method": method
    }
    this.authService.generateTransaction(data).subscribe((res: any) => {
      this.transactionId = res.transactionId
      this.transactionCode = res.transactionCode
      if (method == 'opay') {
        this.opayAPI()
      }
      if (method == 'paymob') {
        this.paymobAPI()
      }
    },
    error => {
      this.errorHandler.handleError(error);
    })
  }

  opayAPI() {
    let data = {
      "country": "EG",
      "reference": this.transactionCode,
      "amount": {
          "total": 250000,
          "currency": "EGP"
      },
      "returnUrl": `${BASE_URL}/dashboard/profile/0?opay=true`,
      "callbackUrl": `${BASE_URL}/dashboard/profile/0?opay=true`,
      "cancelUrl": `${BASE_URL}/dashboard/profile/0?opay=true`,
      "expireAt": 3600,
      "userInfo":{
              "userEmail": this.personalDetails.email,
              "userId": this.userId,
              "userMobile": this.personalDetails.phone,
              "userName": this.personalDetails.firstname + ' ' + this.personalDetails.lastname
      },
      "productList":[
          {
              "productId": "81811823125",
              "name": "Subscription Renewal",
              "description": "Subscription Renewal Product",
              "price": 250000,
              "quantity": 1
          }
      ],
      "payMethod": "BankCard"
    }
    localStorage.setItem('transactionId', this.transactionCode)
    this.authService.opayTransaction(data).subscribe((res: any) => {
      this.loader = false
      window.location.href = res.transaction.data.cashierUrl
    })
  }

  opayTransactionStatus() {
    let data = {
      "country":"EG",
      "reference": localStorage.getItem('transactionId')
    }
    this.authService.getOpayStatus(data).subscribe((res: any) => {
      if (res.transaction.data.status == 'SUCCESS') {
        this.updateUserSubscription()
      }
      else {
        alert("Transaction Failed")
      }
      localStorage.removeItem('transactionId')
      this.router.navigateByUrl('/dashboard/profile/0')
    })
  }

  paymobAPI() {
    this.authService.paymobTokenGeneration().subscribe((res: any) => {
      this.paymobOrder(res.token)
    })
  }

  updateUserSubscription() {
    let data = {
      "userId": localStorage.getItem('Id'),
      "membershipId": localStorage.getItem('membershipId')
    }
    this.authService.updateUserSubscription(data).subscribe((res: any) => {
      if (res.status) {
        alert('Subscription Renewed')
      }
      else {
        alert('Transaction Failed')
      }
      this.subscriptionPopup = false
      this.isVerified = false
      this.transactionId = null
      this.verificationCode = ""
      localStorage.removeItem('coursearray')
      this.router.navigateByUrl('/dashboard/profile/0')
    })
  }

}
