<app-overlayfullscreen *ngIf="loader"></app-overlayfullscreen>

<div class="elhusseniey-search-filter-root">
    <div class="elhusseniey-search-filter-body flex-display align-center">
      <div class="elhusseniey-search-wrapper">
        <h1>Enter Question Id or Keywords</h1>
        <div class="elhusseniey-input-wrapper flex-display align-center">
          <input type="text" [(ngModel)]="keyword"/>
          <i class="pi pi-search" (click)="searchQuestions()"></i>
          <span class="flex-display align-center">
            <i
              class="pi pi-info-circle"
              pTooltip="Search from the questions you have already used by entering the question ID or keywords"
              tooltipPosition="bottom"
              tabindex="0" tooltipEvent="hover" style="outline: 0;"
            ></i>
          </span>
          <p-dropdown [options]="courses" [(ngModel)]="selectedCourse" optionLabel="courseName" optionValue="courseID"></p-dropdown>
        </div>
      </div>
      <div class="elhusseniey-filter-wrapper flex-column justify-center align-end" >
        <button
          class="filter-btn flex-display align-center"
          (click)="show()"
          pTooltip="Filter search result"
          tooltipPosition="bottom"
          tabindex="0" tooltipEvent="hover" style="outline: 0;" *ngIf="noFilter">
          <i class="pi pi-filter"></i>
          Filters
        </button>
        <div class="elhusseniey-floating-filter-wrapper" *ngIf="showFilter">
          <div class="elhusseniey-filter-heading-wrapper">
            <h2>Filters</h2>
          </div>
          <!-- dropdown for subjects and topics start from here -->
  
          <div class="elhusseniey-filter-allsubjects-wrapper">
            <div class="menu-container">
              <button class="menu-toggler" (click)="toggleMenu('subject')">
                <span *ngIf="subject.length > 0">{{ subject }}</span>
                <span *ngIf="subject.length <= 0">All Subjects</span>
                <i class="fa fa-caret-down" style="color: rgb(164, 161, 161)"></i>
              </button>
              <div class="menu" *ngIf="isMenuOpened == 'subject'">
                <div class="check-box-wrapper" *ngFor="let item of subjects; let i = index">
                    <p-checkbox name="subject{{i}}" value="{{item.post_title}}" [(ngModel)]="subject"></p-checkbox>
                    <label class="checkbox-label">{{item.post_title}}</label>
                </div>
              </div>
            </div>
          </div>
  
          <div class="elhusseniey-filter-allsubjects-wrapper">
            <div class="menu-container">
              <button class="menu-toggler" (click)="toggleMenu('system')">
                <span *ngIf="system.length > 0">{{ system }}</span>
                <span *ngIf="system.length <= 0">All Systems</span>
                <i class="fa fa-caret-down" style="color: rgb(164, 161, 161)"></i>
              </button>
              <div class="menu" *ngIf="isMenuOpened == 'system'">
                <div class="check-box-wrapper" *ngFor="let item of systems">
                    <p-checkbox name="system{{i}}" value="{{item.post_title}}" [(ngModel)]="system"></p-checkbox>
                    <label class="checkbox-label">{{item.post_title}}</label>
                </div>
              </div>
            </div>
          </div>
  
          <div class="elhusseniey-filter-allsubjects-wrapper">
            <div class="menu-container">
              <button class="menu-toggler" (click)="toggleMenu('topic')">
                <span *ngIf="topic.length > 0">{{ topic }}</span>
                <span *ngIf="topic.length <= 0">All Topics</span>
                <i class="fa fa-caret-down" style="color: rgb(164, 161, 161)"></i>
              </button>
              <div class="menu" *ngIf="isMenuOpened == 'topic'">
                <div class="check-box-wrapper" *ngFor="let item of topics">
                    <p-checkbox name="topic{{i}}" value="{{item.post_title}}" [(ngModel)]="topic"></p-checkbox>
                    <label class="checkbox-label">{{item.post_title}}</label>
                </div>
              </div>
            </div>
          </div>
  
          <div class="elhusseniey-filter-apply-clear-wrapper flex-display align-center space-between">
            <button (click)="clear()" class="clear-btn">Clear</button>
  
            <button (click)="apply()" class="apply-btn">Apply</button>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="search-table">
    <p-table id="userActivityTable" #dt [value]="questions" [scrollable]="true" [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="selectedNum" [totalRecords]="total" [globalFilterFields]="['questions.subject','questions.system','questions.topic']">
        <ng-template pTemplate="header" class="table_header" styleClass="myTable" class="some-custom-class-name" pResizableColumn>
            <tr>
                <th pSortableColumn="id" class="id_prevTest_table" [ngStyle]="{'width':'75px'}">ID</th>
                <th pSortableColumn="subject" [ngStyle]="{'width':'130px'}">SUBJECT<p-sortIcon field="questions.subject"></p-sortIcon></th>
                <th pSortableColumn="system" [ngStyle]="{'width':'130px'}">SYSTEM<p-sortIcon field="questions.system"></p-sortIcon></th>
                <th pSortableColumn="topic" [ngStyle]="{'width':'130px'}">TOPIC<p-sortIcon field="questions.topic"></p-sortIcon></th>
                <!-- <th pSortableColumn="questions.quizMode" [ngStyle]="{'width':'120px'}">%Correct<p-sortIcon field="questions.quizMode"></p-sortIcon></th> -->
                <th pSortableColumn="actions" class="pr-5 mr-5" [ngStyle]="{'width':'100px'}">ACTIONS </th>
            </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-question let-i="rowIndex" class="table_body">
                <tr>
                    <td class="id_prevTest_table" [ngStyle]="{'width':'75px'}">{{ question.UWorldID ? question.UWorldID : question.post_id }}</td>
                    <td class="name" [ngStyle]="{'width':'130px'}" pTooltip="{{question.subject}}">{{question.subject}}</td>
                    <td class="name" [ngStyle]="{'width':'130px'}" pTooltip="{{question.system}}">{{question.system}}</td>
                    <td class="name" [ngStyle]="{'width':'130px'}" pTooltip="{{question.topic}}">{{question.topic}}</td>
                    <td class="actions pr-5" [ngStyle]="{'width':'100px'}"> 
                        <div class="action_wrapper">
                            <div class="resume_action" (click)="viewQuestions(i)" style="cursor: pointer;"><div>View</div> </div>
                        </div> 
                    </td>  
                </tr>
        </ng-template>
    </p-table>
</div>

<p-toast></p-toast>