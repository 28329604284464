<app-overlay-loader *ngIf="loader && !quizloader"></app-overlay-loader>
<app-overlay-loader *ngIf="quizloader"></app-overlay-loader>
<div class="prevTestParent">
<div class="useractivityParent" >
    <div class="useractivity_left_parent">
    <div class="useractivity_table" [ngClass]="(showCalender)?'showCalender':'hideCalender'">
        <!-- [style]="{width:'78vw'}" -->
<p-table *ngIf="!quizloader" id="userActivityTable" #dt [value]="prevQuizData" [scrollable]="true"   (onLazyLoad)="loadCustomers($event)" [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [loading]="loading"  [rows]="selectedNum" [totalRecords]="tests.length" [loading]="loading" [globalFilterFields]="['quiz_meta.quizTitle','quiz_meta.quizMode','quiz_meta.quizStatus']" dataKey="name">

    <ng-template pTemplate="caption" class="table_top_bar">
        <div class="p-d-flex p-ai-center p-jc-between">
            <h5 class="p-m-0"><div class="Dropdown_columnNumber">
                <span class="dropdown_text">Show </span> 
                <p-dropdown [options]="NumofRows" [(ngModel)]="NumofRows.numOfRows" optionLabel="numOfRows" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown> 
                <span class="dropdown_text"> entries</span>
                </div></h5>
            <span class="p-input-icon-left mr-3">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header" class="table_header" styleClass="myTable" class="some-custom-class-name" pResizableColumn>
        <tr>
            <th pSortableColumn="id" class="id_prevTest_table" [ngStyle]="{'width':'55px'}">ID </th>
            <th pSortableColumn="quiz_meta.quizTitle" class="name" [ngStyle]="{'width':'200px'}">Name <p-sortIcon field="quiz_meta.quizTitle"></p-sortIcon></th>
            <th pSortableColumn="quiz_meta.quizScore" [ngStyle]="{'width':'110px'}">SCORE <p-sortIcon field="quiz_meta.quizScore"></p-sortIcon></th>
            <th pSortableColumn="quiz_meta.quizDate" [ngStyle]="{'width':'160px'}">DATE <p-sortIcon field="quiz_meta.quizDate"></p-sortIcon></th>
            <th pSortableColumn="quiz_meta.quizMode" [ngStyle]="{'width':'120px'}">MODE <p-sortIcon field="quiz_meta.quizMode"></p-sortIcon></th>
            <th pSortableColumn="quiz_meta.quizStatus" [ngStyle]="{'width':'120px'}">STATUS <p-sortIcon field="quiz_meta.quizStatus"></p-sortIcon></th>
            <th pSortableColumn="subjects" [ngStyle]="{'width':'130px'}">SUBJECTS <p-sortIcon field="subjects"></p-sortIcon></th>
            <!-- <th pSortableColumn="PastPages" [ngStyle]="{'width': '350px'}">PAST PAGES <p-sortIcon field="PastPages"></p-sortIcon></th> -->
            <th pSortableColumn="quiz_meta.quizTotalQuestions" [ngStyle]="{'width':'150px'}">QUESTIONS <p-sortIcon field="quiz_meta.quizTotalQuestions"></p-sortIcon></th>
            <th pSortableColumn="actions" class="pr-5 mr-5" [ngStyle]="{'width':'210px'}">ACTIONS </th>
        </tr>
        
    </ng-template>
    <ng-template pTemplate="body" let-quiz let-i="rowIndex" class="table_body">
        <tr>
            <td class="id_prevTest_table" [ngStyle]="{'width':'55px'}">{{i+1}}</td>
            <td class="name" id="quizId" [ngStyle]="{'width':'200px'}">
                <div class="entry">
                    <div class="text" pTooltip="{{quiz.quiz_meta.quizTitle}}" *ngIf="!editTitle || quizId != quiz.quiz_id">
                        {{quiz.quiz_meta.quizTitle}} 
                    </div>
                    <div class="text" *ngIf="editTitle && quizId == quiz.quiz_id">
                        <input type="text" id="editTitle" [(ngModel)]="quizTitle">
                    </div>
                    <div class="icons" *ngIf="!isDemo">
                        <i class="fa fa-save" (click)="editQuizTitle(quiz, i)" *ngIf="editTitle && quizId == quiz.quiz_id"></i>
                        <i class="fa fa-edit" (click)="selectTitle(quiz)" *ngIf="!editTitle || quizId != quiz.quiz_id"></i>
                        <i style="margin-left: 5px;" class="fa fa-clipboard" (click)="copyText(quiz.quiz_id)"></i>
                        <i class="fa fa-trash" style="color: red; margin-left: 5px; font-size: 14px;" (click)="selectQuiz(quiz.quiz_id)"></i>
                    </div>
                </div>
            </td>
            <td class="score" [ngStyle]="{'width':'110px'}">{{((quiz.quiz_meta.quizScore / quiz.quiz_meta.quizTotalQuestions)* 100).toFixed(2)}}%</td>
            <td class="date" [ngStyle]="{'width':'160px'}">{{quiz.quiz_meta.quizDate}}</td>
            <td class="mode" [ngStyle]="{'width':'120px'}"> {{quiz.quiz_meta.quizMode}} </td>
            <td class="status" [ngStyle]="{'width':'120px'}" > {{quiz.quiz_meta.quizStatus}}</td>
            <td class="subjects" [ngStyle]="{'width':'130px'}" pTooltip="{{(quiz.subjects.length == 1)? quiz.subjects[0]: 'Multiple'}}">{{(quiz.subjects.length == 1)? quiz.subjects[0]: 'Multiple'}}</td>
            <!-- <td class="Past " [ngStyle]="{'width':'350px'}" [style]="{'overflow': 'visible'}"><p-dropdown [options]="test.past_pages.length > 0 ? test.past_pages: '-'" [(ngModel)]="pastPage" optionLabel="name" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown></td> -->
            <td class="noofquestions " [ngStyle]="{'width':'150px'}"> {{quiz.quiz_meta.quizTotalQuestions}}</td>
            <td class="actions pr-5" [ngStyle]="{'width':'210px'}"> 
                <div class="action_wrapper">
                    <div class="resume_action" (click)="resumeQuiz(quiz.quiz_id, quiz.quiz_meta.quizScore)" *ngIf="demo == -1"><i class="pi pi-caret-right"></i><div>Resume</div> </div>
                    <div class="resume_action" (click)="resumeQuiz(quiz.quiz_id, quiz.quiz_meta.quizScore)" *ngIf="quiz.quiz_meta.quizStatus == 'suspended' && userMembership"><i class="pi pi-caret-right"></i><div>Resume</div> </div>
                    <div class="results_action" (click)="gotoPerformanceScreen(1 , quiz.quiz_id, quiz.quiz_meta.quizTitle, quiz.subjects[0], quiz.quiz_meta.quizScore, quiz.quiz_meta.quizStatus)" ><i class="pi pi-file"></i><div>Results</div></div>
                    <div class="analysis_action mr-5 pr-5" (click)="gotoPerformanceScreen(2, quiz.quiz_id, quiz.quiz_meta.quizTitle, quiz.subjects[0], quiz.quiz_meta.quizScore, quiz.quiz_meta.quizStatus)"> <i class="pi pi-chart-bar"></i><div>Test Analysis</div> </div>
                </div> 
            </td>  
        </tr>
    </ng-template>
</p-table>
</div>
</div>

</div>

<!-- CALENDER -->
<div class="calender_parent" *ngIf="showCalender">
    <div class="previousQuiz_calender">
        <h4>Calendar</h4>
        <h3>{{currentDate}}</h3>
        <div class="date_line" *ngFor="let dates of calendarDates;">
            <h5>{{dates.Date}} <br>{{dates.Month}}</h5>
            <hr *ngIf="dates.Quiz.length <= 0">
            <div class="date_boxes" *ngIf="dates.Quiz.length > 0">
                <div class="date_box" *ngFor="let quiz of dates.Quiz; let j = index">
                    <h6 pTooltip="{{quiz}}">{{quiz}}</h6>
                    <h4>{{dates.Time[j]}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<p-dialog header="" styleClass="copyDialog" [(visible)]="displayCopy" position="center" [modal]="false" [baseZIndex]="10000" [draggable]="false">
    <span>Quiz ID Copied!</span>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="deleteModal" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Are you sure you want to delete this quiz?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="deleteQuiz()" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="deleteModal=false" label="No"></p-button>
  </ng-template>
</p-dialog>