import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { LoginComponent } from './login/login.component';
import {OverviewComponent} from "./dashboard/overview/overview.component";
import {AllCoursesComponent} from "./dashboard/all-courses/all-courses.component";
import {CourseOverviewComponent} from "./dashboard/all-courses/course-overview/course-overview.component";
import { FocusboardComponent } from './dashboard/focusboard/focusboard.component';
import { FlashcardComponent } from './dashboard/flashcard/flashcard/flashcard.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { QuizModuleComponent } from './dashboard/quiz-module/quiz-module.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';
import { UseractivityComponent } from './admindashboard/useractivity/useractivity.component';
import { GenerateQuizComponent } from './dashboard/generate-quiz/generate-quiz.component';
import { PreviousTestComponent } from './dashboard/previous-test/previous-test.component';
import { PerformanceScreenComponent } from './dashboard/performance-screen/performance-screen.component';
import { TestResultsComponent } from './dashboard/performance-screen/test-results/test-results.component';
import { TestAnalysisComponent } from './dashboard/performance-screen/test-analysis/test-analysis.component';
import { NotesComponent } from './dashboard/performance-screen/notes/notes.component';
import { OverallPerformanceComponent } from './dashboard/overall-performance/overall-performance.component';
import { PerformanceChartComponent } from './dashboard/overall-performance/performance-chart/performance-chart.component';
import { FeedbackComponent } from './admindashboard/feedback/feedback.component';
import { HomeScreenComponent } from './admindashboard/home-screen/home-screen.component';
import { LinkedQuestionsComponent } from './admindashboard/linked-questions/linked-questions.component';
import { ScoreCalculatorComponent } from './dashboard/score-calculator/score-calculator.component';
import { ChatModuleComponent } from './chat-module/chat-module.component';

import { UserchatComponent } from './dashboard/userchat/userchat.component';
import { NotebookModuleComponent } from './notebook-module/notebook-module.component';
import { SearchQuestionsComponent } from './search-questions/search-questions.component';
import { BrowseComponent } from './flashcard-module/browse/browse.component';
import { StudyComponent } from './flashcard-module/study/study.component';
import { FlashcardModuleComponent } from './flashcard-module/flashcard-module.component';
import { ResetModuleComponent } from './reset-module/reset-module.component';
import { PeerRankingComponent } from './dashboard/overall-performance/peer-ranking/peer-ranking.component';
import { UserNotebookComponent } from './dashboard/user-notebook/user-notebook.component';
import { LinkedUworldQuestionsComponent } from './admindashboard/linked-uworld-questions/linked-uworld-questions.component';
import { ReadyFlashcardsComponent } from './admindashboard/ready-flashcards/ready-flashcards.component';
import { ReadyFlashcardModuleComponent } from './ready-flashcard-module/ready-flashcard-module.component';
import { AITestAnalysisComponent } from './dashboard/ai-components/ai-test-analysis/ai-test-analysis.component';
import { AiFlashcardModuleComponent } from './ai-flashcard-module/ai-flashcard-module.component';
import { InstructorsComponent } from './admindashboard/instructors/instructors.component';


const routes: Routes = [

  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'authentication',
    component: AuthenticationComponent
  },
  {
    path: 'admin',
    component: AdmindashboardComponent,
    children: [
      {
        path: 'chat',
        component: ChatModuleComponent
      },
      {
        path: 'useractivity',
        component: UseractivityComponent
      },
      {
        path: 'feedback',
        component: FeedbackComponent
      },
      {
        path: 'home',
        component: HomeScreenComponent
      },
      {
        path: 'profile/:id',
        component: ProfileComponent
      },
      {
        path: 'performance',
        component: OverallPerformanceComponent
      },
      {
        path: 'linkedquestions',
        component: LinkedQuestionsComponent
      },
      {
        path: 'linked-uworld-questions',
        component: LinkedUworldQuestionsComponent
      },
      {
        path: 'ready-decks',
        component: ReadyFlashcardsComponent
      },
      {
        path: 'manage-instructors',
        component: InstructorsComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'welcome',
        component: OverviewComponent
      },
      {
        path: 'courses',
        component: AllCoursesComponent
      },
      {
        path: 'course-overview/:id',
        component: CourseOverviewComponent
      },
      {
        path: 'flashcards',
        component: FlashcardComponent
      },
      {
        path: 'ready-flashcards',
        component: ReadyFlashcardModuleComponent
      },
      {
        path: 'ai-flashcards',
        component: AiFlashcardModuleComponent
      },
      {
        path: 'profile/:id',
        component: ProfileComponent
      },
      {
        path: 'generatequiz',
        component: GenerateQuizComponent
      },
      {
        path: 'previoustest',
        component: PreviousTestComponent
      },
      {
        path: 'notes',
        component: NotesComponent
      },
      {
        path: 'scorecalculator',
        component: ScoreCalculatorComponent
      },
      {
        path: 'overallperformance',
        component: OverallPerformanceComponent
      },
      {
        path: 'peerranking',
        component: PeerRankingComponent
      },
      {
        path: 'overallperformancechart',
        component: PerformanceChartComponent
      },
      {
        path: 'search',
        component: SearchQuestionsComponent
      },
      {
        path: 'chat',
        component: UserchatComponent
      },
      {
        path: 'adminchat',
        component: ChatModuleComponent
      },
      {
        path: 'flashcard',
        component: FlashcardModuleComponent,
        children: [
          {
            path: 'browse',
            component: BrowseComponent
          },
          {
            path: 'study',
            component: StudyComponent
          }

        ]
      },
      {
        path: 'archivedNotebook',
        component: NotebookModuleComponent
      },
      {
        path: 'notebook',
        component: UserNotebookComponent
      },
      {
        path: 'reset',
        component: ResetModuleComponent
      },
      {
        path: 'performancescreen',
        component: PerformanceScreenComponent,
        children: [
          {
            path: 'results',
            component: TestResultsComponent
          },
          {
            path: 'analysis',
            component: TestAnalysisComponent
          }
        ]
      },
      {
        path: 'ai-test-analysis',
        component: AITestAnalysisComponent
      },
    ]
  },
  {
      path: 'focusboard/:cid/:lid/:tid',
      component: FocusboardComponent
  },
  {
    path: 'quiz',
    component: QuizModuleComponent
  },
  {
    path: '**',
    component: FocusboardComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes ,
    { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
