import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL, createAuthorizationHeader } from "../../models/globals";

@Injectable({
  providedIn: 'root'
})
export class NotebookService {
  baseUrl = BASE_URL;

  constructor(private httpClient: HttpClient) { }

  addUserNotebook(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/addUserNotebook', { data },
    { headers: createAuthorizationHeader() });
  }

  getUserNotebook(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getUserNotebook', { data },
    { headers: createAuthorizationHeader() });
  }

  deleteUserNotebook(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/deleteUserNotebook', { data },
    { headers: createAuthorizationHeader() });
  }

  swapUserNotebook(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/swapUserNotebook', { data },
    { headers: createAuthorizationHeader() });
  }

  updateLatestNotebook(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateLatestNotebook', { data },
    { headers: createAuthorizationHeader() });
  }

  generateUsingOpenAI(data: any) {
    return this.httpClient.post(this.baseUrl + '/openai/generate', { data });
  }

  updateNotebookSummary(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateNotebookSummary', { data },
    { headers: createAuthorizationHeader() });
  }

  generateVoiceUsingOpenAI(data: any) {
    return this.httpClient.post(this.baseUrl + '/openai/generate-voice', { data });
  }

  updateNotebookVoiceNotes(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateNotebookVoiceNotes', { data },
    { headers: createAuthorizationHeader() });
  }

  deleteNotebookVoiceNotes(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/deleteNotebookVoiceNotes', { data },
    { headers: createAuthorizationHeader() });
  }

  updateNotebookReferenceLinks(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateNotebookReferenceLinks', { data },
    { headers: createAuthorizationHeader() });
  }

  updateNotebookFlashcards(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateNotebookFlashcards', { data },
    { headers: createAuthorizationHeader() });
  }

  getNotesLanguages() {
    return this.httpClient.get(this.baseUrl + '/new/getNotesLanguages',
    { headers: createAuthorizationHeader() });
  }

  getNotebookTranslations(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getNotebookTranslations', { data },
    { headers: createAuthorizationHeader() });
  }

  updateNotebookTranslations(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/updateNotebookTranslations', { data },
    { headers: createAuthorizationHeader() });
  }

  deleteNotebookTranslations(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/deleteNotebookTranslations', { data },
    { headers: createAuthorizationHeader() });
  }

  getAWSS3CloudFrontLink(data: any) {
    return this.httpClient.post(this.baseUrl + '/new/getS3CloudFrontLink', { data });
  }
}
