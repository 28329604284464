
<p-dataView #dv  [value]="notes" [paginator]="true" [rows]="selectedNumber" filterBy="notes" layout="grid">

      <ng-template  pTemplate="header">
          <div class="top_head">
              <span class="dropdown">
                <h5 class="p-m-0"><div class="Dropdown_columnNumber">
                    <span class="dropdown_text">Show </span>
                    <p-dropdown [options]="NumofRows" [(ngModel)]="NumofRows.numOfRows" optionLabel="numOfRows" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown>
                    <span class="dropdown_text"> entries</span>
                    </div></h5>
              </span>
              <span class="p-input-icon-right p-mb-2 p-mb-md-0">
                  <i class="pi pi-search"></i>
                  <input type="search" pInputText placeholder="Search Notes" (input)="dv.filter($event.target.value)">
              </span>
          </div>
          <div class="col-sm-12 mt-4 pt-2 pb-2 text-light" style="background-color: #7583F0; border-radius: 5px;">
            Notes
        </div>
      </ng-template>


      <ng-template let-note let-i="rowIndex" pTemplate="gridItem">
          <div class="p-col-12 mt-3 mb-3 data_Body">
              <div class="data_notes">{{note.notes}}</div>
          <div class="p-col-12 data_body_bottom">
              <div class="bottom_left_content">
                  <div>  <span style="color: #1A3350;">Test Name: </span>  <span style="color: #000000;">{{note.quiz_name}}</span></div>
                  <div class="mx-4"> <span style="color: #1A3350;">Question ID: </span>  <span style="color: #000000;">{{note.UWorldID ? note.UWorldID : note.question_id}}</span></div>
                  <!-- <div>  <span style="color: #1A3350;">System: </span>  <span style="color: #000000;">{{note.System}}</span></div> -->
              </div>
              <div>
                  <i class="pi pi-book mr-3" style="color: rgba(24, 200, 83, 0.828);" (click)="getSpecificQuestion(note.question_id, i, note.quiz_id)"></i>
                  <i *ngIf="!isdemo" class="pi pi-trash mr-2" style="color: red;" (click)="deleteNote(note)"></i>
                  <i class="pi pi-pencil ml-2" style="color: rgb(46, 154, 255);" (click)="editNote(note)"></i>
              </div>

          </div>

        </div>


        <p-dialog header="Edit Note" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="p-field p-col-12 p-md-4" style="width: 100%">
        <textarea inputId="textarea" rows="8" cols="60" [(ngModel)]="editNoteValue" pInputTextarea  placeholder="Textarea"></textarea>
    </div>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="editNotes()" label="Edit" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>
      </ng-template>

  </p-dataView>
