<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<div class="notebook-root">
  <div class="sidebar">
    <i class="fa fa-times" (click)="openNotebook()" *ngIf="sidebar"></i>
    <i class="fa fa-bars" (click)="openNotebook()" *ngIf="!sidebar"></i>
  </div>
  <div class="list" id="list">
    <section class="container">
      <div class="search-contact-wrapper">
        <span class="p-input-icon-right" style="width: 100%;">
          <i style="cursor: pointer; color: black;" class="pi pi-search" *ngIf="!searcher" (click)="onSearchChange($event)"></i>
          <i style="cursor: pointer; color: black;" class="pi pi-times" *ngIf="searcher" (click)="cancelSearch($event)"></i>
          <input type="text" pInputText [(ngModel)]="searchString" placeholder="Search" (input)="searcher = false" />
        </span>
      </div>
      <!-- button for creating a Note -->
      <div class="create-note-wrapper">
        <button class="create-note-btn">
          Your Notes
        </button>
      </div>
      <div class="accordian-wrapper">
        <div class="" *ngFor="let qbank of qbanks; let i = index" id="qbank{{i}}">
          <ng-template [ngIf]="qbank.enable">
            <div class="content">
              <div class="header_text" id="qbankheader{{i}}" (click)="sendItem(qbank.name, '', '', '', i, -1,-1, -1, qbank.description, qbank.createdDate, qbank.updatedDate, true)">
                <span class="text{{i}}"><i [ngClass]="(qbank.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{qbank.name}}</span>
              </div>
            </div>
            <div>
              <div class="hide" *ngFor="let subject of qbank.subjects; let a = index" id="subject{{i}}{{a}}" @slideDownUp>
                <ng-template [ngIf]="subject.enable">
                  <div class="content">
                    <div class="header_text" id="subjectheader{{i}}{{a}}" style="margin-left: 8px;" (click)="sendItem(qbank.name, subject.name, '', '', i, a, -1, -1, subject.description, subject.createdDate, subject.updatedDate, true)">
                      <span class="text{{i}}{{a}}"><i [ngClass]="(subject.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{subject.name}}</span>
                    </div>
  
                  </div>
                  <div>
                    <div class="hide" *ngFor="let system of subject.system; let b = index" id="system{{i}}{{a}}{{b}}" @slideDownUp>
                      <ng-template [ngIf]="system.enable">
                        <div class="content">
                          <div class="header_text" id="systemheader{{i}}{{a}}{{b}}" style="margin-left: 16px;" (click)="sendItem(qbank.name, subject.name, system.name, '', i, a, b, -1, system.description, system.createdDate, system.updatedDate, true)">
                            <span class="text{{i}}{{a}}{{b}}"><i [ngClass]="(system.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{system.name}}</span>
                          </div>
                        </div>
                        <div>
                          <div class="hide topic-wrapper" *ngFor="let topic of system.topics; let t = index" id="topic{{i}}{{a}}{{b}}{{t}}" @slideDownUp>
                            <ng-template [ngIf]="topic.enable">
                              <span class="text{{i}}{{a}}{{b}}{{t}}" id="topicheader{{i}}{{a}}{{b}}{{t}}" style="margin-left: 38px;" (click)="sendItem(qbank.name, subject.name, system.name, topic.name, i, a, b, t, topic.description, topic.createdDate, topic.updatedDate, true)">
                                <i class="fa fa-circle"></i>
                                {{ topic.name }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </div>
        <!-- input checkbox will be displayed none and label will be used instead  -->
      </div>

      <!--/ac-->
    </section>
  </div>
  <div class="data" id="data">
    <!-- <app-course-view [items]="items"></app-course-view> -->
    <div class="root pt-3 pl-1">
      <div class="template-body flex-column align-center justify-center" *ngIf="!newNotebook">
        <div class="template-header">
          <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
        <div class="template-content-wrapper">
          <div class="content-heading-wrapper mb-3">
            <h3 style="font-weight: 500; font-size: 1.4rem !important; margin-top: 5px;">Getting Started</h3>
          </div>
          <div class="template-dates-wrapper">
            <hr>
            <div class="dates">
              <span>Created: Apr 21, 2022</span>
              <span>Updated: Apr 21, 2022</span>
            </div>
            <hr>
          </div>
          <div class="template-description-wrapper">
            <div class="notebookdialog" style="width: 100%;">
              <span *ngIf="!isEdit" [innerHTML]="sampleText"></span>
            </div>
            <p>

            </p>
          </div>
          <div class="bottom_btns mt-3" *ngIf="showSave">
            <button class="cancel_btn mr-3" (click)="cancelEdit()">Cancel</button>
            <button class="save_btn" (click)="saveNotebooktoDB()">Save</button>
          </div>
        </div>
      </div>
      <div class="template-body flex-column align-center justify-center" *ngIf="newNotebook">
        <div class="note">
          <strong>Note: </strong>This notebook has been archived. To add new notes, please use the <strong>'My Notebook'</strong> tab. 
        </div>
        <div class="template-header">
          <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
        <div class="template-content-wrapper">
          <div class="content-heading-wrapper mb-3">
            <input type="text" *ngIf="isEdit" (keyup)="SaveNote()" [(ngModel)]="heading">
            <span *ngIf="!isEdit">{{heading}}</span>
            <span class="pr-3 ">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="isEdit ? 'disabled' : ''">
                  <i class="pi pi-ellipsis-h"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="runQuill()"><i class="pi pi-plus mr-2"></i> Edit</a>
                </div>
              </div>
            </span>
          </div>
          <div class="template-dates-wrapper">
            <hr>
            <div class="dates">
              <span>Created: {{currentDate}}</span>
              <span *ngIf="updatedDate">Last Updated: {{updatedDate}}</span>
            </div>
            <hr>
          </div>
          <div class="template-description-wrapper">
            <div class="notebookdialog" id="notebookdialog" style="width: 100%;">
              <span id="selection" *ngIf="isEdit && quizEdit" (click)="updateText()">Add Content Here
              </span>
              <ckeditor *ngIf="isEdit" id="ckEditorNotebook" [(ngModel)]="notebookEditor"></ckeditor>
              <span *ngIf="!isEdit" [innerHTML]="notebookText | sanitizer" class="notebookText"></span>
            </div>
            <p>

            </p>
          </div>
          <div class="page-loader" *ngIf="isSave">
            <div class="loader"></div>
          </div>
          <div class="bottom_btns mt-3" *ngIf="showSave && !isSave">
            <button class="cancel_btn mr-3" (click)="cancelEdit()">Cancel</button>
            <button class="save_btn" (click)="saveNotebooktoDB()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<p-dialog header="Confirmation" [(visible)]="displayDelete" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Do you want to delete this note?</p>
  <div class="delete_content">
    <span>Type <strong>`delete`</strong> to confirm deletion.</span>
    <input type="text" [(ngModel)]="confirmDeletion">
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayDelete=false" label="No"></p-button>
    <p-button icon="pi pi-check" (click)="deleteNote()" label="Yes" [disabled]="confirmDeletion != 'delete'" class="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="displaySave" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Do you want to save this note?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="saveNotebooktoDB();" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displaySave=false" label="No"></p-button>
  </ng-template>
</p-dialog>


<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">

</p-confirmDialog>

<p-toast></p-toast>