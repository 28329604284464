
<div class="useractivityParent">
    <div class="useractivity_left_parent">
    <div class="Dropdown_useractivity">
        <div class="Dropdown_columnNumber">
       <span class="dropdown_text">Show </span> 
       <p-dropdown [options]="NumofRows" [(ngModel)]="NumofRows.numOfRows" optionLabel="numOfRows" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown> 
       <span class="dropdown_text"> entries</span>
       </div>
       <div class="Searchbar_wrapper">
           <input type="text" class="searchbar" placeholder="Search Table Field"> 
           <i class="fa fa-search"></i> 
       </div>
    </div>
    <div class="useractivity_table">
<p-table id="userActivityTable"  [value]="usersActivityData"  [scrollable]="true" [style]="{width:'80vw'}"  (onLazyLoad)="loadCustomers($event)"
    [paginator]="true" [loading]="loading"  [rows]="selectedNum" [totalRecords]="usersActivityData.length" [loading]="loading" [globalFilterFields]="['user.user_nicename','device', 'company', 'representative']" dataKey="name">

    <ng-template pTemplate="header" styleClass="myTable" class="some-custom-class-name">
        <tr>
            <th pSortableColumn="id" [ngStyle]="{'width':'55px'}">ID </th>
            <th pSortableColumn="name" [ngStyle]="{'width':'110px'}">USER <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="Email" [ngStyle]="{'width':'140px'}">EMAIL <p-sortIcon field="email"></p-sortIcon></th>
            <th pSortableColumn="IPAddress" [ngStyle]="{'width':'160px'}">IP ADDRESS <p-sortIcon field="IPAddress"></p-sortIcon></th>
            <th pSortableColumn="browser" [ngStyle]="{'width':'130px'}">BROWSER <p-sortIcon field="browser"></p-sortIcon></th>
            <th pSortableColumn="CurrentPage" [ngStyle]="{'width':'130px'}">DEVICE <p-sortIcon field="CurrentPage"></p-sortIcon></th>
            <th pSortableColumn="CurrentPage" [ngStyle]="{'width':'160px'}">CURRENT PAGE <p-sortIcon field="CurrentPage"></p-sortIcon></th>
            <th pSortableColumn="PastPages" [ngStyle]="{'width': '350px'}">PAST PAGES <p-sortIcon field="PastPages"></p-sortIcon></th>
            <th pSortableColumn="visits" [ngStyle]="{'width':'60px'}">VISITS <p-sortIcon field="visits"></p-sortIcon></th>
            <!-- <th pSortableColumn="ActiveVisitors" [ngStyle]="{'width':'70px'}">ACTIVE VISITORS <p-sortIcon field="ActiveVisitors"></p-sortIcon></th> -->
        </tr>
        
    </ng-template>
    <ng-template pTemplate="body" let-customer let-i="rowIndex">
        <tr>
            <td [ngStyle]="{'width':'55px'}">{{i+1}}</td>
            <td class="name" [ngStyle]="{'width':'110px'}">{{customer.user.user_nicename}}</td>
            <td class="email" [ngStyle]="{'width':'140px'}"> {{customer.user.user_email}}</td>
            <td class="IpAddress" [ngStyle]="{'width':'160px'}">{{customer.ip_address}}</td>
            <td class="browser" [ngStyle]="{'width':'130px'}"><i class="fa" [ngClass]="[customer.browser=='Opera'?'fa-opera':'' || customer.browser=='Chrome'?'fa-chrome':''|| customer.browser=='Safari'?'fa-safari':'']"></i> {{customer.browser}}</td>
            <td class="Device" [ngStyle]="{'width':'130px'}"><i class="fa fa-laptop mr-1"></i>{{customer.device}}</td>
            <td class="Current" [ngStyle]="{'width':'160px'}">{{customer.current_page}}</td>
            <td class="Past " [ngStyle]="{'width':'350px'}" [style]="{'overflow': 'visible'}"><p-dropdown [options]="customer.past_pages.length > 0 ? customer.past_pages: '-'" [(ngModel)]="pastPage" optionLabel="name" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown></td>
            <td class="visits" [ngStyle]="{'width':'60px'}">{{customer.visits}}</td>
            <!-- <td class="ActiveVisitors" [ngStyle]="{'width':'70px'}">{{customer.ActiveVisiors}}</td> -->
            
        </tr>
    </ng-template>
</p-table>
</div>
</div>

</div>