<div class="root">
  <div class="template-body flex-column align-center justify-center">
    <div class="template-header">
      <p-breadcrumb [model]="items"></p-breadcrumb>
    </div>
    <div class="template-content-wrapper">
      <div class="content-heading-wrapper mb-3">
        <input type="text" [(ngModel)]="heading" (click)="isEdit = true">
        <span class="pr-3 ">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="pi pi-ellipsis-h"></i>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="isEdit = !isEdit"><i class="pi pi-pencil mr-2"></i> Edit</a>
              <a class="dropdown-item" (click)="deleteNote()"><i class="pi pi-trash mr-2"></i> Delete</a>
            </div>
          </div>
        </span>
      </div>
      <div class="template-description-wrapper">
        <div class="notebookdialog" style="width: 100%;">
          <p-editor *ngIf="isEdit" [(ngModel)]="notebookText" [style]="{height:'50vh', width:'100%'}"></p-editor>
          <span *ngIf="!isEdit" [innerHTML]="notebookText"></span>
        </div>
        <p>

        </p>
      </div>
      <div class=""></div>
    </div>
  </div>
</div>
