<div class="usmle-score-cal-root">
    <div class="usmle-score-cal-parent flex-display align-center justify-center">
        <div class="usmle-score-cal-body flex-column  justify-center">
            <div class="usmle-select-formula-wrapper flex-display align-center ">
                <div class="usmle-formula-dropdown-wrapper flex-column  justify-center">
                    <div class="usmle-formula-dropdown-heading">
                    <h5>Select Formula</h5>
                    </div>
                    <div class="dropdown">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{sectionValue}}
                       </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item"  *ngFor="let item of filterOptions" (click)="setSection(item)">{{item.name}}</a>
                        </div>
                      </div>
                </div>
                <div class="usmle-input-total-number-wrapper  flex-column  justify-center">
                     <h5 *ngIf="!heading" >Enter Total Number Of Incorrect Answers</h5>
                     <h5 *ngIf="heading" >Enter Total Number Of Correct Answers</h5>
                     <input type="text" [(ngModel)]= "numberofanswers"  >
                </div>
              
                <div class="usmle-calculate-btn-wrapper  flex-column  justify-center">
                  <button (click)= "calculateScore()">Calculate</button>
                </div>

            </div>



            <div class="usmle-predicted-digit-wrapper">
              <h5>Predicted 3-Digit Score:</h5>

              <input type="text" [(ngModel)]= "result" readonly>
              <p >{{sectiontext}}</p>
            </div>
            <div class="usmle-graph-image-wrapper" *ngIf="sectionImg!=null">
               <img src="{{sectionImg}}">
            </div>
        </div>

    </div>

</div>