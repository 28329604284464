<div class="eup_reset_root">
    <div class="eup_reset_parent">
        <div class="reset_options">
            <span *ngFor="let option of options; let i = index;" (click)="changeIndex(i)" [ngStyle]="{'color': i == activeIndex ? '#2196F3' : 'black', 'border-bottom': i == activeIndex ? '2px solid #2196F3' : 'none'}">{{option}}</span>
        </div>
        <hr>
        <div class="reset_content">
            <span [innerHTML]="values[activeIndex]"></span>
        </div>
        <div class="reset_button" *ngIf="activeIndex == 0">
            <div class="buttons" style="display: flex; align-items: center;">
                <button style="margin-right: 20px;" *ngFor="let course of courses" (click)="resetContent(course.ID)" [disabled]="timerCheck">Reset {{course.Title}}</button>
            </div>
        </div>
        <div class="reset_button" *ngIf="activeIndex != 0">
            <div class="buttons" style="display: flex; align-items: center;">
                <button (click)="resetContent(null)" [disabled]="timerCheck">{{buttons[activeIndex]}}</button>
            </div>
        </div>
    </div>
</div>

<p-dialog header="{{buttons[activeIndex]}}" [(visible)]="displayTimer" [modal]="true" [style]="{width: '280px'}">
    <div class="timer_parent">
        <div class="timer">
            <span>You have <strong>{{timer}}</strong> seconds to undo your action.</span>
        </div>
        <div class="undo_button">
            <button (click)="stopTimer()">Undo</button>
        </div>
    </div>
</p-dialog>

<p-toast></p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
</p-confirmDialog>