<div class="eup_flashcards">
    <div class="courses">
        <h2>My Decks</h2>
      </div>
      <hr>
    <button (click)="module = 'browse'" [ngStyle]="{'color' : module == 'browse' ? '#2196F3' : 'black'}">Browse</button>
    <button (click)="module = 'study'" [ngStyle]="{'color' : module == 'study' ? '#2196F3' : 'black'}">Study</button>

    <app-browse *ngIf="module == 'browse'" [courseId]="selectedCourse"></app-browse>
    <app-study *ngIf="module == 'study'" [courseId]="selectedCourse"></app-study>
</div>
