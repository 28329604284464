<div class="test_analysis_root">
<div class="test_analysis_parent">
<!-- Charts wrapper -->
<app-analysis-chart></app-analysis-chart>
<!-- Chart Component Ends -->

  <!-- Table Component Starts -->
<app-analysis-table></app-analysis-table>
  <!-- Table Component Starts -->

  
</div>
</div>