import { Injectable } from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }
  // Handle all type of errors and status codes here
  handleError(error: any){
    if(error instanceof HttpErrorResponse && error.status === 401){
      this.AutoLogout();
    }
  }

  AutoLogout(){
    const arr = ['getCategories','getQuestionNUmbers','prevQuizData','UserName', 'ifAdmin', 'quizTitle', 'timeMode', 'UserEmail', 'showsubjectdetails','lessonNumber', 'Id', 'CourseDetailsAPIResult', 'coursearray', 'CourseID', 'CompletedTopicsIds', 'topicID',
  'CurrentIndex', 'AllQuestions', 'token', 'lessonName', 'SubmittedQuestions', 'strikedThroughOptions', 'email','QuizId','NotesArray','LessonID', 'QuizTheme', 'FontSize', 'flaggedQuestion', 'FeedbackArray', 'subscriptions'];
  arr.forEach(i => {
    localStorage.removeItem(i);
  });
    window.location.href = '';
  }
}
