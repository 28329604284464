<app-overlayfullscreen *ngIf="quizloader"></app-overlayfullscreen>

<!-- Getting time mode and tutoe Exam Mode -->
<p-dialog header="Test Mode" [(visible)]="modeModel" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div class="mode_wrapper">
      <div *ngFor="let mode of TestMode" class="p-field-checkbox">
        <p-radioButton [inputId]="mode.key" name="mode" [value]="mode" [(ngModel)]="selectedMode"></p-radioButton>
        <label [for]="mode.key">{{mode.name}}</label>
    </div>

    </div>
    <div>
      <h5>Time Mode</h5>
      <p-inputSwitch [(ngModel)]="timeMode"></p-inputSwitch>
    </div>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="getQuiz(QuizId, quizTitle)" label="Take Quiz" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>

<div class="eup-fb-root" >
    <div class="eup-fb-parent">
      <div class="eup-fb-sub-parent">
        <div class="eup-fb-grid-wrapper">
            <!-- Top Bar -->
            <div class="nav-wrapper">
                <div class="logo mt-2 ml-4 ">
                    <img class="pl-3 mt-1" src="../../../../assets/images/logo-edit.png" alt="" srcset="" width="55%" >
                </div>
                <div class="mt-3 ml-2 mr-3 hamburger" (click)="hamburgerclicked()">
                  <i class="fa fa-bars fa-2x" style="color: #FFFFFF;"></i>
                </div>

                <div class="percent">
                    <div class="progress mt-3 mr-3">
                        <div class="progress-bar" role="progressbar" style="width: {{percentage1}}%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <p class="progresstext">{{percentage1}}% complete</p>

                </div>
                <div class="complete pt-3 text-center" (click)="(topicCompleteloader)?'':TopicCompleted()">
                    <p  class="navtext pt-1" ><i class="fa fa-check pl-2"> Mark Complete</i> </p>
                </div>
                <div class="previous pt-3 text-center" (click)="topPrevLesson()">
                  <div class="topbaricontext pt-1" >
                    <div style="display: flex;">
                    <div class="icon mr-1"><i class="fa fa-chevron-left pl-3 mr-1"> </i></div>
                    <div class="text1"><p class="navtext"> Previous Lesson</p></div>
                  </div>
                  </div>
                </div>
                <div class="next pt-3 text-center" (click)="topnextLesson()">
                  <div class="topbaricontext pt-1" >
                    <div style="display: flex;">
                    <div class="text1"><p class="navtext"> Next Lesson</p></div>
                    <div class="icon ml-1"><i class="fa fa-chevron-right ml-1"> </i></div>
                  </div>
                  </div>
                </div>

                <div class="user pt-2 text-center ">
                <!-- <a routerLink="/dashboard/profile"> -->
                  <div class="topbaricontext dropdown">
                    <div class="topbar-user" data-toggle="dropdown">
                    <div style="display: flex;">
                    <div class="text1 pt-1"><p class="navtextsmall pt-2">Hello {{UserName}}! </p></div>
                    <div class="icon ml-1"><i class="fa fa-user-circle-o fa-3x ml-2"></i></div>
                  </div>
                </div>
                <ol class="dropdown-menu">
                  <li class="pt-3 h6 " routerLink="/dashboard/profile/1"><a  ><i class="fa fa-user-circle-o"></i> Profile</a></li>
                  <li class="pt-3 h6" routerLink="/dashboard/courses" ><a ><i class="fa fa-sticky-note"></i> Courses</a></li>
                  <li class="pt-3 h6" (click)="logout()"><a><i class="fa fa-sign-out"></i> Logout</a></li>
                </ol>
                  </div>
                    <!-- </a> -->
                </div>
            </div>
            <!-- Main Content -->
            <div class="coursecontent">
              <!-- *********************************************************************************************************** -->
                <!-- THE SIDE BAR -->
                <div class="sidebar_Content" [ngClass]="[isopen == true ? 'flex-20' : 'flex-0']">
            <div id="sidebar" class="sidebar mb-5" >


                <!-- HEADING -->
                <div class="side-heading">
                    <p class="sideheading text-center text-light pt-1 pb-1 white base-bg1">{{courseTitle}}</p>
                </div>
                <!-- PROGRESS BAR -->

                <div class="percentage">
                  <div class="mt-3 mb-3" *ngIf="topicCompleteloader">
                  <app-overlay ></app-overlay>
                </div>
                        <div class="progress mt-3 ml-2 mr-2"  *ngIf="!topicCompleteloader">
                            <div class="progress-bar" role="progressbar" style="width: {{percentage1}}%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p class="progressbartext ml-2"  *ngIf="!topicCompleteloader">{{percentage1}}% complete</p>
                </div>
                <!-- MODULE CONTENT -->

                <!-- Content of old Accordion  -->
                <!-- <p-accordion [multiple]="true" *ngFor="let lesson12 of lessondescription; let i = index">
                  <p-accordionTab header="Lesson {{i+1}}: {{lesson12.post_title}}" (click)="getLessondetails(lesson12.ID)">
                    <ul *ngFor="let topic of topicsarray">
                        <li>{{topic.post_title}}</li>
                      </ul>
                  </p-accordionTab>
              </p-accordion> -->
              <!-- COntent ends of OLD ACCORDION -->

              <app-overlay *ngIf="loader"></app-overlay>
              <!-- NEW ACCORDION -->
              <div class="leftsideAccordion">
              <div  *ngFor="let course of lessondescription; let i = index" >
              <p-accordion [multiple]="true"  *ngIf="course.topics.length > 0" >
                <!-- (click)="getLessondetails(course.lesson.ID)" -->
                <p-accordionTab  class="accordion" [selected]="lessonNumber-1 == i? true : false" [ngClass]="[lessondescription[lessonNumber-1].lesson[0].ID == course.lesson[0].ID ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right']" >
                  <p-header>
                    <div class="knob_heading" style="display: flex; flex-direction: row; align-items: center;">
                    <span class="mr-1"><p-knob valueColor="#18c02f" rangeColor="#FFFFFF" [size]="20" [(ngModel)]="knobArray[i]" [readonly]="true" valueTemplate=""></p-knob></span>
                    <span> Lesson {{i+1}}: {{course.lesson[0].post_title}}</span>
                  </div>
                  </p-header>
                    <ul *ngFor="let topic of course.topics">
                      <li class="topicsnames mb-1" [ngClass]="[topicId == topic.topic.ID ? 'font-weight-bold' : '']" (click)="showData(topic.topic.ID,course.lesson[0].post_title , i+1, course.lesson[0].ID)"> <i class="fa fa-check-square mr-2" [ngClass]="topic.topic.isCompleted == true?'Check_Square':'Hide_Check_Square'" ></i> {{topic.topic.post_title}}</li>
                      <div *ngFor="let quiz of topic.quizzes">

                        <span class="topicQuizzes Quiz_names mt-1 pl-2 pl-md-3" (click)="getQuizMode(quiz.ID, quiz.post_title)"><i class="fa fa-paste"></i> {{quiz.post_title}}</span>

                      </div>
                    </ul>
                    <!-- <div *ngFor="let quiz of course.quizzes; let j = index">
                    <p class="font-weight-bold base-color1" *ngIf="j == 0" >QUIZZES</p>
                  </div> -->
                      <ul *ngFor="let quiz1 of course.quizzes; let i = index">
                        <li class="pl-2 pl-md-3" (click)="getQuizMode(quiz1.ID,quiz1.post_title)"> <i class="fa fa-paste"></i> {{quiz1.post_title}}</li>
                      </ul>
                        <!-- <button class="btn btn-primary pl-3 pr-3" >Get Details</button> -->

                </p-accordionTab>

            </p-accordion>
            <div class="title" *ngIf="course.topics.length == 0" (click)="showData(-1,course.lesson[0].post_title , i+1, course.lesson[0].ID)">
              <div class="knob_heading" style="display: flex; flex-direction: row; align-items: center;">
                <span class="mr-1"><p-knob valueColor="#18c02f" rangeColor="#FFFFFF" [size]="20" [(ngModel)]="knobArray[i]" [readonly]="true" valueTemplate=""></p-knob></span>
                <span> Lesson {{i+1}}: {{course.lesson[0].post_title}}</span>
              </div>
            </div>
          </div>
        </div>


                <!-- <div id="accordion" *ngFor="let topic of topicsarray; let i = index">
                    <div class="card">
                      <div class="card-header" id="accordion">
                        <h5 class="mb-0">
                          <button class="btn base-bg1 w-100 py-3" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div style="display: flex; flex-direction: row;">
                            <p style="width: 90%;"> Module {{i+1}}: {{topic.post_title}}</p>
                            <p style="width: 10%;"><i class="fa fa-angle-down"></i></p>
                        </div>
                          </button>
                        </h5>
                      </div>

                      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                          <ul>
                              <li>abcd</li>
                              <li>bced</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="divider " style="height: 30px; background: #FFFFFF;">

                  </div>

                  <!-- ############ FOR QUIZZES OF COURSES ###################################33 -->
                  <!-- <div class="Quiz mb-2" *ngIf="!loader">
                      <p class="pl-3 pt-2 base-color1 font-weight-bold">Quizzes</p>
                      <ul class="pl-3 coursequizzes" >
                          <li *ngFor="let quiz of lessonQuizzes" (click)="getQuiz(quiz.ID)">
                              {{quiz.post_title}}
                          </li>
                      </ul>

                  </div> -->

            </div></div>
            <!-- ************************************************ MAIN CONTENT ************************************************ -->
            <div class="eup_MainContent" (scroll)="onElementScroll($event)" [ngClass]="[isopen == true ? 'flex-80' : 'flex-100']">
            <div id="maincontent" class="maincontent" >
                <h4 class="greencolor mb-3">
                  <!-- Lesson {{lessonNumber}}: {{lessonName}} -->
                  {{Topic_Title}}
                </h4>

                <!-- video  -->
                <!-- <video width="100%" height="" controls>
                    <source src="movie.mp4" type="video/mp4">
                </video> -->

                <!-- Topics detail will be shown here -->
                <div>
                  <div style="min-height: 40vh;" *ngIf="topicloader">
                    <div style="padding-top: 20vh;">
                  <app-overlay ></app-overlay>
                </div>
                </div>
                <!-- NgIf="topicloader === 0" -->
                <div *ngIf="containsTable">
                  <input id="search_bar" class="form-control col-md-6 col-lg-4 mb-2 mt-2" placeholder="Search.." (keyup)="searchFunction($event)">
                </div>

                <div class="Video_Content_Area" #testDiv id="Video_Content_Area">
                <div id="embedBox" style="width:1280px;max-width:100%;height:auto;"></div>
                <iframe *ngIf="videoURL && !topicloader" 
                [src]=videoURL
                style="width: 1280px; max-width: 100%; height: 350px;"
                frameborder="0"
                allow="encrypted-media"
                allowfullscreen
              ></iframe>
                </div>


                <div class="table_Area" #testDiv1 id="table_Area">
                </div>
                <div class="text-center"  id="lessondescriptionid">  </div>

                <div *ngIf="!topicloader">
                <div class="quiz_Nam" *ngFor="let topic3 of lessondescription[lessonNumber-1].topics" >
                  <div *ngIf="topic3.topic.ID == topicId">
                  <div *ngFor="let quiz2 of topic3.quizzes" >
                   <h5 class="coursequizzes pl-2 pl-md-3"> <li (click)="getQuizMode(quiz2.ID, quiz2.post_title)"> <i class="fa fa-paste"></i> {{quiz2.post_title}}</li> </h5>
                </div>
                </div>
              </div>
            </div>


              </div>



                <!-- <div *ngFor="let description of lessonDescription">
                  <p>{{description.post_content}} </p>

                </div> -->

                <!-- <p id="lessondescriptioni" class="small mt-4">DESCRIPTION: Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque vel impedit quidem corrupti eum provident nam quaerat. Accusantium, praesentium similique temporibus molestias esse quam dolorum dolorem, aspernatur sunt quisquam necessitatibus?</p>

                <div>
                <p class="greencolor font-weight-bold Roboto">Learning Outcome</p>
                <p class="small mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque vel impedit quidem corrupti eum provident nam quaerat. Accusantium, praesentium similique temporibus molestias esse quam dolorum dolorem, aspernatur sunt quisquam necessitatibus?</p>
            </div>

            <div>
                <p class="greencolor font-weight-bold Roboto">Course Content</p>
                <p class="small mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque vel impedit quidem corrupti eum provident nam quaerat. Accusantium, praesentium similique temporibus molestias esse quam dolorum dolorem, aspernatur sunt quisquam necessitatibus?</p>
            </div> -->

            <div class="Eup_Flash_Card_Parent" *ngIf="Show_Questions_Data"  [ngClass]="[Show_Questions_Data == true ? 'displayobject' : 'notdisplay']">
              <div class="eup_Flash_Card_Wrapper" >
                <h4 class="Heading_Flashcard" [ngClass]="[Show_Questions_Data == true ? 'displayobject' : 'notdisplay']">
                  {{Topic_Title?.split("(",1)}} Flashcards
                </h4>
                <div class="Question_Number" [ngClass]="[Show_Questions_Data == true ? 'displayobject' : 'notdisplay']" *ngIf="CurrentQuestionIndex != -2 && CurrentQuestionIndex != -1" >
                  <div class="navigation_number">
                    <i class="fa fa-fast-backward ml-3 mt-1 mr-2" (click)="FirstFlashcardQuestion()"></i>
                    <i class="fa fa-chevron-left mt-1 mr-2" (click)="PrevFlashcardQuestion()"></i>
                    <div style="font-weight: bold;">{{CurrentQuestionIndex+1}}</div>
                    <i class="fa fa-chevron-right ml-2 mt-1 mr-2" (click)="NextFlashcardQuestion()"></i>
                    <i class="fa fa-fast-forward mt-1 mr-2" (click)="lastFalshcardQuestion()"></i>
                  </div>
                  <div>Question {{CurrentQuestionIndex+1}} of {{TotalQuestionLength}}</div>
                </div>
                <div class="Question_Flashcard"  id="Question_Flashcard" [ngClass]="[Show_Questions_Data == true ? 'displayobject' : 'notdisplay']"  [ngClass]="[CurrentQuestionIndex == -1? 'boldQuestionText':'']">
                  <div style="color: #0056b3; font-size: 18px!important">Memorize & Study in interactive way.</div>
                </div>
                <div class="Answer_Flascard Question_Flashcard" id="Answer_Flascard" [ngClass]="[displayObject == true ? 'displayobject' : 'notdisplay']">
                  Answer:
                </div>
                <div class="Flascard_Buttons" [ngClass]="[Show_Questions_Data == true ? 'displaybtns' : 'notdisplay']">

                <button class="btn next-btn-flashcard base-bg1" (click)="startQuiz()" *ngIf="CurrentQuestionIndex == -1">Start Quiz</button>

                <button class="btn next-btn-flashcard base-bg1" (click)="startQuiz()" *ngIf="CurrentQuestionIndex == -2">Take the Quiz Again</button>

                <button class="btn prev-btn-flashcard base-bg1" (click)="saveForPractice()" *ngIf="CurrentQuestionIndex != -1 && CurrentQuestionIndex != -2" [ngClass]="[displayObject == true ? 'displayobject' : 'notdisplay']"> Need More Practice</button>

                <button class="ShowAnswer btn base-bg1 mr-1 ml-1" (click)="displayObject = !displayObject" *ngIf="CurrentQuestionIndex != -1 && CurrentQuestionIndex != -2" [ngClass]="[displayObject == false ? 'displayobject' : 'notdisplay']">
                  Show Me The Answer
                </button>

                <button class="btn next-btn-flashcard base-bg1" (click)="NextFlashcardQuestion()" *ngIf="CurrentQuestionIndex != -1 && CurrentQuestionIndex != -2" [ngClass]="[displayObject == true ? 'displayobject' : 'notdisplay']">I Got It </button>

              </div>
              </div>

            </div>

            <div class="bottombtns mt-5 mb-5">
            <button class="btn greenbackground text-light pl-4 pr-4 mt-3 " (click)="previousTopic()"><i class="fa fa-angle-left"></i> Previous Topic</button>
            <button class="btn bg-light greencolor pl-4 pr-4 mt-3" (click)="back()">Back to Course</button>
            <button class="btn greenbackground text-light pl-4 pr-4 mt-3 " (click)="nextTopic()">Next Topic <i class="fa fa-angle-right"></i></button>
        </div>

            </div>
          </div>

            </div>


        </div>
       </div>
    </div>
</div>
