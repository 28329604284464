import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-sync-questions',
  templateUrl: './sync-questions.component.html',
  styleUrls: ['./sync-questions.component.scss'],
  providers: [MessageService]
})
export class SyncQuestionsComponent implements OnInit {
  modalType = '';
  showModal = false;
  questionIds = [];
  loader = false;
  questionsList: any = [];
  selectedQuestions: any = [];
  confirmationModal = false;

  constructor(private adminService: AdminService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getQuestionsList();
  }

  openModal(type: string) {
    this.questionIds = [];
    this.modalType = type;
    this.showModal = true;
  }

  openConfirmationModal(type: string) {
    this.modalType = type;
    this.confirmationModal = true;
  }

  addSyncQuestion(status: boolean) {
    const data = {
      postIds: status ? this.selectedQuestions : this.questionIds
    }
    this.loader = true;
    this.adminService.addSyncQuestions(data).subscribe((res: any) => {
      this.refreshQuestions();
      if (res.data?.questionIds.length > 0) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Sync Questions Added: ' + res.data.questionIds}); 
      } else {
        this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Adding Sync Questions'});
      }
    }, (error: any) => {
      this.refreshQuestions();
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Adding Sync Questions'});
    })
  }

  editSyncQuestion(status: boolean) {
    const data = {
      postIds: status ? this.selectedQuestions : this.questionIds
    }
    this.loader = true;
    this.adminService.editSyncQuestions(data).subscribe((res: any) => {
      this.refreshQuestions();
      if (res.data?.questionIds.length > 0) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Sync Questions Edited: ' + res.data.questionIds}); 
      } else {
        this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Editing Sync Questions'});
      }
    }, (error: any) => {
      this.refreshQuestions();
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Editing Sync Questions'});
    })
  }

  deleteSyncQuestion(status: boolean) {
    const data = {
      postIds: status ? this.selectedQuestions : this.questionIds
    }
    this.loader = true;
    this.adminService.deleteSyncQuestions(data).subscribe((res: any) => {
      this.refreshQuestions();
      if (res.data?.questionIds.length > 0) {
        this.messageService.add({severity:'success', summary: 'Confirmation', detail: 'Sync Questions Deleted: ' + res.data.questionIds}); 
      } else {
        this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Deleting Sync Questions'});
      }
    }, (error: any) => {
      this.refreshQuestions();
      this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'Error Deleting Sync Questions'});
    })
  }

  getQuestionsList() {
    this.loader = true;
    this.adminService.getQuestionsList().subscribe((res: any) => {
      this.loader = false;
      this.questionsList = res.data.questions;
      this.questionsList.forEach((element: any) => {
        element.status = false;
      });
    }, (error: any) => {
      this.loader = false;
    })
  }

  selectQuestions() {
    this.selectedQuestions = this.questionsList.filter((item: any) => item.status).map((question: any) => question.postId);
  }

  refreshQuestions() {
    this.loader = false;
    this.showModal = false;
    this.confirmationModal = false;
    this.questionsList.map((item: any) => item.status = false);
    this.selectedQuestions = [];
  }
}
