import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';

@Component({
  selector: 'app-ai-test-analysis',
  templateUrl: './ai-test-analysis.component.html',
  styleUrls: ['./ai-test-analysis.component.scss'],
  providers: [MessageService]
})
export class AITestAnalysisComponent implements OnInit {
  totalScore = 0;
  weakAreas: any = []
  subjects = [];
  tempSubjects = [];
  quizCount = 0;
  testAnalysisEnabled = false;
  generateTestAnalysis = false;
  showUSMLECriteria = false;
  loader = false;
  aiLoader = false;
  aiText = 'AI Test Analysis';
  aiSubjects = [];
  improvedStatistics = null;
  usmleFeedback = false;
  goodPerformer = false;
  studyPlanQuizzes = []
  graphOptions: any;
  graphData: any;
  performanceAccuracy = '';

  studyPlanAvg = 0;
  displayStudyPlanPopup = false;
  displayCategoryPopup = false;
  options = ['Subjects', 'Systems', 'Topics'];
  activeIndex = 0;
  allSubjects = [];
  allSystems = [];
  allTopics = [];
  categoryLoader = false;
  isStudyPlanCompleted = false;

  courses: any = [];
  selectedCourse = null;

  constructor(private quizService: QuizServiceService, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    if (localStorage.getItem('USMLE_Test')) {
      this.usmleFeedback = true;
    }
    this.courses = JSON.parse(localStorage.getItem('subscriptions'))
    if (this.courses.length > 0) {
      this.selectedCourse = this.courses[0].courseID
    }
    this.getUserQuizzes()
  }

  getUserQuizzes() {
    this.testAnalysisEnabled = false;
    this.generateTestAnalysis = false;
    const data = {
      userId: localStorage.getItem('Id'),
      courseId: this.selectedCourse
    }
    if (sessionStorage.getItem('AI_Analysis')) {
      this.aiLoader = true;
    } else {
      this.loader = true;
    }
    this.quizService.getUserQuizzes(data).subscribe((res: any) => {
      this.loader = false;
      this.quizCount = eval(res.subjects.map((item: any) => item.QuizCount).join('+'));
      this.subjects = res.subjects;
      this.tempSubjects = JSON.parse(JSON.stringify(this.subjects));

      // Insufficient Data Logic: //
      // If 3 subjects quizzes count is greater than 3 then user will be able to generate AI Test Analysis
      // If subjects quizzes count is less than 3 then those subjects will be displayed to the users so that they can generate more quizzes for those subjects

      let counter = 0;
      const updatedSubjects = [];
      this.subjects.forEach((element: any) => {
        if (element.QuizCount > 2) {
          counter ++;
        } else {
          updatedSubjects.push(element)
        }
      })
      if (counter >= 3) {
        this.testAnalysisEnabled = true;
      } else {
        this.aiLoader = false;
        sessionStorage.removeItem('AI_Analysis')
      }
  
      this.subjects = updatedSubjects;

      // -------------------------- //

      // If the user has solved the USMLE Mock Test, then AI Test Analysis will be calculated automatically to show the results //

      if (localStorage.getItem('USMLE_Test')) {
        localStorage.removeItem('USMLE_Test');
      }

      if (sessionStorage.getItem('AI_Analysis') && this.testAnalysisEnabled) {
        this.generateAITestAnalysis();
      }
    });
  }

  generateAITestAnalysis() {
    this.allSubjects = [];
    this.allSystems = [];
    this.allTopics = [];
    const data = {
      userId: localStorage.getItem('Id'),
      subjects: this.tempSubjects,
      courseId: this.selectedCourse
    }
    this.aiLoader = true;
    this.quizService.generateAITestAnalysis(data).subscribe((res: any) => {
      sessionStorage.setItem('AI_Analysis', JSON.stringify(true))
      this.aiLoader = false;
      this.totalScore = res.totalScore;
      this.weakAreas = res.weakAreas;
      this.aiSubjects = this.weakAreas.map((item: any) => item.subject);
      this.improvedStatistics = res.improvedStatistics;
      this.improvedStatistics.percentage = parseFloat(this.improvedStatistics.percentage.toFixed(0))
      this.studyPlanQuizzes = res.studyPlanQuizzes;
      this.generateTestAnalysis = true;

      if (res.systems.length > 0) {
        for (let i = 0; i < res.systems.length; i++) {
          this.weakAreas.forEach((element: any) => {
            if (element.solvedQuestions.map((item: any) => item.QuestionID).includes(res.systems[i].ID)) {
              element.systems.push(res.systems[i].QSystem)
            }
          })
        }

        this.weakAreas.forEach((element: any) => {
          element.systems = [...new Set(element.systems)]
          element.systems = element.systems.slice(0, 3)
        })
      }

      // If the total score is greater than 100, then good performance screen will be displayed - Good Performance Case //

      if (this.totalScore >= 100) {
        this.goodPerformer = true;
        this.generateTestAnalysis = false;
        this.testAnalysisEnabled = false;
      } else {
        // Performance Over Time Graph //

        const userQuizzes = res.userQuizzes;
        this.performanceAccuracy = res.performanceAccuracy;

        this.graphOptions = {
          legend: { 
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                display: false
              },
              gridLines: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true
              }
            }],
            yAxes: [{
              ticks: {
                display: true
              },
              gridLines: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true
              }
            }]
          },
        }

        this.graphData = {
          labels: userQuizzes.map((item: any) => item.QuizID),
          datasets: [
            {
              label: 'Score',
              data: userQuizzes.map((item: any) => item.Score),
              fill: false,
              borderColor: '#1362764F',
              borderWidth: 2,
              pointBorderColor: '#13627636'
            }
          ]
        }

        // Check for the Study Plan Completion //

        let checker = false;
        let sumScore = 0;
        this.studyPlanQuizzes.forEach((element: any) => {
          if (element.solved) {
            sumScore += parseInt(element.score);
          } else {
            checker = true;
          }
        })

        if (!checker) {
          this.isStudyPlanCompleted = true;
          this.studyPlanAvg = parseFloat((sumScore / this.studyPlanQuizzes.length).toFixed(0));

          if (!localStorage.getItem('studyPlanPopup')) {
            this.displayStudyPlanPopup = true;
            localStorage.setItem('studyPlanPopup', 'true');
          }
        }
      }
    })
  }

  generateAICustomQuiz(quiz: any) {
    const data = {
      userId: localStorage.getItem('Id'),
      questionIds: quiz.questions,
      count: quiz.questions.length
    }
    this.loader = true;
    this.quizService.generateAICustomQuiz(data).subscribe((res: any) => {
      this.loader = false;

      if (res.status) {
        var realFlaggedQuestions = [];
        localStorage.setItem('viewMode', JSON.stringify(false));
        localStorage.setItem('QuizId', res.quizId);
        localStorage.setItem(
          'flaggedQuestion',
          JSON.stringify(realFlaggedQuestions)
        );
        localStorage.setItem('quizTitle', 'Custom AI Quiz ' + new Date().toISOString());
        localStorage.setItem(
          'AllQuestions',
          JSON.stringify(res.questions)
        );
        if (quiz.studyPlanID) {
          localStorage.setItem('studyPlanID', quiz.studyPlanID);
          localStorage.setItem('studyPlanCourse', this.selectedCourse);
        }

        const arr = ['SelectedOptionArray', 'SubmittedQuestions', 'singleQuestionTime', 'currentQuestionIndex', 'CurrentIndex', 'dotQuestionsArray', 'quiz_time', 'timesup', 'AnswerChanges', 'highlightedQuestionIndexes', 'qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
        arr.forEach(i => {
          localStorage.removeItem(i);
        });
        this.router.navigate(['/quiz']);
      }
    })
  }

  getCategoriesStatistics() {
    const data = {
      userId: localStorage.getItem('Id'),
      courseId: this.selectedCourse
    }
    this.categoryLoader = true;
    this.quizService.getCategoriesStatistics(data).subscribe((res: any) => {
      this.allSubjects = res.subjects;
      this.allSystems = res.systems;
      this.allTopics = res.topics;
      this.categoryLoader = false;
      this.displayCategoryPopup = true;
    })
  }

  changeIndex(index: any) {
    this.activeIndex = index;
  }

  generateUSMLECustomQuiz() {
    const data = {
      userId: localStorage.getItem('Id')
    }
    this.loader = true;
    this.quizService.generateUSMLECustomQuiz(data).subscribe((res: any) => {
      if (res.quizQuestions.length > 0) {
        const quiz = {
          questions: res.quizQuestions,
          studyPlanID: null
        }
        this.generateAICustomQuiz(quiz);
        localStorage.setItem('USMLE_Test', JSON.stringify(true));
      }
    })
  }
}
