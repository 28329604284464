import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ChartOptions, ChartType} from "chart.js";
import {Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet} from "ng2-charts";
import {ActivatedRoute, Router} from "@angular/router";
import {CourseService} from "../../shared/services/course.service";
import {ErrorHandlerService} from "../../shared/services/error-handler.service";
import { GlobalService } from 'src/app/shared/services/global.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ConfirmationService } from 'primeng/api';
import { Chart } from "chart.js";

@Component({
  selector: 'app-overall-performance',
  templateUrl: './overall-performance.component.html',
  styleUrls: ['./overall-performance.component.scss']
})
export class OverallPerformanceComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription = [];
  isDemo =false;

  totalCorrect = 0;
  totalIncorrect = 0;
  totalOmitted = 0;
  totalQuizzes= 0;
  quizCompleted= 0;
  quizSuspended= 0;
  corr_corr = 0;
  corr_incorr = 0;
  incorr_incorr = 0;
  incorr_corr = 0;
  bestSubject: any;
  worstSubject: any;
  ifAdmin = false;
  subscribedCourses: any = [];

  contentLoader = false;
  basicData: any;
  basicOptions: any;

  pointersColorClasses = ['eup-green-pointer','eup-red-pointer','eup-blue-pointer','eup-purple-pointer'];

  chartType: ChartType = 'pie';
  // search = faSearch;
  // sortAmount = faSortAmountDown;
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  chartColors: Array<any> = [
    {
      backgroundColor: [
        '#61ce87',
        '#e14f6a',
        '#389cf2',
        '#7583f0'
      ]
    }
  ];

  pieChartLabels: Label[] = [['Correct to Correct'], ['Correct to Incorrect'], ['Incorrect to Correct'], ['Incorrect to Incorrect']];

  pieChartData: SingleDataSet = [20, 16, 36, 40];
  pieChartType: ChartType = 'pie';
  pieChartLegend = false;
  pieChartPlugins = [];
  cardLabels = [
    {label: 'Correct to Correct', value: 12, bgClass: 'eup-green-card'},
    {label: 'Correct to Incorrect', value: 2, bgClass: 'eup-red-card'},
    {label: 'Incorrect to Correct', value: 14, bgClass: 'eup-blue-card'},
    {label: 'Incorrect to Incorrect', value: 20, bgClass: 'eup-purple-card'},
  ];

  pieChartLabels1: Label[] = [['Total Questions'], ['Used  Questions'], ['Unused Questions']];
  pieChartData1: SingleDataSet = [];
  cardLabels1 = [
    {label: 'Total Questions', value: 12, bgClass: 'eup-green-card'},
    {label: 'Used Questions', value: 2, bgClass: 'eup-red-card'},
    {label: 'Unused Questions', value: 14, bgClass: 'eup-blue-card'}
  ];

  totalCorrectPercentage: any = 40;
  totalUsedPercentage: any = 20;

  tooltipContent="You got a score that is higher than 80% of course subscribers and 20% of course subscribers got a score that is higher than your score.";

  isPeerLoaded = false;
  isCategoryLoaded = false;

  userRank = 0
  peerRank = 0

  xUserValue = 0
  yUserValue = 0

  xPeerValue = 0

  avgQuizScore = 0
  avgPeerQuizScore = 0

  innerWidth: any;
  @HostListener('window:resize', ['$event'])

  barData: any;
  barOptions: any;

  selectedCategory = 'Subject'
  categories: any = []
  myScores: any = []
  peerScores: any = []
  allCategories: any = []
  tempCategories: any = []

  @ViewChild('myChart') barChart: Chart; 
  loader = false
  loaderValue = 0;

  paginationValue = 0

  performanceOptions = ['Overall', 'Analysis']

  selectedOption = 'Overall'

  totalQuestions = 0;

onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup_username').innerText = '';
    document.getElementById('eup-navbar-heading').innerText=''
  }
}
removeMouseOptions(e:any){
  e.preventDefault()
}
  constructor(private confirmationService: ConfirmationService,private adminService: AdminService,private globalService: GlobalService,private route:ActivatedRoute, private router:Router, private courseService: CourseService, private errorHandler: ErrorHandlerService) {
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
   }
  ngOnDestroy(): void {
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null;
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  courseId: any = [];
  ngOnInit(): void {
    if (localStorage.getItem('subscriptions')) {
      this.subscribedCourses = JSON.parse(localStorage.getItem('subscriptions'))
      this.courseId = this.subscribedCourses.map((item: any) => item.courseID)
      this.totalQuestions = this.subscribedCourses.map((item: any) => item.courseQuestions).reduce((sum: any, value: any) => sum + value, 0);
    }

    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Overall Performance';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
    if(localStorage.getItem("ifAdmin")){
      this.ifAdmin = JSON.parse(localStorage.getItem("ifAdmin"))
    }
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

    if(localStorage.getItem('Id') != '-1'){
      this.getPerformanceStats()
    }else{
      this.isDemo = true;
      this.getDemoValues();
    }
    // @ts-ignore
    this.subscriptions.push(
      this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        if(res.status){

        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );

    this.generateBarChart()
  }

  getPerformanceCategoriesGraph() {
    this.courseService.getPerformanceCategoriesGraph(this.selectedCategory).subscribe((res: any) => {
      this.allCategories = res.categories
      this.tempCategories = res.categories
      this.bestSubject = res.bestSubject
      this.worstSubject = res.worstSubject

      this.isCategoryLoaded = true

      this.allCategories = this.tempCategories.slice(0, 10)

      if (this.allCategories.length > 0) {
        for (let i = 0; i < this.allCategories.length; i++) {
          this.categories.push(this.allCategories[i].title)
  
          let mycorrect = this.allCategories[i].user_correct
          let mytotal = this.allCategories[i].user_total
          this.myScores.push(((mycorrect / mytotal) * 100).toFixed(2))
  
          mycorrect = this.allCategories[i].peer_correct
          mytotal = this.allCategories[i].peer_total
          this.peerScores.push(((mycorrect / mytotal) * 100).toFixed(2))
        }

        setTimeout(() => {
          //@ts-ignore
          this.barChart.chart.destroy();
          this.generateBarChart()
        }, 100)
      }
    })
  }

  changeOption(value: any) {
    this.selectedOption = value
    this.loader = false;
  }

  generateBarChart() {
    this.barData = {
      labels: this.categories,
      datasets: [
          {
            backgroundColor: '#D2D5DD',
            hoverBackgroundColor: '#D2D5DD',
            borderColor: '#D2D5DD',
            barThickness: 20,
            data: this.myScores
          },
          {
            backgroundColor: '#61ce87',
            hoverBackgroundColor: '#61ce87',
            borderColor: '#61ce87',
            barThickness: 20,
            data: this.peerScores
          }
      ]
    };

    this.barOptions = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            color: '#495057'
          }
        }],
        yAxes: [{
          ticks: {
            color: '#495057',
            beginAtZero: true,
            steps: 10,
            stepValue: 1,
            max: 100
          }
        }]
      }
    };
  }



  makeGraph(peerAvgScore:any,avgScore:any)
    {

     let backgroundColor:any;
     let backgroundColor1:any;
      if (avgScore>=55)
      {
        backgroundColor1='rgba(92, 166, 239,.5)';
        backgroundColor='rgba(134, 207, 131,.5)'

      }
      else if (avgScore<55)
      {
        backgroundColor1='rgba(92, 166, 239,.5)';
        backgroundColor='rgb(237, 28, 36,.5)';
      }


      this. basicData={
        labels: ["Peer","You",''],
         datasets: [
           {
             barPercentage: 0.5,
             borderRadius:10,
             label: "Current Score Overall",
             //backgroundColor: "bl86CF83ue",
             opacity:.6,
             data: [peerAvgScore,avgScore,0],
             backgroundColor: [

              backgroundColor1,
                 backgroundColor


             ],

           },
           {
             type: 'line',
             label: "Passing Peer Average",

             borderColor: '#86CF83',

             data:[50,50,50],
             //backgroundColor:"green",
             fill: false,


           }
         ]
       };

       this.basicOptions={
         legend: {
           display: false,

           //usePointStyle: true,
          // align:'left',

         },
         gridLines:{
             display:false,
         },
         title: {
           display: false,

         },
         scales: {

           yAxes: [
             {

               display: true,
               scaleLabel: {

                 show: false,
               },
               ticks: {
                 beginAtZero: true,
                 stepSize: 50,
                 max: 100 ,
                 min: 0,
               }
             }
           ],
           xAxes: [
             {
                 lineThickness: 0,
               ticks: {
                 display: true,
                 beginAtZero: 0
               }
             }
           ]
         }
       };

    }





  confirm(event: Event) {
    this.confirmationService.confirm({
        target: event.target,
        message: 'All of your data will be reset. Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if(localStorage.getItem('Id')!= '-1'){
            //confirm action
            // @ts-ignore
  }
        },
        reject: () => {
            //reject action
        }
    });
}

getDemoValues(){
  this.subscribedCourses = ['Free Trail'];
        this.bestSubject = 'Pharmacology';
        this.worstSubject = 'Medicine';
        this.totalCorrect = 5;
        this.totalIncorrect = 2;
        this.totalOmitted = 3;
        this.totalQuizzes = 3;
        this.quizCompleted = 2;
        this.quizSuspended = 1;
        this.pieChartData = [3, 2, 3,2]
        this.corr_corr =3;
        this.corr_incorr = 2;
        this.incorr_corr = 3;
        this.incorr_incorr = 2;
        this.cardLabels[0].value = this.corr_corr;
        this.cardLabels[1].value = this.corr_incorr;
        this.cardLabels[2].value = this.incorr_corr;
        this.cardLabels[3].value = this.incorr_incorr;
        this.totalCorrectPercentage = 60;
        this.totalUsedPercentage = 80;

        this.pieChartData1 = []
        this.pieChartData1.push(10);
        this.pieChartData1.push(8);
        this.pieChartData1.push(10 - 8);
        this.cardLabels1[0].value = 10;
        this.cardLabels1[1].value = 8;
        this.cardLabels1[2].value = 10 - 8;
        this.makeGraph(55.1, 65.3);
}

  getPerformanceStats(){
    this.getOverallPerformanceStats()
  }

  getOverallPerformanceStats() {
    this.loader = true;
    this.subscriptions.push(
      this.courseService.getOverallPerformanceStats(this.courseId).subscribe((res: any) => {
        this.loader = false
        this.totalCorrect = res.totalCorrectQuestions;
        this.totalIncorrect = res.totalIncorrectQuestions;
        this.totalOmitted = res.totalOmittedQuestions;
        this.totalQuizzes = res.totalQuizzes;
        this.quizCompleted = res.totalCompletedQuizzes;
        this.quizSuspended = res.totalSuspendedQuizzes;
        this.pieChartData = [res.totalCorrect_Correct, res.totalCorrect_Incorrect, res.totalIncorrect_Correct, res.totalIncorrect_Incorrect]
        this.corr_corr = res.totalCorrect_Correct;
        this.corr_incorr = res.totalCorrect_Incorrect;
        this.incorr_corr = res.totalIncorrect_Correct;
        this.incorr_incorr = res.totalIncorrect_Incorrect;
        this.cardLabels[0].value = this.corr_corr;
        this.cardLabels[1].value = this.corr_incorr;
        this.cardLabels[2].value = this.incorr_corr;
        this.cardLabels[3].value = this.incorr_incorr;

        this.totalCorrectPercentage = ((this.totalCorrect / (this.totalCorrect + this.totalIncorrect + this.totalOmitted)) * 100).toFixed(1);
        if(this.totalCorrectPercentage>100){
          this.totalCorrectPercentage = 100;
        }

        this.totalUsedPercentage = ((res.totalUsedQuestions/this.totalQuestions)*100).toFixed(1);
        if(this.totalUsedPercentage>100){
          this.totalUsedPercentage = 100;
        }

        this.pieChartData1 = []
        this.pieChartData1.push(this.totalQuestions);
        this.pieChartData1.push(res.totalUsedQuestions);
        this.pieChartData1.push(this.totalQuestions - res.totalUsedQuestions);
        this.cardLabels1[0].value = this.totalQuestions;
        this.cardLabels1[1].value = res.totalUsedQuestions;
        this.cardLabels1[2].value = this.totalQuestions - res.totalUsedQuestions;
      })
    )
  }

  handleSingleCourse(courseId: any){
    this.totalQuestions =  this.subscribedCourses[this.subscribedCourses.findIndex((item: any) => item.courseID == courseId)].courseQuestions
    this.courseId = [courseId];
    this.getPerformanceStats()
  }

}
