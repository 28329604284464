<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<app-ai-loader *ngIf="aiLoader" [loaderText]="aiText"></app-ai-loader>
<div class="switch">
  <p-inputSwitch [(ngModel)]="checked" (onChange)="changeNotebook()"></p-inputSwitch>
  <span>Archived Notebook</span>
</div>

<app-course-list *ngIf="checked" [notebookText]="originalText" [quizEdit]="quizEdit" [html]="originalText"></app-course-list>

<div class="notebook-root" *ngIf="!checked">
  <div class="sidebar">
    <i class="fa fa-times" (click)="openNotebook()" *ngIf="sidebar"></i>
    <i class="fa fa-bars" (click)="openNotebook()" *ngIf="!sidebar"></i>
  </div>

  <!-- Notebook Left List -->
  <div class="list" id="list">
    <section class="container">
      <div class="search-contact-wrapper">
        <span class="p-input-icon-right" style="width: 100%;" [ngClass]="newNote ? 'disabled' : ''">
          <i style="cursor: pointer; color: black;" class="pi pi-search" *ngIf="!searcher" (click)="onSearchChange()"></i>
          <i style="cursor: pointer; color: black;" class="pi pi-times" *ngIf="searcher" (click)="cancelSearch()"></i>
          <input type="text" pInputText [(ngModel)]="searchString" placeholder="Search" (input)="searcher = false" />
        </span>
      </div>
      <div class="create-note-wrapper" [ngClass]="newNote ? 'disabled' : ''">
        <button class="create-note-btn" (click)="addNewSection('qbank', [-1, -1, -1, -1])">
          Create New Note
          <i class="pi pi-plus"></i>
        </button>
      </div>
      <div cdkDropList class="accordian-wrapper" (cdkDropListDropped)="dropQbank($event)" [ngClass]="isSwap ? 'disabled': ''">
        <div class="" *ngFor="let qbank of qbanks; let i = index" id="qbank{{i}}">
          <ng-template [ngIf]="qbank.enable">
            <div class="content">
              <div class="example-custom-placeholder" *cdkDragPlaceholder>
              </div>
              <div class="header_text" id="qbankheader{{i}}" (click)="selectNotebookSection('qbank', qbank, [i, -1, -1, -1], true)" cdkDrag>
                <span class="text{{i}}"><i [ngClass]="(qbank.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{qbank.Title}}</span>
              </div>
              <span class="icons" [ngClass]="newNote ? 'disabled' : ''">
                <i (click)="setDeleteNote(i, -1, -1, -1)" class="pi pi-trash mr-2"></i>
                <i (click)="isEdit= true" class="pi pi-plus" (click)="addNewSection('subject', [i, -1, -1, -1])"></i>
              </span>
            </div>
            <div cdkDropList (cdkDropListDropped)="dropSubject($event)">
              <div class="hide" *ngFor="let subject of qbank.Subjects; let a = index" id="subject{{i}}{{a}}" @slideDownUp>
                <ng-template [ngIf]="subject.enable">
                  <div class="example-custom-placeholder" *cdkDragPlaceholder>
                  </div>
                  <div class="content">
                    <div class="header_text" id="subjectheader{{i}}{{a}}" style="margin-left: 8px;" (click)="selectNotebookSection('subject', subject, [i, a, -1, -1], true)" cdkDrag>
                      <span class="text{{i}}{{a}}"><i [ngClass]="(subject.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{subject.Title}}</span>
                    </div>
                    <span class="icons" [ngClass]="newNote ? 'disabled' : ''">
                      <i (click)="setDeleteNote(i, a, -1, -1)" class="pi pi-trash mr-2"></i>
                      <i (click)="isEdit= true" class="pi pi-plus" (click)="addNewSection('system', [i, a, -1, -1])"></i>
                    </span>
                  </div>
                  <div cdkDropList (cdkDropListDropped)="dropSystem($event)">
                    <div class="hide" *ngFor="let system of subject.Systems; let b = index" id="system{{i}}{{a}}{{b}}" @slideDownUp>
                      <ng-template [ngIf]="system.enable">
                        <div class="example-custom-placeholder" *cdkDragPlaceholder>
                        </div>
                        <div class="content">
                          <div class="header_text" id="systemheader{{i}}{{a}}{{b}}" style="margin-left: 16px;" (click)="selectNotebookSection('system', system, [i, a, b, -1], true)" cdkDrag>
                            <span class="text{{i}}{{a}}{{b}}"><i [ngClass]="(system.open ? 'pi pi-chevron-down' : 'pi pi-chevron-right')" style="margin-right: 7px;"></i>{{system.Title}}</span>
                          </div>
                          <span class="icons" [ngClass]="newNote ? 'disabled' : ''">
                            <i (click)="setDeleteNote(i, a, b, -1)" class="pi pi-trash mr-2"></i>
                            <i (click)="isEdit= true" class="pi pi-plus" (click)="addNewSection('topic', [i, a, b, -1])"></i>
                          </span>
                        </div>
                        <div cdkDropList (cdkDropListDropped)="dropTopic($event)">
                          <div class="hide topic-wrapper" *ngFor="let topic of system.Topics; let t = index" id="topic{{i}}{{a}}{{b}}{{t}}" @slideDownUp> 
                            <ng-template [ngIf]="topic.enable">
                              <div class="example-custom-placeholder" *cdkDragPlaceholder>
                              </div>
                              <div cdkDrag style="max-width: 200px;">
                                <span class="text{{i}}{{a}}{{b}}{{t}}" id="topicheader{{i}}{{a}}{{b}}{{t}}" style="margin-left: 38px;" (click)="selectNotebookSection('topic', topic, [i, a, b, t], true)">
                                  <i class="fa fa-circle"></i>
                                  {{ topic.Title }}
                                </span>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </section>
  </div>
  <!-------------------------->

  <!-- Notebook Right Details -->
  <div class="data" id="data">
    <div class="root pt-3 pl-1">
      <div class="template-body flex-column align-center justify-center" *ngIf="!newNotebook">
        <div class="template-header">
          <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
        <div class="template-content-wrapper">
          <div class="content-heading-wrapper mb-3">
            <h3 style="font-weight: 500; font-size: 1.4rem !important; margin-top: 5px;">Getting Started</h3>
          </div>
          <div class="template-dates-wrapper">
            <hr>
            <div class="banner_text">
              <div class="dates">
                <span>Created: Apr 21, 2022</span>
                <span>Updated: Apr 21, 2022</span>
              </div>
            </div>
            <hr>
          </div>
          <div class="template-description-wrapper">
            <div class="notebookdialog" style="width: 100%;">
              <span *ngIf="!isEdit" [innerHTML]="sampleText"></span>
            </div>
            <p>

            </p>
          </div>
          <div class="bottom_btns mt-3" *ngIf="showSave">
            <button class="cancel_btn mr-3" (click)="cancelEdit(false)">Cancel</button>
            <button class="save_btn" (click)="updateUserNotebook()">Save</button>
          </div>
        </div>
      </div>
      <div class="template-body flex-column align-center justify-center" *ngIf="newNotebook">
        <div class="note">
          <strong>Keyboard Shortcuts:</strong> Copy -> CTRL + C and Paste -> CTRL + V
        </div>
        <div class="template-header">
          <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
        <div class="template-content-wrapper">
          <div class="content-heading-wrapper mb-3">
            <input type="text" *ngIf="isEdit" [(ngModel)]="heading">
            <span *ngIf="!isEdit">{{heading}}</span>
            <span class="pr-3 content-wrapper">
              <div class="voice_notes" *ngIf="voiceNoteFile && !isEdit">
                <div class="audio_file">
                  <audio #audioElement id="audioPlayer">
                    <source src="{{ voiceNoteFile }}" type="audio/wav">
                  </audio>
                  <div class="controls">
                    <div class="audio-loader" *ngIf="audioLoader">
                      <svg viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50"></circle>
                      </svg>
                    </div>
                    <img src="../../../assets/images/play.svg" *ngIf="!playAudio && !audioLoader" (click)="playAudioFile()" />
                    <img src="../../../assets/images/pause.svg" *ngIf="playAudio && !audioLoader" (click)="pauseAudioFile()" />
                  </div>
                  <div class="time_duration">
                    <span>{{ voiceCurrentTime }}-{{ voiceTotalTime }}</span>
                  </div>
                  <div class="audio_bar" [ngClass]="audioLoader ? 'disabled': ''">
                    <div class="custom-video-bar">
                      <input id="seek-bar" type="range" min="0" value="0" />
                    </div>
                  </div>
                  <div class="delete_icon" (click)="displayVoiceDelete = true;">
                    <img src="../../../assets/images/blue_trash.svg">
                  </div>
                </div>
              </div>
              <div class="AI_features" *ngIf="!isEdit">
                <button type="button" id="ai_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="../../../assets/images/ai_stars.svg">
                  <span>Create with AI</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="ai_dropdown">
                  <a class="dropdown-item ai_dropdown_item" (click)="generateSummaryUsingAI()">
                    <img src="../../../assets/images/generate_summary.svg">
                    <span>Generate summary</span>
                  </a>
                  <a class="dropdown-item ai_dropdown_item" style="margin-top: 0.4rem;" (click)="generateTextSpeechUsingAI()">
                    <img src="../../../assets/images/text-to-speech.svg">
                    <span>Generate text to speech</span>
                  </a>
                  <a class="dropdown-item ai_dropdown_item" style="margin-top: 0.4rem;" (click)="generateReferenceLinksUsingAI()">
                    <img src="../../../assets/images/reference_link.svg">
                    <span>Generate reference links</span>
                  </a>
                  <a class="dropdown-item ai_dropdown_item" style="margin-top: 0.4rem;" (click)="generateFlashcardsUsingAI()">
                    <img src="../../../assets/images/flashcard.svg">
                    <span>Generate flashcards</span>
                  </a>
                  <a class="dropdown-item ai_dropdown_item" style="margin-top: 0.4rem;" (click)="openTranslationPopup()">
                    <img src="../../../assets/images/translate.svg">
                    <span>Translate</span>
                  </a>
                </div>
              </div>
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="isEdit ? 'disabled' : ''">
                  <i class="pi pi-ellipsis-h"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" (click)="runQuill()"><i class="pi pi-plus mr-2"></i> Edit</a>
                  <a class="dropdown-item" (click)="setDeleteNote(indexes[0], indexes[1], indexes[2], indexes[3])"><i class="pi pi-trash mr-2"></i> Delete</a>
                </div>
              </div>
            </span>
          </div>
          <div class="template-dates-wrapper">
            <hr>
            <div class="banner_text">
              <div class="dates">
                <span>Created: {{currentDate | date: 'MMM dd, yyyy'}}</span>
                <span *ngIf="updatedDate">Last Updated: {{updatedDate | date: 'MMM dd, yyyy'}}</span>
              </div>
              <div class="translations" *ngIf="noteTranslations.length > 0 && !isEdit">
                <div class="languages">
                  <span (click)="activeLanguageIndex(null, 'original')" [ngClass]="!selectedTranslation ? 'active' : ''">Original</span>
                  <span (click)="activeLanguageIndex(option, 'translation')" [ngClass]="selectedTranslation?.LanguageID === option.LanguageID ? 'active' : ''" *ngFor="let option of translationOptions; let i = index;">{{ option.Title }}</span>
                </div>
                <div class="options">
                  <div class="icon" id="language_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="../../../assets/images/edit_pencil.svg">
                  </div>
                  <div class="language-dropdown-menu dropdown-menu" aria-labelledby="language_dropdown">
                    <a class="dropdown-item language_dropdown_item" *ngFor="let option of translationOptions; let i = index;">
                      <div class="text">
                        <span>{{ option.Title }}</span>
                      </div>
                      <div class="icons">
                        <img src="../../../assets/images/language_regenerate.svg" (click)="openExistingTranslationPopup(option)">
                        <img src="../../../assets/images/language_trash.svg" (click)="openDeleteTranslationPopup(option)">
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
          <div class="template-description-wrapper">
            <div class="flashcards" *ngIf="flashcards">
              <h5>Your flashcards for these notes are generated.</h5>
              <h6 (click)="accessFlashcards()">Access flashcards</h6>
            </div>
            <div class="translation" *ngIf="notebookUpdated && selectedTranslation">
              <h5>Your notebook has been updated. To update the current translation click on the icon.</h5>
              <img src="../../../assets/images/language_regenerate.svg" (click)="openExistingTranslationPopup(selectedTranslation)">
            </div>
            <div class="notebook-summary" *ngIf="textSummary">
              <div class="summary">
                <div class="text">
                  <h4>Notebook Summary</h4>
                  <img src="../../../assets/images/note_chevron_down.svg" *ngIf="!summaryOpened" (click)="summaryOpened = !summaryOpened; setSummarySettings()">
                  <img src="../../../assets/images/note_chevron_up.svg" *ngIf="summaryOpened" (click)="summaryOpened = !summaryOpened">
                </div>
                <div class="description" *ngIf="summaryOpened">
                  <div class="summary_text" #textContainer [innerHTML]="textSummary" [class.expanded]="isExpanded"></div>
                  <div class="read_more" *ngIf="showSeeMoreSummary">
                    <h6 (click)="toggleExpand()">{{ isExpanded ? 'See Less' : 'See More' }}</h6>
                  </div>
                  <div class="regenerate" *ngIf="isRegenerateSummary">
                    <button (click)="generateSummaryUsingAI()">Regenerate summary</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="notebook-summary" *ngIf="referenceLinks">
              <div class="reference_links">
                <div class="text">
                  <h4>Reference Links</h4>
                  <img src="../../../assets/images/note_chevron_down.svg" *ngIf="!linksOpened" (click)="linksOpened = !linksOpened">
                  <img src="../../../assets/images/note_chevron_up.svg" *ngIf="linksOpened" (click)="linksOpened = !linksOpened">
                </div>
                <div class="description" *ngIf="linksOpened">
                  <div class="links">
                    <div class="links" *ngIf="!showLinks || showSeeMoreLinks"> 
                      <span class="link" *ngFor="let link of referenceLinks"><a target="_blank" href="{{ link.link }}">{{ link.link }}</a></span>
                    </div>
                    <div class="links" *ngIf="showLinks && !showSeeMoreLinks"> 
                      <span class="link" *ngFor="let link of tempReferenceLinks"><a target="_blank" href="{{ link.link }}">{{ link.link }}</a></span>
                    </div>
                  </div>
                  <div class="read_more" *ngIf="showLinks">
                    <h6 (click)="showSeeMoreLinks = !showSeeMoreLinks">{{ showSeeMoreLinks ? 'See Less' : 'See More' }}</h6>
                  </div>
                  <div class="regenerate" *ngIf="isRegenerateLinks">
                    <button (click)="generateReferenceLinksUsingAI()">Regenerate reference links</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="notebookdialog" id="notebookdialog" style="width: 100%;">
              <span id="selection" *ngIf="isEdit && quizEdit" (click)="updateText()">Add Content Here
              </span>
              <ckeditor *ngIf="isEdit" id="ckEditorNotebook" [(ngModel)]="notebookEditor"></ckeditor>
              <span *ngIf="!isEdit" [innerHTML]="notebookText | sanitizer" class="notebookText"></span>
            </div>
            <p>

            </p>
          </div>
          <div class="page-loader" *ngIf="isSave">
            <div class="loader"></div>
          </div>
          <div class="bottom_btns mt-3" *ngIf="showSave && !isSave">
            <button class="cancel_btn mr-3" (click)="cancelEdit(false)">Cancel</button>
            <button class="save_btn" (click)="updateUserNotebook()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-------------------------->
</div>

<p-dialog header="Confirmation" [(visible)]="displayDelete" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Do you want to delete this note?</p>
  <div class="delete_content">
    <span>Type <strong>`delete`</strong> to confirm deletion.</span>
    <input type="text" [(ngModel)]="confirmDeletion">
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayDelete=false" label="No"></p-button>
    <p-button icon="pi pi-check" (click)="deleteNote()" label="Yes" [disabled]="confirmDeletion != 'delete'" class="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="displaySave" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Do you want to save this note?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="updateUserNotebook();" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displaySave=false" label="No"></p-button>
  </ng-template>
</p-dialog>


<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle">

</p-confirmDialog>

<p-dialog header="Confirmation" [(visible)]="displayVoiceDelete" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Do you want to delete this audio file?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="deleteNotebookVoiceNotes()" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displayVoiceDelete = false" label="No"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>

<p-dialog header="Notebook Updated" [(visible)]="displayUpdatedNotebook" [modal]="true" [closable]="false"
  [style]="{width: '400px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="notebook_dialog">
    <p>You have updated your notebook. You will need to regenerate “text to speech” to listen to your notebook.</p>
    <button class="button" (click)="displayUpdatedNotebook = false;">Continue</button>
  </div>
</p-dialog>

<p-dialog header="Translate Notebook" [(visible)]="displayTranslationPopup" [modal]="true" [closable]="false"
  [style]="{width: '600px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="translation_dialog">
    <div class="field">
      <label>Translate To</label>
      <div class="content">
        <span class="language" *ngIf="selectedLanguage">{{ selectedLanguage.Title }}</span>
        <p-dropdown 
          *ngIf="!selectedLanguage"
          [options]="languages" 
          [(ngModel)]="selectedLanguage" 
          optionLabel="Title" 
          placeholder="Select language"
          [filter]="true"
          filterBy="Title" ></p-dropdown>
        <button (click)="generateTranslationUsingAI()" [ngStyle]="{ 'background': !selectedLanguage ? '#E3E3E3' : '' }" [disabled]="!selectedLanguage">Translate <span *ngIf="translatedText">again</span></button>
      </div>
    </div>
    <div class="field">
      <label>Translated Text</label>
      <div class="translated_text">
        <ckeditor id="ckEditorTranslation" [(ngModel)]="translatedText"></ckeditor>
      </div>
    </div>
    <div class="buttons">
      <button class="cancel" (click)="displayTranslationPopup = false;">Discard</button>
      <button class="create" (click)="updateNotebookTranslations()" [ngStyle]="{ 'background': !translatedText ? '#E3E3E3' : '' }" [disabled]="!translatedText">Save translated note</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Delete Translation" [(visible)]="displayTranslationDelete" [modal]="true" [closable]="false"
  [style]="{width: '400px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Are you sue you want to delete this? This will delete the {{ selectedDeleteLanguage?.Title }} translation of your notebook.</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="deleteNotebookTranslations()" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displayTranslationDelete = false" label="No"></p-button>
  </ng-template>
</p-dialog>