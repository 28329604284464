import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CourseService} from "../../../shared/services/course.service";
import {QuizServiceService} from '../../../shared/services/quiz-service.service';
// import {Subscription} from "rxjs";
import {Lesson} from "../../../models/lesson";
import {Course} from "../../../models/course";
import {Router} from "@angular/router";
import {ErrorHandlerService} from "../../../shared/services/error-handler.service";
import { AdminService } from 'src/app/shared/services/admin.service';
import {GlobalService} from '../../../shared/services/global.service'

@Component({
  selector: 'app-course-overview',
  templateUrl: './course-overview.component.html',
  styleUrls: ['./course-overview.component.scss' ,
              '../../../../assets/stylesheet/courseoverview.scss']
})
export class CourseOverviewComponent implements OnInit, OnDestroy {
  Questionsforquiz: any;
  loader = true;

  // Getting data from new APi
  CourseDetails: any;
  CourseDetailsFullArray: any;
  //
  i=0;
  courseNo: any;
  coursearray: any;
  percentage1: any;
  coursequizzes: any;
  lessonquizzes: any;
  courseId: any;
  // lessonId:any;
  courseDetails: any;
  // @ts-ignore
  subscriptions: Subscription = [];
  // lessons: Lesson[] = [];
  lessons: any;
  course: Course = {
    courseId: 0,
    courseTitle: '',
    courseIntro: '',
    courseImage: ''
  };
  CourseDetailsLocalTemp: any;
  knobValue = 60;
  knobArray = []
  // value = 70;
  constructor(private globalService: GlobalService,private adminService: AdminService,private router: Router,private route: ActivatedRoute,
     private courseService: CourseService, private quizService: QuizServiceService, private errorHandler: ErrorHandlerService) {

    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
      }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null;
  }
  removeMouseOptions(e: any){
    e.preventDefault();
  }

     //  FOR POP UP OF QUIZ EXAM MODE
    TestMode: any[] = [{name: 'Tutor Mode', key: true}, {name: 'Exam Mode', key: false}]
    selectedMode: any;
    innerWidth: any;

  async ngOnInit(){
    this.selectedMode = this.TestMode[0]


    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Course Overview';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
    this.courseId = localStorage.getItem('CourseID');

    // Getting Percentage
    // @ts-ignore
    this.coursearray = JSON.parse(localStorage.getItem("coursearray"));
    console.log(this.coursearray);
    this.coursearray = this.coursearray.filter((course) => course.ID == this.courseId)
    console.log('*********************** ', this.coursearray)
    if(this.coursearray){
    this.coursearray.forEach((lesson: any) => {
      this.i++;
      if(lesson.ID == this.courseId){
        this.courseNo = this.i++;
        this.percentage1 = (lesson.value).toFixed(2)
      }
    })
  }

  // Checking If this course details are in local Storage
   if(localStorage.getItem("CourseDetailsAPIResult") != null && localStorage.getItem("CourseDetailsAPIResult") != "undefined" ){
     if(localStorage.getItem("CourseDetailsAPIResult")){
    // @ts-ignore
    this.CourseDetailsLocalTemp = JSON?.parse(localStorage.getItem("CourseDetailsAPIResult"));
    if(this.CourseDetailsLocalTemp.course[0].ID){
    if(this.CourseDetailsLocalTemp.course[0].ID == this.courseId){
      this.loader =true;
      this.coursequizzes = this.CourseDetailsLocalTemp.quizzes;
          this.course = {
            courseId: this.CourseDetailsLocalTemp.course[0].ID,
            courseTitle: this.CourseDetailsLocalTemp.course[0].post_title,
            courseIntro: this.CourseDetailsLocalTemp.course[0].post_excerpt,
            courseImage: ''
          };
          this.CourseDetails = this.CourseDetailsLocalTemp.courseDetails;
          this.loader = false;
          this.lessons = this.CourseDetailsLocalTemp.lessons;
          this.lessonquizzes = this.CourseDetails[0].quizzes;
    }
    else{
      this.getData();
  } }
    else{
      this.getData();
    }
  }
  else{
    this.getData();
  }
}
    else{
      // Course Details are not in local Storage
      this.getData();
    }
    this.getCompletedTopicValue()


    await this.compareToken();

    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
  }
  compareToken(){
    // @ts-ignore
    this.subscriptions.push(
      this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        // this.loader = false;
        if(res.status){
        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );
  }

  // Getting knob value of completed courses
  getCompletedTopicValue(): any{
    this.CourseDetailsLocalTemp = JSON?.parse(localStorage.getItem("CourseDetailsAPIResult"));
    this.knobArray = []
    for(let i= 0; i<this.CourseDetailsLocalTemp.courseDetails.length; i++){
      if(this.CourseDetailsLocalTemp?.courseDetails[i]?.topics?.length == 0){
        // It doesn't have Topics
        if(this.CourseDetailsLocalTemp.courseDetails[i].lesson[0].isCompleted){
          this.knobArray.push(100)
        }else{
          this.knobArray.push(0)
        }
      }
      else{
        // It has Topics
        let numberOfTopicsCompleted = 0;
        for(let j=0; j<this.CourseDetailsLocalTemp.courseDetails[i].topics.length;j++){
          if(this.CourseDetailsLocalTemp.courseDetails[i].topics[j].topic.isCompleted){
            numberOfTopicsCompleted++
          }
        }
        let percentage = ((numberOfTopicsCompleted / this.CourseDetailsLocalTemp.courseDetails[i].topics.length)*100).toFixed();
        this.knobArray.push(percentage)
      }
    }
  }
  // getLessondetails(lessonId:any): void{
  //   this.courseService.getLessonDetails(this.courseId,lessonId).subscribe((res: any) => {
  //     if(res.status){
  //       this.lessonquizzes = res.quizzes;
  //       this.courseDetails = res.topics;

  //   }
  //   else{
  //   }
  //   });
  // }
  getData(): void{
    if (localStorage.getItem('CourseID')){
      // @ts-ignore
      this.courseId = localStorage.getItem('CourseID');
      this.loader = true;


      // WE ARE GETTING LESSONS DETAILS along with COURSE
      // @ts-ignore
      // this.subscriptions.push(
        // localStorage.setItem("AllQuestions",JSON.stringify(this.Questionsforquiz));
        // this.coursearray = JSON.parse(localStorage.getItem("coursearray"));
        this.courseService.getCourseDetails(this.courseId).subscribe((res: any) => {
          console.log("***************** ",res)
          if(res.status){
          this.CourseDetailsFullArray = res;

          if(this.CourseDetailsFullArray){
            localStorage.setItem("CourseDetailsAPIResult",JSON.stringify(this.CourseDetailsFullArray));
            this.getCompletedTopicValue()
          }
          this.coursequizzes = res.quizzes;
          this.course = {
            courseId: res.course[0].ID,
            courseTitle: res.course[0].post_title,
            courseIntro: res.course[0].post_excerpt,
            courseImage: ''
          };
          this.CourseDetails = res.courseDetails;
          this.loader = false;
          this.lessons = res.lessons;
          this.lessonquizzes = this.CourseDetails[10].quizzes;
        }else{
          this.router.navigate(["/dashboard/courses"])
        }


          // ASMA's CODE
          // res.lessons.forEach((lesson: any) => {
          //   this.lessons.push({
          //     lessonId: lesson.ID,
          //     lessonTitle: lesson.post_title,
          //     lessonContent: lesson.post_excerpt
          //     }
          //   );
          // })

        },
        error => {
          this.errorHandler.handleError(error);
        })
      // );
    }
  }
  modeModel = false;
  tutorMode = true;
  timeMode = false;
  quizSuspended = -1;
  QuizId: any;
  quizTitle: any;

  getQuizMode(quizId: any, quizName:any): any{

    this.quizloader = true;
    this.courseService.getCourseQuizStatus(quizId).subscribe((res: any) => {
      this.quizSuspended = res.completed
      this.QuizId = quizId;
    this.quizTitle = quizName;
    this.modeModel = true;

      this.quizloader = false;
    },
    error => {
      this.errorHandler.handleError(error);
    })

  }

  quizloader = false;
  getQuiz(quizId: any, quizName: any): void{
    this.tutorMode = this.selectedMode.key;
    (this.tutorMode == true) ? localStorage.setItem("QuizMode", "tutor") : localStorage.setItem("QuizMode", "exam");
    (this.timeMode == true) ? localStorage.setItem("isTimed", JSON.stringify(true)): localStorage.setItem("isTimed", JSON.stringify(false))
    localStorage.setItem("quizTitle", quizName)
    // localStorage.setItem("QUIZTitle", quizName)
    this.quizloader = true;
    localStorage.setItem("QuizId", quizId);
    this.quizService.getQuizDetails(quizId).subscribe((res: any) => {
      this.Questionsforquiz = res.data;
      const arr = ['SelectedOptionArray', 'SubmittedQuestions', 'singleQuestionTime', 'currentQuestionIndex', 'CurrentIndex', 'dotQuestionsArray', 'quiz_time', 'timesup', 'AnswerChanges', 'qbankStatistics', 'subjectStatistics', 'systemStatistics', 'topicStatistics'];
      arr.forEach(i => {
        localStorage.removeItem(i);
      });
      localStorage.setItem("AllQuestions",JSON.stringify(this.Questionsforquiz));
      this.router.navigate(['/quiz']);
      this.quizloader = false;
    },
    error => {
      this.errorHandler.handleError(error);
    })
  }

  gotoFocusboard(lessonid:any , title:any ,index:any, topicID: any, topicName: any): void{
    localStorage.setItem("lessonName", title);
    localStorage.setItem("lessonNumber", index);
    localStorage.setItem("topicID", topicID);
    localStorage.setItem("LessonID", lessonid);
    localStorage.setItem("CourseID", JSON.stringify(this.course.courseId));
    if(this.CourseDetailsFullArray){
      localStorage.setItem("CourseDetailsAPIResult",JSON.stringify(this.CourseDetailsFullArray));
    }

    this.course.courseTitle = this.course.courseTitle.replace(/\s/g, '-')
    title = title.replace(/\s/g, '-')
    title = title.replace("(", '-')
    title = title.replace(")", '-')
    topicName = topicName.replace(/\s/g, '-')
    topicName = topicName.replace("(", '-')
    topicName = topicName.replace(")", '-')

    this.router.navigate(['focusboard',this.course.courseTitle, title, topicName ]);
  }


}
