import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-linked-uworld-questions',
  templateUrl: './linked-uworld-questions.component.html',
  styleUrls: ['./linked-uworld-questions.component.scss'],
  providers: [MessageService]
})
export class LinkedUworldQuestionsComponent implements OnInit {
  systemQuestionId: any;
  uworldQuestionId: any;
  message = ''
  linkedQuestions: any = [];
  messageColor = true;
  editLinkedModal = false;
  deleteModal = false;
  editSystemQuestionId: any;
  editUworldQuestionId: any;

  constructor(private adminService: AdminService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getLinkedQuestions();
  }

  getLinkedQuestions() {
    this.adminService.getUWorldLinkedQuestions().subscribe((res: any) => {
      this.linkedQuestions = res.linkedQuestions;
    })
  }

  linkQuestions() {
    this.message = ''
    if (this.systemQuestionId && this.uworldQuestionId) {
      let data = {
        postId: this.systemQuestionId,
        uworldId: this.uworldQuestionId
      }
      if (this.linkedQuestions.findIndex((item: any) => item.PostID === data.postId || item.UWorldID === data.uworldId) !== -1) {
        this.messageColor = false;
        this.message = 'Post ID or UWorld ID is already linked.';
      } else {
        this.adminService.linkQuestionIDs(data).subscribe((res: any) => {
          if(res.status) {
            this.messageColor = true;
            this.message = res.message;
            this.systemQuestionId = '';
            this.uworldQuestionId = '';
            setTimeout(() => {
              this.message = '';
            }, 2000)
            this.getLinkedQuestions();
          }
          else {
            this.message = res.message;
            this.messageColor = false;
          }
        })
      }
    } else { 
      this.message = "Kindly add both Question IDs"
      this.messageColor = false;
    }
  }

  openEditModal(data: any) {
    this.message = '';
    this.editSystemQuestionId = data.PostID;
    this.editUworldQuestionId = data.UWorldID;
    this.editLinkedModal = true;
  }

  openDeleteModal(data: any) {
    this.message = '';
    this.editSystemQuestionId = data.PostID;
    this.editUworldQuestionId = data.UWorldID;
    this.deleteModal = true;
  }

  updateLinkedQuestion() {
    let data = {
      postId: this.editSystemQuestionId,
      uworldId: this.editUworldQuestionId
    }
    this.adminService.updateUWorldLinkedQuestions(data).subscribe((res: any) => {
      if(res.status) {
        this.messageColor = true;
        this.message = res.message;
        this.systemQuestionId = '';
        this.uworldQuestionId = '';
        setTimeout(() => {
          this.message = '';
          this.editLinkedModal = false;
        }, 2000)
        this.getLinkedQuestions();
      }
      else {
        this.message = res.message;
        this.messageColor = false;
      }
    })
  }

  deleteLinkedQuestion() {
    let data = {
      postId: this.editSystemQuestionId,
      uworldId: this.editUworldQuestionId
    }
    this.adminService.deleteUWorldLinkedQuestions(data).subscribe((res: any) => {
      if(res.status) {
        this.deleteModal = false;
        this.messageService.add({severity:'success', summary: 'Deletion Confirmation', detail: 'Linked Question Deleted Successfully'});
        this.getLinkedQuestions();
      }
    })
  }
}
