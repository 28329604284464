<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<div class="instructors_root">
    <div class="instructors_parent">
        <div class="instructors_content">
            <h1>Manage Instructors</h1>
            <div class="instructor_button">
                <div class="add_button" (click)="openAddModal()">
                    <i class="pi pi-plus"></i>
                    <span>Add Instructor</span>
                </div>
            </div>
            <div class="instructor_table">
                <p-table id="instructorTable" #dt [value]="instructors" [globalFilterFields]="['FullName']"
                responsiveLayout="scroll" [paginator]='true' [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rows]="rows">
    
                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search by Name" />
                            </span>
                        </div>
                    </ng-template>
                
                    <ng-template pTemplate="header" class="table_header" styleClass="myTable" class="some-custom-class-name" pResizableColumn>
                        <tr>
                            <th pSortableColumn="id" class="id_prevTest_table" [ngStyle]="{'width':'40px'}">#</th>
                            <th pSortableColumn="FullName" [ngStyle]="{'width':'130px'}">Full Name</th>
                            <th pSortableColumn="Email" [ngStyle]="{'width':'160px'}">Email</th>
                            <th pSortableColumn="Permissions" [ngStyle]="{'width':'220px'}">Permissions</th>   
                            <th pSortableColumn="Permissions" [ngStyle]="{'width':'80px'}">Actions</th>        
                        </tr>
                        
                    </ng-template>
                    <ng-template pTemplate="body" let-instructor let-i="rowIndex" class="table_body">
                        <tr>
                            <td class="id_prevTest_table" [ngStyle]="{'width':'40px'}">{{i+1}}</td>
                            <td class="name" [ngStyle]="{'width':'130px'}">{{instructor.FullName}}</td>
                            <td class="email" [ngStyle]="{'width':'160px'}">{{instructor.Email}}</td>
                            <td class="permissions" [ngStyle]="{'width':'220px'}">{{instructor.Permissions.length > 0 ? instructor.Permissions : '-'}}</td>
                            <td class="actions" [ngStyle]="{'width':'80px'}">
                                <i (click)="openEditModal(i)" class="pi pi-pencil" style="cursor: pointer;"></i>
                                <i (click)="openDeleteModal(i)" class="pi pi-trash" style="cursor: pointer; margin-left: 1.25rem;"></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Add Instructor" [(visible)]="addInstructorModal" [modal]="true" [closable]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
  <div class="instructor_form">
    <form [formGroup]="userForm">
        <div class="form_field">
            <label>Full Name</label>
            <input type="text" placeholder="Enter Full Name" required="required" formControlName="fullName">
        </div>
        <div class="form_field">
            <label>Email</label>
            <input type="email" placeholder="Enter Email" required="required"  formControlName="email">
            <div class="error_message">
                <span *ngIf="userForm.get('email').hasError('email')">Email address is not valid.</span>
            </div>
        </div>
        <div class="form_field">
            <label>Password</label>
            <input type="password" placeholder="Enter Password" required="required"  formControlName="password">
        </div>
        <div class="form_field">
            <label>Permissions</label>
            <div class="item d-flex align-items-center" *ngFor="let permission of permissions; let i = index;">
                <input id="checkbox{{i}}" type="checkbox" (change)="setPermission($event, i)">
                <label>{{ permission.title }}</label>
            </div>
        </div>
    </form>
    <div class="page-loader" *ngIf="modalLoader">
        <div class="loader"></div>
    </div>
    <div class="buttons" *ngIf="!modalLoader">
        <button (click)="addInstructorModal = false;">Cancel</button>
        <button (click)="addInstructor()" [disabled]="userForm.invalid">Save</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit Instructor" [(visible)]="editInstructorModal" [modal]="true" [closable]="true" [style]="{width: '30vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
  <div class="instructor_form">
    <form [formGroup]="editForm">
        <div class="form_field">
            <label>Full Name</label>
            <input type="text" placeholder="Enter Full Name" required="required" formControlName="fullName">
        </div>
        <div class="form_field">
            <label>Email</label>
            <input type="text" placeholder="Enter Email" required="required" formControlName="email">
            <div class="error_message">
                <span *ngIf="emailExists">Email already exists.</span>
                <span *ngIf="userForm.get('email').hasError('email')">Email address is not valid.</span>
            </div>
        </div>
        <div class="form_field">
            <label>Permissions</label>
            <div class="item d-flex align-items-center" *ngFor="let permission of permissions; let i = index;">
                <input id="checkbox{{i}}" type="checkbox" (change)="setPermission($event, i)">
                <label>{{ permission.title }}</label>
            </div>
        </div>
    </form>
    <div class="page-loader" *ngIf="modalLoader">
        <div class="loader"></div>
    </div>
    <div class="buttons" *ngIf="!modalLoader">
        <button (click)="editInstructorModal = false;">Cancel</button>
        <button (click)="checkDuplicateInstructor()" [disabled]="editForm.invalid">Save</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Confirmation" [(visible)]="deleteInstructorModal" [modal]="true" [closable]="false"
  [style]="{width: '330px'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <p class="p-m-0">Are you sure you want to delete this instructor?</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="deleteInstructor()" label="Yes" class="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="deleteInstructorModal = false" label="No"></p-button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>