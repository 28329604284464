import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { GlobalService } from 'src/app/shared/services/global.service';
import {AdminService} from '../../shared/services/admin.service';

@Component({
  selector: 'app-useractivity',
  templateUrl: './useractivity.component.html',
  styleUrls: ['./useractivity.component.scss']
})


export class UseractivityComponent implements OnInit {

  usersActivityData: any;
  pastPagesTempArray= [{name: "test"}];
  pastPagesArray!: any[];

    customers!: any[];

    totalRecords!: number;

    cols!: any[];

    loading!: boolean;
    
    representatives!: any[];

    NumofRows!: any;

    selectedNum = 5;
    duplicateSelectedNum = 5;

    UserName = "Ali Asghar";

    pastPage: any;

  constructor(private globalService: GlobalService,private adminService: AdminService) { }

  ngOnInit(): void {
    
    document.getElementById('eup-navbar-heading').innerText = 'Dashboard';
    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
    
    this.getUserActivityData();


    this.NumofRows = [
      {numOfRows: 5},
      {numOfRows: 10},
      {numOfRows: 15},
      {numOfRows: 20},
      {numOfRows: 25}
  ];


    this.representatives = [
      {name: "Amy Elsner", image: 'amyelsner.png'},
      {name: "Anna Fali", image: 'annafali.png'},
      {name: "Asiya Javayant", image: 'asiyajavayant.png'},
      {name: "Bernardo Dominic", image: 'bernardodominic.png'},
      {name: "Elwin Sharvill", image: 'elwinsharvill.png'},
      {name: "Ioni Bowcher", image: 'ionibowcher.png'},
      {name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
      {name: "Onyama Limba", image: 'onyamalimba.png'},
      {name: "Stephen Shaw", image: 'stephenshaw.png'},
      {name: "Xuxue Feng", image: 'xuxuefeng.png'}
  ];

  this.customers = [
    {name: "Amy Elsner" , email: "abcd@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk/home", PastPages: [{name: "edlink.ord.uk/home" , value: 1},{name: "edlink.ord.uk/aboutandabout" , value: 2}], visits: 3 , ActiveVisiors: 11},
    {name: "Amy B" , email: "Amy@gmail.com", IPAddress: "192.897.555.1", browser: "Opera", Device: "Macbook Air", CurrentPage: "-", PastPages: [{name: "edlink.ord.uk/home" , value: 1},{name: "edlink.ord.uk/about" , value: 2}], visits: 4 , ActiveVisiors: 7},
    {name: "Amy Elsa" , email: "hello@gmail.com", IPAddress: "192.897.547.21", browser: "Chrome", Device: "Hp Envy", CurrentPage: "www.medlink.ord.uk/about", PastPages: "www.medlink.ord.uk/about", visits: 5 , ActiveVisiors: 13},
    {name: "john doe" , email: "Abcd@gmail.com", IPAddress: "192.891.587.21", browser: "Safari", Device: "Dell Inspiron", CurrentPage: "-", PastPages: "www.medlink.ord.uk/about", visits: 9 , ActiveVisiors: 15},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.899.577.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk/home", PastPages: "edlink.ord.uk/home", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "john@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk/homr", PastPages: "edlink.ord.uk/homr", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk/home", PastPages: "edlink.ord.uk/home", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10},
    {name: "Amy Elsner" , email: "abc@gmail.com", IPAddress: "192.897.567.21", browser: "Chrome", Device: "Macbook Pro", CurrentPage: "www.medlink.ord.uk", PastPages: "www.medlink.ord.uk", visits: 4 , ActiveVisiors: 10}
]



  this.loading = false;
  this.pastPage = this.customers[0].pastPages;
  }
  selectNum(a: any): void{
    // this.selectedNum = this.duplicateSelectedNum;
    this.selectedNum = a.numOfRows;
  }

  getUserActivityData(): void{
    this.loading = true;
    this.adminService.getUserActivityData().subscribe((res: any) => {
      this.usersActivityData = res.response;
      this.usersActivityData.forEach((element:any) => {
        const temp = JSON.parse(element.past_pages);
        this.pastPagesTempArray = [];
        for(let i=0; i<temp.length; i++){
          this.pastPagesTempArray.push({name:temp[i]});
        }
        element.past_pages = this.pastPagesTempArray;
          
      });


      // for(let k=1; k< this.usersActivityData.length ; k++){
      //   for(let j=0 ; j< this.usersActivityData[k].length; j++){
      //     this.pastPagesArray[k].push({name: this.pastPagesTempArray[k]})
      //   }
      // }
      
      this.loading = false;
    })
  }

  // LAZY DEFAULT FUNCTIONS
  loadCustomers(event: LazyLoadEvent) {  
    
        // this.customerService.getCustomers({lazyEvent: JSON.stringify(event)}).then(res => {
        //     this.customers = res.customers;
        //     this.totalRecords = res.totalRecords;
        //     this.loading = false;
        // })
        this.loading = false;
    
}


}
