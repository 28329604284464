<div class="usmle_criteria_root">
  <div class="usmle_criteria_parent">
    <div class="usmle_criteria_content">
      <div class="header">
        <img src="../../../../../assets/images/AI_Test_Analysis/chevron_left.svg" (click)="backToMainPage()">
        <h4>USMLE Criteria</h4>
      </div>
      <div class="content">
        <h6>USMLE Syllabus comprises mainly the subjects learned by students during the first two years of their medical school. Here is the subject-specific information along with the weightage range.</h6>
        <div class="weightages">
          <div class="item" *ngFor="let criteria of usmleCriteria">
            <div class="header">
              <h5>Assigned Weightage: <span>{{ criteria.weightage }}</span></h5>
            </div>
            <div class="content">
              <ul>
                <li *ngFor="let subject of criteria.subjects">{{ subject }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>