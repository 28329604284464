import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { QuizServiceService } from 'src/app/shared/services/quiz-service.service';
import { AutoLogout } from 'src/app/models/globals';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import {GlobalService} from '../../../shared/services/global.service'
import {Router} from "@angular/router";
import { demo_variables } from 'src/app/models/demo_global';



@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {
  // @ts-ignore
  subscriptions: Subscription = [];
  quizId: any;
  displayModal = false;
  editNoteValue: any;
  editNoteId: any;
  notes = [
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},    
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},  
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},  
    {quiz_name: 'Demo Notes',question_id: 1, notes: 'Free trial is for testing, saving text is available only for premium users.', quiz_id: 1, System: 'MAcOS'},  
  ]

  NumofRows = [
    {numOfRows: 5},
    {numOfRows: 10},
    {numOfRows: 15},
    {numOfRows: 20},
    {numOfRows: 25}
];

  selectedNumber: any;
  innerWidth: any;
  isdemo =false;
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if(this.innerWidth < 750){
    document.getElementById('eup-navbar-heading').innerText=''
  }
}

  constructor(private globalService: GlobalService,private errorHandler: ErrorHandlerService,private adminService: AdminService ,private quizService: QuizServiceService,private router: Router) {
    document.addEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = function(e) {
      // @ts-ignore
      if(event.keyCode == 123) {
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'A'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'F'.charCodeAt(0)){
      return false;
      }
      if(e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)){
      return false;
      }
      }
   }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
    document.removeEventListener('contextmenu', this.removeMouseOptions);
    document.onkeydown = null
  }
  removeMouseOptions(e: any){
    e.preventDefault();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth>750){
    document.getElementById('eup-navbar-heading').innerText = 'Notes';
  }else{
    document.getElementById('eup-navbar-heading').innerText = '';
  }
    this.selectedNumber = 5
    if(localStorage.getItem('Id') != '-1'){
      this.getNotes()
    }else{
      this.isdemo = true;
    }
    // @ts-ignore
    this.subscriptions.push(
      this.adminService.compareToken(JSON.parse(localStorage.getItem("Id"))).subscribe((res: any) => {
        if(res.status){
        }else{
          this.errorHandler.AutoLogout();
        }
      },
        error => {
          this.errorHandler.handleError(error);
        })
    );

    this.globalService.tawkIO(localStorage.getItem('UserName'), localStorage.getItem('email'))
  }

  getNotes(){
    this.subscriptions.push(
      this.quizService.getNotes().subscribe((res: any) => {
          this.notes = res.notes;
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  selectNum(num: any): void{
    this.selectedNumber = num.numOfRows;
  }

  deleteNote(note: any): any{

    if(localStorage.getItem('Id') != '-1'){
    var noteId = note.ideup_user_notes;
    this.subscriptions.push(
      this.quizService.deleteNotes(noteId).subscribe((res: any) => {
          this.notes = res.notes;
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
    this.getNotes();
  }
  }

  editNote(note: any){

    this.editNoteValue = note.notes;
    this.editNoteId = note.ideup_user_notes;
    this.displayModal = true;

  }

  editNotes(): any{
    // Send Backend Request and make get data again and do displayModel = false;
    this.displayModal = false;
    var notes = {
      noteId: this.editNoteId,
      notes: this.editNoteValue
    }
    if(localStorage.getItem('Id') != '-1'){
    this.subscriptions.push(
      this.quizService.editNotes(notes).subscribe((res: any) => {
          this.notes = res.notes;
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
    this.getNotes();
  }
  }

getSpecificQuestion(questionsid:any, i: any, quizId)
{
  let viewMode = false;

  let data = {
    // @ts-ignore
    userId: JSON.parse(localStorage.getItem("Id")),
    questionId: questionsid,
    quizId: quizId
  }
  if(localStorage.getItem('Id') != '-1'){
    // this.loader =  true;
    let viewMode = true;
  this.subscriptions.push(
    this.quizService.getSpecificQuestion(data).subscribe((res: any) => {
      var submitteddata;
      if(res.data.question.submitData != null){
        submitteddata = JSON.parse(res.data.question.submitData)
        console.log(submitteddata)
        localStorage.setItem('SubmittedQuestions', JSON.stringify([submitteddata]))
    }
      localStorage.setItem("AllQuestions",JSON.stringify([res.data.question]));
          localStorage.setItem("quizScore", ('0'));
          localStorage.setItem("NotesArray", JSON.stringify([{note: 'dummy note' , questionId: -1},{note: this.notes[i].notes , questionId: this.notes[i].question_id}]));
          localStorage.setItem("quizTitle", this.notes[i].quiz_name);
          localStorage.setItem("QuizMode",'tutor');
          localStorage.setItem("quiz_time",'0');
          localStorage.setItem("QuizId",JSON.stringify(this.notes[i].quiz_id));
          localStorage.setItem("isTimed",JSON.stringify(false));
          localStorage.setItem("isResumed", JSON.stringify(1));
          localStorage.setItem("viewMode", JSON.stringify(true));
          localStorage.setItem("currentIndexId", JSON.stringify(0));
          localStorage.setItem("flaggedQuestion", JSON.stringify([]));
          this.router.navigate(['/quiz'])

          },
          (        err: any) => {
            AutoLogout(err);
          }
        )
      );
        }



}


}
