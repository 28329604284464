<div style="height: 100vh" *ngIf="loader">
<app-overlay ></app-overlay>
</div>
<app-overlayfullscreen *ngIf="quizloader"></app-overlayfullscreen>
<!-- Getting time mode and tutoe Exam Mode -->
<p-dialog header="Test Mode" [(visible)]="modeModel" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <div *ngIf="quizSuspended == 0 || quizSuspended == 2">
    <div class="mode_wrapper">
      <div *ngFor="let mode of TestMode" class="p-field-checkbox">
        <p-radioButton [inputId]="mode.key" name="mode" [value]="mode" [(ngModel)]="selectedMode"></p-radioButton>
        <label [for]="mode.key">{{mode.name}}</label>
    </div>
      
    </div>
    <div>
      <h5>Time Mode</h5>
      <p-inputSwitch [(ngModel)]="timeMode"></p-inputSwitch>
    </div>
  </div>
  <div *ngIf="quizSuspended == 1" style="color: rgb(236, 99, 99);">
    <h5>This quiz is Suspended Kindly Complete It.</h5>
  </div>
  <div class="mt-2" *ngIf="quizSuspended == 2">
    <h5> <i class="pi pi-info-circle" style="font-size: 1.4rem;"></i> Your previous quiz will be replaced by the new one if you proceed.</h5>
  </div>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="getQuiz(QuizId, quizTitle)" label="Take Quiz" styleClass="p-button-text" *ngIf="quizSuspended == 0 || quizSuspended == 2"></p-button>
        <p-button icon="pi pi-check" (click)="modeModel = false" label="Close" styleClass="p-button-text" *ngIf="quizSuspended == 1"></p-button>
        </ng-template>
</p-dialog>



<div class="eup-cov-root pt-3" *ngIf="!loader">
  <div class="eup-cov-parent">
    <div class="eup-cov-sub-parent">
      <div class="eup-cov-grid-wrapper">
        <div class="container">
          <div class="row ">
            <div class="col-md-12 ">
              <div class="eup-cov-content-wrapper">

                <div class="eup-cov-left-parent">
                  <div class="eup-cov-course-details">
                    <div class="eup-cov-details-banner-img text-center">
                      <img [src]="coursearray[0].courseImage" alt="" width="90%" >
                    </div>
                    <div class="eup-cov-course-intro">
                      <div class="eup-cov-course-overview-text">
                        <div class="eup-cov-course-ov-text-heading">
                          <h4>{{course.courseTitle}}</h4>
                        </div>
                        <div class="eup-cov-course-ov-content">
                          <p>{{course.courseIntro}}</p>
                        </div>
                      </div>
                      <div class="eup-cov-course-lessons-content">
                        <div class="eup-cov-course-ov-text-heading">
                          <h5>Course Content</h5>
                        </div>
                        <!-- Accordion From here -->
                        <div *ngFor="let course of CourseDetails; let i = index">
                        <p-accordion [multiple]="true" *ngIf="course.topics.length > 0">
                          <!-- (click)="getLessondetails(course.lesson.ID)" -->
                          <p-accordionTab header="Lesson {{i+1}}: {{course.lesson[0].post_title}}" >
                            <p-header>
                              <div class="knob_heading" style="display: flex; flex-direction: row; align-items: center;">
                              <span class="mr-1"><p-knob valueColor="#18c02f" rangeColor="#DCDCDC" [size]="20" [(ngModel)]="knobArray[i]" [readonly]="true" valueTemplate=""></p-knob></span> 
                              <span> Lesson {{i+1}}: {{course.lesson[0].post_title}}</span> 
                            </div>
                            </p-header>
                            
                              <ul *ngFor="let topic of course.topics">
                                <!--  -->
                                <li class="topicsnames" (click)="gotoFocusboard(course.lesson[0].ID,course.lesson[0].post_title , i+1 , topic.topic.ID, topic.topic.post_title)"><div [ngClass]="topic.topic.isCompleted == true?'Check_Square':'Hide_Check_Square'"><i class="fa fa-check-square mr-2" *ngIf="topic.topic.isCompleted == true"></i></div>{{topic.topic.post_title}}</li>
                              <div *ngFor="let quiz of topic.quizzes">
                                <ul>
                                <li class="topicQuizzes Quiz_names pl-2 pl-sm-3 pl-md-5" (click)="getQuizMode(quiz.ID, quiz.post_title)"><i class="fa fa-paste"></i> {{quiz.post_title}}</li>
                              </ul>
                              </div>
                              </ul>
                              
                              
                              <div *ngFor="let quiz of course.quizzes; let j = index">
                              <p class="font-weight-bold base-color1" *ngIf="j == 0" >QUIZZES</p>
                            </div>
                                <ul *ngFor="let quiz of course.quizzes; let k = index" >
                                  <li class="Quiz_names ml-4" (click)="getQuizMode(quiz.ID, quiz.post_title)">{{quiz.post_title}}</li>
                                </ul>                                                         
                                  <!-- <button class="btn btn-primary pl-3 pr-3" >Get Details</button> -->
                          </p-accordionTab>
                          
                      </p-accordion>
                      <!-- <div class="title" *ngIf="course.topics.length == 0" (click)="gotoFocusboard(course.lesson[0].ID,course.lesson[0].post_title , i+1 , -1)">
                        Lesson {{i+1}}: {{course.lesson[0].post_title}}
                      </div> -->
                      <div class="title" *ngIf="course.topics.length == 0" (click)="gotoFocusboard(course.lesson[0].ID,course.lesson[0].post_title , i+1 , -1, 'no-topic')">
                        <div class="knob_heading" style="display: flex; flex-direction: row; align-items: center;">
                          <span class="mr-1"><p-knob valueColor="#18c02f" rangeColor="#DCDCDC" [size]="20" [(ngModel)]="knobArray[i]" [readonly]="true" valueTemplate=""></p-knob></span> 
                          <span> Lesson {{i+1}}: {{course.lesson[0].post_title}}</span> 
                        </div>
                      </div>
                    </div>
                        <!-- Testing -->
                        <!-- <div class="eup-cov-lesson-wrapper">
                          <div class="eup-cov-lesson-single-wrapper" *ngFor="let lesson of lessons; let i = index">
                            <div class="eup-cov-lesson-single" >
                              <div class="eup-cov-lesson-accordion-text row">
                                <div class="eup-cov-lesson-name col-10" (click)="gotoFocusboard(lesson.ID,lesson.post_title , i+1)">
                                  <h6>Lesson {{i+1}}: {{lesson.post_title}}</h6>
                                </div>
                                <div class="eup-cov-lesson-toggle-btn col-2">
                                <span>
                                  <i class="fa fa-angle-down"></i>
                                </span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div> -->



                      </div>
                    </div>
                  </div>


                </div>

                <div class="eup-cov-right-parent">
                  <div class="eup-cov-course-summary-wrapper">
                    <div class="eup-cov-course-lessons-summary-wrapper">
                      <div class="eup-cov-course-lessons-summary">
                        <div class="eup-cov-course-lessons-progress-bar">
                          <p-progressBar [value]="percentage1"></p-progressBar>
                          <p>{{percentage1}}% Completed</p>
                        </div>
                        <div class="eup-cov-course-lessons-outline" *ngFor="let lesson of lessons; let i =index">
                          <p> Lesson {{i+1}}: {{lesson.post_title}}</p>
                        </div>
                      </div>
                    </div>
                    
                    <!-- <div class="eup-cov-course-quiz-summary-wrapper">
                      <div class="eup-cov-course-quiz-summary">
                          <div class="eup-cov-course-quiz-heading">
                            <p>Quizzes</p>
                          </div>
                          <div class="eup-cov-course-lessons-outline " *ngFor="let quiz of coursequizzes; let i =index">
                            <p (click)="getQuiz(quiz.ID)" class= "quiz-list-sidebar base-color1 " > Quiz {{i+1}}: {{quiz.post_title}}</p>

                          </div>
                      </div>
                    </div> -->

                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
